import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { VideoModel } from '../Interfaces/documentLibrary/VideoModel';
import { ITicketAddMessage } from '../Interfaces/helpdesk/ITicketAddMessage';
import { ITicketData } from '../Interfaces/helpdesk/ITicketData';
import { IValidatedUserPreference } from '../Interfaces/identitymanagement/IValidatedUserPreference';
import { IEmployeePayDataSearch } from '../Interfaces/IEmployeePayDataSearch';
import { IPayroll } from '../Interfaces/paymanager/IPayroll';
import { IEmployeePersonalInfo } from '../Interfaces/user/IEmployeePersonalInfo';
import { IEmployeeViewMessages } from '../Interfaces/user/IEmployeeViewMessages';
import { IPasswordGroup } from '../Interfaces/user/IPasswordGroup';
import { ISecurityQuestionsGroup } from '../Interfaces/user/ISecurityQuestions';
import { DateService } from '../shared/services/date.service';
import { EmployeeDocumentModel } from './../Interfaces/documentLibrary/employeeDocumentModel';
import { BaseService } from './baseService.service';
import { RequestType } from './requestType';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  UserProfileResposiveHeight: any;
  tenant: string = window.sessionStorage.getItem('tenant');
  relativeURL = 'Employee/';
  relativeURLV2 = `V2/Employee/`;
  relativeURLIdentityV2 = `V2/IdentityManagement/`;

  ticketData: ITicketData[];
  messageList: any;
  doRefesh = new BehaviorSubject(false);
  employeeTickets: any[] = [];
  unReadMessagesCount = 0;
  doRefreshChange = this.doRefesh.asObservable();

  showProfileSecurity = false;

  constructor(private baseService: BaseService, private securityService: SecurityService, private dateService: DateService) {

  }

  setRefreshValue(refresh: boolean) {
    this.doRefesh.next(refresh);
  }
  // Help Tickets
  setTicketData(fromData: any) {
    this.ticketData = fromData;
  }
  getTicketData(): any {
    return this.ticketData;
  }

  setMessageList(messageList: any) {
    this.messageList = messageList;
  }
  getMessageList() {
    return this.messageList;
  }

  GetEmployeeDetails(): Observable<IEmployeePersonalInfo> {
    return this.baseService.Execute<IEmployeePersonalInfo>(RequestType.GET,
      this.relativeURL + this.tenant, 'baseEmployeeEndpoint', null);
  }

  UpdateEmployeeDetails(data: IEmployeePersonalInfo): Observable<IEmployeePersonalInfo> {
    return this.baseService.Execute<IEmployeePersonalInfo>(RequestType.POST,
      this.relativeURL + this.tenant, 'baseEmployeeEndpoint', data);
  }

  GetSecurityQuestions(): Observable<ISecurityQuestionsGroup> {
    return this.baseService.Execute<ISecurityQuestionsGroup>(RequestType.GET,
      this.relativeURL + `security/questions/${this.tenant}`, 'baseEmployeeEndpoint', null);
  }

  // removed as part of security fixes
  // UpdateSecurityQuestion(data: ISecurityQuestionsGroup, staffNumber: string = null): Observable<boolean> {
  //   staffNumber = staffNumber ? staffNumber : this.securityService.securityObject.staffNumber;
  //   return this.baseService.Execute<boolean>(RequestType.POST,
  //     this.relativeURL + `security/questions/${this.tenant}/${staffNumber}`, 'baseEmployeeEndpoint', data);
  // }

  UpdateSecurityQuestionForRegistrationV2(data: ISecurityQuestionsGroup, staffNumber: string = null): Observable<boolean> {
    staffNumber = staffNumber ? staffNumber : this.securityService.securityObject.staffNumber;
    return this.baseService.Execute<boolean>(RequestType.POST,
      this.relativeURLIdentityV2 + `security/questions/${this.tenant}?staffNumber=${encodeURIComponent(staffNumber)}`,
      'baseIdentityManagementEndpoint', data);
  }

  UpdateSecurityQuestionV2(data: ISecurityQuestionsGroup): Observable<boolean> {
    return this.baseService.Execute<boolean>(RequestType.POST,
      this.relativeURLV2 + `security/questions/${this.tenant}`, 'baseEmployeeEndpoint', data);
  }

  UpdatePassword(data: IPasswordGroup): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `updatepassword/${this.tenant}`, 'baseEmployeeEndpoint', data);
  }

  GetEmployeeDashboard(startYear: string = null, endYear: string = null, taxPeriod: string = null): Observable<any> {
    let relativeURL = '';
    if (startYear !== null && endYear !== null && taxPeriod !== null) {
      relativeURL = this.relativeURL + `dashboard/${this.tenant}/${taxPeriod}/${startYear}/${endYear}`;
    } else {
      relativeURL = this.relativeURL + `dashboard/${this.tenant}`;
    }
    return this.baseService.Execute<any>(RequestType.GET, relativeURL, 'baseEmployeeEndpoint', null);
  }

  GetEmployeeMessages(): Observable<IEmployeeViewMessages[]> {
    const currentDate = this.dateService.getLocalFormatedDate(new Date());
    return this.baseService.Execute<IEmployeeViewMessages[]>(RequestType.POST,
      this.relativeURL + `messaging/${this.tenant}`, 'baseEmployeeEndpoint', {date: currentDate});
  }

  UpdateMessageMappingStatus(messageId: number, messageTypeId: number): Observable<IPasswordGroup> {
    return this.baseService.Execute<IPasswordGroup>(RequestType.POST,
      this.relativeURL + `messaging/${this.tenant}/mapping/${messageId}/${messageTypeId}`, 'baseEmployeeEndpoint', null);
  }

  IsUsernameUnique(userName, staffNumber): Observable<any> {
    const details = { name: userName, staffNumber };
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `isusernameunique/${this.tenant}`, 'baseEmployeeEndpoint', details);
  }

  getEvents(selectedPayrollId: number): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET,
      this.relativeURL + `Calendar/Events/${this.tenant}/${selectedPayrollId}`, 'baseEmployeeEndpoint', null);
  }

  getEmployeeClientVideos(): Observable<VideoModel[]> {
    return this.baseService.Execute<VideoModel[]>(RequestType.GET,
      this.relativeURL + `videos/${this.tenant}`, 'baseEmployeeEndpoint', null);
  }

  getEmployeeDocumentLibrary(): Observable<EmployeeDocumentModel[]> {
    const currentDate = this.dateService.getLocalFormatedDate(new Date());
    return this.baseService.Execute<EmployeeDocumentModel[]>(RequestType.POST,
      this.relativeURL + `documents/${this.tenant}`, 'baseEmployeeEndpoint', {date: currentDate});
  }

  changeDocumentIsNewstatus(docId: string): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `documents/${this.tenant}/mapping/${docId}`, 'baseEmployeeEndpoint', null);
  }

  GetTaxSectionComponentData(): Observable<any> {
    return this.baseService.Execute<any>(
      RequestType.GET, this.relativeURL + `taxsectioncomponentdata/${this.tenant}`, 'baseEmployeeEndpoint', null);
  }

  GetPayrollMetadata(): Observable<IPayroll[]> {
    return this.baseService.Execute<IPayroll[]>(RequestType.GET,
      this.relativeURL + `getpayrollmetadata/${this.tenant}`, 'baseEmployeeEndpoint', null);
  }

  getEmployeeTickets(staffNumber: any): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET,
      this.relativeURL + `ticket/${this.tenant}/${staffNumber}`, 'baseEmployeeEndpoint', null);
  }

  addTicketRating(ticket: any): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `ticket/rating/${this.tenant}/${ticket.id}/${ticket.rating}`, 'baseEmployeeEndpoint', {});
  }

  deleteTicketAttachment(ticketAttachmentId: number): Observable<any> {
    return this.baseService.Execute<any>(RequestType.DELETE,
      this.relativeURL + `ticket/attachment/${this.tenant}/${ticketAttachmentId}`, 'baseEmployeeEndpoint', null);
  }

  addAttachmentsToTicket(ticketId: any, ticketAttachments: FormData): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `ticket/attachments/${this.tenant}/${ticketId}`, 'baseEmployeeEndpoint', ticketAttachments);
  }

  unresolveTicket(ticketId: any): Observable<boolean> {
    const userRoleId = this.securityService.securityObject.currentRoleId;
    return this.baseService.Execute<boolean>(RequestType.GET,
      this.relativeURL + `ticket/unresolve/${this.tenant}/${ticketId}/${userRoleId}`, 'baseEmployeeEndpoint', null);
  }

  getDocumentTypes(): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET, `employee/calendar/documents/${this.tenant}`, 'baseEmployeeEndpoint', null);
  }

  getClientVideoLibrary(): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET, `employee/clientVideos/${this.tenant}`, 'baseEmployeeEndpoint', null);
  }

  getRatings(): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET,
      this.relativeURL + `tickets/ratings/${this.tenant}`, 'baseEmployeeEndpoint', null);
  }

  GetTicketDetails(ticketId: number): Observable<any[]> {
    const staffNumber = this.securityService.securityObject.staffNumber;
    const userRoleId = this.securityService.securityObject.currentRoleId;
    return this.baseService.Execute<any[]>(RequestType.GET,
      this.relativeURL + `ticket/${this.tenant}/${ticketId}/${staffNumber}/${userRoleId}`, 'baseEmployeeEndpoint', null);
  }

  getEmployeeTicketMessages(ticketId: any): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET,
      this.relativeURL + `ticket/message/${this.tenant}/${this.securityService.securityObject.staffNumber}/${ticketId}`,
      'baseEmployeeEndpoint', null);
  }

  addTicketMessage(ticketMessage: ITicketAddMessage, currentTicketStatus: string): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `ticket/message/${this.tenant}/${currentTicketStatus}`, 'baseEmployeeEndpoint', ticketMessage);
  }

  updateTicketStatus(ticketId: any, ticketStatus: any, messageData: any): Observable<any> {
    const staffNumber = this.securityService.securityObject.staffNumber;
    const userRoleId = (this.securityService.securityObject.claims && this.securityService.securityObject.claims.employee &&
      this.securityService.securityObject.claims.employee.roleId) ? this.securityService.securityObject.claims.employee.roleId : 'none';
    let messageObj: any = {};
    if (messageData != null) {
      messageObj = {
        messageData,
        userRoleId
      };
    } else {
      messageObj = {
        messageData: null,
        userRoleId: this.securityService.securityObject.currentRoleId
      };
    }
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `ticket/change/status/${this.tenant}/${staffNumber}/${ticketId}/${ticketStatus}`,
      'baseEmployeeEndpoint', messageObj);
  }

  GetTicketTypes(): Observable<IEmployeePayDataSearch[]> {
    return this.baseService.Execute<IEmployeePayDataSearch[]>(RequestType.GET,
      this.relativeURL + `tickettypes/${this.tenant}/`, 'baseEmployeeEndpoint', null);
  }

  AddNewTicket(ticketObject: any, userRoleId?: number): Observable<IValidatedUserPreference> {
    userRoleId = (userRoleId) ? userRoleId : this.securityService.securityObject.currentRoleId;
    return this.baseService.Execute<IValidatedUserPreference>(RequestType.POST,
      this.relativeURL + `ticket/add/${this.tenant}/${userRoleId}/${this.securityService.securityObject.name}`,
      'baseEmployeeEndpoint', ticketObject);
  }

  getCompanyVideos(): Observable<VideoModel[]> {
    return this.baseService.Execute<VideoModel[]>(RequestType.GET,
      this.relativeURL + `videos/${this.tenant}`, 'baseEmployeeEndpoint', null);
  }

  changeVideoLibraryNewStatus(videoLibraryId: number): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `videos/${this.tenant}/mapping/${videoLibraryId}`, 'baseEmployeeEndpoint', null);
  }
}
