<div class="position-relative">
    <div class="PopCloseIcon" (click)="close()">
        <i class="material-icons">close</i>
    </div>
</div>
<div class="row">
    <div class="col-md-12 col-lg-12 ">
        <div class="page_title text-uppercase orange_clr font17 font400 d-inline">
            Profile Image
        </div>
    </div>
</div>
<div class="line dark mt15 mb10 extra_minus"></div>
<div class="row mb10">
    <div class="col-md-12 col-lg-12 text-center">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [imageURL]="imageURL" [maintainAspectRatio]="true"
            [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="1 / 1" [resizeToWidth]="256"
            [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
            [transform]="transform" [alignImage]="'left'" format="jpeg" (imageCropped)="imageCropped($event)"
            (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
</div>
<div class="row">
    <div class="col-md-12 text-center">
        <div class="ImageTools">
            <ul class="ImageOptions">
                <li>
                    <p class="font12 text-center mb0">{{zoomLevel.value + '%'}}</p>
                    <input id="myinput" type="range" min="50" max="150" step="25" [value]="zoomPt" #zoomLevel
                        (change)="zoom(zoomLevel.value)" matTooltip="Zoom" matTooltipClass="custom-tooltip">
                </li>
                <li (click)="rotateLeft()" class="rotateH" matTooltip="Rotate Left" matTooltipClass="custom-tooltip">
                    &#10226;
                </li>
                <li (click)="rotateRight()" class="rotateV" matTooltip="Rotate Right" matTooltipClass="custom-tooltip">
                    &#10227;
                </li>
                <li (click)="flipVertical()" class="flipV" matTooltip="Flip Vertical" matTooltipClass="custom-tooltip">
                    <mat-icon class="cursor-pointer wizardsvgclass svgIcon22" svgIcon="icon_flip_V"></mat-icon>
                </li>
                <li (click)="flipHorizontal()" class="flipH" matTooltip="Flip Horizontal"
                    matTooltipClass="custom-tooltip">
                    <mat-icon class="cursor-pointer wizardsvgclass svgIcon22" svgIcon="icon_flip_H"></mat-icon>
                </li>
                <li (click)="toggleContainWithinAspectRatio()" class="aspectR pb3"
                    matTooltip="{{ (containWithinAspectRatio?'Stretch':'Fit')  }}" matTooltipClass="custom-tooltip">
                    <span class="pb3 font14"
                        [ngClass]="{'pl3 pr4': !containWithinAspectRatio}">{{containWithinAspectRatio?'&#8596;':'&#8597;'}}</span>
                </li>
                <li (click)="resetImage()" class="reset text-danger" matTooltip="Reset"
                    matTooltipClass="custom-tooltip">
                    &#10008;
                </li>
                <li>
                    <mat-icon class="cursor-pointer wizardsvgclass svgIcon22" svgIcon="icon_uploadImage"
                        matTooltip="Upload Image" matTooltipClass="custom-tooltip"
                        (click)="employeePicture.click()"></mat-icon>
                </li>
            </ul>
            <input type="file" id="employeePicture" #employeePicture accept="image/*" style="display:none"
                (change)="pictureChanged($event)">
        </div>
    </div>
</div>
<div class="line dark mt15 mb10 extra_minus"></div>
<div class="row">
    <div class="col-md-12 text-right">
        <button class="btn btn-success btn-round btn-fab btn-sm mt0 mb0" rel="tooltip" type="button"
            ng-reflect-klass="btn btn-success btn-round btn-" (click)="confirmCrop()">
            <i class="material-icons">check</i>
        </button>
    </div>
</div>