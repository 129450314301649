import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { AppMaterialModule } from '../app-material.module';
import {
  RoleActivationAuthorizationComponent,
} from '../role-activation/role-activation-authorization/role-activation-authorization.component';
import { RoleActivationComponent } from '../role-activation/role-activation.component';
import { DisplayMessageialogComponent } from '../shared/dialog/display-message-dialog/display-message-dialog.component';
import { SideMenuFilterPipe } from './../shared/pipes/side-menu-filter-pipe.pipe';
import { SidebarComponent } from './sidebar.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false
};
@NgModule({
  imports: [RouterModule, CommonModule, AppMaterialModule, PerfectScrollbarModule],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
  ],
  declarations: [
    SidebarComponent,
    RoleActivationComponent,
    RoleActivationAuthorizationComponent,
    SideMenuFilterPipe
  ],
  exports: [
    SidebarComponent
  ],
  entryComponents: [
    RoleActivationComponent,
    RoleActivationAuthorizationComponent,
    DisplayMessageialogComponent
  ]
})

export class SidebarModule { }
