import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { IUserNotifications } from '../../Interfaces/IUserNotifications';
import { BaseService } from '../../services/baseService.service';
import { RequestType } from '../../services/requestType';
import { SecurityService } from '../../services/security.service';
import { DateService } from './date.service';

@Injectable()
export class UtilityService {

  tenant: string = window.sessionStorage.getItem('tenant');

  isFilterOn = false;
  isEmployeeDashboard = false;
  currentPayslipData: any;
  isEmployee = false;
  isHelpdeskManager = false;
  documentFieldLabelMapping: any;
  showFilterData = false;
  title = '';
  userNotifications: IUserNotifications;
  totalNotifications = 0;
  hasAccessToCalendar = false;
  hasAccessToDouments = false;
  hasAccessToMessages = false;
  hasAccessToVideos = false;
  showSpinner = true;
  checkNotification = true;
  rectentPayslipStatus: any;
  releaseNotificationDetails: any;
  stepsCompleted: any;
  newFeaturesViewed: boolean = false;

  constructor(private baseService: BaseService, private securityService: SecurityService, private dateService: DateService) {
    this.isEmployee = this.securityService.securityObject.currentRoleId === 5 ? true : false;
    this.isHelpdeskManager = this.securityService.securityObject.currentRoleId === 3 ? true : false;
  }

  checkRoles() {
    this.isEmployee = this.securityService.securityObject.currentRoleId === 5 ? true : false;
    this.isHelpdeskManager = this.securityService.securityObject.currentRoleId === 3 ? true : false;
  }

  setFilterOn(tooltip: string = '') {
    this.isFilterOn = true;
    this.title = tooltip;
  }

  getFilterOn() {
    return this.isFilterOn;
  }

  compareFields(): (a: any, b: any) => number {
    return (n1, n2) => {
      if (n1 > n2) {
        return 1;
      }
      if (n1 < n2) {
        return -1;
      }
      return 0;
    };
  }

  setTenant() {
    this.securityService.setTenant();
  }

  IsStaffNumberSet() {
    return this.securityService.securityObject.staffNumber != null && this.securityService.securityObject.staffNumber !== '';
  }
  notificationCount() {
    this.getNotifications(this.tenant).subscribe(userNotifications => {
      this.userNotifications = userNotifications;

      if (userNotifications) {
        this.totalNotifications = 0;
        if (this.hasAccessToCalendar) {
          this.totalNotifications += this.userNotifications.calendarEventCount;
        }
        if (this.hasAccessToMessages) {
          this.totalNotifications += this.userNotifications.messagesCount;
        }
        if (this.hasAccessToDouments) {
          this.totalNotifications += this.userNotifications.documentsCount;
        }
        if (this.hasAccessToVideos) {
          this.totalNotifications += this.userNotifications.videosCount;
        }
        this.userNotifications.remindersCount = this.userNotifications.remindersCount ?
          this.userNotifications.remindersCount : 0;
        if (this.isHelpdeskManager) {
          this.totalNotifications = this.userNotifications.remindersCount;
        }
      }
    });
  }

  public getNotifications(_tenant?: string): Observable<IUserNotifications> {
    const itemToReturn = new Observable<IUserNotifications>();
    const tenant = window.sessionStorage.getItem('tenant') ? window.sessionStorage.getItem('tenant') : _tenant;
    const relativeURL = 'Employee/GetNotifications/' + tenant;
    const staffNumber = this.securityService.securityObject.staffNumber;
    if (staffNumber) {
      const currentDate = this.dateService.getLocalFormatedDate(new Date());
      return this.baseService.Execute<IUserNotifications>(RequestType.POST, relativeURL, 'baseEmployeeEndpoint',
        { date: currentDate });
    }
    return itemToReturn;
  }

  public updateRecentPayslipStatus(status: number, _tenant?: string): Observable<number> {
    const tenant = this.tenant ? this.tenant : window.sessionStorage.getItem('tenant');
    const relativeURL = 'Employee/UpdateRecentPayslipStatus/' + tenant;
    const staffNumber = this.securityService.securityObject.staffNumber;
    if (staffNumber) {
      return this.baseService.Execute(
        RequestType.GET, relativeURL + '/' + staffNumber + '/' + status, 'baseEmployeeEndpoint', null);
    }
  }

  public updateApplicationTheme(selectedTheme: boolean, _tenant?: string): Observable<boolean> {
    const tenant = this.tenant ? this.tenant : window.sessionStorage.getItem('tenant');
    const relativeURL = 'Employee/UpdateApplicationTheme/' + tenant;
    const staffNumber = this.securityService.securityObject.staffNumber;
    if (staffNumber) {
      return this.baseService.Execute(
        RequestType.POST, relativeURL + '/' + staffNumber + '/' + selectedTheme, 'baseEmployeeEndpoint', null);
    }
  }

  capitalize(input) {
    const words = input.split(' ');
    const CapitalizedWords = [];
    words.forEach(element => {
      CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
    });
    return CapitalizedWords.join(' ');
  }
}
