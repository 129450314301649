import 'rxjs/add/operator/filter';

import { Location, PopStateEvent, TitleCasePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { drawDOM, exportPDF, Group } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

import { MatDialog } from '@angular/material';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { EmployeeDocumentDisplayComponent } from 'src/app/employee/employee-document-display/employee-document-display.component';
import { Feature } from 'src/app/Interfaces/security/feature';
import { SecurityService } from 'src/app/services/security.service';
import { DialogService } from 'src/app/shared/dialog/dailog.service';
import { NumberToWordsPipe } from 'src/app/shared/pipes/number-to-words.pipe';
import { AppMessagesService } from 'src/app/shared/services/app-messages.service';
import { DocumentDataParsingService } from 'src/app/shared/services/documentDataParsing.service';
import { FormatterService } from 'src/app/shared/services/formatter.service';
import { environment } from 'src/environments/environment';

import { NavbarComponent } from '../../shared/navbar/navbar.component';
import { IconService } from '../../shared/services/svg-icon-service';
import { SidebarComponent } from './../../sidebar/sidebar.component';

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  providers: [DocumentDataParsingService, NumberToWordsPipe, TitleCasePipe]
})

export class AdminLayoutComponent implements OnInit, AfterViewInit {
  url: string;
  location: Location;
  showLanding = false;
  @ViewChild(SidebarComponent, { static: false }) sidebar: any;
  @ViewChild(NavbarComponent, { static: false }) navbar: NavbarComponent;
  _router: Subscription;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  MenuOpen: boolean = false;
  MenuClose: boolean = true;
  isMenuToggleOn = false;
  menuItems = [];
  payDate: any;
  netPay: any;
  grossPay: any;
  totalDeductions: any;
  showPayDetails: boolean;
  payMonth: string;
  payYear: string;
  payslipData: any;
  documentFieldDisplay: any;
  downloadPayslipDocument: boolean;
  documentData: any;
  template: any;
  documentDisplayMappingData: any;
  isSlipTimeAvailable: boolean;
  selectedPayrollData: any;
  selectedCurrency: any;
  otherDocuments: Feature[];
  currentRole: string;
  payDataLoaded: boolean;
  showMenu: boolean;

  constructor(private router: Router, location: Location, private iconService: IconService, private utilityService: UtilityService,
              private userProfileService: UserProfileService, public dialog: MatDialog, private dialogService: DialogService, public appMessagesService: AppMessagesService
    ,         private titlecasePipe: TitleCasePipe, private numberToWordsPipe: NumberToWordsPipe, private documentDataParsingService: DocumentDataParsingService
    ,         public formatterService: FormatterService, private spinnerVisibilityService: SpinnerVisibilityService, public securityService: SecurityService) {
    this.location = location;
    this.iconService.init();
  }
  ngOnInit() {
    this.showLanding = JSON.parse(window.sessionStorage.getItem('showLanding'));
    const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
    this.otherDocuments = this.securityService.securityObject.claims.employee.roleMenu.childMenus.filter(_ => _.path.includes('view-documents'));
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY);
        }
      } else if (event instanceof NavigationEnd) {
        if (event.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else {
          window.scrollTo(0, 0);
        }
      }
    });
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((_event: NavigationEnd) => {
      elemMainPanel.scrollTop = 0;
      elemSidebar.scrollTop = 0;
    });
    const html = document.getElementsByTagName('html')[0];
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      html.classList.add('perfect-scrollbar-on');
    } else {
      html.classList.add('perfect-scrollbar-off');
    }
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((_event: NavigationEnd) => {
      this.navbar.sidebarClose();
    });
    this.utilityService.isEmployeeDashboard = true;

    this.userProfileService.GetEmployeeDashboard().subscribe(response => {
      this.utilityService.rectentPayslipStatus = response.recentPayslipStatus;
      this.documentFieldDisplay = response.documentFieldDisplay;
      this.payDataLoaded = true;

      const parsedJsonResponse = JSON.parse(JSON.stringify(response.employeeDashboardData).replace(/[\u200b]/g, ''));
      this.payslipData = parsedJsonResponse.filter(item => item.name.toLowerCase() === 'payslip');
      if (this.payslipData[0]) {
        const payslipResult = this.payslipData[0].data[0];
        this.selectedPayrollData = JSON.parse(JSON.stringify(payslipResult.payrollInformation[0]));
        this.selectedCurrency = JSON.parse(JSON.stringify(payslipResult.payrollTemplates[0])).currency;

        const payrollData = this.payslipData[0].data[0].payrollInformation[0].payrollData;
        this.payDate = payrollData.paydate;
        this.netPay = this.formatterService.getFormattedValue(this.selectedCurrency, payrollData.netpay, 2, true);
        this.grossPay = this.formatterService.getFormattedValue(this.selectedCurrency, payrollData.grosspay, 2, true);
        this.totalDeductions = this.formatterService.getFormattedValue(this.selectedCurrency, payrollData.totaldeductions, 2, true);

        const date = moment(this.payDate, 'DD/MM/YYYY');
        this.payMonth = date.format('MMM').toUpperCase();
        this.payYear = date.format('YYYY');
      }
    }
    );
  }
  ngAfterViewInit() {
    this.runOnRouteChange();
  }
  public isMap() {
    if (this.location.prepareExternalUrl(this.location.path()) === '/maps/fullscreen') {
      return true;
    }
    return false;

  }
  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
    }
  }
  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }
  MenuToggle(collapse: boolean = true, openMenu: boolean = false, menuToggled: boolean = false, showLanding: boolean = false) {
    this.isMenuToggleOn = menuToggled;
    if (this.MenuOpen) {
      this.MenuOpen = false;
      setTimeout(() => {
        this.MenuClose = true;
      }, 1000);
    } else if (openMenu) {
      this.MenuOpen = true;
      this.MenuClose = false;
    }
    if (collapse) {
      this.sidebar.collapseAll();
    }
    this.showLanding = showLanding;
    window.sessionStorage.setItem('showLanding', this.showLanding.toString());
  }
  MenuOutSide() {
    if (!this.isMenuToggleOn && this.MenuOpen) {
      this.MenuOpen = false;
      setTimeout(() => {
        this.MenuClose = true;
      }, 1000);
    } else if (this.isMenuToggleOn) {
      this.isMenuToggleOn = false;
    }
    this.MenuToggle(false, false, false, this.showLanding);
  }
  roleChanged(role) {
    this.showLanding = true;
    window.sessionStorage.setItem('showLanding', this.showLanding.toString());
    this.sidebar.changeRoute(role);
  }
  OpenNav() {
    this.navbar.toggleNav();
  }
  changeMenuItems(event) {
    this.showLanding = JSON.parse(window.sessionStorage.getItem('showLanding'));
    this.menuItems = event.filter(item => item.type === 'quickLink');
    this.currentRole = this.securityService.navigatedRole.toLowerCase();
    this.showPayDetails = false;
  }
  changeRoute(route) {
    if (route === '' && this.showMenu) {
      this.showMenu = false;
      return;
    }
    if (route === '') {
      this.showMenu = true;
      return;
    }
    this.showLanding = false;
    window.sessionStorage.setItem('showLanding', this.showLanding.toString());
    this.sidebar.closeSideBar(this.showLanding);
    this.router.navigate([route]);
  }
  hideTileMenu() {
    if (this.showMenu) {
      this.showMenu = false;
    }
  }
  setShowLanding(event) {
    this.showLanding = event;
    window.sessionStorage.setItem('showLanding', this.showLanding.toString());
  }
  getColumns() {

    const noOfTiles = this.menuItems.length <= 3 ? this.menuItems.length : this.menuItems.length <= 4 ? 2 : 3;

    return 'repeat(' + noOfTiles + ', 1fr)';
  }
  size() {
    if (this.menuItems.length >= 3) {
      return '50%';
    }
    return '295px';
  }

  displayTile(menuitem: Feature) {
    if (menuitem.path) {
      return true;
    }
    if (menuitem.title.toLowerCase().includes('documents')) {
      return this.otherDocuments.length > 0;
    }
  }

  OpenCurrentPayslipDialog() {
    this.dialogService.dialog.closeAll();
    if (this.payslipData != null && this.payslipData.length > 0) {
      const isPayslipDataAvailable = this.payslipData ? true : false;
      const payslipResult = this.payslipData[0].data[0];
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('payslippopupopen');
      if (isPayslipDataAvailable) {
        this.dialog.open(EmployeeDocumentDisplayComponent, {
          panelClass: 'payslipPopup',
          data: {
            type: 'payslip',
            documentData: this.selectedPayrollData.payrollData,
            documentFiledDisplayData: this.documentFieldDisplay,
            template: (payslipResult.payrollTemplates
              .filter(template => template.payslipTemplateId === this.selectedPayrollData.payslipTemplateId)[0]).payslipTemplate,
            currency: this.selectedCurrency
          }
        });
      } else {
        this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.Employee.NoPayData, false)
          .afterClosed();
      }
    } else {
      this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.Employee.NoPayData, false)
        .afterClosed();
    }
  }

  downloadPayslip() {
    this.spinnerVisibilityService.show();
    this.downloadPayslipDocument = true;
    this.documentDisplayMappingData = Object.assign([], this.documentFieldDisplay);
    const payslipResult = this.payslipData[0].data[0];
    const selectedPayrollData = JSON.parse(JSON.stringify(payslipResult.payrollInformation[0]));
    const selectedCurrency = JSON.parse(JSON.stringify(payslipResult.payrollTemplates[0])).currency;

    const data = {
      type: 'payslip',
      documentData: selectedPayrollData.payrollData,
      documentFiledDisplayData: this.documentFieldDisplay,
      template: (payslipResult.payrollTemplates
        .filter(template => template.payslipTemplateId === selectedPayrollData.payslipTemplateId)[0]).payslipTemplate,
      currency: selectedCurrency
    };

    this.createDocument(data);
  }

  createDocument(payslipData: any) {

    this.documentData = payslipData.documentData;
    this.template = payslipData.template;

    const documentElement = document.createElement('div'); // is a node
    if (this.documentData.hasOwnProperty('netpay_p') && this.documentData.hasOwnProperty('netpayinwords')) {
      this.documentData['netpayinwords'] = this.titlecasePipe.transform(
        this.numberToWordsPipe.transform(Math.trunc(parseFloat(this.documentData['netpay_p']))).toUpperCase());
    }
    let data = { documentData: this.documentData, template: this.template };
    data = this.documentDataParsingService.bindValuestoHtml(data, payslipData.type, payslipData.currency, this.formatterService);
    this.documentData = data.documentData;
    this.template = data.template;
    this.template = this.template ? this.template.replace(new RegExp('##(.*?)##', 'gi'), '') : '';

    documentElement.innerHTML = this.template;
    documentElement.setAttribute('id', 'templatecontent');
    document.getElementById('payslipDoc').appendChild(documentElement);
    if (payslipData.type.toLowerCase() === 'payslip') {

      this.isSlipTimeAvailable = document.getElementById('sliptimedata') != null;

      if (document.getElementById('earnings') != null) {
        const earningsTable = document.getElementById('earnings').getElementsByTagName('table');
        if (this.documentData['earnings'] != null) {
          document.getElementById('earnings').appendChild(this.buildTable(this.documentData, earningsTable[0], 'earnings'));
        }
      }

      if (document.getElementById('deductions') != null) {
        const deductionsTable = document.getElementById('deductions').getElementsByTagName('table');
        if (deductionsTable != null && this.documentData['deductions'] != null) {
          document.getElementById('deductions').appendChild(this.buildTable(this.documentData, deductionsTable[0], 'deductions'));
        }
      }

      if (document.getElementById('previousEmployment') != null) {
        const previousEmploymentTable = document.getElementById('previousEmployment').getElementsByTagName('table');
        if (previousEmploymentTable != null && this.documentData['previousemployment'] != null) {
          document.getElementById('previousEmployment')
            .appendChild(this.buildTable(this.documentData, previousEmploymentTable[0], 'previousemployment'));
        }
      }

      if (document.getElementById('currentEmployment') != null) {
        const currentEmploymentTable = document.getElementById('currentEmployment').getElementsByTagName('table');
        if (currentEmploymentTable != null && this.documentData['currentemployment'] != null) {
          document.getElementById('currentEmployment')
            .appendChild(this.buildTable(this.documentData, currentEmploymentTable[0], 'currentemployment'));
        }
      }

      if (document.getElementById('ytdCumulatives') != null) {
        const ytdCumulativesTable = document.getElementById('ytdCumulatives').getElementsByTagName('table');
        if (ytdCumulativesTable != null && this.documentData['ytdcumulatives'] != null) {
          document.getElementById('ytdCumulatives')
            .appendChild(this.buildTable(this.documentData, ytdCumulativesTable[0], 'ytdcumulatives'));
        }
      }

      if (document.getElementById('sliptimedata') != null) {
        const sliptimedataTable = document.getElementById('sliptimedata').getElementsByTagName('table');
        if (sliptimedataTable != null && this.documentData['sliptimedata'] != null) {
          document.getElementById('sliptimedata')
            .appendChild(this.buildTable(this.documentData, sliptimedataTable[0], 'sliptimedata'));
        }
      }
    }

    if (payslipData.type.toLowerCase() === 'taxstatement' || payslipData.type.toLowerCase() === 'tax statement') {
      Object.keys(this.documentData).forEach(el => {
        if (el.includes('_table')) {
          const feildString: string = el.toString().split('_')[0];
          if (document.getElementById(feildString) != null) {
            const table = document.getElementById(feildString).getElementsByTagName('table');
            if (this.documentData[el] != null) {
              document.getElementById(feildString).appendChild(this.buildTaxTable(this.documentData, table[0], el));
            }
          }
        }
      });
    }

    if (payslipData.type.toLocaleLowerCase() === 'appointmentletter' || payslipData.type.toLocaleLowerCase() === 'appointment letter' ||
      payslipData.type.toLocaleLowerCase() === 'revised ctc' || payslipData.type.toLocaleLowerCase() === 'revisedctc') {
      const ctcbreakup = (document.getElementById('d-table')) ? document.getElementById('d-table').getElementsByTagName('table') : null;
      if (ctcbreakup != null && this.documentData['ctcbreakup'] != null) {
        document.getElementById('d-table')
          .appendChild(this.buildCTCBreakUpTable(this.documentData, ctcbreakup[0], 'ctcbreakup'));
      }
    }

    const element = document.getElementById('templatecontent');
    drawDOM(element, {
      paperSize: 'A4',
      forcePageBreak: '.page-break',
      margin: {
        top: '1cm',
        left: '1cm',
        right: '8.5mm'
      },
      scale: { x: 0.65, y: 0.7 }
    }).then((group: Group) => {
      return exportPDF(group);
    }).then((pdfData: string) => {
      const fileName = `${payslipData.type}-${new Date().toDateString()}.pdf`;
      const url = environment['baseSharedEndpoint'] + 'Shared/export';
      saveAs(pdfData, fileName, {
        proxyURL: url
      });
      this.spinnerVisibilityService.hide();
      document.getElementById('payslipDoc').innerHTML = null;
    });
  }

  buildTable(data, table: HTMLTableElement, type: string) {
    const currency = this.selectedCurrency;
    const formatterService = this.formatterService;
    const thead = document.createElement('thead');
    const tbody = document.createElement('tbody');
    const headRow = document.createElement('tr');
    data[type]['rowheader'].forEach(el => {
      const th = document.createElement('th');
      el = el.toString().replace(formatterService.getFormattedValue(currency, 0, 2, true).charAt(0),
        "<ng-container class='currencySymbol'>" + formatterService.getFormattedValue(currency, 0, 2, true).charAt(0) + '</ng-container>');
      th.innerHTML = el;
      headRow.appendChild(th);
    });
    thead.appendChild(headRow);
    table.appendChild(thead);
    let tableLenght = 0;
    if (type === 'earnings' || type === 'deductions' || type === 'ytdcumulatives') {
      tableLenght = Math.max(data['earnings'] != null ? data['earnings']['components'].length : 0,
        data['deductions'] != null ? data['deductions']['components'].length : 0,
        document.getElementById('ytdCumulatives') != null && data['ytdcumulatives'] != null ?
          data['ytdcumulatives']['components'].length : 0);

      if (tableLenght >= 10) {
        tableLenght = ++tableLenght;
        // tslint:disable-next-line: no-collapsible-if
      } else {
        if (this.isSlipTimeAvailable) {
          tableLenght = tableLenght + 2;
        } else {
          tableLenght = tableLenght + 4;
        }
      }
    }

    let counter = 0;
    const taxYear = data['taxyear'];
    const taxperiod = data['taxperiod'];
    const payrollId = data['payrollid'];
    data[type]['components'].forEach(component => {
      ++counter;
      const tr = document.createElement('tr');
      let td = document.createElement('td');
      const componentDisplayLabel = this.getDisplayLabel(component, taxYear, taxperiod, payrollId);
      td.appendChild(document.createTextNode(componentDisplayLabel));
      tr.appendChild(td);
      data[type]['rowheader'].forEach((header, index) => {
        if (index !== 0) {
          td = document.createElement('td');
          if (data[type]['values'][component][header] == null) {
            td.appendChild(document.createTextNode('\u00A0'));
            // tslint:disable-next-line: no-collapsible-if
          } else {
            if (type === 'sliptimedata' && index === 1) {
              td.appendChild(document.createTextNode(data[type]['values'][component][header]));
            } else {
              td.appendChild(document.createTextNode(this.formatterService.getFormattedValue(
                this.selectedCurrency, Number(data[type]['values'][component][header]), 2, false)));
            }
          }

          tr.appendChild(td);
        }
      });

      tbody.appendChild(tr);
    });

    if (counter < tableLenght && (type === 'earnings' || type === 'deductions' || type === 'ytdcumulatives')) {
      for (let index = counter; index < tableLenght; index++) {
        const tr = document.createElement('tr');
        data[type]['rowheader'].forEach(() => {
          const td = document.createElement('td');
          td.appendChild(document.createTextNode('\u00A0'));
          tr.appendChild(td);
        });
        tbody.appendChild(tr);
      }
    }
    table.appendChild(tbody);
    return table;
  }

  buildTaxTable(data, table: HTMLTableElement, type: string) {
    const thead = document.createElement('thead');
    const tbody = document.createElement('tbody');
    const headRow = document.createElement('tr');
    data[type]['rowheader'].forEach(el => {
      const th = document.createElement('th');
      th.appendChild(document.createTextNode(el));
      headRow.appendChild(th);
    });
    thead.appendChild(headRow);
    table.appendChild(thead);
    let tableLenght = 0;
    tableLenght = data[type] != null ? data[type]['components'].length : 0;
    let counter = 0;
    data[type]['components'].forEach(component => {
      ++counter;
      const tr = document.createElement('tr');
      let td = document.createElement('td');
      td.appendChild(document.createTextNode(component));
      tr.appendChild(td);
      data[type]['rowheader'].forEach((header, index) => {
        if (index !== 0) {
          td = document.createElement('td');
          if (data[type]['values'][component][header] == null) {
            td.appendChild(document.createTextNode('\u00A0'));
          } else if (isNaN(data[type]['values'][component][header])) {
            td.appendChild(document.createTextNode(data[type]['values'][component][header]));
          } else {
            td.appendChild(document.createTextNode(
              this.formatterService.getFormattedValue(this.selectedCurrency, data[type]['values'][component][header], 2, false)));
          }
          tr.appendChild(td);
        }
      });
      tbody.appendChild(tr);
    });

    if (counter < tableLenght) {
      for (let index = counter; index < tableLenght; index++) {
        const tr = document.createElement('tr');
        data[type]['rowheader'].forEach(() => {
          const td = document.createElement('td');
          td.appendChild(document.createTextNode('\u00A0'));
          tr.appendChild(td);
        });
        tbody.appendChild(tr);
      }
    }
    table.appendChild(tbody);
    return table;
  }

  buildCTCBreakUpTable(data, table: HTMLTableElement, type: string) {
    const tbody = document.createElement('tbody');
    data[type]['components'].forEach(component => {
      const tr = document.createElement('tr');
      let td = document.createElement('td');
      const componentDisplayLabel = this.changeDisplayLabel(component);
      td.appendChild(document.createTextNode(componentDisplayLabel));
      tr.appendChild(td);
      data[type]['rowheader'].forEach((header, index) => {
        if (index !== 0) {
          td = document.createElement('td');
          if (data[type]['values'][component][header] == null) {
            td.appendChild(document.createTextNode('\u00A0'));
          } else {
            td.appendChild(document.createTextNode(
              this.formatterService.getFormattedValue(this.selectedCurrency, data[type]['values'][component][header], 2, false)));
          }

          tr.appendChild(td);
        }
      });

      tbody.appendChild(tr);
    });
    table.appendChild(tbody);
    return table;
  }

  changeDisplayLabel(row: any) {
    row = row.replace(new RegExp('##(.*?)##', 'gi'), '');
    let index = -1;
    if (this.documentDisplayMappingData != null && this.documentDisplayMappingData.length > 0) {
      index = this.documentDisplayMappingData.findIndex(x => x.dataCode === row);
    }
    if (index !== -1) {
      return this.documentDisplayMappingData[index].displayLabel;
    }
    return row;
  }

  getDisplayLabel(component: any, taxyear: any, taxperiod: any, payrollId: any) {
    component = component.replace(new RegExp('##(.*?)##', 'gi'), '');
    let index = -1;
    if (this.documentDisplayMappingData != null && this.documentDisplayMappingData.length > 0) {
      index = this.documentDisplayMappingData.findIndex(x => x.dataCode === component &&
        x.taxYear === taxyear && x.taxPeriod === taxperiod && x.payrollId === payrollId);

      if (index === -1) {
        index = this.documentDisplayMappingData.findIndex(x => x.dataCode === component &&
          x.taxYear === taxyear && x.taxPeriod === 0 && x.payrollId === payrollId);
      }
    }
    if (index !== -1) {
      return this.documentDisplayMappingData[index].displayLabel;
    }
    return component;
  }

}
