import { IConfigurableFeatures } from '../identitymanagement/IConfigurableFeatures';
import { AppUserClaim } from './app-user-claim';

export class AppUserAuth {
  userName = '';
  staffNumber = '';
  bearerToken = '';
  isAuthenticated = false;
  name = '';
  imageUrl = '';
  claims: AppUserClaim;
  currentRoleId = 5;
  selectedMenu = '';
  isCurrentRoleActive = false;
  emailAddress = '';
  isCleardownConfigured = true;
  isEmailingOn: boolean;
  configurableFeatures: IConfigurableFeatures[];
  selectedTheme: boolean;
}
