import 'rxjs/add/observable/of';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { IHelpCalendar } from '../Interfaces/paymanager/IHelpCalendar';
import { BaseService } from './baseService.service';
import { RequestType } from './requestType';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root',
})
export class CalendarEventService {

  tenant: string = window.sessionStorage.getItem('tenant');
  relativeURL = 'Paymanager/';

  constructor(private baseService: BaseService, private securityService: SecurityService) { }

  public getEvents(): Observable<any> {
    const staffNumber = this.securityService.securityObject.staffNumber;
    return this.baseService.Execute<any>(RequestType.GET,
      this.relativeURL + `calendar/events/${this.tenant}/${staffNumber}/`, 'basePayManagerEndpoint', null);
  }

  public getDocumentTypes(): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET,
      this.relativeURL + `calendar/documents/${this.tenant}/`, 'basePayManagerEndpoint', null);
  }

  public insertCalendarEvent(helpCalendar: IHelpCalendar, modifierRoleId: number, creatorRoleId: number): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `calendar/event/${this.tenant}/${modifierRoleId}/${creatorRoleId}`, 'basePayManagerEndpoint', helpCalendar);
  }

  public deleteEvent(eventId: number, modifierRoleId: number, creatorRoleId: number): Observable<any> {
    return this.baseService.Execute<any>(RequestType.DELETE,
      this.relativeURL + `calendar/event/${this.tenant}/${eventId}/${modifierRoleId}/${creatorRoleId}`, 'basePayManagerEndpoint', null);
  }
}
