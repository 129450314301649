<div class="pt30 pb30 pop_dialog" style="background:#ffffff;">
  <div class="swal2-icon swal2-warning pulse-warning text-center line-height50" style="display: block;">
    <div class="font50 pt15">!</div>
  </div>
  <div class="swal2-icon swal2-success" style="display: none;">
    <span class="line tip"></span>
    <span class="line long"></span>
    <div class="placeholder"></div>
    <div class="fix"></div>
  </div>
  <img class="swal2-image" style="display: none;" alt="message">
  <h2 align="center" class="font20 font500">{{dialogData.BodyText}}</h2>
  <div align="center" *ngIf="dialogData.isShow">
    <button mat-button (click)="onCloseCancel()" class="mr30 swal2-cancel btn btn-danger">Cancel</button>
    <button mat-button (click)="onCloseConfirm()" class="swal2-confirm btn btn-success">Confirm</button>
  </div>
  <div align="center" *ngIf="!dialogData.isShow">
    <button mat-button (click)="onCloseConfirm()" class="swal2-cancel btn btn-danger">Ok</button>
  </div>
</div>
