import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { BaseService } from '../../services/baseService.service';
import { RequestType } from '../../services/requestType';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  relativeURL = 'shared/';
  tenant: string = window.sessionStorage.getItem('tenant');
  errorsObject: any[] = [];
  constructor(private baseService: BaseService, private injector: Injector) {
    super();
  }
  handleError(error) {
    const router = this.injector.get(Router);
    if ((environment['name'] === 'local' || environment['name'] === 'dev' || environment['name'] === 'qa') &&
      !error.message.includes('ExpressionChangedAfterItHasBeenCheckedError')) {
      this.errorsObject.push({ message: router.url, error: error.toString() });
      if (this.errorsObject.length >= 1) {
        // this.storeErrors().subscribe(() => {
        //   this.errorsObject = [];
        // });
        console.error(error);
      }
    }
  }

  storeErrors(): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST, this.relativeURL + 'errorHandling/' + this.tenant,
      'baseSharedEndpoint', this.errorsObject);
  }

}
