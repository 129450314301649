import { Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import {
  EmployeeDocumentDisplayComponent,
} from 'src/app/employee/employee-document-display/employee-document-display.component';

import { CreateTicketDialogComponent } from '../../employee/dialogs/create-ticket-dialog/create-ticket-dialog.component';
import { IHelpGuideDetails } from '../../Interfaces/IHelpGuideDetails';
import { SecurityService } from '../../services/security.service';
import { SharedService } from '../../services/shared.service';
import { SidebarRoutingService } from '../../sidebar/sidebar-routing.service';
import { UpdatesDialogComponent } from '../components/updates-dialog/updates-dialog.component';
import { DialogService } from '../dialog/dailog.service';
import { AppMessagesService } from '../services/app-messages.service';
import { DataService } from '../services/data.service';
import { UtilityService } from '../services/utility.service';
import updates from './../../../assets/i18n/updates.json';
import { IdentityManagerService } from './../../services/identity-manager.service';
import { UserProfileService } from './../../services/user-profile.service';

const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0
};

// declare var $: any;

@Component({
  selector: 'app-navbar-cmp',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss']

})

export class NavbarComponent implements OnInit, AfterViewInit {

  updates: any;
  subscription: any;
  location: Location;
  _mobileMenuVisible: any = 0;
  isPayslipOn: any;
  isTicketModuleAvailable = true;
  payslipPopupDisplay = false;
  showPaySettings = false;
  @ViewChild('app-navbar-cmp', { static: false }) button: any;
  ROUTES: any;
  isDisabled: boolean;
  helpGuideUrls: IHelpGuideDetails[];
  _router: Subscription;
  private listTitles: any[];
  private toggleButton: any;
  private sidebarVisible: boolean;
  currentTheme: any;
  selectedTheme: boolean;

  @Output() navigationProcessed: EventEmitter<any> = new EventEmitter<any>();
  @Output() openSideBar: EventEmitter<any> = new EventEmitter<any>();
  @Output() openNavBar: EventEmitter<any> = new EventEmitter<any>();
  @Output() roleChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() showLanding: EventEmitter<any> = new EventEmitter<any>();
  @Input() roles: any[];
  releaseNotificationDetails: any;
  showNotificationBox = false;
  stepsCompleted: any;
  notificationDelayInMS: any;
  shownavbarmenu = true;
  notificationCount = 0;
  imageUrl: string;
  name: string;
  disableNotifications: boolean = false;
  disableNewFeatures: boolean = false;

  constructor(
    location: Location,
    public appMessagesService: AppMessagesService,
    private element: ElementRef,
    private router: Router,
    private sidebarRoutingService: SidebarRoutingService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    public utilityService: UtilityService,
    public securityService: SecurityService,
    private identityManagerRegistrationService: IdentityManagerService,
    private userProfileService: UserProfileService,
    private sharedService: SharedService,
    private dataService: DataService) {
    this.location = location;
    this.sidebarVisible = false;
    this.updates = updates;
    this.utilityService.notificationCount();
    this.utilityService.checkRoles();
    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        this.utilityService.notificationCount();
        this.utilityService.checkRoles();
      }
    });
  }
  ngAfterViewInit(): void {
    if (this.securityService.isLoggedIn) {
      this.securityService.isLoggedIn = false;
      this.OpenCurrentPayslipDialog(true);
    }
  }

  logOut() {
    this.dialogService.getConfirmDialog('Logout', this.appMessagesService.appStaticMessages.AppLevel.LogoutMessage, true)
      .afterClosed().subscribe(res => {
        if (res === 0) {
          window.sessionStorage.removeItem('showNotifications');
          window.localStorage.setItem('isLoggedOut', Math.random().toString());
          const auditLogObj = {
            logType: 'Activity',
            staffNumber: this.securityService.securityObject.staffNumber,
            userRoleId: 5,
            status: 'Success',
            activity: 'Logout',
            type: 'GET'
          };
          this.identityManagerRegistrationService.LogoutActivityLog(auditLogObj).subscribe(
            () => {
            },
            () => {
            },
            () => {
              this.securityService.Logout();
              window.open('?returnUrl=', '_self');
            }
          );
        } else {
        }
      });
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];
    setTimeout(() => {
      body.classList.remove('sidebar-mini');
      body.classList.add('sidebar-mini');

      misc.sidebar_mini_active = true;
    }, 300);
    // if (misc.sidebar_mini_active === true) {
    //     body.classList.remove('sidebar-mini');
    //     misc.sidebar_mini_active = false;

    // } else {
    //     setTimeout(function () {
    //         body.classList.add('sidebar-mini');

    //         misc.sidebar_mini_active = true;
    //     }, 300);
    // }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(() => {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
  hideSidebar() {
    const body = document.getElementsByTagName('body')[0];
    const sidebar = document.getElementsByClassName('sidebar')[0];

    if (misc.hide_sidebar_active === true) {
      setTimeout(() => {
        body.classList.remove('hide-sidebar');
        misc.hide_sidebar_active = false;
      }, 300);
      setTimeout(() => {
        sidebar.classList.remove('animation');
      }, 600);
      sidebar.classList.add('animation');

    } else {
      setTimeout(() => {
        body.classList.add('hide-sidebar');
        // $('.sidebar').addClass('animation');
        misc.hide_sidebar_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(() => {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
  assignRoutes(routes: any) {
    this.ROUTES = routes;
    this.listTitles = this.ROUTES.filter(listTitle => listTitle);
  }
  changeTheme() {
    if (this.currentTheme === 'dark') {
      this.currentTheme = 'lite';
      this.selectedTheme = false;
    } else {
      this.currentTheme = 'dark';
      this.selectedTheme = true;
    }

    this.updateThemeToApp();

    // Update theme in the backend
    this.utilityService.updateApplicationTheme(this.selectedTheme).subscribe(resp => {
      this.selectedTheme = resp;
    });
  }

  setSelectedTheme(selectedTheme: boolean) {
    if (selectedTheme) {
      this.currentTheme = 'dark';
    } else {
      this.currentTheme = 'lite';
    }
    this.updateThemeToApp();
  }

  updateThemeToApp() {
    window.sessionStorage.setItem('currentTheme', this.currentTheme);
    window.localStorage.setItem('currentTheme', this.currentTheme);
    const body = document.getElementsByTagName('body')[0];
    setTimeout(() => {
      body.classList.remove('dark');
      body.classList.remove('lite');
      body.classList.add(this.currentTheme);
    });
    const ele = document.getElementsByName('pmdiv')[0];
    setTimeout(() => {
      ele.classList.remove('dark');
      ele.classList.remove('lite');
      ele.classList.add(this.currentTheme);
    });
  }

  ngOnInit() {
    const notified = JSON.parse(window.sessionStorage.getItem('showNotifications')) ? true : false;
    this.dataService.userProfilePictureData.subscribe(imageUrl => this.imageUrl = imageUrl);
    this.imageUrl = this.securityService.securityObject.imageUrl;
    this.name = this.securityService.securityObject.name;
    this.selectedTheme = this.securityService.securityObject.selectedTheme;
    this.setSelectedTheme(this.selectedTheme);

    if (!notified) {
      this.notificationDelayInMS = 20000;
      this.showNotificationBox = true;
      window.sessionStorage.setItem('showNotifications', 'true');
    }
    this.securityService.GetReleaseNotification(this.updates.version, 0).subscribe(response => {
      this.utilityService.releaseNotificationDetails = response;
      this.utilityService.stepsCompleted = response.completedSteps && response.completedSteps.length > 0 ?
        JSON.parse(response.completedSteps)[0].CompletedSteps.split(',') : 0;
      this.stepsCompleted = this.utilityService.releaseNotificationDetails.completedSteps
        && this.utilityService.releaseNotificationDetails.completedSteps.length > 0 ?
        JSON.parse(this.utilityService.releaseNotificationDetails.completedSteps)[0].CompletedSteps.split(',') : 0;

      this.disableNewFeatures = this.stepsCompleted.includes('6');
      this.utilityService.newFeaturesViewed = this.disableNewFeatures;
      if (this.utilityService.totalNotifications <= 0) {
          this.disableNotifications = true;
        }

      setTimeout(() => {
        this.closeNotifications();
      }, this.notificationDelayInMS);
    });
    if (this.securityService.securityObject.claims.employee !== undefined &&
      this.securityService.securityObject.claims.employee.roleMenu !== undefined
      && this.securityService.securityObject.claims.employee.roleMenu.childMenus !== undefined) {
      this.isTicketModuleAvailable = this.securityService.securityObject.claims.employee.roleMenu.childMenus.filter(
        item => item.abbreviation === 'MT').length === 1;

      this.utilityService.hasAccessToCalendar = this.securityService.securityObject.claims.employee.roleMenu.childMenus.filter(
        item => item.abbreviation === 'MC').length === 1;
      this.utilityService.hasAccessToMessages = this.securityService.securityObject.claims.employee.roleMenu.childMenus.filter(
        item => item.abbreviation === 'MB').length === 1;
      this.utilityService.hasAccessToDouments = this.securityService.securityObject.claims.employee.roleMenu.childMenus.filter(
        item => item.abbreviation === 'DL').length === 1;
      this.utilityService.hasAccessToVideos = this.securityService.securityObject.claims.employee.roleMenu.childMenus.filter(
        item => item.abbreviation === 'VL').length === 1;
    }

    this.sidebarRoutingService.currentRoutes.subscribe(routes => this.assignRoutes(routes));
    //  this.subscription = this.comparentchildservice.on('collapse-sidebar').subscribe(() => this.minimizeSidebar());
    this.listTitles = this.ROUTES.filter(listTitle => listTitle);

    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    }
    if (body.classList.contains('hide-sidebar')) {
      misc.hide_sidebar_active = true;
    }
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((_event: NavigationEnd) => {
      const $layer = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
      }
    });
    this.minimizeSidebar();

    this.sharedService.getHelpguideDocumentURLs().subscribe(response => {
      this.helpGuideUrls = response;
    },
      () => {
        this.helpGuideUrls = [];
      }
    );
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(() => {
      toggleButton.classList.add('toggled');
    }, 500);
    body.classList.add('nav-open');

    this.sidebarVisible = true;
  } sidebarClose() {
    const body = document.getElementsByTagName('body')[0];
    if (this.toggleButton) {
      this.toggleButton.classList.remove('toggled');
    }
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  } sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
    const body = document.getElementsByTagName('body')[0];

    const $toggle = document.getElementsByClassName('navbar-toggler')[0];
    if (this._mobileMenuVisible === 1) {
      // $('html').removeClass('nav-open');
      body.classList.remove('nav-open');
      // if ($layer) {
      //     $layer.remove();
      // }

      setTimeout(() => {
        $toggle.classList.remove('toggled');
      }, 400);

      this._mobileMenuVisible = 0;
    } else {
      setTimeout(() => {
        $toggle.classList.add('toggled');
      }, 430);

      const $layer = document.createElement('div');
      $layer.setAttribute('class', 'close-layer');

      if (body.querySelectorAll('.main-panel')) {
        document.getElementsByClassName('main-panel')[0].appendChild($layer);
      } else if (body.classList.contains('off-canvas-sidebar')) {
        document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
      }

      setTimeout(() => {
        $layer.classList.add('visible');
      }, 100);

      $layer.onclick = function() { // asign a function
        body.classList.remove('nav-open');
        this._mobileMenuVisible = 0;
        $layer.classList.remove('visible');
        setTimeout(() => {
          $layer.remove();
          $toggle.classList.remove('toggled');
        }, 400);
      }.bind(this);

      body.classList.add('nav-open');
      this._mobileMenuVisible = 1;

    }
  }

  getTitle() {
    const titlee: any = this.location.prepareExternalUrl(this.location.path());
    this.listTitles.forEach(element => {

      if (element.type === 'link' && (element.path === titlee || titlee.includes(element.path))) {
        return element.title;
      }
      if (element.type === 'sub') {
        element.children.forEach(j => {
          const subtitle = element.path + '/' + j.path;
          if (subtitle === titlee) {
            return j.title;
          }
        });
      }
    });
    return 'Dashboard';
  }

  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }

  OpenNewTicketDialog(): void {
    this.dialogService.dialog.closeAll();
    this.navigationProcessed.emit();
    this.isDisabled = false;
    const dialogRef = this.dialog.open(CreateTicketDialogComponent, {
      panelClass: 'createTicketDialog'
    });

    dialogRef.afterClosed().subscribe(() => {
      this.userProfileService.setRefreshValue(true);
    });
  }

  OpenHelpGuide(): void {
    let url = null;
    this.navigationProcessed.emit();
    if (this.helpGuideUrls && this.helpGuideUrls.length > 0) {
      switch (this.securityService.securityObject.currentRoleId) {
        case 1:
          url = this.helpGuideUrls.filter(x => x.userRoleId === 1)[0].helpGuideUrl;
          break;
        case 2:
          url = this.helpGuideUrls.filter(x => x.userRoleId === 2)[0].helpGuideUrl;
          break;
        case 3:
          url = this.helpGuideUrls.filter(x => x.userRoleId === 3)[0].helpGuideUrl;
          break;
        case 4:
          url = this.helpGuideUrls.filter(x => x.userRoleId === 4)[0].helpGuideUrl;
          break;
        case 5:
          url = this.helpGuideUrls.filter(x => x.userRoleId === 5)[0].helpGuideUrl;
          break;
      }
      window.open(url, '_blank');
    } else {
      this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.AppLevel.ErrorEncounteredTryAgain, false)
        .afterClosed();
    }
  }

  OpenCurrentPayslipDialog(OnLogin: boolean = false): void {
    this.dialogService.dialog.closeAll();
    this.navigationProcessed.emit();
    if (!this.payslipPopupDisplay) {
      this.payslipPopupDisplay = true;
      this.userProfileService.GetEmployeeDashboard().subscribe(response => {
        if (response != null && ((OnLogin && response.recentPayslipStatus !== 0) || !OnLogin)) {
          this.utilityService.rectentPayslipStatus = response.recentPayslipStatus;
          if (response.employeeDashboardData != null && response.employeeDashboardData.length > 0) {
            const parsedJsonResponse = JSON.parse(JSON.stringify(response.employeeDashboardData).replace(/[\u200b]/g, ''));
            if (parsedJsonResponse) {
              const payslipData = parsedJsonResponse.filter(item => item.name.toLowerCase() === 'payslip');
              const isPayslipDataAvailable = payslipData ? true : false;
              this.utilityService.currentPayslipData = payslipData;
              const payslipResult = this.utilityService.currentPayslipData[0].data[0];
              const selectedPayrollData = JSON.parse(JSON.stringify(payslipResult.payrollInformation[0]));
              const selectedCurrency = JSON.parse(JSON.stringify(payslipResult.payrollTemplates[0])).currency;
              const body = document.getElementsByTagName('body')[0];
              body.classList.add('payslippopupopen');
              if (isPayslipDataAvailable) {
                this.dialog.open(EmployeeDocumentDisplayComponent, {
                  panelClass: 'payslipPopup',
                  data: {
                    type: 'payslip',
                    documentData: selectedPayrollData.payrollData,
                    documentFiledDisplayData: response.documentFieldDisplay,
                    template: (payslipResult.payrollTemplates
                      .filter(template => template.payslipTemplateId === selectedPayrollData.payslipTemplateId)[0]).payslipTemplate,
                    currency: selectedCurrency
                  }
                }).afterClosed().subscribe(() => {
                  this.payslipPopupDisplay = false;
                });
              } else {
                this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.Employee.NoPayData, false)
                  .afterClosed();
              }
            }
          } else {
            this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.Employee.NoPayData, false)
              .afterClosed();
          }
        }
        this.payslipPopupDisplay = false;
      });
    }
  }

  showNotificationsPopOver() {
    if (this.utilityService.isHelpdeskManager && this.utilityService.userNotifications.remindersCount === 0) {
      return false;
    }

    if (this.utilityService.totalNotifications === 0) {
      return false;
    }
    this.navigationProcessed.emit();
    this.showPaySettings = false;
  }

  openUpdatesDialog() {
    this.closeNotifications();
    this.dialogService.dialog.closeAll();
    this.navigationProcessed.emit();
    this.dialog.open(UpdatesDialogComponent, {
      panelClass: 'updateDialogPopUp',
      width: '70%',
      data: this.utilityService.releaseNotificationDetails
    }).afterClosed().subscribe(_result => {
      if (_result && _result.manual !== 'on') {
        this.utilityService.releaseNotificationDetails = _result;
        this.stepsCompleted = _result.completedSteps && _result.completedSteps.length > 0 ? JSON.parse(_result.completedSteps)[0].CompletedSteps.split(',') : 0;
        if (_result.menu && _result.menu === 'on') {
          this.openSideBar.emit();
        }
        if (_result.nav && _result.nav === 'on') {
          this.openNavBar.emit();
        }
        if (_result.status === 'Success') {
          this.notificationDelayInMS = 5000;
          this.showNotificationBox = true;
          setTimeout(() => {
            this.securityService.GetReleaseNotification(this.updates.version, 0, 'Completed').subscribe(res => {
              this.utilityService.releaseNotificationDetails = res;
              this.stepsCompleted = res.completedSteps.length > 0 ? JSON.parse(res.completedSteps)[0].CompletedSteps.split(',') : 0;
              this.disableNewFeatures = this.stepsCompleted.includes('6');
              this.utilityService.newFeaturesViewed = this.disableNewFeatures;
              if (this.utilityService.releaseNotificationDetails.status === 'Completed') {
                this.closeNotifications();
              }
            });
          }, this.notificationDelayInMS);
        }
      } else {
        this.securityService.GetReleaseNotification(this.updates.version, 0).subscribe(_response => {
          _result = _response;
          this.stepsCompleted = _result.completedSteps.length > 0 ? JSON.parse(_result.completedSteps)[0].CompletedSteps.split(',') : 0;
          this.disableNewFeatures = this.stepsCompleted.includes('6');
          this.utilityService.newFeaturesViewed = this.disableNewFeatures;
        });
      }
    });
  }

  roleChanged(roleTitle) {
    const role = this.roles.find(r => r.title === roleTitle);
    this.roleChange.emit(role);
  }

  toggleViewPayslip() {
    this.showPaySettings = true;
    this.utilityService.updateRecentPayslipStatus(this.utilityService.rectentPayslipStatus === 0 ? 1 : 0).subscribe(resp => {
      this.utilityService.rectentPayslipStatus = resp;
    });
  }
  LatestPayslipSettingOpen() {
    this.closeNotifications();
    this.navigationProcessed.emit();
    this.showPaySettings = !this.showPaySettings;
  }
  LatestPayslipSettingClose() {
    this.showPaySettings = false;
  }
  closeNotifications() {
    this.showNotificationBox = false;
    if (this.utilityService.totalNotifications <= 0) {
      this.disableNotifications = true;
    }
    if (this.utilityService.releaseNotificationDetails.status === 'Success') {
      this.securityService.GetReleaseNotification(this.updates.version, 0, 'Completed').subscribe(response => {
        this.utilityService.releaseNotificationDetails = response;
        this.stepsCompleted = response.completedSteps.length > 0 ? JSON.parse(response.completedSteps)[0].CompletedSteps.split(',') : 0;
        this.utilityService.newFeaturesViewed = this.stepsCompleted;
        this.disableNewFeatures = this.stepsCompleted.includes('6');
      });
    }
  }

  toggleNav() {
    this.shownavbarmenu = true;
  }
}
