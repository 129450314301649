import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CommunicationDetails } from '../Interfaces/helpdesk/CommunicationDetails';
import { IClearDownDeletionAuditLog } from '../Interfaces/helpdesk/IClearDownDeletionAuditLog';
import { IClearDownRequestAuditLog } from '../Interfaces/helpdesk/IClearDownRequestAuditLog';
import { EmployeeEnableDisableStatus } from '../Interfaces/IEmployeeEnableDisable';
import { IMessagingData } from '../Interfaces/IMessagingData';
import { IEnableDisabledLog } from '../Interfaces/logs/IEnableDisabledLog';
import { IMessages } from '../Interfaces/paymanager/IMessages';
import { ICountry } from './../Interfaces/ICountry';
import { BaseService } from './baseService.service';
import { RequestType } from './requestType';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root',
})
export class SharedService {

  tenant: string = window.sessionStorage.getItem('tenant');
  relativeURL = 'shared/';
  public maxSecondsToWaitBeforeResend: number;
  public maxAttemptsForResend: number;
  public emailTimeOutCounter: number = 0;
  public emailTimeOutInterval: number = 0;
  public mobileTimeOutCounter: number = 0;
  public mobileTimeOutInterval: number = 0;

  lastViewId: any = 1;

  constructor(private baseService: BaseService, private securityService: SecurityService) {
  }

  InsertAuditLog(auditLogObj): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST, this.relativeURL + `activitylog/${this.tenant}`,
      'baseSharedEndpoint', auditLogObj);
  }

  LockDownUserActivation(staffNumber: string): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `/activation/lockdown/${this.tenant}/${staffNumber}`, 'baseSharedEndpoint', null);
  }

  SendCommunicationBasedOnPreference(data: CommunicationDetails, purpose: string, currentRoleId: any, mailDetails: any): Observable<any> {
    mailDetails = mailDetails ? mailDetails : '';
    if (data == null || data.communicationPreference == null) {
      return Observable.of(false);
    }

    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + 'sendcommunication/' + this.tenant + '/' + currentRoleId + '/' + purpose + '/' + mailDetails,
      'baseSharedEndpoint', data);
  }

  AttachReportToEmailAndSendEmail(data: any): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `attachtoemail/${this.tenant}`, 'baseSharedEndpoint', data);
  }

  InsertMessage(messagingData: IMessagingData): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `messaging/insert/${this.tenant}/${this.securityService.securityObject.currentRoleId}`,
      'baseSharedEndpoint', messagingData);
  }

  GetAllMessages(): Observable<IMessages[]> {
    const currentRoleId = this.securityService.securityObject.currentRoleId;
    return this.baseService.Execute<IMessages[]>(RequestType.GET,
      this.relativeURL + `messaging/allmessages/${this.tenant}/${currentRoleId}`, 'baseSharedEndpoint', null);
  }

  DeleteMessage(messageId: number): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `messaging/delete/${this.tenant}/${this.securityService.securityObject.currentRoleId}/${messageId}`,
      'baseSharedEndpoint', null);
  }

  getUserRoles(): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET, this.relativeURL + `roles/user/${this.tenant}/`, 'baseSharedEndpoint', null);
  }

  getEmployeeListByLogtype(searchType: any, searchText: any, logtype: any, startDate?: string, endDate?: string): Observable<any> {
    startDate = (startDate) ? startDate : null;
    endDate = (endDate) ? endDate : null;
    searchText = (searchText) ? searchText : null;
    let relativeApiURL = '';
    if (logtype === 'DocumentLoadedLog') {
      relativeApiURL = this.relativeURL
        + `logtype/employeelist/${this.tenant}/${searchType}/${searchText}/${logtype}/${startDate}/${endDate}/`;
    } else {
      relativeApiURL = this.relativeURL
        + `logtype/employeelist/${this.tenant}/${searchType}/${searchText}/${logtype}/`;
    }
    return this.baseService.Execute<any>(RequestType.GET, relativeApiURL, 'baseSharedEndpoint', null);
  }

  viewEnableDisableLog(startDate: any, endDate: any, employeeName: any, staffNumber: any): Observable<IEnableDisabledLog[]> {
    return this.baseService.Execute<IEnableDisabledLog[]>(RequestType.GET,
      this.relativeURL + `enableDisableLog/${this.tenant}/${startDate}/${endDate}/${staffNumber}/${employeeName}`,
      'baseSharedEndpoint', null);
  }

  clearDownRequest(startDate: any, endDate: any, employeeName: any, staffNumber: any): Observable<IClearDownRequestAuditLog[]> {
    return this.baseService.Execute<IClearDownRequestAuditLog[]>(RequestType.GET,
      this.relativeURL + `log/data/${this.tenant}/cleardownrequest/${startDate}/${endDate}/${staffNumber}/${employeeName}`,
      'baseSharedEndpoint', null);
  }

  clearDownDeletion(startDate: any, endDate: any): Observable<IClearDownDeletionAuditLog[]> {
    return this.baseService.Execute<IClearDownDeletionAuditLog[]>(RequestType.GET,
      this.relativeURL + `log/cleardowndeletion/${this.tenant}/cleardowndeletion/${startDate}/${endDate}/`,
      'baseSharedEndpoint', null);
  }

  getActivityTypes(): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET, this.relativeURL + `activitytypes/${this.tenant}/`,
      'baseSharedEndpoint', null);
  }

  getDocumentLogTypes(stafNumber: any): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET, this.relativeURL + `documentlogtypes/${this.tenant}/${stafNumber}`,
      'baseSharedEndpoint', null);
  }

  getActivityLogs(activitytypeId: number, staffNumber, startdate: any, enddate: any): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET,
      this.relativeURL + `activitylog/${this.tenant}/${staffNumber}/${activitytypeId}/${startdate}/${enddate}/`,
      'baseSharedEndpoint', null);
  }

  getAllEmployees(): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET,
      this.relativeURL + `auditlog/userlog/employeeDetails/${this.tenant}/`, 'baseSharedEndpoint', null);
  }

  getEmployeeDetails(staffNumber: string): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET,
      this.relativeURL + `auditlog/userlog/employeeDetails/${this.tenant}/${staffNumber}/`, 'baseSharedEndpoint', null);
  }

  getFieldHistory(field: string, staffNumber: string, startDate: any, endDate: any): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET,
      this.relativeURL + `auditlog/userlog/employeeHistory/${this.tenant}/${staffNumber}/${field}/${startDate}/${endDate}`,
      'baseSharedEndpoint', null);
  }

  getTaxYears(): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET,
      this.relativeURL + `auditlog/documentload/filters/${this.tenant}/`, 'baseSharedEndpoint', null);
  }

  getAllEmployeeList(searchType: any, searchText: any): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET,
      this.relativeURL + `search/employee/${this.tenant}/${searchType}/${searchText}`, 'baseSharedEndpoint', null);
  }

  getDocumentLodedLogs(req): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `documentloadedlogs/${this.tenant}`, 'baseSharedEndpoint', req);
  }

  getActivationLogs(startdate: any, enddate: any, staffNumber?: any, userName?: any): Observable<any> {
    let relativeURL = this.relativeURL + `activationlog/${this.tenant}/${startdate}/${enddate}`;
    relativeURL = ((staffNumber && userName) ? relativeURL + '/' + staffNumber + '/' + userName
      : ((staffNumber) ? relativeURL + '/' + staffNumber
        : ((userName) ? relativeURL + '/' + null + '/' + userName
          : relativeURL)));
    return this.baseService.Execute<any>(RequestType.GET, relativeURL, 'baseSharedEndpoint', null);
  }

  getEmployeeRoles(staffNumber): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET,
      this.relativeURL + `employee/roles/${this.tenant}/${staffNumber}/`, 'baseSharedEndpoint', null);
  }

  GetEmployeeInfoForEnableDisableAccount(searchedName?: string, searchedNumber?: string): Observable<EmployeeEnableDisableStatus[]> {
    let relativeURL = this.relativeURL + `enableDisable/account/${this.tenant}`;
    relativeURL = ((searchedName && searchedNumber) ? relativeURL + '/' + searchedName + '/' + searchedNumber
      : ((searchedName) ? relativeURL + '/' + searchedName
        : ((searchedNumber) ? relativeURL + '/' + null + '/' + searchedNumber
          : relativeURL)));
    return this.baseService.Execute<EmployeeEnableDisableStatus[]>(RequestType.GET, relativeURL, 'baseSharedEndpoint', null);
  }

  UpdateEmployeeEnableDisableStatus(data: EmployeeEnableDisableStatus): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `enableDisable/status/${this.tenant}`, 'baseSharedEndpoint', data);
  }

  GetEmployeeInfoForResetActivation(searchedName?: string, searchedNumber?: string): Observable<any> {
    let relativeURL = this.relativeURL + `reset/activation/${this.tenant}`;
    relativeURL = ((searchedName && searchedNumber) ? relativeURL + '/' + searchedName + '/' + searchedNumber
      : ((searchedName) ? relativeURL + '/' + searchedName
        : ((searchedNumber) ? relativeURL + '/' + null + '/' + searchedNumber
          : relativeURL)));
    return this.baseService.Execute<any>(RequestType.GET, relativeURL, 'baseSharedEndpoint', null);
  }

  UpdateEmployeeInfoForResetActivation(staffNumber): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `reset/user/${this.tenant}/${staffNumber}`, 'baseSharedEndpoint', staffNumber);
  }

  GetEmployeesofResetTicket(): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET,
      this.relativeURL + `reset/Applicants/${this.tenant}`, 'baseSharedEndpoint', null);
  }

  SendEmailToAdmin(userRoleId, purpose, ticketId): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `sendEmail/${this.tenant}/${userRoleId}/${purpose}/${ticketId}`,
      'baseSharedEndpoint', userRoleId);
  }

  getCountries(): Observable<ICountry[]> {
    return this.baseService.Execute<ICountry[]>(RequestType.GET, `shared/getcountries/${this.tenant}`, 'baseSharedEndpoint', null);
  }

  getHelpguideDocumentURLs(): Observable<any[]> {
    return this.baseService.Execute<any[]>(RequestType.GET, `shared/helpguidedocuments/${this.tenant}`, 'baseSharedEndpoint', null);
  }

  GetEmployeeInfoWithStatus(searchedName?: string, searchedNumber?: string): Observable<any[]> {
    let relativeURL = this.relativeURL + `employee/status/${this.tenant}`;
    relativeURL = ((searchedName && searchedNumber) ? relativeURL + '/' + searchedName + '/' + searchedNumber
      : ((searchedName) ? relativeURL + '/' + searchedName + '/' + null
        : ((searchedNumber) ? relativeURL + '/' + null + '/' + searchedNumber
          : relativeURL)));
    return this.baseService.Execute<any[]>(RequestType.GET, relativeURL, 'baseSharedEndpoint', null);
  }

  sendNotificationsByPurposeAndActivity(purpose: string, activity: string, id: number): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + 'TriggerNotifications' + '/' + this.tenant + '/' + purpose + '/' + activity + '/' + id,
      'baseSharedEndpoint', null);
  }

  dataURLtoFile(dataurl, filename) {
    filename = (filename != null) ? filename.substring(0, filename.lastIndexOf('.')) + '.jpeg' : '';
    if (filename) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
  }

  checkEmailTimer(): number {
    // this.emailTimeOutCounter = this.emailTimeOutCounter + 1;
    if (this.emailTimeOutInterval === 0) {
      if (this.emailTimeOutCounter === this.maxAttemptsForResend) {
        // tslint:disable-next-line: prefer-const
        let emailRefreshIntervalId = setInterval(() => {
          this.emailTimeOutInterval = this.emailTimeOutInterval + 1;
          if (this.emailTimeOutInterval >= this.maxSecondsToWaitBeforeResend) {
            clearInterval(emailRefreshIntervalId);
          }
        }, 1000);
      }
      return 0;
      // tslint:disable-next-line: no-unnecessary-else
    } else {
      if (this.emailTimeOutInterval >= this.maxSecondsToWaitBeforeResend) {
        this.emailTimeOutInterval = 0;
        this.emailTimeOutCounter = 0;
        return 0;
      }
      return this.maxSecondsToWaitBeforeResend - this.emailTimeOutInterval;
    }
  }

  checkMobileTimer(): number {
    // this.mobileTimeOutCounter = this.mobileTimeOutCounter + 1;
    if (this.mobileTimeOutInterval === 0) {
      if (this.mobileTimeOutCounter === this.maxAttemptsForResend) {
        // tslint:disable-next-line: prefer-const
        let mobileRefreshIntervalId = setInterval(() => {
          this.mobileTimeOutInterval = this.mobileTimeOutInterval + 1;
          if (this.mobileTimeOutInterval >= this.maxSecondsToWaitBeforeResend) {
            clearInterval(mobileRefreshIntervalId);
          }
        }, 1000);
      }
      return 0;
      // tslint:disable-next-line: no-unnecessary-else
    } else {
      if (this.mobileTimeOutInterval >= this.maxSecondsToWaitBeforeResend) {
        this.mobileTimeOutInterval = 0;
        this.mobileTimeOutCounter = 0;
        return 0;
      }
      return this.maxSecondsToWaitBeforeResend - this.mobileTimeOutInterval;
    }
  }

  formatString(str: string, ...val: any[]) {
    for (let index = 0; index < val.length; index++) {
      str = str.replace(`{${index}}`, val[index]);
    }
    return str;
  }
  getTemplateList(): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET, this.relativeURL + 'template/alltemplates/'
      + this.tenant + '/' + this.securityService.securityObject.currentRoleId,
      'baseSharedEndpoint', null);
  }

  getTemplateById(templateId: number): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET, this.relativeURL + 'template/' + this.tenant + '/' + templateId,
      'baseSharedEndpoint', null);
  }

  saveTemplate(templateData: any): Observable<boolean> {
    return this.baseService.Execute<boolean>(RequestType.POST,
      this.relativeURL + `template/insert/${this.tenant}/${this.securityService.securityObject.currentRoleId}`,
      'baseSharedEndpoint', templateData);
  }

  deleteTemplate(templateId: number): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST, this.relativeURL + 'template/delete/' + this.tenant + '/'
      + this.securityService.securityObject.currentRoleId + '/' + templateId,
      'baseSharedEndpoint', null);
  }

  getDocumentDisplayName(document: string) {
    return this.baseService.Execute<any>(RequestType.GET,
      this.relativeURL + 'getDocumentDisplayName'  + '/' + this.tenant + '/' + document,
      'baseSharedEndpoint', null);
  }
}
