import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import crypto from 'crypto-js';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { IUserDeviceSettings } from 'src/app/Interfaces/identitymanagement/IUserDeviceSettings';
import { SharedService } from 'src/app/services/shared.service';
import { DateService } from 'src/app/shared/services/date.service';

import { IAuthModel } from '../../Interfaces/identitymanagement/IAuthModel';
import { IValidatedUserPreference } from '../../Interfaces/identitymanagement/IValidatedUserPreference';
import { AppUser } from '../../Interfaces/security/app-user';
import { AppUserAuth } from '../../Interfaces/security/app-user-auth';
import { ITenantConfigurationSettings } from '../../Interfaces/tenantconfiguration/ITenantConfigurationSettings';
import { SecurityService } from '../../services/security.service';
import { DialogService } from '../../shared/dialog/dailog.service';
import { DialogEnum } from '../../shared/dialog/DialogEnum.js';
import { AppMessagesService } from '../../shared/services/app-messages.service';
import { IconService } from '../../shared/services/svg-icon-service';
import { IdentityManagerService } from './../../services/identity-manager.service';

declare var $: any;

@Component({
  selector: 'app-login-cmp',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  currentPassword: string;
  password: string;
  test: Date = new Date();
  user: AppUser = new AppUser();
  securityObject: AppUserAuth = null;
  returnUrl: string;
  showAuthenticationBox = false;
  verifiedAuthenticationCode = false;
  verifiedUserCredentials = false;
  validateUserPreference: IValidatedUserPreference;
  tenantName = '';
  passwordReset: boolean;
  showPassword = false;
  showConfirmPassword = false;
  showCurrentPassword = false;
  showLoginPassword = false;
  passwordForm: FormGroup;
  loginPasswordType = 'password';
  currentPasswordType = 'password';
  passwordType = 'password';
  confirmPasswordType = 'password';
  _pwdText = 'visibility_off';
  _cnfrmPwdText = 'visibility_off';
  _curPwdText = 'visibility_off';
  _loginPwdText = 'visibility_off';

  staffNumber: any;
  isError = false;
  errorMessage: string = null;
  passwordErrorMsgPattern: string;
  tenantConfigSettings: ITenantConfigurationSettings;
  isChangedPasswordValid: boolean;
  isFormValid: boolean;
  isDirty: boolean;
  passwordSameError;
  emailConfiguration: boolean;
  type: string = null;
  purposeType: string = null;
  showSpamMsg = false;
  option: string = null;
  emailAddress: string = null;
  phoneNumber: string = null;
  isTwoFactorEnabled = false;
  currentPasswordCheckError = false;
  isSendAsSMS: boolean = false;
  emailTimer: number = 0;
  mobileTimer: number = 0;
  capsOn;
  @ViewChild('otp1', { static: false }) firstOtpBox: ElementRef;
  private toggleButton: any;
  private sidebarVisible: boolean;
  nativeElement: Node;
  userDeviceSettings: IUserDeviceSettings;

  slides: any[];
  slideConfig = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 7000,
    useTransform: true,
    cssEase: 'ease-in-out'
  };

  @ViewChild('slickModal', { static: false }) slickModal: SlickCarouselComponent;

  constructor(
    private dateService: DateService,
    public appMessagesService: AppMessagesService,
    private element: ElementRef,
    customiconservice: IconService,
    private router: Router,
    private route: ActivatedRoute,
    private securityService: SecurityService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private identityManagerRegistrationService: IdentityManagerService,
    public sharedService: SharedService
  ) {
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
    customiconservice.init();
    this.tenantName = 'REGANTO'; // only done for demo env - window.sessionStorage.getItem("tenant").toUpperCase()
  }

  clearVerifiedCheck() {
    this.showAuthenticationBox = false;
    this.showSpamMsg = false;
    this.verifiedAuthenticationCode = false;
    this.verifiedUserCredentials = false;
  }

  validateEmployeeCredentials() {
    this.loginPasswordType = 'password';
    this._loginPwdText = 'visibility_off';
    if (this.showAuthenticationBox) {
      let otp = '';
      $(`.${this.purposeType}.authenticationcode`).each(function() {
        if ($(this).val()) {
          otp = `${otp}${$(this).val()}`;
        } else {
          return false;
        }
      });

      if (otp.length === 6) {
        this.authenticate(
          this.type,
          this.purposeType,
          this.option,
          this.emailAddress,
          this.phoneNumber,
          otp
        );
      } else {
        this.dialogService.getSucessDialog('Error', this.appMessagesService.personalRequiredMessages('authorisation code'), false)
          .afterClosed();
      }
    } else {
      this.user.password = crypto.SHA256(this.user.password).toString(crypto.enc.Base64);
      this.securityService.ValidateUserCredentials(this.user).subscribe(
        response => {
          if (response != null) {
            this.securityService.isLoggedIn = true;
            this.validateUserPreference = response;
            this.user.isTwoFactorEnabled = this.validateUserPreference.isTwoFactorEnabled;
            this.user.isPasswordReset = this.validateUserPreference.isPasswordReset;
            window.sessionStorage.setItem('showNotifications', 'false');
            window.sessionStorage.setItem('showLanding', 'true');
            if (this.validateUserPreference.isPasswordReset) {
              this.passwordReset = true;
              if (
                this.user.userName.trim().length > 0 &&
                !this.verifiedUserCredentials
              ) {
                const temp = this.user;
                temp.isTwoFactorEnabled = false;
                this.securityService.LoginUser(temp).subscribe(
                  resp => {
                    this.securityObject = resp;
                    if (this.securityObject.isAuthenticated) {
                      this.identityManagerRegistrationService
                        .ValidateUsername(this.user.userName.trim())
                        .subscribe(res => {
                          if (res != null) {
                            this.staffNumber = res;
                          }
                        },
                          error => {
                            if (error != null && error.error != null && error.error !== undefined && error.error.error !== null
                              && error.error.error !== undefined) {
                              this.dialogService
                                .getSucessDialog('Error', error.error.error[0].message, false)
                                .afterClosed();
                            } else {
                              this.dialogService
                                .getSucessDialog('Error', error, false)
                                .afterClosed();
                            }
                          });
                    }
                  });
              }
            } else if (
              this.validateUserPreference.communicationPreference &&
              this.validateUserPreference.isTwoFactorEnabled
            ) {
              if (this.validateUserPreference.communicationPreference.toLowerCase() === 'email'
                || this.validateUserPreference.communicationPreference.toLowerCase() === 'both') {
                this.sharedService.emailTimeOutCounter = this.sharedService.emailTimeOutCounter + 1;
              } else {
                this.sharedService.mobileTimeOutCounter = this.sharedService.mobileTimeOutCounter + 1;
              }
              this.showAuthenticationBox = true;
              this.verifiedAuthenticationCode = true;
              this.verifiedUserCredentials = true;
            } else if (this.validateUserPreference.communicationPreference) {
              this.authenticate(
                this.validateUserPreference.communicationPreference,
                'login',
                'send',
                this.validateUserPreference.emailAddress,
                this.validateUserPreference.mobileNumber,
                '000000'
              );
            }
          }
        },
        error => {
          this.user.password = '';
          this.showAuthenticationBox = false;
          this.verifiedAuthenticationCode = false;
          this.verifiedUserCredentials = false;
          if (error != null && error.error != null && error.error !== undefined && error.error.error !== null
            && error.error.error !== undefined) {
            this.dialogService
              .getSucessDialog('Error', error.error.error[0].message, false)
              .afterClosed();
          } else {
            this.dialogService
              .getSucessDialog('Error', error, false)
              .afterClosed();
          }
        }
      );
    }
  }

  authenticate(
    type: string,
    purposeType: string,
    option: string,
    emailAddress: string,
    mobileNumber: string,
    code: string
  ) {
    this.user.authModel = {
      type,
      purposeType,
      option,
      emailAddress,
      mobileNumber,
      otp: code,
      isSendAsSMS: this.isSendAsSMS
    };
    this.securityService.LoginUser(this.user).subscribe(
      resp => {
        this.securityObject = resp;
        if (this.securityObject.isAuthenticated) {
          this.showAuthenticationBox = false;
          if (this.returnUrl) {
            this.router.navigate(['/dashboard']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
      },
      error => {
        this.clear(purposeType);
        if (error != null && error.error != null && error.error !== undefined) {
          this.dialogService
            .getSucessDialog('Error', error.error.error[0].message, false)
            .afterClosed();
        } else {
          this.dialogService
            .getSucessDialog('Error', error, false)
            .afterClosed();
        }
      }
    );
  }

  onKeyup(
    evt,
    next,
    prev,
    type = null,
    purposeType = null,
    option = null,
    emailAddress = null,
    phoneNumber = null
  ) {
    this.type = type;
    this.purposeType = purposeType;
    this.option = option;
    this.emailAddress = emailAddress;
    this.phoneNumber = phoneNumber;
    if (
      (this.onKeydown(evt) || evt.code === 'ArrowRight') &&
      next !== '' &&
      evt.key !== 'Backspace'
    ) {
      next.focus();
    }
    if ((evt.key === 'Backspace' || evt.code === 'ArrowLeft') && prev !== '') {
      prev.focus();
    }
  }

  onKeydown(event) {
    const pattern = /^\d*[0-9](|.\d*[0-9])?$/;
    if (event.keyCode !== 8 && !pattern.test(event.key)) {
      return false;
    }

    return true;
  }

  clear(type) {
    $(`.${type}.authenticationcode`).each(function() {
      $(this).val('');
    });

    if (this.firstOtpBox) {
      this.firstOtpBox.nativeElement.focus();
    }
  }

  resendStatus(type) {
    if (type.toLowerCase() === 'email' || type.toLowerCase() === 'both') {
      this.emailTimer = this.sharedService.checkEmailTimer();
      return this.emailTimer === 0;
    }
    if (type.toLowerCase() === 'mobile') {
      this.mobileTimer = this.sharedService.checkMobileTimer();
      return this.mobileTimer === 0;
    }
  }

  resend(type, purposeType, option, emailAddress, phoneNumber) {
    this.showSpamMsg = (type.toLowerCase() === 'email' || type.toLowerCase() === 'both') ? true : false;

    if (this.resendStatus(type)) {
      this.clear(purposeType);
      this.isSendAsSMS = false;
      if (this.tenantConfigSettings.isMobileAppConfigured) {
        this.identityManagerRegistrationService.GetDeviceSettings(this.validateUserPreference.staffNumber)
          .subscribe((data: IUserDeviceSettings) => {
            this.userDeviceSettings = <IUserDeviceSettings>data;
            if ((this.validateUserPreference.communicationPreference.toLowerCase() === 'mobile'
              || this.validateUserPreference.communicationPreference.toLowerCase() === 'both')
              && this.userDeviceSettings.deviceToken != null && this.userDeviceSettings.deviceToken != null) {
              this.sendAuthenticationCodeToEmployee(type,
                purposeType,
                option,
                emailAddress,
                phoneNumber);
            } else {
              this.sendAuthenticationCode(
                type,
                purposeType,
                option,
                emailAddress,
                phoneNumber
              );
            }
          });
      } else {
        this.sendAuthenticationCode(
          type,
          purposeType,
          option,
          emailAddress,
          phoneNumber
        );
      }
    }
  }

  sendAuthenticationCode(
    type: string,
    purposeType: string,
    option: string,
    emailAddress: string,
    mobileNumber: string,
    isSendAsSMS: boolean = false
  ) {
    this.emailConfiguration = this.tenantConfigSettings.emailingConfiguration.isEmailingOn;
    const authModel: IAuthModel = {
      type,
      purposeType,
      option,
      emailAddress,
      mobileNumber,
      otp: null
    };
    this.isSendAsSMS = isSendAsSMS;
    if (this.resendStatus(type)) {
      this.identityManagerRegistrationService
        .SendAuthenticationCode(authModel)
        .subscribe(
          response => {
            if (response != null) {
              this.showAuthenticationBox = response;
            }
          },
          error => {
            this.showAuthenticationBox = false;
            this.verifiedAuthenticationCode = false;
            this.dialogService
              .getSucessDialog('Error', error.error.error[0].message, false)
              .afterClosed();
          }
        );
    }
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
    body.classList.add('off-canvas-sidebar');
    const card = document.getElementsByClassName('card')[0];
    setTimeout(() => {
      // after 1000 ms we add the class animated to the login/register card
      card.classList.remove('card-hidden');
    }, 700);
    this.createForm();
    this.passwordSameError = this.appMessagesService.appStaticMessages.Employee.PasswordMatchingWithCurrentPassword;
    this.loadTenantSettings();
    this.slides = this.appMessagesService.appStaticMessages.AppLevel.SliderContent;
  }
  sidebarToggle() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
      setTimeout(() => {
        toggleButton.classList.add('toggled');
      }, 500);
      body.classList.add('nav-open');
      this.sidebarVisible = true;
    } else {
      this.toggleButton.classList.remove('toggled');
      this.sidebarVisible = false;
      body.classList.remove('nav-open');
    }
  }
  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
    body.classList.remove('off-canvas-sidebar');
  }

  validateAndRedirect() {
    if (this.user.userName.trim().length > 0 && !this.verifiedUserCredentials) {
      this.identityManagerRegistrationService
        .ValidateUsernameV2(this.user.userName.trim())
        .subscribe(
          response => {
            if (response != null) {
              if (response === '-1') {
                this.dialogService
                  .getSucessDialog(
                    'Error',
                    this.appMessagesService.appStaticMessages.AppLevel.ProfileNotActivated,
                    false
                  )
                  .afterClosed();
              } else {
                window.sessionStorage.setItem('AnswersFailedCount', '0');
                window.sessionStorage.setItem('RecoveryAnswersFailedCount', '0');
                this.router.navigate(['/pages/password/reset', response]);
              }
            } else {
              this.dialogService
                .getSucessDialog(
                  'Error',
                  this.appMessagesService.appStaticMessages.Employee.EnterValidUsername,
                  false
                )
                .afterClosed();
            }
          },
          error => {
            if (error != null && error.error != null && error.error !== undefined && error.error.error !== null
              && error.error.error !== undefined) {
              this.dialogService
                .getSucessDialog('Error', error.error.error[0].message, false)
                .afterClosed();
            } else {
              this.dialogService
                .getSucessDialog('Error', error, false)
                .afterClosed();
            }
          }
        );
    } else {
      this.dialogService
        .getSucessDialog(
          'Error',
          this.appMessagesService.appStaticMessages.Employee.EnterUsername,
          false
        )
        .afterClosed();
    }
  }

  // Reset password
  createForm() {
    this.passwordForm = this.formBuilder.group({
      passwordGroup: this.formBuilder.group(
        {
          currentPassword: ['', [Validators.required]],
          password: ['', [Validators.required]],
          confirmPassword: ['', [Validators.required]]
        },
        { validator: this.validatePasswords }
      )
    });
  }
  validatePasswords(c: any) {
    if (c.get('password').value !== c.get('confirmPassword').value) {
      return { invalid: true };
    }
    return { invalid: null };
  }
  toggleLoginPasswordShow() {
    this.showLoginPassword = !this.showLoginPassword;
    if (this.showLoginPassword) {
      this.loginPasswordType = 'text';
      this._loginPwdText = 'visibility';
    } else {
      this.loginPasswordType = 'password';
      this._loginPwdText = 'visibility_off';
    }
  }
  toggleCurrentPasswordShow() {
    this.showCurrentPassword = !this.showCurrentPassword;
    if (this.showCurrentPassword) {
      this.currentPasswordType = 'text';
      this._curPwdText = 'visibility';
    } else {
      this.currentPasswordType = 'password';
      this._curPwdText = 'visibility_off';
    }
  }
  togglePasswordShow() {
    this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.passwordType = 'text';
      this._pwdText = 'visibility';
    } else {
      this.passwordType = 'password';
      this._pwdText = 'visibility_off';
    }
  }
  toggleConfirmPasswordShow() {
    this.showConfirmPassword = !this.showConfirmPassword;
    if (this.showConfirmPassword) {
      this.confirmPasswordType = 'text';
      this._cnfrmPwdText = 'visibility';
    } else {
      this.confirmPasswordType = 'password';
      this._cnfrmPwdText = 'visibility_off';
    }
  }
  clearError() {
    this.isError = false;
    this.errorMessage = '';
  }
  RedirectToLogin() {
    this.passwordReset = false;
  }

  redirectToLogin() {
    this.dialogService
      .getConfirmDialogForReset()
      .afterClosed()
      .subscribe(result => this.Redirect(result));
  }

  Redirect(result) {
    if (result === DialogEnum.Confirm) {
      this.passwordForm.reset();
      this.RedirectToLogin();
    }
  }
  setPasswordValidators() {
    let passwordPattern = '^';
    let subPattern = '';
    this.passwordErrorMsgPattern = '';
    let isRegexConfigured = false;
    if (this.tenantConfigSettings.passwordConfiguration.isAlpha) {
      passwordPattern = passwordPattern + '(?=.*?[a-zA-Z])';
      subPattern = 'a-zA-Z';
      this.passwordErrorMsgPattern = 'alphabets, ';
      isRegexConfigured = true;
    }
    if (this.tenantConfigSettings.passwordConfiguration.isNumeric) {
      passwordPattern = passwordPattern + '(?=.*?[0-9])';
      subPattern = subPattern + '0-9';
      this.passwordErrorMsgPattern = this.passwordErrorMsgPattern + 'numbers ';
      isRegexConfigured = true;
    }
    if (this.tenantConfigSettings.passwordConfiguration.isCharacters) {
      passwordPattern =
        passwordPattern +
        '(?=.*?[' +
        this.tenantConfigSettings.passwordConfiguration
          .allowedSpecialCharacters +
        '])';
      subPattern =
        subPattern +
        this.tenantConfigSettings.passwordConfiguration
          .allowedSpecialCharacters;
      this.passwordErrorMsgPattern =
        this.passwordErrorMsgPattern + '& symbols (!, $, -, etc..)';
      isRegexConfigured = true;
    }
    this.passwordErrorMsgPattern = this.passwordErrorMsgPattern.replace(/(.+)(, )$/, '$1');
    this.passwordErrorMsgPattern = this.passwordErrorMsgPattern.replace(/^& (.+)/, '$1');
    this.passwordErrorMsgPattern = this.passwordErrorMsgPattern.replace(/^(.+)(, and )(.+)$/, '$1' + ' and ' + '$3');
    const lengthPattern =
      '{' +
      this.tenantConfigSettings.passwordConfiguration.minimumLength +
      ',' +
      this.tenantConfigSettings.passwordConfiguration.maximumLength +
      '}';

    if (isRegexConfigured === true) {
      passwordPattern =
        passwordPattern + '[' + subPattern + ']' + lengthPattern + '$';
    } else {
      passwordPattern = '.' + lengthPattern + '$';
    }

    this.passwordErrorMsgPattern =
      'Please enter ' +
      this.tenantConfigSettings.passwordConfiguration.minimumLength +
      ' to ' +
      this.tenantConfigSettings.passwordConfiguration.maximumLength +
      ' characters' +
      (this.passwordErrorMsgPattern != null
        ? ' of ' + this.passwordErrorMsgPattern
        : '');

    this.passwordForm.controls.passwordGroup
      .get('password')
      .setValidators([
        Validators.pattern(passwordPattern),
        this.passwordForm.controls.passwordGroup.get('password').validator
      ]);
    this.passwordForm.controls.passwordGroup
      .get('password')
      .updateValueAndValidity();
    this.passwordForm.controls.passwordGroup
      .get('currentPassword')
      .updateValueAndValidity();
  }
  loadTenantSettings() {
    this.identityManagerRegistrationService
      .GetTenantConfigurationSettings()
      .subscribe((response: ITenantConfigurationSettings) => {
        this.tenantConfigSettings = <ITenantConfigurationSettings>response;
        this.sharedService.maxAttemptsForResend =
          parseInt(this.tenantConfigSettings.clientGlobalSettings['MaxAttemptsforResend'], 10);
        this.sharedService.maxSecondsToWaitBeforeResend =
          parseInt(this.tenantConfigSettings.clientGlobalSettings['MaxSecondsToWaitBeforeResend'], 10);
        const index = this.tenantConfigSettings.country.findIndex(x => x.countryName.toLowerCase() === this.tenantConfigSettings.location);
        if (index !== -1) {
          this.dateService.dateFormat = this.tenantConfigSettings.country[index].dateFormat;
        }
        if (this.tenantConfigSettings.clientGlobalSettings && this.tenantConfigSettings.clientGlobalSettings.IsTwoFactorEnabled
          && this.tenantConfigSettings.clientGlobalSettings.IsTwoFactorEnabled === '1') {
          this.isTwoFactorEnabled = true;
        }
        this.setPasswordValidators();
      });

    this.passwordForm.valueChanges.subscribe(_res => (this.isDirty = this.passwordForm.dirty)
    );
    this.passwordForm.valueChanges.subscribe(_res => {
      this.isFormValid = this.passwordForm.valid && this.passwordForm.dirty;
    });
    this.passwordForm.valueChanges.subscribe(_res => (this.isChangedPasswordValid = this.isPasswordFormValid())
    );
  }

  isPasswordFormValid() {
    this.isChangedPasswordValid = false;
    if (
      this.passwordForm.controls.passwordGroup.get('password') != null &&
      this.passwordForm.controls.passwordGroup.get('password').value &&
      this.passwordForm.controls.passwordGroup.get('password').valid &&
      this.passwordForm.controls.passwordGroup.get('confirmPassword') != null &&
      this.passwordForm.controls.passwordGroup.get('confirmPassword').value &&
      this.passwordForm.controls.passwordGroup.get('confirmPassword').valid
    ) {
      this.isChangedPasswordValid = true;
    }

    if (this.isChangedPasswordValid) {
      const res = this.validatePasswords(
        this.passwordForm.controls.passwordGroup
      );
      if (!res.invalid) {
        this.isFormValid = true;
        return true;
      }
    }

    this.isFormValid = false;
    return false;
  }
  currentPasswordCheck() {
    const currentPassword = crypto.SHA256(this.passwordForm.controls.passwordGroup.get('currentPassword').value)
      .toString(crypto.enc.Base64);
    if (currentPassword !== this.user.password) {
      this.currentPasswordCheckError = true;
    } else {
      this.currentPasswordCheckError = false;
    }
  }

  resetPassword() {
    const passwordGroup = {
      staffNumber: '',
      password: crypto.SHA256(this.passwordForm.controls.passwordGroup.get('confirmPassword').value).toString(crypto.enc.Base64)
    };
    if (!this.currentPasswordCheckError && this.passwordForm.controls.passwordGroup.get('currentPassword').value &&
      this.passwordForm.controls.passwordGroup.get('confirmPassword').value ===
      this.passwordForm.controls.passwordGroup.get('password').value
    ) {
      if (
        (this.passwordForm.controls.passwordGroup.get('confirmPassword')
          .value === this.user.password)
      ) {
        this.dialogService
          .getSucessDialog(
            'Error',
            this.appMessagesService.appStaticMessages.Employee
              .PasswordMatchingWithCurrentPassword,
            false
          )
          .afterClosed();
      } else {
        this.identityManagerRegistrationService
          .ResetEmployeePassword(passwordGroup)
          .subscribe(response => {
            if (response === -1) {
              this.dialogService
                .getSucessDialog(
                  'Error',
                  this.appMessagesService.appStaticMessages.Employee
                    .PasswordMatchingWithOldPasswords,
                  false
                )
                .afterClosed();
            } else if (response === 1) {
              this.passwordReset = true;
              this.passwordForm.reset();
              this.dialogService
                .getSucessDialog(
                  'Sucess',
                  this.appMessagesService.appStaticMessages.Employee.PasswordUpdateSuccesful,
                  true
                )
                .afterClosed();
              this.RedirectToLogin();
            }
          });
      }
    }
  }

  sendAuthenticationCodeToEmployee(type: string, purposeType: string, option: string, emailAddress: string, mobileNumber: string) {
    const authModel: IAuthModel = {
      type,
      purposeType,
      option,
      emailAddress,
      mobileNumber,
      otp: null,
    };

    authModel['deviceId'] = this.userDeviceSettings.deviceId;
    authModel['deviceToken'] = this.userDeviceSettings.deviceToken;
    authModel['hasValidToken'] = this.userDeviceSettings.hasValidToken;
    this.identityManagerRegistrationService.SendAuthenticationCodeToEmployee(authModel, this.validateUserPreference.staffNumber)
      .subscribe(response => {
        if (response != null) {
          this.showAuthenticationBox = response;
        }
      },
        error => {
          this.showAuthenticationBox = false;
          this.verifiedAuthenticationCode = false;
          this.dialogService
            .getSucessDialog('Error', error.error.error[0].message, false)
            .afterClosed();
        });
  }
}
