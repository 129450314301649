import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { SecurityService } from './services/security.service';
import { DialogService } from './shared/dialog/dailog.service';
import { AppMessagesService } from './shared/services/app-messages.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private securityService: SecurityService, private dialogService: DialogService,
              public appMessagesService: AppMessagesService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let pathTovalidate = '';
    if (next.parent.url.length > 0) {
      pathTovalidate = `/${next.parent.url[0].path}/${next.url[0].path}`;
    }

    if (next.url.length > 0 && this.securityService.securityObject != null && this.securityService.securityObject.claims != null) {
      const claimName: string = next.data.claimName;
      this.securityService.dontNotify = next.data.dontNotify ? true : false;
      let oldRoleId = this.securityService.securityObject.currentRoleId;
      switch (claimName.toLowerCase()) {
        case 'superadministrator':
          this.securityService.securityObject.selectedMenu = 'App Manager';
          this.securityService.securityObject.currentRoleId = 1;
          this.securityService.securityObject.isCurrentRoleActive = this.securityService.securityObject.claims.superAdministrator.isActive;
          break;
        case 'paymanager':
          this.securityService.securityObject.selectedMenu = 'Pay Manager';
          this.securityService.securityObject.currentRoleId = 2;
          this.securityService.securityObject.isCurrentRoleActive = this.securityService.securityObject.claims.payManager.isActive;
          break;
        case 'helpdeskmanager':
          this.securityService.securityObject.selectedMenu = 'Help Desk Manager';
          this.securityService.securityObject.currentRoleId = 3;
          this.securityService.securityObject.isCurrentRoleActive = this.securityService.securityObject.claims.helpDeskManager.isActive;
          break;
        case 'helpdeskadministrator':
          this.securityService.securityObject.selectedMenu = 'Help Desk Assistant';
          this.securityService.securityObject.currentRoleId = 4;
          this.securityService.securityObject.isCurrentRoleActive =
            this.securityService.securityObject.claims.helpDeskAdministrator.isActive;
          break;
        case 'employee':
          this.securityService.securityObject.selectedMenu = 'Employee';
          this.securityService.securityObject.currentRoleId = 5;
          this.securityService.securityObject.isCurrentRoleActive = true;
          break;
        default:
          this.securityService.securityObject.selectedMenu = 'Employee';
          this.securityService.securityObject.currentRoleId = 5;
          this.securityService.securityObject.isCurrentRoleActive = true;
          break;
      }

      let newRoleId = this.securityService.securityObject.currentRoleId;

      if (oldRoleId !== newRoleId) {
        this.securityService.RefreshAuthTokenWithRole();
      }
      const result = this.securityService.securityObject.claims[claimName];
      let isAccessible = false;
      if (result != null) {
        isAccessible = result.isActive &&
          result.roleMenu.childMenus.filter(x => x.path.indexOf(pathTovalidate !== '' ? pathTovalidate : state.url) >= 0).length > 0;
      }
      if (this.securityService.securityObject != null && this.securityService.securityObject.isAuthenticated && isAccessible) {
        return true;
      }
      this.dialogService.getSucessDialog('Logout', this.appMessagesService.appStaticMessages.AppLevel.NoAccess, false)
        .afterClosed().subscribe(res => {
          if (res === 0) {
            this.securityService.Logout();
            window.open('?returnUrl=', '_self');
          }
        });
      return false;
    }
    window.open('?returnUrl=', '_self');
    return false;
  }
}
