<div class="pos_r">
  <div class="position-relative">
    <div class="PopCloseIcon" (click)="close()"><i class="material-icons">close</i></div>
  </div>
  <div>
    <h4 class="text-center nopadding mb15 text-uppercase text-grey font400 pt15 font16">SECURITY QUESTIONS</h4>
  </div>
  <div class="alert alert-danger cus_alert_pos" *ngIf="isError">
    <div class="container-fluid">
      <div class="alert-icon">
        <i class="material-icons">error_outline</i>
      </div>
      <span>{{errorMessage}}</span>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true"><i class="material-icons">clear</i></span>
      </button>
    </div>
  </div>
  <form [formGroup]="securityQuestionsForm">
    <div class="row">
      <div class="col-md-6" formArrayName="securityQuestionsArray"
        *ngFor="let item of securityQuestionsForm.get('securityQuestionsArray')['controls']; let i = index">
        <div [formGroupName]="i">
          <div class="">
            <mat-form-field>
              <input matInput placeholder='{{securityQuestions[i].question}}' formControlName="Answer"
                (input)="onInputChange($event)">
              <mat-error *ngIf="item.controls.Answer.errors?.required">{{ appMessagesService.personalRequiredMessages("answer") }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="container pl0 pr0">
    <div class="col-md-12 pl0 pr0">
      <div class="td-actions text-right">
        <button type="button" rel="tooltip" [ngClass]="isDisabled ? 'btn-disabled cursor-default' : '' " (click)="reset()"
          class="btn btn-danger btn-round btn-fab btn-sm">
          <i class="material-icons">close</i>
        </button>
        <button type="button" rel="tooltip" [ngClass]="!isFormValid ? 'btn-disabled cursor-default' : '' " (click)="save()"
          class="btn btn-success btn-round btn-fab btn-sm">
          <i class="material-icons">check</i>
        </button>
      </div>
    </div>
  </div>
</div>
