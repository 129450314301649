import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { DocumentModel } from '../../../Interfaces/documentLibrary/documentModel';
import { ITicketData } from '../../../Interfaces/helpdesk/ITicketData';
import { SecurityService } from '../../../services/security.service';
import { UserProfileService } from '../../../services/user-profile.service';
import { DialogService } from '../../../shared/dialog/dailog.service';
import { DialogEnum } from '../../../shared/dialog/DialogEnum';
import { AppMessagesService } from '../../../shared/services/app-messages.service';

@Component({
  selector: 'app-create-ticket-dialog',
  templateUrl: './create-ticket-dialog.component.html',
  styleUrls: ['./create-ticket-dialog.component.scss']
})

export class CreateTicketDialogComponent implements OnInit {

  @ViewChild('ticketTypes', { static: false }) ticketTypes;
  addTicketFormGroup: FormGroup;
  uploadedFiles: any[] = [];
  filesInvalidStatus = false;
  duplicateFiles: string = '';
  sizeExceededFiles: string = '';
  isFileValid: boolean = true;
  fileExtensions: string[] = [];
  adminDocument: DocumentModel;
  ticketType: any;
  popUpSelected = false;
  constructor(
    public appMessagesService: AppMessagesService,
    private eRef: ElementRef,
    private securityService: SecurityService,
    private formBuilder: FormBuilder,
    private employeeApiService: UserProfileService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<CreateTicketDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogRef.disableClose = true;
    this.adminDocument = new DocumentModel();
    this.fileExtensions = ['pdf', 'doc', 'docx', 'docb', 'rtf', 'txt', 'xlsx', 'xlsm', 'JPG', 'JPEG', 'jpg', 'jpeg', 'PNG', 'png', 'msg'];
    this.adminDocument.docFiles = [];
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target) && this.popUpSelected) {
      this.ticketTypes.close();
    }
  }

  ngOnInit() {
    this.dialogRef.updateSize('50%', 'auto');
    this.createFormGroup();
    this.getTicketTypes();
  }

  getTicketTypes() {
    this.employeeApiService.GetTicketTypes().subscribe(response => {
      this.ticketType = response;
    });
  }
  openedChange(opened: boolean) {
    if (opened) {
      this.popUpSelected = true;
    } else {
      this.popUpSelected = false;
    }
  }
  createFormGroup() {
    this.addTicketFormGroup = this.formBuilder.group({
      ticketType: ['', Validators.required],
      file: [null],
      subject: ['', Validators.compose([Validators.required, Validators.maxLength(140)])],
      description: ['', Validators.compose([Validators.required, Validators.maxLength(1000)])]
    });
  }

  onSelectFile(event) {
    this.filesInvalidStatus = false;
    this.duplicateFiles = '';
    this.sizeExceededFiles = '';
    if (this.uploadedFiles) {
      if ((this.uploadedFiles.length + event.target.files.length) <= 20) {
        for (let findex = 0; findex < event.target.files.length; findex++) {
          this.isFileValid = true;
          const element = event.target.files[findex];
          const splitArray: any = element.name.split('.');
          if (!this.fileExtensions.includes(splitArray[splitArray.length - 1])) {
            this.filesInvalidStatus = true;
            this.isFileValid = false;
          }
          if (this.uploadedFiles.filter(x => x.name.toLowerCase() === element.name.toLowerCase()).length > 0) {
            this.duplicateFiles = (
              this.duplicateFiles !== '' ? this.duplicateFiles + ', \'' + element.name + '\'' : '\'' + element.name + '\'');
            this.isFileValid = false;
          }
          if ((element.size / 1024) / 1024 > 10) {
            this.sizeExceededFiles = (
              this.sizeExceededFiles !== '' ? this.sizeExceededFiles + ', \'' + element.name + '\'' : '\'' + element.name + '\'');
            this.isFileValid = false;
          }
          if (this.isFileValid) {
            this.adminDocument.docFiles.push({ fileName: element.name, fileSize: (element.size / 1024) / 1024 });
            this.uploadedFiles.push(element);
          }
        }
        if (this.filesInvalidStatus) {
          this.dialogService.getErrorDialogBoxWithMessage(
            this.appMessagesService.appStaticMessages.ClientAdmin.AttachingInvalidFileExtension);
          return;
        }
        if (this.duplicateFiles !== '') {
          this.dialogService.getErrorDialogBoxWithMessage(
            this.appMessagesService.appStaticMessages.ClientAdmin.AttachingDuplicateFiles + this.duplicateFiles);
          return;
        }
        if (this.sizeExceededFiles !== '') {
          this.dialogService.getErrorDialogBoxWithMessage(
            this.appMessagesService.appStaticMessages.ClientAdmin.AttachingSizeExceededFiles + this.sizeExceededFiles);
          return;
        }
      } else {
        this.dialogService.getErrorDialogBoxWithMessage(this.appMessagesService.appStaticMessages.ClientAdmin.NumberOfFilesUpload);
      }
    }
  }

  removeFile(i) {
    if (i >= 0) {
      this.adminDocument.docFiles.splice(i, 1);
      this.uploadedFiles.splice(i, 1);
    }
  }

  save() {
    if (this.addTicketFormGroup.valid) {
      const newTicket = new ITicketData();
      newTicket.subject = this.addTicketFormGroup.controls.subject.value;
      newTicket.description = this.addTicketFormGroup.value.description;
      newTicket.ticketTypeId = this.addTicketFormGroup.get('ticketType').value;
      newTicket.ticketStatusId = 1;
      newTicket.staffNumber = this.securityService.securityObject.staffNumber;
      const formdata = new FormData();
      formdata.append('data', JSON.stringify(newTicket));
      this.uploadedFiles.forEach(element => {
        formdata.append('files', element);
      });
      this.employeeApiService.AddNewTicket(formdata, 5).subscribe(response => {
        if (response) {
          this.dialogService.getSucessDialog('Success',
            this.appMessagesService.appStaticMessages.Employee.EmployeeTicketCreatedSuccessfully + response + '.', true)
            .afterClosed().subscribe(_response => {

            });
          this.dialogRef.close();
        }
      });
    }
  }

  escapeHtml(unsafe) {
    return unsafe.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;').replace(/'/g, '&#039;');
  }

  close() {
    this.dialogRef.close();
  }

  reset() {
    if (this.addTicketFormGroup.dirty) {
      this.dialogService.getConfirmDialogForReset().afterClosed().subscribe(response => {
        if (response === DialogEnum.Confirm) {
          this.addTicketFormGroup.reset();
         // this.dialogRef.close();
        }
      });
    }
  }
}
