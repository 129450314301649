<div class="pt30 pb30 pop_dialog" style="background:#ffffff;">
    <div class="swal2-icon swal2-warning pulse-warning text-center line-height50" *ngIf="!dialogData.isShow">
        <div class="font50 pt15">!</div>
    </div>
    <div class="swal2-icon pulse-success text-center line-height50" *ngIf="dialogData.isShow">
        <div class="font50 pt15">
            <img src="assets/img/success.png" alt="success"/>
        </div>
    </div>
    <h2 align="center" class="font20 font500" [innerHTML]='dialogData.BodyText'></h2>
    <div align="center" *ngIf="dialogData.isShow">
        <button mat-button (click)="onCloseConfirm()"
            class="swal2-confirm btn btn-success">{{dialogData.TittleText.toLowerCase() == 'logout' ? 'Login' : 'OK'}}</button>
    </div>
    <div align="center" *ngIf="!dialogData.isShow">
        <button mat-button (click)="onCloseConfirm()"
            class="swal2-confirm btn btn-danger">{{dialogData.TittleText.toLowerCase() == 'logout' ? 'Login' : 'OK'}}</button>
    </div>
</div>
