import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-actions-component',
  templateUrl: './actions.component.html'
})

export class ActionsComponent implements OnInit, OnChanges {
  @Input()
  enableReset: boolean;

  @Input()
  resetWithoutChange = false;

  @Input()
  enableSave: boolean;

  enableEdit: boolean;

  @Output()
  editClickEvent = new EventEmitter();

  @Output()
  resetClickEvent = new EventEmitter();

  @Output()
  saveClickEvent = new EventEmitter();

  @Input()
  showSave = true;

  @Input()
  showEdit = true;

  @Input()
  showCheck = true;

  @Input()
  showEditButton = true;

  @Input()
  enableResetForNonEdit: boolean;

  @Input()
  enableSaveForNonEdit: boolean;

  @Output()
  resetClickEventForNonEdit = new EventEmitter();

  @Output()
  saveClickEventForNonEdit = new EventEmitter();

  ngOnInit() {
    this.enableEdit = true;
    this.enableReset = false;
    this.enableSave = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'enableReset' && changes[propName].previousValue !== undefined && changes[propName].currentValue === false) {
        this.enableEdit = true;
      } else if (propName === 'resetWithoutChange' && changes[propName].currentValue !== changes[propName].previousValue) {
        this.enableEdit = true;
        this.enableReset = false;
        this.enableSave = false;
      }
    }
  }

  edit() {
    this.enableEdit = false;
    this.enableReset = true;
    this.editClickEvent.emit();
  }

  reset() {
    this.resetClickEvent.emit();
  }

  save() {
    this.saveClickEvent.emit();
  }

  resetForNonEdit() {
    this.resetClickEventForNonEdit.emit();
  }

  saveForNonEdit() {
    this.saveClickEventForNonEdit.emit();
  }
}
