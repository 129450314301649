import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppMessagesService } from '../../shared/services/app-messages.service';
import { BlockchainService } from './../../services/blockchain.service';

@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.scss']
})
export class DocumentDetailsComponent implements OnInit {

  blockIdFromRoute: string;
  data: any;
  items: any[];
  isBlockAvailable = false;
  companyLogo: string;
  documentAlias: string;
  template: string;
  documentUrl: string;
  docType: string;

  constructor(private route: ActivatedRoute,
              public appMessagesService: AppMessagesService,
              private documentDetailsService: BlockchainService) {
  }

  ngOnInit() {
    this.isBlockAvailable = true;
    this.route.paramMap.subscribe(params => {
      this.blockIdFromRoute = params.get('blockid');
      this.documentAlias = params.get('documentAlias');
    });

    if (this.blockIdFromRoute != null && this.blockIdFromRoute.length > 0) {
      this.documentDetailsService.getDocumentDetails(this.blockIdFromRoute, this.documentAlias).subscribe((response: any) => {
        if (response) {
          this.companyLogo = response.ClientLogoUrl;
          if (response.template || response.documentUrl || response.documentData) {
            this.template = response.template;
            this.documentUrl = response.documentUrl ? response.documentUrl : 'data:application/pdf;base64,' + response.documentData;
            this.docType = response.documentType;
          } else {
            this.isBlockAvailable = false;
          }
        } else {
          this.isBlockAvailable = false;
        }
      },
        (() => {
          // this.isBlockAvailable = true;
        }));
    } else {
      this.isBlockAvailable = false;
    }
  }
}
