<div class="prev_btn h">
    <div class="PopCloseIcon" (click)="close()"><i class="material-icons">close</i></div>
</div>
<div>
    <div>
        <div class="popuplogo"><img alt="logo"
                src="../../../../assets/img/popIcons/logo.svg"></div>
                <div>Version 2.1.1</div>
                <h3>About Reganto</h3>
        <p class="fontstyle-h">Reganto presents a distinct interactive platform that provides employees with a dashboard for accessing their complete historical pay information. This includes access to payslips, pension statements, and P60s spanning their entire career. Utilizing data visualization, users can receive both educational and informative insights into their historical pay data. This platform offers employees a straightforward and comprehensive overview of their data, while also making it effortless for them to explore the finer details of their payslips when needed. The payslip is presented in a clear and easily understandable format, free from technical jargon. The entire platform is accessible through the web or a fully immersive mobile application.

        </p>
    </div>
</div>