import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { NavigationEnd, Router } from '../../../node_modules/@angular/router';
import { ValidationHelper } from '../../helpers/validation-helper';
import { IUserCommunication } from '../Interfaces/identitymanagement/IUserCommunication';
import { IValidatedUserPreference } from '../Interfaces/identitymanagement/IValidatedUserPreference';
import { IQuestion } from '../Interfaces/user/IQuestions';
import { RoleActivationService } from '../services/role-activation.service';
import { SharedService } from '../services/shared.service';
import { DialogService } from '../shared/dialog/dailog.service';
import { DialogEnum } from '../shared/dialog/DialogEnum';
import { AppMessagesService } from '../shared/services/app-messages.service';

@Component({
  selector: 'app-role-activation',
  templateUrl: './role-activation.component.html',
  styleUrls: ['./role-activation.component.scss']
})
export class RoleActivationComponent implements OnInit {

  isDirty = false;
  isDisabled = true;
  isFormValid = false;
  securityQuestions: IQuestion[];
  securityQuestionsForm: FormGroup;
  userCommunicationDetails: IUserCommunication;
  errorMessage: string = null;
  isError = false;
  roleId: number;
  staffNumber: string;
  status = false;
  validateUserPreference: IValidatedUserPreference;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public router: Router,
              private formBuilder: FormBuilder,
              public appMessagesService: AppMessagesService,
              public dialog: MatDialog,
              private roleActivationService: RoleActivationService,
              private dialogService: DialogService,
              private sharedService: SharedService,
              public dialogRef: MatDialogRef<RoleActivationComponent>) {

    router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.dialogRef.close();
      }
    });

    this.roleId = this.data.roleId;
    this.staffNumber = this.data.staffNumber;
    this.securityQuestions = this.data.securityQuestions.filter(x => x.type.toLowerCase() !== 'hintquestions');
  }

  ngOnInit() {

    this.dialogRef.updateSize('50%', 'auto');
    this.createForm();
    this.updateClient(this.securityQuestions);
  }

  createForm() {

    this.securityQuestionsForm = this.formBuilder.group({
      securityQuestionsArray: new FormArray([])
    });
  }

  updateClient(securityQuestions: IQuestion[]) {

    this.createRoleDisplayUnit(securityQuestions);
  }

  createRoleDisplayUnit(securityQuestions: IQuestion[]) {

    if (securityQuestions) {
      const control = <FormArray>this.securityQuestionsForm.get('securityQuestionsArray');
      this.clearFormArray(control);
      control.reset();
      for (const question of securityQuestions) {
        control.push(this.initializeRoleDisplayUnit(question));
      }
    }
  }

  clearFormArray(formArray: FormArray) {

    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  initializeRoleDisplayUnit(question: IQuestion) {

    if (question) {
      return this.formBuilder.group({
        Id: [question.id],
        Type: [question.type],
        Question: [question.question],
        Answer: [question.answer, [Validators.required, ValidationHelper.hasWhiteSpaces()]]
      });
    }
  }

  onInputChange(_event) {

    this.isDirty = true;
    this.isError = false;
    this.isDisabled = false;
    this.securityQuestionsForm.markAsDirty();
    this.isFormValid = this.securityQuestionsForm.valid;
  }

  reset() {

    if (this.securityQuestionsForm.dirty) {
      this.dialogService.getConfirmDialogForReset().afterClosed().subscribe(result => {
          if (result === DialogEnum.Confirm) {
            this.securityQuestionsForm.reset();
            this.updateClient(this.securityQuestions);
            this.isDirty = false;
            this.isError = false;
            this.isDisabled = true;
            this.isFormValid = false;
          }
        }
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  save() {

    if (this.isFormValid && this.isDirty) {
      this.isError = false;
      const validateAnswers: IQuestion[] = this.securityQuestionsForm.get('securityQuestionsArray').value;
      this.ValidateAnswersAndSendOTP(this.roleId, validateAnswers);
    }
  }

  ValidateAnswersAndSendOTP(roleId: number, data: IQuestion[]) {

    this.roleActivationService.ValidateAndSendOTP(roleId, data).subscribe(
      (response: IValidatedUserPreference) => {
        this.validateUserPreference = response;
        if (!this.validateUserPreference) {
          this.isError = true;
          this.errorMessage = this.appMessagesService.appStaticMessages.AppLevel.SecurityAnswersNotValid;
        } else {
          if (this.validateUserPreference.communicationPreference === 'Email'
          || this.validateUserPreference.communicationPreference === 'Both') {
          this.sharedService.emailTimeOutCounter = this.sharedService.emailTimeOutCounter + 1;
        } else {
          this.sharedService.mobileTimeOutCounter = this.sharedService.mobileTimeOutCounter + 1;
        }
          this.status = true;
          this.dialogRef.close();
        }
      }, error => {
        this.dialogRef.close();
        if (error.error.error[0].type === 'Custom') {
          const minutesRemaining = error.error.error[0].message.split('|')[1] === 0 ? 1 : error.error.error[0].message.split('|')[1];
          this.dialogService.getErrorDialogBoxWithMessage(this.appMessagesService.appStaticMessages.AppLevel.RoleActivationExpired
             + minutesRemaining + this.appMessagesService.appStaticMessages.AppLevel.RoleActivationExpiryMinutes).afterClosed();
        } else {
          this.dialogService.getErrorDialogBox().afterClosed();
        }
      }
    );
  }
}
