import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar, MatCalendarHeader, MatDatepicker, MatDatepickerIntl } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomDatePickerComponent<D> extends MatCalendarHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  selectedDate: any;

  constructor(
    _intl: MatDatepickerIntl,
    _datePicker: MatDatepicker<D>,
    _calendar: MatCalendar<D>,
    _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    super(_intl, _calendar, _dateAdapter, _dateFormats, cdr);
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  getDate() {
    return this.calendar.activeDate;
  }

  public monthClicked() {
    this.calendar.currentView = 'year';
  }

  public yearClicked() {
    this.calendar.currentView = 'multi-year';
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
