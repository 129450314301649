import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class DataService {

  updateSource = new BehaviorSubject('');
  updateUserPictureSource = new BehaviorSubject('');
  updateUserNameSource = new BehaviorSubject('');

  messageData = this.updateSource.asObservable();
  userProfilePictureData = this.updateUserPictureSource.asObservable();
  updateUserNameData = this.updateUserNameSource.asObservable();
  constructor() { }

  updateData(data: string) {
    this.updateSource.next(data);
  }

  updateProfilePic(data: string) {
    this.updateUserPictureSource.next(data);
  }

  updateUserName(data: string) {
    this.updateUserNameSource.next(data);
  }
}
