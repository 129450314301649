import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

class CustomIcon {
  IconName: string;
  IconPath: string;
}
export const menuIconsArray = {
  Dashboard: 'icon_dashboard.svg',
  Configuration: 'icon_Setting.svg',
  HelpDesk: 'icon_helpdesk.svg',
  Messaging: 'Messaging_outline_ico.svg',
  CurrentProcessing: 'currentProcessing.svg',
  Calendar: 'Calendar_outline_ico.svg',
  Logs: 'activitylogs-ico.svg',
  DocumentLibrary: 'icon_documentLibrary.svg',
  ClearDown: 'ClearDown_ico.svg',
  ControlReports: 'Reports_outline_ico.svg',
  MessageBoard: 'icon_messaging.svg',
  MyTickets: 'myticket-ico.svg',
  MyCalendar: 'icon_calendar.svg',
  ViewPayslips: 'viewpayslip-ico.svg',
  MyProfile: 'profile-ico.svg',
  ViewOtherDocuments: 'View Document_outline_ico.svg',
  OtherServices: 'supportservices-ico.svg',

  HelpDeskAdministrator: 'dashboard-ico.svg',
  HelpDeskManager: 'dashboard-ico.svg',
  PayManager: 'dashboard-ico.svg',
  Administrator: 'dashboard-ico.svg',
  YourAccount: 'dashboard-ico.svg',
  TaxCalculator: 'taxcalculator-ico.svg',
  VideoLibrary: 'video-library-ico.svg',
  MyAccount: 'icon_MyAccount.svg',
  ManageEmployee: 'icon_ManageEmployee.svg',
  ViewDocuments: 'icon_viewDocument.svg',
  Reports: 'icon_Report.svg',
  ServiceLogs: 'ServiceLogs_ouline_ico.svg',
};

const array: CustomIcon[] = [

  { IconName: 'icon_info', IconPath: '../assets/img/icon_info.svg' },
  { IconName: 'icon_attachment', IconPath: '../assets/img/icon_attachment.svg' },
  { IconName: 'icon_calender', IconPath: '../assets/img/icon_calender.svg' },
  { IconName: 'icon_notification', IconPath: '../assets/img/icon_notification.svg' },
  { IconName: 'icon_people', IconPath: '../assets/img/icon_people.svg' },

  { IconName: 'icon_mail', IconPath: '../assets/img/icon_mail.svg' },
  { IconName: 'icon_comment', IconPath: '../assets/img/icon_comment.svg' },
  { IconName: 'icon_edit', IconPath: '../assets/img/icon_edit.svg' },
  { IconName: 'icon_timer', IconPath: '../assets/img/icon_timer.svg' },
  { IconName: 'icon_next', IconPath: '../assets/img/icon_next.svg' },
  { IconName: 'icon_checkmark', IconPath: '../assets/img/icon_checkmark.svg' },
  { IconName: 'icon_back', IconPath: '../assets/img/icon_back.svg' },
  { IconName: 'icon_refresh', IconPath: '../assets/img/icon_refresh.svg' },
  { IconName: 'icon_close', IconPath: '../assets/img/icon_close.svg' },
  { IconName: 'icon_xls', IconPath: '../assets/img/icon_xls.svg' },
  { IconName: 'icon_email', IconPath: '../assets/img/icon_email.svg' },

  { IconName: 'icon_pdf', IconPath: '../assets/img/icon_pdf.svg' },
  { IconName: 'icon_print', IconPath: '../assets/img/icon_print.svg' },
  { IconName: 'icon_right', IconPath: '../assets/img/icon_right.svg' },
  { IconName: 'icon_add', IconPath: '../assets/img/icon_add.svg' },
  { IconName: 'icon_download', IconPath: '../assets/img/icon_download.svg' },
  { IconName: 'help-ico', IconPath: '../assets/img/help-ico.svg' },
  { IconName: 'icon_history', IconPath: '../assets/img/icon_history.svg' },
  { IconName: 'icon_cancel', IconPath: '../assets/img/icon_cancel.svg' },

  { IconName: 'icon_blockchain', IconPath: '../assets/img/blockchainUni.svg' },
  { IconName: 'icon_login_vault', IconPath: '../assets/img/icon_login_vault.svg' },
  { IconName: 'icon_login_rain', IconPath: '../assets/img/icon_login_rain.svg' },
  { IconName: 'icon_login_star', IconPath: '../assets/img/icon_login_star.svg' },
  { IconName: 'icon_easyAccess', IconPath: '../assets/img/easyAccess.svg' },
  { IconName: 'icon_cancel', IconPath: '../assets/img/icon_cancel.svg' },
  { IconName: 'icon_flip_H', IconPath: '../assets/img/flip_hori.svg' },
  { IconName: 'icon_flip_V', IconPath: '../assets/img/flip_verticle.svg' },
  { IconName: 'icon_uploadImage', IconPath: '../assets/img/icon_uploadImage.svg' },
  { IconName: 'help_icon_round', IconPath: '../assets/img/help-round-ico.svg' },
  { IconName: 'icon_theme', IconPath: '../assets/img/icon-theme.svg' },
  { IconName: 'pdf_icon', IconPath: '../assets/img/icon_pdf_new.svg' },
  { IconName: 'xls_icon', IconPath: '../assets/img/icon_xls_new.svg' },
  { IconName: 'email_icon', IconPath: '../assets/img/email_icon.svg' },
  { IconName: 'icon_pm_viewpayslip', IconPath: '../assets/img/ViewPayslip.svg' },

  { IconName: 'icon_lnd_Calendar', IconPath: '../assets/img/LandingIcons/icon_calendar.svg' },
  { IconName: 'icon_lnd_ControlReports', IconPath: '../assets/img/LandingIcons/icon_controlReport.svg' },
  { IconName: 'icon_lnd_CurrentProcessing', IconPath: '../assets/img/LandingIcons/icon_currentProcessing.svg' },
  { IconName: 'icon_lnd_Dashboard', IconPath: '../assets/img/LandingIcons/icon_dashboard.svg' },
  { IconName: 'icon_lnd_DocumentLibrary', IconPath: '../assets/img/LandingIcons/icon_documentLibrary.svg' },
  { IconName: 'icon_lnd_EmployeeReports', IconPath: '../assets/img/LandingIcons/icon_employeeReport.svg' },
  { IconName: 'icon_lnd_HelpDesk', IconPath: '../assets/img/LandingIcons/icon_helpdesk.svg' },
  { IconName: 'icon_lnd_Messaging', IconPath: '../assets/img/LandingIcons/icon_messaging.svg' },
  { IconName: 'icon_lnd_ServiceLogs', IconPath: '../assets/img/LandingIcons/icon_serviceLogs.svg' },
  { IconName: 'icon_lnd_AppConfiguration', IconPath: '../assets/img/LandingIcons/icon_AppConfiguration.svg' },
  { IconName: 'icon_lnd_AccessSettings', IconPath: '../assets/img/LandingIcons/icon_calendar.svg' },
  { IconName: 'icon_lnd_UserSettings', IconPath: '../assets/img/LandingIcons/icon_USerSettings.svg' },
  { IconName: 'icon_lnd_ClearDown', IconPath: '../assets/img/LandingIcons/icon_ClearDown.svg' },
  { IconName: 'icon_lnd_LockedEmployees', IconPath: '../assets/img/LandingIcons/icon_LockedEmployees.svg' },
  { IconName: 'icon_lnd_MyAccount', IconPath: '../assets/img/LandingIcons/icon_employeeReport.svg' },
  { IconName: 'icon_lnd_MyCalendar', IconPath: '../assets/img/LandingIcons/icon_calendar.svg' },
  { IconName: 'icon_lnd_MessageBoard', IconPath: '../assets/img/LandingIcons/icon_messaging.svg' },
  { IconName: 'icon_lnd_EmployeeStatus', IconPath: '../assets/img/LandingIcons/icon_employeeStatus.svg' },
  { IconName: 'icon_lnd_MyAccount', IconPath: '../assets/img/LandingIcons/icon_MyAccount.svg' },
  { IconName: 'backto-landing', IconPath: '../assets/img/backto-landing.svg' },
  { IconName: 'home_icon', IconPath: '../assets/img/home_black_24dp.svg' },
  { IconName: 'icon_lnd_PayDocuments', IconPath: '../assets/img/MenuIcons/icon_viewDocument.svg' }
];

@Injectable({
  providedIn: 'root',
})
export class IconService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { }
  init() {
    array.forEach(element => {
      this.matIconRegistry.addSvgIcon(
        element.IconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(element.IconPath)
      );
    });
  }
  menuItemsInit() {
    Object.keys(menuIconsArray).forEach(key => {
      this.matIconRegistry.addSvgIcon(
        key,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/img/MenuIcons/${menuIconsArray[key]}`)
      );
    });
  }
}
