import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { IUserDeviceSettings } from 'src/app/Interfaces/identitymanagement/IUserDeviceSettings';
import { SharedService } from 'src/app/services/shared.service';

import { IAuthModel } from '../../Interfaces/identitymanagement/IAuthModel';
import { IValidatedUserPreference } from '../../Interfaces/identitymanagement/IValidatedUserPreference';
import { ITenantConfigurationSettings } from '../../Interfaces/tenantconfiguration/ITenantConfigurationSettings';
import { ClientAdminEmailingService } from '../../services/clientAdmin-emailing.service';
import { RoleActivationService } from '../../services/role-activation.service';
import { SecurityService } from '../../services/security.service';
import { DialogService } from '../../shared/dialog/dailog.service';
import { AppMessagesService } from '../../shared/services/app-messages.service';
import { IconService } from '../../shared/services/svg-icon-service';
import { IdentityManagerService } from './../../services/identity-manager.service';

@Component({
  selector: 'app-role-activation-authorization',
  templateUrl: './role-activation-authorization.component.html',
  styleUrls: ['./role-activation-authorization.component.scss']
})
export class RoleActivationAuthorizationComponent implements OnInit {

  validateUserPreference: IValidatedUserPreference;
  errorMessage: string = null;
  isError = false;
  roleId: number;
  staffNumber: string;
  status = false;
  isSendAsSMS: boolean = false;
  showSpamMsg = false;
  emailTimer: number = 0;
  mobileTimer: number = 0;
  emailConfiguration: boolean;
  tenantConfigSettings: ITenantConfigurationSettings;
  userDeviceSettings: IUserDeviceSettings;

  constructor(private iconService: IconService,
              public appMessagesService: AppMessagesService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private router: Router,
              public snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<RoleActivationAuthorizationComponent>,
              private roleActivationService: RoleActivationService,
              private securityService: SecurityService,
              private identityManagerRegistrationService: IdentityManagerService,
              private dialogService: DialogService,
              private clientAdminEmailingService: ClientAdminEmailingService,
              public sharedService: SharedService) {

    router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.dialogRef.close();
      }
    });

    this.iconService.init();
    this.validateUserPreference = this.data.validateUserPreference;
    this.roleId = this.data.roleId;
    this.staffNumber = this.data.staffNumber;
  }

  ngOnInit() {
    this.isError = false;
    this.loadTenantSettings();
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(_result => {
      this.router.navigate([`/dashboard`]);
    }
    );
  }

  onKeyup(evt, next, prev, type = null, purposeType = null, option = null, emailAddress = null, phoneNumber = null) {

    if (this.onKeydown(evt) && next !== '' && evt.key !== 'Backspace') {
      next.focus();
    }
    if (evt.key === 'Backspace' && prev !== '') {
      prev.focus();
    }

    let otp = '';
    $(`.${purposeType}.loginpassword2`).each(function() {
      if ($(this).val()) {
        otp = `${otp}${$(this).val()}`;
      } else { return false; }
    });

    if (otp.length === 6) {
      this.validateAuthenticationCode(type, purposeType, option, emailAddress, phoneNumber, otp);
    }
  }

  onKeydown(event) {

    const pattern = /^\d*[0-9](|.\d*[0-9])?$/;
    if (event.keyCode !== 8 && !pattern.test(event.key)) {
      return false;
    }
    return true;
  }

  validateAuthenticationCode(type: string, purposeType: string, option: string,
                             emailAddress: string, mobileNumber: string, code: string) {

    this.isError = false;
    const authModel: IAuthModel = {
      type,
      purposeType,
      option,
      emailAddress,
      mobileNumber,
      otp: code,
      isSendAsSMS: this.isSendAsSMS
    };

    this.identityManagerRegistrationService.ValidateAutheticationCodeForRoleActivation(authModel, this.staffNumber,
      this.roleId).subscribe(response => {
        if (response != null && response === true) {

          this.roleActivationService.UpdateRoleAsActivated(this.staffNumber, this.roleId).subscribe(
            (updateResponse: boolean) => {
              if (updateResponse) {
                this.updateClaimObject(this.roleId);
                this.status = true;
                this.dialogRef.close();
              } else {

              }
            }, error => {
              if (error != null && error.error != null && error.error !== undefined) {
                this.isError = true;
                this.errorMessage = error.error.error[0].message;
              } else {
                this.dialogService.getSucessDialog('Error', error, false).afterClosed();
              }
            }
          );
        } else {
          this.isError = true;
          this.errorMessage = this.appMessagesService.appStaticMessages.AppLevel.RequestNewCode;
        }
      }, error => {
        if (error != null && error.error != null && error.error !== undefined && error.error.error[0].type === 'Custom') {
          this.dialogRef.close();
          const minutesRemaining = error.error.error[0].message.split('|')[1] === 0 ? 1 : error.error.error[0].message.split('|')[1];
          this.dialogService.getErrorDialogBoxWithMessage(this.appMessagesService.appStaticMessages.AppLevel.RoleActivationExpired
            + minutesRemaining + this.appMessagesService.appStaticMessages.AppLevel.RoleActivationExpiryMinutes).afterClosed();
        } else if (error != null && error.error != null && error.error !== undefined) {
          this.isError = true;
          this.errorMessage = error.error.error[0].message;
        } else {
          this.dialogService.getErrorDialogBox().afterClosed();
        }
      }
      );
  }

  updateClaimObject(roleId: number) {
    switch (roleId) {
      case 1:
        this.securityService.securityObject.claims.superAdministrator.isActive = true;
        break;
      case 2:
        this.securityService.securityObject.claims.payManager.isActive = true;
        break;
      case 3:
        this.securityService.securityObject.claims.helpDeskManager.isActive = true;
        break;
      case 4:
        this.securityService.securityObject.claims.helpDeskAdministrator.isActive = true;
        break;
    }
    this.securityService.UpdateSecurityObject();
  }

  resendStatus(type) {
    if (type.toLowerCase() === 'email' || type.toLowerCase() === 'both') {
      this.emailTimer = this.sharedService.checkEmailTimer();
      return this.emailTimer === 0;
    }
    if (type.toLowerCase() === 'mobile') {
      this.mobileTimer = this.sharedService.checkMobileTimer();
      return this.mobileTimer === 0;
    }
  }

  resend(type, purposeType, option, emailAddress, phoneNumber) {
    this.isSendAsSMS = false;
    this.showSpamMsg = (type.toLowerCase() === 'email' || type.toLowerCase() === 'both') ? true : false;
    if (this.resendStatus(type)) {
      this.clear(purposeType);
      if (this.tenantConfigSettings.isMobileAppConfigured) {
        this.identityManagerRegistrationService.GetDeviceSettings(this.staffNumber).subscribe((data: IUserDeviceSettings) => {
          this.userDeviceSettings = <IUserDeviceSettings>data;
          if ((this.validateUserPreference.communicationPreference.toLowerCase() === 'mobile'
            || this.validateUserPreference.communicationPreference.toLowerCase() === 'both')
            && this.userDeviceSettings.deviceToken != null && this.userDeviceSettings.deviceToken != null) {

            this.sharedService.emailTimeOutCounter = this.sharedService.emailTimeOutCounter + 1;

            this.sendAuthenticationCodeToEmployee(type,
              purposeType,
              option,
              emailAddress,
              phoneNumber);
          } else {
            this.sharedService.mobileTimeOutCounter = this.sharedService.mobileTimeOutCounter + 1;
            this.sendAuthenticationCode(type, purposeType, option, emailAddress, phoneNumber);
          }
        });
      } else {
        this.sendAuthenticationCode(type, purposeType, option, emailAddress, phoneNumber);
      }
    }
  }

  clear(type) {
    this.isError = false;
    $(`.${type}.loginpassword2`).each(function() {
      $(this).val('');
    });
  }

  sendAuthenticationCode(type: string, purposeType: string, option: string, emailAddress: string,
                         mobileNumber: string, isSendAsSMS: boolean = false) {
    this.clientAdminEmailingService.GetClientEmailingConfiguration().subscribe(response => {
      this.emailConfiguration = response.isEmailingOn;
    });
    const authModel: IAuthModel = {
      type,
      purposeType,
      option,
      emailAddress,
      mobileNumber,
      otp: null
    };
    this.isSendAsSMS = isSendAsSMS;
    this.identityManagerRegistrationService.SendAuthenticationCode(authModel).subscribe(_response => {
      if (this.validateUserPreference.communicationPreference.toLowerCase() === 'email'
        || this.validateUserPreference.communicationPreference.toLowerCase() === 'both') {
        this.sharedService.emailTimeOutCounter = this.sharedService.emailTimeOutCounter + 1;
      } else {
        this.sharedService.mobileTimeOutCounter = this.sharedService.mobileTimeOutCounter + 1;
      }
    }, error => {
      if (error != null && error.error != null && error.error !== undefined) {
        this.isError = true;
        this.errorMessage = error.error.error[0].message;
      } else {
        this.dialogService.getSucessDialog('Error', error, false).afterClosed();
      }
    }
    );
  }
  loadTenantSettings() {
    this.identityManagerRegistrationService.GetTenantConfigurationSettings().subscribe((response: ITenantConfigurationSettings) => {
      this.tenantConfigSettings = <ITenantConfigurationSettings>response;
      this.sharedService.maxAttemptsForResend =
        parseInt(this.tenantConfigSettings.clientGlobalSettings['MaxAttemptsforResend'], 10);
      this.sharedService.maxSecondsToWaitBeforeResend =
        parseInt(this.tenantConfigSettings.clientGlobalSettings['MaxSecondsToWaitBeforeResend'], 10);
    });
  }
  sendAuthenticationCodeToEmployee(type: string, purposeType: string, option: string, emailAddress: string, mobileNumber: string) {
    const authModel: IAuthModel = {
      type,
      purposeType,
      option,
      emailAddress,
      mobileNumber,
      otp: null,
    };

    authModel['deviceId'] = this.userDeviceSettings.deviceId;
    authModel['deviceToken'] = this.userDeviceSettings.deviceToken;
    authModel['hasValidToken'] = this.userDeviceSettings.hasValidToken;
    this.identityManagerRegistrationService.SendAuthenticationCodeToEmployee(authModel, this.staffNumber).subscribe(response => {
      if (response) {
        // Do nothing
      }
    }, error => {
      if (error != null && error.error != null && error.error !== undefined) {
        this.isError = true;
        this.errorMessage = error.error.error[0].message;
      } else {
        this.dialogService.getSucessDialog('Error', error, false).afterClosed();
      }
    });
  }
}
