<div class="wrapper wrapper-fkull-page">
  <div class="page-header login-page">
    <div class="container">
      <div class="col-lg-6 col-md-9 col-sm-12 ml-auto mr-auto">
        <div class="pt50 pb50 CustomPopup">
           <h2 align="center" class="font20 font500">You don't have an access to the application.</h2>
        </div>
      </div>
    </div>
  </div>
</div>
