import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { EmployeeROUTES } from './routes/employeeRoutes';
import { RouteInfo } from './sidebar.component';

@Injectable()
export class SidebarRoutingService {

  ROUTES: RouteInfo[];
  routeSource = new BehaviorSubject(EmployeeROUTES);
  currentRoutes = this.routeSource.asObservable();

  constructor() { }

  changRoutes(routeCollection: RouteInfo[]) {
    this.routeSource.next(routeCollection);
  }
}
