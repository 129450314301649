import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customloader',
  templateUrl: './customloader.component.html',
  styleUrls: ['./customloader.component.scss']
})
export class CustomloaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
