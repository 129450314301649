<div *ngIf="accessCheck">
  <div *ngIf="hasAccess">
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="!hasAccess">
    <app-access-denied></app-access-denied>
  </div>
</div>
<!-- customLoader -->
<ng-http-loader *ngIf="utilityService.showSpinner" [backdrop]="true" [backgroundColor]="'#ff6000'" [entryComponent]="customLoader" [debounceDelay]="1000"
  [minDuration]="300" [spinner]="spinkit.skWave">
</ng-http-loader>
