<div class="wrapper" (click)="hideTileMenu()">
  <div class="NavAndSideBar">
    <app-navbar-cmp (navigationProcessed)="MenuToggle(true,false,false,showLanding)"
      (openSideBar)="MenuToggle(true,true,true,showLanding)" (openNavBar)="OpenNav()" (roleChange)="roleChanged($event)"
      (showLanding)="setShowLanding($event)" [roles]="sidebar?.rolesMenuItems"></app-navbar-cmp>
    <div class="sidebarToggle" (click)="MenuToggle(true,true,true,showLanding)"
      [ngClass]="{'MenuBig':MenuOpen,'MenuSmall': MenuClose}">
      <span class="material-icons">
        <span *ngIf="MenuOpen">menu_open</span>
        <span *ngIf="!MenuOpen">menu</span>
      </span>
    </div>
    <div class="sidebar" [ngClass]="{'MenuBig':MenuOpen,'MenuSmall': MenuClose}" data-color="white"
      data-background-color="black" (mouseleave)="MenuOutSide()">
      <app-sidebar-cmp [showLanding]="showLanding" (toggleSidebar)="MenuToggle(false,false,false,$event)"
        (roleChanged)="changeMenuItems($event)"></app-sidebar-cmp>
      <div class="sidebar-background"></div>
    </div>
  </div>
  <div class="main-panel">

    <!-- <perfect-scrollbar class="perfectScroll_custom"> -->
    <div class="main-content">
      <div class="container-fluid">
        <div *ngIf="!showLanding; else landingPage">
          <router-outlet></router-outlet>
        </div>
        <ng-template #landingPage>
          <div class="flexDev">
            <div class="Lpanel">
              <div class="row">
                <div class="employee-layout">
                  <div class="left-div" *ngIf="currentRole === 'employee' && grossPay && payDataLoaded">
                    <mat-card class="payslip-card">
                      <mat-card-title class="">PAYSLIP {{ payMonth }} {{ payYear }}
                        <mat-slide-toggle (change)="showPayDetails = !showPayDetails" [checked]="showPayDetails">
                          <span class="toogle-txt" *ngIf="showPayDetails">Hide</span> <span
                            *ngIf="!showPayDetails">Show</span>
                        </mat-slide-toggle>
                      </mat-card-title>
                      <mat-card-content>
                        <div class="underline">
                          <mat-label>Gross Income</mat-label>
                          <h4 class="maskfont" *ngIf="!showPayDetails">************</h4>
                          <h4 *ngIf="showPayDetails">{{ grossPay }}</h4>
                        </div>
                        <div class="underline">
                          <mat-label>Deductions</mat-label>
                          <h4 class="maskfont" *ngIf="!showPayDetails">************</h4>
                          <h4 *ngIf="showPayDetails">{{ totalDeductions }}</h4>
                        </div>
                        <div class="underline">
                          <mat-label>Net Income</mat-label>
                          <h4 class="maskfont" *ngIf="!showPayDetails">************</h4>
                          <h4 *ngIf="showPayDetails">{{ netPay }}</h4>
                        </div>

                        <button class="btn btn-rose mt15 payslip-btn" (click)="OpenCurrentPayslipDialog()">See
                          Payslip</button>
                        <mat-icon class="svgclass25 icon_download landingPageDownload"
                          svgIcon="icon_download" (click)="downloadPayslip()">Download</mat-icon>
                        <mat-icon class="svgclass18" matTooltipClass="iconsmattool">
                        </mat-icon>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <div class="left-div" *ngIf="currentRole === 'employee' && !grossPay">
                    <mat-card class="payslip-card noData">
                      <mat-card-title class="f20" style="text-align: center;">
                        <span *ngIf="!payDataLoaded">Loading ...</span>
                        <span *ngIf="payDataLoaded">No Paydata available</span>
                      </mat-card-title>
                    </mat-card>
                  </div>
                  <div class="rt-div">
                    <div class="LandingTiles emptiles" [ngStyle]="{'grid-template-columns': getColumns()}">
                      <div *ngFor="let menuitem of menuItems; let i = index" class="tile"
                        (click)="changeRoute(menuitem.path); $event.stopPropagation()">
                        <div class="box" style="--aspect-ratio: 1/1;">
                          <div [ngClass]="menuitem.title.toLowerCase().includes('records') && otherDocuments.length > 0 && showMenu ? 'tab-menu-1' : 'tab-menu'">
                            <ul *ngIf="otherDocuments.length > 0">
                              <li *ngFor="let doc of otherDocuments" (click)="changeRoute(doc.path); $event.stopPropagation()">{{ doc.title }}</li>
                            </ul>
                            <ul *ngIf="otherDocuments.length === 0">
                              <li>No documents available</li>
                            </ul>
                          </div>
                        </div>
                        <div class="content ">
                          <mat-icon [svgIcon]="'icon_lnd_' + menuitem.icon" matTooltipClass="iconsmattool">
                          </mat-icon>
                          <div class="text">{{menuitem.title}}</div>
                        </div>
                      </div>
                      <!-- <mat-menu #showMenu="matMenu" xPosition="before" class="harsh">
                        <button mat-menu-item *ngFor="let doc of otherDocuments" (click)="changeRoute(doc.path)">
                          {{ doc.title }}</button>
                      </mat-menu>
                      <mat-menu #hideMenu="matMenu" xPosition="before">
                        <button mat-menu-item>No documents available</button>
                      </mat-menu> -->
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                  <div #SrhTile class="searchBox">
                    <div class="searchTitle">Quick Search</div>
                    <div class="history">History</div>
                    <div class="">
                      <mat-form-field class="example-full-width customInputs">
                        <mat-placeholder>Select University </mat-placeholder>
                        <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                        <mat-select panelClass="CustomMatSelectDropDown MatSelectblacktheme bordermultiselect">
                          <perfect-scrollbar style="max-width: 100%;"
                            class="CustomMatSelectDropDown_Scroll perfectScroll_custom">
                            <mat-option>One</mat-option>
                            <mat-option>Two</mat-option>
                            <mat-option>Three</mat-option>
                            <mat-option>Four</mat-option>
                          </perfect-scrollbar>
                        </mat-select>
                      </mat-form-field>
                      <div class="form-group">
                        <input type="email" class="" placeholder="Student name, Enrolment ID, Certificate ID">
                      </div>
                      <div class="form-group">
                        <input type="email" class="" placeholder="Course Name">
                      </div>
                      <div class="form-group">
                        <input type="email" class="" placeholder="Year of Graduation">
                      </div>
                    </div>
                    <div class="text-right mt5">
                      <a mat-raised-button class="btn btn-rose">Search</a>
                    </div>
                  </div>
                </div> -->
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <!-- </perfect-scrollbar> -->
  </div>
</div>

<div id="payslipDoc" name="pdf">

</div>
