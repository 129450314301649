import { Location } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

import { IValidatedUserPreference } from '../Interfaces/identitymanagement/IValidatedUserPreference';
import { IHelpGuideDetails } from '../Interfaces/IHelpGuideDetails';
import { IQuestion } from '../Interfaces/user/IQuestions';
import {
  RoleActivationAuthorizationComponent,
} from '../role-activation/role-activation-authorization/role-activation-authorization.component';
import { RoleActivationComponent } from '../role-activation/role-activation.component';
import { RoleActivationService } from '../services/role-activation.service';
import { SecurityService } from '../services/security.service';
import { SharedService } from '../services/shared.service';
import { UpdatesDialogComponent } from '../shared/components/updates-dialog/updates-dialog.component';
import { DialogService } from '../shared/dialog/dailog.service';
import { DisplayMessageialogComponent } from '../shared/dialog/display-message-dialog/display-message-dialog.component';
import { AppMessagesService } from '../shared/services/app-messages.service';
import { DataService } from '../shared/services/data.service';
import { IconService } from '../shared/services/svg-icon-service';
import { UtilityService } from '../shared/services/utility.service';
import updates from './../../assets/i18n/updates.json';
import { SidebarRoutingService } from './sidebar-routing.service';

declare const $: any;

// Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icon: string;
  ab: string;
}
export interface MenuRouteInfo {
  path: string;
  title: string;
  type: string;
  icon: string;
  abbreviation: string;
  show: boolean;
  systemSetupRoleName: string;
}

export interface IRoles {
  Adminstrator: string;
  HelpDeskManager: string;
  PayManager: string;
  HelpDeskAdministrator: string;
  YourAccount: string;
}

export const Roles: IRoles = {
  Adminstrator: 'administrator',
  HelpDeskManager: 'helpDeskManager',
  PayManager: 'paymanager',
  HelpDeskAdministrator: 'helpDeskAdmin',
  YourAccount: ''
};

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

@Component({
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
  styles: ['i.material-icons.sidebarTick {float: right;padding: 0;margin: 0;color: #02b30a!important;}'
    + '.sidebarTick {float: right;width: 20px;} .sideIcon {float: left;position: relative;top: 0px;left: 5px;}']
})

export class SidebarComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('perfectScroll', { static: false }) perfectScroll: PerfectScrollbarComponent;
  @Output() navigationProcessed: EventEmitter<any> = new EventEmitter<any>();
  @Output() openSideBar: EventEmitter<any> = new EventEmitter<any>();
  @Output() openNavBar: EventEmitter<any> = new EventEmitter<any>();
  notificationDelayInMS: any;
  showNotificationBox = false;
  helpGuideUrls: IHelpGuideDetails[];
  disableNotifications: boolean = false;
  disableNewFeatures: boolean = false;
  updates: any;
  public menuItems: any[];
  public rolesMenuItems: any[] = [];
  ROUTES: RouteInfo[];
  securityQuestions: IQuestion[];
  validateUserPreference: IValidatedUserPreference;
  status: boolean;
  public name = '';
  public imageUrl = '';
  features: any[];
  public hoverOn = false;
  currentRole: any = '';
  @Input() showLanding: boolean = true;

  @Output() toggleSidebar: EventEmitter<any> = new EventEmitter<any>();
  @Output() roleChanged: EventEmitter<any> = new EventEmitter<any>();

  stepsCompleted: any;

  constructor(
    public dialog: MatDialog,
    public appMessagesService: AppMessagesService,
    private sidebarRoutingService: SidebarRoutingService,
    private router: Router,
    private location: Location,
    private roleActivationService: RoleActivationService,
    private iconService: IconService,
    private dialogService: DialogService,
    private dataService: DataService,
    public securityService: SecurityService,
    private sharedService: SharedService,
    public utilityService: UtilityService) {

    this.iconService.menuItemsInit();
    this.securityService.navigatedRole = this.securityService.securityObject.selectedMenu;
    this.updates = updates;
  }
  loadRoutesByPath(path: string): void {
    if (path.includes(Roles.Adminstrator)) {
      this.loadRoutes(Roles.Adminstrator);
    } else if (path.includes(Roles.HelpDeskAdministrator)) {
      this.loadRoutes(Roles.HelpDeskAdministrator);
    } else if (path.includes(Roles.HelpDeskManager)) {
      this.loadRoutes(Roles.HelpDeskManager);
    } else if (path.includes(Roles.PayManager)) {
      this.loadRoutes(Roles.PayManager);
    } else {
      this.loadRoutes(Roles.YourAccount);
    }
  }

  ngAfterViewInit(): void {
    this.bindDataToControls();
  }
  ngOnInit() {
    this.sidebarRoutingService.currentRoutes.subscribe(routes => {
      this.menuItems = routes.filter(_ => _.type);
      this.roleChanged.emit(this.menuItems);
    });
    this.showLanding = true;
    if (this.securityService.securityObject.claims.superAdministrator != null &&
      this.securityService.securityObject.claims.superAdministrator.roleMenu.mainFeature != null &&
      this.securityService.securityObject.claims.superAdministrator.roleMenu.mainFeature.title.trim().length > 0) {
      this.rolesMenuItems.push(this.securityService.securityObject.claims.superAdministrator.roleMenu.mainFeature);
    }
    if (this.securityService.securityObject.claims.payManager != null &&
      this.securityService.securityObject.claims.payManager.roleMenu.mainFeature != null &&
      this.securityService.securityObject.claims.payManager.roleMenu.mainFeature.title.trim().length > 0) {
      this.rolesMenuItems.push(this.securityService.securityObject.claims.payManager.roleMenu.mainFeature);
    }
    if (this.securityService.securityObject.claims.helpDeskManager != null &&
      this.securityService.securityObject.claims.helpDeskManager.roleMenu.mainFeature != null &&
      this.securityService.securityObject.claims.helpDeskManager.roleMenu.mainFeature.title.trim().length > 0) {
      this.rolesMenuItems.push(this.securityService.securityObject.claims.helpDeskManager.roleMenu.mainFeature);
    }
    if (this.securityService.securityObject.claims.helpDeskAdministrator != null &&
      this.securityService.securityObject.claims.helpDeskAdministrator.roleMenu.mainFeature != null &&
      this.securityService.securityObject.claims.helpDeskAdministrator.roleMenu.mainFeature.title.trim().length > 0) {
      this.rolesMenuItems.push(this.securityService.securityObject.claims.helpDeskAdministrator.roleMenu.mainFeature);
    }
    if (this.securityService.securityObject.claims.employee != null &&
      this.securityService.securityObject.claims.employee.roleMenu.mainFeature != null &&
      this.securityService.securityObject.claims.employee.roleMenu.mainFeature.title.trim().length > 0) {
      this.rolesMenuItems.push(this.securityService.securityObject.claims.employee.roleMenu.mainFeature);
    }
    this.rolesMenuItems.forEach(x => x.show = false);
    this.rolesMenuItems.find(x => x.title === this.securityService.securityObject.selectedMenu).show = true;

    this.loadRoutesByPath(this.location.path());
    this.dataService.userProfilePictureData.subscribe(imageUrl => this.imageUrl = imageUrl);
    this.dataService.updateUserNameData.subscribe(name => this.name = name);

    this.sharedService.getHelpguideDocumentURLs().subscribe(response => {
      this.helpGuideUrls = response;
    },
      () => {
        this.helpGuideUrls = [];
      }
    );

    this.securityService.GetReleaseNotification(this.updates.version, 0).subscribe(response => {
      this.utilityService.releaseNotificationDetails = response;
      this.utilityService.stepsCompleted = response.completedSteps && response.completedSteps.length > 0 ?
        JSON.parse(response.completedSteps)[0].CompletedSteps.split(',') : 0;
      this.stepsCompleted = this.utilityService.releaseNotificationDetails.completedSteps
        && this.utilityService.releaseNotificationDetails.completedSteps.length > 0 ?
        JSON.parse(this.utilityService.releaseNotificationDetails.completedSteps)[0].CompletedSteps.split(',') : 0;

      this.disableNewFeatures = this.stepsCompleted.includes('6');
      if (this.utilityService.totalNotifications <= 0) {
          this.disableNotifications = true;
        }

      setTimeout(() => {
        this.closeNotifications();
      }, this.notificationDelayInMS);
    });
  }

  bindDataToControls() {
    this.name = this.securityService.securityObject.name;
    this.imageUrl = this.securityService.securityObject.imageUrl;
  }

  changeRoute(route: MenuRouteInfo = null) {
    const navigatedRole = this.securityService.navigatedRole;

    this.showLanding = this.currentRole.toLowerCase() !== route.title.toLowerCase();
    this.currentRole = route.title.toLowerCase();
    if (!route && navigatedRole) {
      this.securityService.navigatedRole = '';
      route = this.rolesMenuItems.find(x => x.systemSetupRoleName === navigatedRole);
    }

    if (!this.securityService.securityObject.claims[route.systemSetupRoleName].isActive) {

      const staffNumber = this.securityService.securityObject.staffNumber;
      this.roleActivationService.GetSecurityQuestions(this.securityService.securityObject.claims[route.systemSetupRoleName].roleId,
        staffNumber).subscribe(
          (response: IQuestion[]) => {
            this.securityQuestions = response;
            this.displaySecurityQuestions(this.securityQuestions, staffNumber,
              this.securityService.securityObject.claims[route.systemSetupRoleName].roleId, route);
          }, error => {

            if (error.error.error[0].type === 'Custom') {
              const minutesRemaining = error.error.error[0].message.split('|')[1] === 0 ?
                1 : error.error.error[0].message.split('|')[1];
              this.dialogService.getErrorDialogBoxWithMessage(this.appMessagesService.appStaticMessages.AppLevel.RoleActivationExpired
                + minutesRemaining + this.appMessagesService.appStaticMessages.AppLevel.RoleActivationExpiryMinutes).afterClosed();
            } else {
              this.dialogService.getErrorDialogBox().afterClosed();
            }

          }
        );
    } else {
      this.showSubmenu(route);
    }
    this.utilityService.isEmployee = route.abbreviation === 'E' ? true : false;
    this.closeSideBar(true);
    return true;
  }
  collapseAll() {
    $(' .sub_menu').collapse('hide');
  }
  ngAfterViewChecked() {
    if (this.perfectScroll !== undefined && this.perfectScroll !== null) {
      this.perfectScroll.directiveRef.update();
    }
  }
  displaySecurityQuestions(securityQuestions: IQuestion[], staffNumber: string, roleId: number, route: MenuRouteInfo) {

    const dialogRef = this.dialog.open(RoleActivationComponent, {
      data: {
        securityQuestions,
        staffNumber,
        roleId
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(() => {
      this.status = dialogRef.componentInstance.status;
      this.validateUserPreference = dialogRef.componentInstance.validateUserPreference;

      if (this.status) {
        this.displayValidateOTPComponent(this.validateUserPreference, staffNumber, roleId, route);
      } else {
        this.securityService.navigatedRole = this.securityService.securityObject.selectedMenu;
      }
    });
  }

  displayValidateOTPComponent(validateUserPreference: IValidatedUserPreference,
                              staffNumber: string, roleId: number, route: MenuRouteInfo) {

    const nextDialogRef = this.dialog.open(RoleActivationAuthorizationComponent, {
      data: {
        validateUserPreference,
        roleId,
        staffNumber
      },
      disableClose: true
    });
    nextDialogRef.afterClosed().subscribe(() => {
      this.status = nextDialogRef.componentInstance.status;
      if (this.status) {
        this.dialogService.getSucessDialog('Success', this.appMessagesService.appStaticMessages.AppLevel.RoleActivationSuccesful, true)
          .afterClosed().subscribe(() => {
            this.showSubmenu(route);
          });
      } else {
        this.securityService.navigatedRole = this.securityService.securityObject.selectedMenu;
      }
    });
  }

  showSubmenu(route: MenuRouteInfo) {
    this.securityService.navigatedRole = route.title;
    let routePath = '';
    if (route.path === '\'\'' || route.path === '\'\'') {
      routePath = '';
    } else {
      routePath = route.path;
    }
    const path = routePath;

    this.rolesMenuItems.forEach(item => item.show = false);
    route.show = true;
    this.loadRoutesByPath(path);
    const navigatedUrl = this.securityService.navigatedUrl;
    this.securityService.navigatedUrl = '';
    this.router.navigate(navigatedUrl ? [navigatedUrl] : [this.features[0].path]);
  }

  loadRoutes(role: string) {
    let routes = [];
    switch (role) {
      case Roles.Adminstrator:
        routes = this.securityService.securityObject.claims.superAdministrator.roleMenu.childMenus;
        break;
      case Roles.PayManager:
        routes = this.securityService.securityObject.claims.payManager.roleMenu.childMenus;
        break;
      case Roles.HelpDeskManager:
        routes = this.securityService.securityObject.claims.helpDeskManager.roleMenu.childMenus;
        break;
      case Roles.HelpDeskAdministrator:
        routes = this.securityService.securityObject.claims.helpDeskAdministrator.roleMenu.childMenus;
        break;
      case Roles.YourAccount:
        routes = this.securityService.securityObject.claims.employee.roleMenu.childMenus;
        break;
      default:
        routes = this.securityService.securityObject.claims.employee.roleMenu.childMenus;
        break;
    }
    this.sidebarRoutingService.changRoutes(routes);
    this.features = routes;
  }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
    }
  }
  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }
  changeLogo(event: any) {
    if (event.target.firstElementChild.id === 'logo' || (event.fromElement != null &&
      event.fromElement.localName !== 'img' && event.fromElement.localName !== 'a' && event.toElement != null)) {
      this.hoverOn = !this.hoverOn;
    }
  }
  closeSideBar(showLanding) {
    this.toggleSidebar.emit(showLanding);
  }
  closeNotifications() {
    this.showNotificationBox = false;
    if (this.utilityService.releaseNotificationDetails.status === 'Success') {
      this.securityService.GetReleaseNotification(this.updates.version, 0, 'Completed').subscribe(response => {
        this.utilityService.releaseNotificationDetails = response;
        this.stepsCompleted = response.completedSteps.length > 0 ? JSON.parse(response.completedSteps)[0].CompletedSteps.split(',') : 0;
        this.disableNewFeatures = this.stepsCompleted.includes('6');
      });
    }
  }
  openUpdatesDialog() {
    this.closeNotifications();
    this.dialogService.dialog.closeAll();
    this.navigationProcessed.emit();
    this.dialog.open(UpdatesDialogComponent, {
      panelClass: 'updateDialogPopUp',
      width: '70%',
      data: this.utilityService.releaseNotificationDetails
    }).afterClosed().subscribe(_result => {
      if (_result && _result.manual !== 'on') {
        this.utilityService.releaseNotificationDetails = _result;
        this.stepsCompleted = _result.completedSteps && _result.completedSteps.length > 0 ? JSON.parse(_result.completedSteps)[0].CompletedSteps.split(',') : 0;
        if (_result.menu && _result.menu === 'on') {
          this.openSideBar.emit();
        }
        if (_result.nav && _result.nav === 'on') {
          this.openNavBar.emit();
        }
        if (_result.status === 'Success') {
          this.notificationDelayInMS = 5000;
          this.showNotificationBox = true;
          setTimeout(() => {
            this.securityService.GetReleaseNotification(this.updates.version, 0, 'Completed').subscribe(res => {
              this.utilityService.releaseNotificationDetails = res;
              this.stepsCompleted = res.completedSteps.length > 0 ? JSON.parse(res.completedSteps)[0].CompletedSteps.split(',') : 0;
              this.disableNewFeatures = this.stepsCompleted.includes('6');
              if (this.utilityService.releaseNotificationDetails.status === 'Completed') {
                this.closeNotifications();
              }
            });
          }, this.notificationDelayInMS);
        }
      } else {
        this.securityService.GetReleaseNotification(this.updates.version, 0).subscribe(_response => {
          _result = _response;
          this.stepsCompleted = _result.completedSteps.length > 0 ? JSON.parse(_result.completedSteps)[0].CompletedSteps.split(',') : 0;
          this.disableNewFeatures = this.stepsCompleted.includes('6');
        });
      }
    });
  }
  OpenHelpGuide(): void {
    let url = null;
    this.navigationProcessed.emit();
    if (this.helpGuideUrls && this.helpGuideUrls.length > 0) {
      switch (this.securityService.securityObject.currentRoleId) {
        case 1:
          url = this.helpGuideUrls.filter(x => x.userRoleId === 1)[0].helpGuideUrl;
          break;
        case 2:
          url = this.helpGuideUrls.filter(x => x.userRoleId === 2)[0].helpGuideUrl;
          break;
        case 3:
          url = this.helpGuideUrls.filter(x => x.userRoleId === 3)[0].helpGuideUrl;
          break;
        case 4:
          url = this.helpGuideUrls.filter(x => x.userRoleId === 4)[0].helpGuideUrl;
          break;
        case 5:
          url = this.helpGuideUrls.filter(x => x.userRoleId === 5)[0].helpGuideUrl;
          break;
      }
      window.open(url, '_blank');
    } else {
      this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.AppLevel.ErrorEncounteredTryAgain, false)
        .afterClosed();
    }
  }
  openAboutRegantoDialog() {
    this.dialog.open(DisplayMessageialogComponent, {
      width: '70%',
      data: { BodyText: '', isShow: true }
    });
  }
}
