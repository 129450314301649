export enum NotificationPurposeEnum {
    UsernameChangedToSetUpOwnUserName = 'SETUPUSERNAME',
    UsernameChangedToEmailAddress = 'USERNAMECHANGEDTOEMAILADDRESS',
    UsernameChangedToStaffNumber = 'USERNAMECHANGEDTOSTAFFNUMBER',
    SecurityQuestionsUpdated = 'SECURITYQUESTIONSUPDATED',
    ChangedPersonalEmailToCorporateEmailAddress = 'CHANGEDPERSONALEMAILTOCORPORATEEMAILADDRESS',
    ChangedCorporateEmailToPersonalEmailAddress = 'CHANGEDCORPORATEEMAILTOPERSONALEMAILADDRESS',
    RemovedOrAddedSomeDomains = 'REMOVEDORADDEDSOMEDOMAINS',
    UsernameComplexityChanged = 'USERNAMECOMPLEXITYCHANGED',
    PasswordComplexityChanged = 'PASSWORDCOMPLEXITYCHANGED',
    DocumentAdded = 'DOCUMENTADDED',
    DocumentUpdated = 'DOCUMENTUPDATED',
    MessageAdded = 'MESSAGEADDED',
    MessageUpdated = 'MESSAGEUPDATED',
    CalendarEventAdded = 'CALENDAREVENTADDED',
    CalendarEventUpdated = 'CALENDAREVENTUPDATED'
}
