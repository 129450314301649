<div class="container h-100">
  <div class="row align-items-center h-100">
    <div class="col-xs-12 col-lg-6 mx-auto">
      <div class="card h-100 border-primary justify-content-center documentDetails ">
        <div class="row logo">
          <div class=" col-4 col-xs-4 LeftLogo">
            <img src="{{companyLogo}}" *ngIf="companyLogo != null" alt="Company" class="lazyload">
          </div>
          <div class="col-4 col-xs-4 LogoHeader" *ngIf="docType==='payslip'">Employee Payslip Details</div>
          <div class="col-4 col-xs-4 RightLogo">
            <img src="../../../assets/img/RegantoLogo.png" alt="Reganto" class="lazyload">
          </div>
        </div>
        <div class="headerBlock"></div>
        <div class="row">
          <div class="col-md-12">

            <div *ngIf="isBlockAvailable">

              <div id="docTemplate" [innerHTML]="template | safeHtml" *ngIf="template" ></div>

              <div class="col-md-12 document_panel">
                <perfect-scrollbar class="perfectscroll_Custom">
                  <pdf-viewer [src]="documentUrl" [original-size]="false" *ngIf="documentUrl" [render-text]="true"
                    style="display: block;" [zoom]="0.9" id="print-section"></pdf-viewer>
                </perfect-scrollbar>
              </div>
            </div>

            <div *ngIf="!isBlockAvailable">{{this.appMessagesService.appStaticMessages.AppLevel.DataNotFound}}</div>

          </div>
        </div>
      </div>
      <div class="row mt20">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 footerBox text-center">
          <span>Powered by Reganto with Blockchain.</span>
        </div>
      </div>
    </div>
  </div>
</div>
