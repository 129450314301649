import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AppMessagesService } from '../services/app-messages.service.js';
import { CancelDialogComponent } from './cancel-dialog/cancel-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DisplayMessageialogComponent } from './display-message-dialog/display-message-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { SucessDialogComponent } from './sucess-dialog/sucess-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(public dialog: MatDialog, public appMessagesService: AppMessagesService) {
  }

  getSucessDialog(tittle: string, bodyText: string, IsShow: boolean) {
    return this.dialog.open(SucessDialogComponent, {
      data: { TittleText: tittle, BodyText: bodyText, isShow: IsShow }
    });
  }

  getCancelDialog(tittle: string, bodyText: string, IsShow: boolean) {
    return this.dialog.open(CancelDialogComponent, {
      data: { TittleText: tittle, BodyText: bodyText, isShow: IsShow }
    });
  }

  getConfirmDialog(tittle: string, bodyText: string, IsShow: boolean) {
    return this.dialog.open(ConfirmDialogComponent, {
      data: { TittleText: tittle, BodyText: bodyText, isShow: IsShow }
    });
  }

  getConfirmDialogForReset() {
    return this.dialog.open(ConfirmDialogComponent, {
      data: {
        TittleText: 'Confirmation', BodyText: this.appMessagesService.appStaticMessages.AppLevel.DiscardChangesConfirmation,
        isShow: true
      }
    });
  }

  getSucessDialogBox() {
    return this.dialog.open(SucessDialogComponent, {
      data: { TittleText: 'Success', BodyText: this.appMessagesService.appStaticMessages.AppLevel.DetailSaveSuccess, isShow: true }
    });
  }

  getErrorDialogBox() {
    return this.dialog.open(ErrorDialogComponent, {
      data: { BodyText: 'Unexpected error encountered' }
    });
  }

  getErrorDialogBoxWithMessage(message) {
    return this.dialog.open(ErrorDialogComponent, {
      data: { BodyText: message }
    });
  }

  getAlertDialogToRemoveUser(user: string, role: string) {
    return this.dialog.open(ConfirmDialogComponent, {
      data: {
        TittleText: 'Confirmation', BodyText: this.appMessagesService.appStaticMessages.AppLevel.RemoveUserRoleConfirmation + user
          + ' from ' + role + ' role.', isShow: true
      }
    });
  }

  displayMessageBodyDialog(message: string, IsShow: boolean) {
    return this.dialog.open(DisplayMessageialogComponent, {
      data: { BodyText: message, isShow: IsShow }
    });
  }
}
