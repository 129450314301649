<div class="prev_btn">
  <div class="PopCloseIcon" (click)="closeClick()"><i class="material-icons">close</i></div>
</div>
<div style="position: relative;">
  <mat-icon style="position: absolute; right:-22px; top:20px;" matTooltip="Download PDF"
    class="svgclass cursor-pointer PdfIcon" svgIcon="pdf_icon" (click)="exportToPdf()"></mat-icon>
</div>
<perfect-scrollbar #perfectScroll style="padding-bottom: 1px;">
  <div #pdf id="pdf" name="pdf">
    <div id="pdfcontent" *ngIf="isPdf">
      <pdf-viewer [src]="pdfUrl" [original-size]="false" [render-text]="true" style="display: block;"></pdf-viewer>
    </div>
  </div>
</perfect-scrollbar>
