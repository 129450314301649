export enum NotificationActivityTypeEnum {
    ActivationConfigurationChanged = 'ACTIVATIONCONFIGURATIONCHANGED',
    EmailingConfigurationChanged = 'EMAILINGCONFIGURATIONCHANGED',
    UsernameConfigurationChanged = 'USERNAMECONFIGURATIONCHANGED',
    PasswordConfigurationChanged = 'PASSWORDCONFIGURATIONCHANGED',
    DocumentLibraryChanged = 'DOCUMENTLIBRARYCHANGED',
    MessagingChanged = 'MESSAGINGCHANGED',
    CalendarChanged = 'CALENDARCHANGED'

}
