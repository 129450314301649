import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DialogService } from 'src/app/shared/dialog/dailog.service';
import { AppMessagesService } from 'src/app/shared/services/app-messages.service';

@Component({
  selector: 'app-imager-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  scaleValue: any = 'scale(1)';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  zoomPt = 100;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  originalImage: any;
  imageURL: any;
  isExisting = true;
  toolTip = {
    zoom: 'Zoom',
    rotateLeft: 'Rotate Left',
    rotateRight: 'Rotate Right',
  };
  @ViewChild('employeePicture', { static: false }) employeePicture: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ImageCropperComponent>,
    private dialogService: DialogService,
    public appMessagesService: AppMessagesService) { }

  ngOnInit(): void {
    this.imageURL = this.data.originalImageURL;
    if (this.imageURL === null) {
      $('#employeePicture').click();
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  loadImageFailed() {
    // show message
  }
  confirmCrop() {
    let fileName;
    if (this.employeePicture.nativeElement.files.length === 0) {
      const firstIndex = this.imageURL.split('userprofileimages/')[1];
      fileName = firstIndex.split('?')[0];
    }
    this.dialogRef.close(
      {
        croppedImage: this.croppedImage,
        originalImage: this.isExisting ? this.imageURL : this.originalImage,
        fileName: fileName ? fileName : this.employeePicture.nativeElement.files[0].name,
        isExisting: this.isExisting
      }
    );
  }

  pictureChanged(_event: any) {
    if (_event.target.files[0]) {
      let uploadedFileSize = (_event.target.files[0].size / 1000) / 1000;
      if (uploadedFileSize > 2) {
        uploadedFileSize = 0;
        this.employeePicture.nativeElement.value = '';
        this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.AppLevel.InvalidImageSize, false)
          .afterClosed();
      } else {
        const fileExtension = _event.target.files[0].name.split('.')[_event.target.files[0].name.split('.').length - 1];
        if (!this.data.imageFileExtensionList.includes(fileExtension.toUpperCase())) {
          this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.AppLevel.InvalidImageTryAgain, false)
            .afterClosed();
        } else {
          this.isExisting = false;
          this.originalImage = _event.target.files[0];
          this.imageChangedEvent = _event;
        }
      }
    }
  }

  zoom(zoomLevel: any) {
    this.zoomPt > parseInt(zoomLevel, 10) ? this.zoomOut() : this.zoomIn();
    this.zoomPt = parseInt(zoomLevel, 10);
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
    this.zoomPt = 100;
  }

  close() {
    this.dialogRef.close();
  }
}

export interface ImageTransform {
  scale?: number;
  rotate?: number;
  flipH?: boolean;
  flipV?: boolean;
}
