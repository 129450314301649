<!-- <div class="logo" align="center">
  <div class="app_text">
    <a onclick="return false;" href="javascript:void(0)" class="simple-text logo-normal"
      (click)="changeRoute(rolesMenuItems[rolesMenuItems.length-1])">
      <img class="lazyload" src="../.././assets/img/RegantoLogo.png" alt="Reganto">
    </a>
  </div>
</div> -->
<div class="sidebar-wrapper" (mouseleave)="collapseAll()">
  <perfect-scrollbar #perfectScroll class="perfectScroll_custom" style="max-height: calc(100vh - 50px);">
    <div class="user pb5">
      <div class="user-info">
        <a data-toggle="collapse" href="#collapseExample" class="collapsed" style="display: none;">
          <span>
            {{name}}
            <b class="caret"></b>
          </span>
        </a>
        <div id="accordion_sm">
          <ul class="nav">
            <li class="nav-item SubMenuYes orangeClr">
              <!-- <a data-toggle="collapse" href="#collapseExample" class="nav-link accoLink collapsed photoLnk" [ngClass] = "name.length > 20 ? 'caret_postion_1' :'carret_position_2'"> -->
              <a class="nav-link accoLink photoLnk" [ngClass]="name.length > 20 ? 'caret_postion_1' :'carret_position_2'">
                <div class="svgclass32 photo">
                  <img src="{{imageUrl}}" alt="{{name}}" />
                </div>
                <div class="sidebar-normal wordbreak name-fullwidth">{{name}}
                  <!--<b class="caret"><span class="material-icons">keyboard_arrow_down</span></b>
                   <span class="roleName" style="display:block">{{securityService.navigatedRole}}</span> -->
                </div>
              </a>
              <!-- <div class="collapse sub_menu" id="collapseExample" role="tabpanel" data-parent="#accordion_sm">
                <ul class="sub_nav">
                  <li routerLinkActive="active" *ngFor="let rolesMenuItem of rolesMenuItems"
                    class="sub_nav-item orangeClr" [ngClass]="rolesMenuItem.show?'active':''">
                    <a *ngIf="rolesMenuItem.type === 'link'" class="sub_nav-link" (click)="changeRoute(rolesMenuItem)">
                      <span class="sidebar-normal">
                        {{rolesMenuItem.title}}
                      </span>
                    </a>
                  </li>
                </ul>
              </div> -->
            </li>
          </ul>
          <ul class="nav" [ngClass]="showLanding ? 'LandingScreen' : ''">
            <li routerLinkActive="active" *ngFor="let menuitem of menuItems; let i = index" class="nav-item orangeClr"
              [ngClass]="[menuitem.subMenu === false ? 'SubMenuNo' : 'SubMenuYes']">
              <a [routerLink]="[menuitem.path]" class="nav-link" (click)="closeSideBar(false)"
                [ngClass]="menuitem.subMenu === false ? 'singleLink' : 'accoLink'"
                *ngIf="menuitem.subMenu === false && menuitem.parentMenuFeatureId === 0 && menuitem.path !== ''">
                <mat-icon class="svgclass20 sideIcon" [svgIcon]="menuitem.icon">
                </mat-icon>
                <span class="sidebar-normal">{{menuitem.title}}</span>
              </a>
              <a data-toggle="collapse" href="#collapse{{i}}"
                *ngIf="menuitem.subMenu === true && (menuItems | sideMenuFilter: menuitem.menuFeatureId).length !== 0"
                class="collapsed nav-link">
                <mat-icon class="svgclass20 sideIcon" [svgIcon]="menuitem.icon"></mat-icon>
                <span class="sidebar-normal">{{menuitem.title}}
                  <b class="caret"><span class="material-icons">keyboard_arrow_down</span></b>
                </span>
              </a>
              <div class="collapse sub_menu" id="collapse{{i}}"
                *ngIf="(menuItems | sideMenuFilter: menuitem.menuFeatureId).length !== 0" role="tabpanel"
                data-parent="#accordion_sm">
                <ul class="sub_nav">
                  <li routerLinkActive="active"
                    *ngFor="let subMenuItem of menuItems | sideMenuFilter: menuitem.menuFeatureId" class="sub_nav-item"
                    [ngClass]="'orangeClr'">
                    <a [routerLink]="[subMenuItem.path]" *ngIf="subMenuItem.subMenu === false && subMenuItem.path !== ''"
                      class="sub_nav-link" (click)="closeSideBar(false)">
                      <span class="sidebar-normal">{{subMenuItem.title}}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
            <ul class="nav navuserguide" [ngClass]="showLanding ? 'LandingScreen' : ''">
              <li class="nav-item orangeClr SubMenuYes ng-star-inserted info-icon" routerlinkactive="active">
                <!----><!----><a class="collapsed nav-link ng-star-inserted" data-toggle="collapse"
                  href="#userguidemenu"><mat-icon _ngcontent-kgq-c11="" class="cursor-pointer wizardsvgclass svgclass20 Icons-grey mat-icon notranslate mat-icon-no-color" role="img" svgicon="help_icon_round" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 29.25 29.25">
                    <g id="Icon_ionic-ios-information-circle-outline" data-name="Icon ionic-ios-information-circle-outline" transform="translate(-3.375 -3.375)">
                      <path  class="cls-1help"  id="Path_429" data-name="Path 429" d="M16.552,12.108a1.449,1.449,0,1,1,1.441,1.406A1.408,1.408,0,0,1,16.552,12.108Zm.1,2.51h2.686V24.75H16.65Z"/>
                      <path  class="cls-1help"  id="Path_430" data-name="Path 430" d="M18,5.344A12.651,12.651,0,1,1,9.049,9.049,12.573,12.573,0,0,1,18,5.344m0-1.969A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Z"/>
                    </g>
                  </svg></mat-icon><span class="sidebar-normal">Info</span></a>
                <div class="collapse sub_menu ng-star-inserted" data-parent="#accordion_sm" role="tabpanel"
                  id="userguidemenu">
                  <ul class="sub_nav">
                    <li class="sub_nav-item orangeClr ng-star-inserted" *ngIf="this.securityService.securityObject.currentRoleId != null &&
                    this.securityService.securityObject.currentRoleId > 0" >
                      <a class="sub_nav-link ng-star-inserted" (click)="OpenHelpGuide()" title="User Guide">
                        <span class="sidebar-normal">User guide</span>
                      </a>
                    </li>
                    <li class="sub_nav-item orangeClr ng-star-inserted" *ngIf="this.securityService.securityObject.currentRoleId != null &&
                    this.securityService.securityObject.currentRoleId == 5 && this.utilityService.newFeaturesViewed == true">
                      <a class="sub_nav-link ng-star-inserted" (click)="openUpdatesDialog()" title="Latest Updates">
                        <span class="sidebar-normal">What's New</span>
                      </a>
                    </li> 
                    <li class="sub_nav-item orangeClr ng-star-inserted" *ngIf="this.securityService.securityObject.currentRoleId != null &&
                    this.securityService.securityObject.currentRoleId > 0">
                      <a class="sub_nav-link ng-star-inserted" (click)="openAboutRegantoDialog()" title="About Reganto">
                        <span class="sidebar-normal">About Reganto</span>
                      </a>
                    </li>                  
                  </ul>
                </div>
              </li>
            </ul>
          </div>
      </div>
    </div>
  </perfect-scrollbar>
</div>
