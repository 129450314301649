<div class="wrapper wrapper-full-page">
  <div class="main-content center-align">
    <div class="container-fluid">
      <div class="col-md-10 mr-auto ml-auto">
        <!--      Wizard container        -->
        <div class="wizard-container">
          <div class="card card-wizard radius3" data-color="rose" id="wizardProfile">
            <form [formGroup]="activationFormGroup" action="" method="">
              <div class="card-header text-center">
                <h3 class="card-title text-grey text-uppercase nomargin pt15 pb15 font20">
                  Your Profile
                </h3>
                <h4 class="btn-finish text-grey font12">
                  Please answer any {{securityQuesCount}} security questions <span
                    *ngIf="isPersonalQuesChecked == true">and
                    add {{personalQuesCount}} question(s) and answer(s) </span> along with recovery question of your
                  choice given below.
                </h4>
              </div>
              <div class="wizard-navigation">
                <ul class="nav nav-pills">
                  <li class="nav-item">
                    <a class="nav-link active" href="#about" data-toggle="tab" role="tab">
                      About You
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#account" data-toggle="tab" role="tab">
                      Activation
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#security" data-toggle="tab" role="tab">
                      Security
                    </a>
                  </li>
                </ul>
              </div>
              <div class="card-body">
                <div class="tab-content">
                  <div class="tab-pane active" id="about">
                    <div class="row justify-content-center">
                      <div class="col-md-6 about_left">
                        <div class="picture-container">
                          <div class="picture mt25">
                            <mat-icon id="removeImageButton" *ngIf="showRemovePicIcon" class="removeProfilePic"
                              (click)="removeProfilePic()" matSuffix svgIcon="icon_cancel">
                            </mat-icon>
                            <img class="img lazyload cursor-pointer picture-src" id="employeePicturePreview"
                              (click)="openImageCropper()" src="{{empImageUrl}}" alt="user" />
                          </div>
                          <h6 class="description">Choose Picture</h6>
                        </div>
                        <div class="input-group form-control-lg mt60 auth_pin_height_place">
                          <table width="100%">
                            <tr>
                              <td width="30">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                    <i class="material-icons">mail_outline</i>
                                  </span>
                                </div>
                              </td>
                              <td colspan="2">
                                <mat-form-field class="example-full-width has-label">
                                  <mat-placeholder>Email Address </mat-placeholder>
                                  <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                                  <input type="text" name="email" matInput formControlName="emailAddress"
                                    (input)="clearVerifiedEmailCheck();">
                                  <mat-error *ngIf="activationFormGroup.get('emailAddress').errors?.required">
                                    {{ this.appMessagesService.personalRequiredMessages("email address") }}</mat-error>
                                  <mat-error
                                    *ngIf="!activationFormGroup.get('emailAddress').errors?.required && activationFormGroup.get('emailAddress').errors?.pattern">
                                    {{ this.appMessagesService.customValidator("email address") }}</mat-error>
                                  <mat-icon matSuffix
                                    *ngIf="!activationFormGroup.get('emailAddress').errors?.required && isUserValid &&
                                                !activationFormGroup.get('emailAddress').errors?.pattern && !verifiedEmailAddress"
                                    class="wizardsvgclass svgclass20 btn-back hw20 custom-position" svgIcon="icon_next"
                                    title="click here to receive activation code"
                                    [ngClass]="(!activationFormGroup.get('emailAddress').errors?.required && isUserValid &&
                                  !activationFormGroup.get('emailAddress').errors?.pattern && !verifiedEmailAddress)?'wizardsvgclassActive':''"
                                    (click)="sendCode('email', 'registration', 'send', activationFormGroup.get('emailAddress').value)">
                                  </mat-icon>
                                  <mat-icon class="btn-back hw20 svgclass20 wizardsvggreenclass  custom-position-tick"
                                    matSuffix *ngIf="verifiedEmailAddress" svgIcon="icon_right"></mat-icon>
                                </mat-form-field>
                                <strong
                                  *ngIf="!activationFormGroup.get('emailAddress').errors?.required && isUserValid &&
                                !activationFormGroup.get('emailAddress').errors?.pattern && !verifiedEmailAddress && !showEmailAuthenticationBox"
                                  class="nomargin nopadding font10 position-absolute text-grey info-text">Click arrow to
                                  receive activation code</strong>
                              </td>
                            </tr>
                            <tr *ngIf="showEmailAuthenticationBox">
                              <td colspan="2">
                                <span class="bmd-form-group codebox ml30" style="width: 100%;">
                                  <div class="input-group">
                                    <div class="loginall">
                                      <input type="text" #otp1 class="email authenticationcode" maxlength="1" size="1"
                                        (keyup)="onKeyup($event,otp2,'','email', 'registration', 'send', activationFormGroup.get('emailAddress').value)"
                                        (keydown)="onKeydown($event)">
                                      <input type="text" #otp2 class="email authenticationcode" maxlength="1" size="1"
                                        (keyup)="onKeyup($event,otp3,otp1,'email', 'registration', 'send', activationFormGroup.get('emailAddress').value)"
                                        (keydown)="onKeydown($event)">
                                      <input type="text" #otp3 class="email authenticationcode" maxlength="1" size="1"
                                        (keyup)="onKeyup($event,otp4,otp2,'email', 'registration', 'send', activationFormGroup.get('emailAddress').value)"
                                        (keydown)="onKeydown($event)">
                                      <input type="text" #otp4 class="email authenticationcode" maxlength="1" size="1"
                                        (keyup)="onKeyup($event,otp5,otp3,'email', 'registration', 'send', activationFormGroup.get('emailAddress').value)"
                                        (keydown)="onKeydown($event)">
                                      <input type="text" #otp5 class="email authenticationcode" maxlength="1" size="1"
                                        (keyup)="onKeyup($event,otp6,otp4,'email', 'registration', 'send', activationFormGroup.get('emailAddress').value)"
                                        (keydown)="onKeydown($event)">
                                      <input type="text" #otp6 class="email authenticationcode" maxlength="1" size="1"
                                        (keyup)="onKeyup($event,'',otp5,'email', 'registration', 'send', activationFormGroup.get('emailAddress').value)"
                                        (keydown)="onKeydown($event)">
                                      <mat-icon class="svgclass25 text-danger cross-icon" svgIcon="icon_close"
                                        (click)="clear('email')"></mat-icon>
                                      <mat-icon class="svgclass25 text-grey ml_inside" svgIcon="icon_refresh"
                                        (click)="resend('email', 'registration', 'send', activationFormGroup.get('emailAddress').value)">
                                      </mat-icon>
                                      <p class="loginAlertTxt nomargin"><span class="asteriskDisplay">*</span>
                                        {{this.appMessagesService.personalRequiredMessages("activation code")}}</p>
                                      <div *ngIf="emailTimer > 0" style="line-height: 14px;" class="loginAlertTxt"><span
                                          class="asteriskDisplay">*</span>
                                        {{
                                        this.sharedService.formatString(this.appMessagesService.appStaticMessages.AppLevel.ResendCodeWaitError,
                                        this.emailTimer) }}
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </td>
                              <td style="width: 49%;">
                                <div *ngIf="showSpamMsg && emailTimer == 0"
                                  style="line-height: 14px; padding-left: 25px;" class="loginAlertTxt">
                                  {{this.appMessagesService.appStaticMessages.AppLevel.EmailSpamMessage}}</div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div class="col-md-6 about_right">
                        <div class="input-group form-control-lg">
                          <table width="100%">
                            <tr>
                              <td width="30">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                    <i class="material-icons">face</i>
                                  </span>
                                </div>
                              </td>
                              <td>
                                <mat-form-field class="example-full-width has-label">
                                  <mat-placeholder>First Name </mat-placeholder>
                                  <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                                  <input type="text" name="firstname" matInput id="firstName"
                                    formControlName="firstName">
                                  <mat-error *ngIf="activationFormGroup.get('firstName').errors?.required">
                                    {{ this.appMessagesService.personalRequiredMessages("first name") }}</mat-error>
                                  <mat-error *ngIf="activationFormGroup.get('firstName').errors?.maxlength">
                                    {{ this.appMessagesService.appStaticMessages.Employee.ValidEmployeeName }}
                                  </mat-error>
                                </mat-form-field>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div class="input-group form-control-lg">
                          <table width="100%">
                            <tr>
                              <td width="30">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                    <i class="material-icons">record_voice_over</i>
                                  </span>
                                </div>
                              </td>
                              <td>
                                <mat-form-field class="example-full-width has-label">
                                  <mat-placeholder>Last Name </mat-placeholder>
                                  <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                                  <input type="text" name="lastname" matInput id="lastName" formControlName="lastName">
                                  <mat-error *ngIf="activationFormGroup.get('lastName').errors?.required">
                                    {{ this.appMessagesService.personalRequiredMessages("last name") }}</mat-error>
                                  <mat-error *ngIf="activationFormGroup.get('lastName').errors?.maxlength">
                                    {{ this.appMessagesService.appStaticMessages.Employee.ValidEmployeeName }}
                                  </mat-error>
                                </mat-form-field>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div class="input-group form-control-lg">
                          <div class="two-col">
                            <div class="eq_col">
                              <table width="100%">
                                <tr>
                                  <td width="30">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                        <i class="material-icons">tune</i>
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <mat-form-field class="example-full-width has-label">
                                      <mat-placeholder>Staff Number </mat-placeholder>
                                      <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                                      <input type="text" name="staffNumber" matInput id="staffNumber"
                                        formControlName="staffNumber" pattern=".{1,20}">
                                      <mat-error *ngIf="activationFormGroup.get('staffNumber').errors?.required">
                                        {{ this.appMessagesService.personalRequiredMessages("staff number") }}
                                      </mat-error>
                                      <mat-error *ngIf="activationFormGroup.get('staffNumber').errors?.pattern">
                                        {{ this.appMessagesService.appStaticMessages.Employee.StaffNumberMax20Char }}
                                      </mat-error>
                                    </mat-form-field>
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <div class="eq_col">
                              <table width="100%">
                                <tr>
                                  <td width="30">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                        <i class="material-icons">date_range</i>
                                      </span>
                                    </div>
                                  </td>
                                  <td class="position-relative">
                                    <mat-form-field class="example-full-width has-label">
                                      <mat-placeholder>Date of Birth </mat-placeholder>
                                      <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                                      <input matInput [matDatepicker]="dateOfBirth" readonly
                                        [max]="dateOfBirthModel.maxDate" (focus)="openCalendar(dateOfBirth)"
                                        (click)="openCalendar(dateOfBirth)" formControlName="dateOfBirth">
                                      <mat-datepicker #dateOfBirth [calendarHeaderComponent]="datepickerHeader">
                                      </mat-datepicker>
                                      <!-- <mat-datepicker-toggle matSuffix [for]="dateOfBirth">
                                    </mat-datepicker-toggle> -->
                                      <mat-error *ngIf="activationFormGroup.get('dateOfBirth').errors?.required">
                                        {{ this.appMessagesService.customRequiredMessages("date of birth") }}
                                      </mat-error>
                                    </mat-form-field>
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <mat-icon *ngIf="!isUserValid" class="wizardsvgclass svgclass20 cursor-pointer"
                              [ngClass]="(!activationFormGroup.controls.firstName.valid || !activationFormGroup.controls.lastName.valid
                            || !activationFormGroup.controls.staffNumber.valid || !activationFormGroup.controls.dateOfBirth.valid)?'':'wizardsvgclassActive'" (click)="validateuserDetails()"
                              svgIcon="icon_next" title="Please click to validate">
                            </mat-icon>
                            <mat-icon *ngIf="isUserValid" class="wizardsvggreenclass hw20 svgclass20"
                              svgIcon="icon_right">
                            </mat-icon>
                          </div>
                        </div>
                        <div class="input-group form-control-lg">
                          <table width="100%">
                            <tr>
                              <td width="30">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                    <i class="material-icons">stay_current_portrait</i>
                                  </span>
                                </div>
                              </td>
                              <td width="60">
                                <mat-form-field>
                                  <mat-select class="margin_country" [(value)]="selectedCountry"
                                    [attr.disabled]="!isUserValid" (selectionChange)="onMobileCountryDDLChange($event)"
                                    panelClass="CustomMatSelectCountry">
                                    <mat-select-trigger>
                                      <img width="20" height="15" src="{{selectedCountry}}" alt="Country">
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let c of countryCodes" [value]="c.flagIcon">
                                      <img width="20" height="15" src="{{c.flagIcon}}" alt="Flag">
                                    </mat-option>

                                  </mat-select>
                                </mat-form-field>
                              </td>
                              <td width="40">
                                <mat-form-field class="example-full-width has-label label-custom">
                                  <input type="text" name="countryCode" matInput id="countryCode"
                                    formControlName="countryCode" style="width:30px;" readonly>
                                </mat-form-field>
                              </td>
                              <td>
                                <mat-form-field class="example-full-width has-label mobile_number">
                                  <mat-placeholder>Mobile Number </mat-placeholder>
                                  <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                                  <input [mask]="mask" name="mobile" matInput formControlName="mobileNumber" type="text"
                                    (input)="clearVerifiedMobileCheck();">
                                  <mat-error *ngIf="activationFormGroup.get('mobileNumber').errors?.required">
                                    {{ this.appMessagesService.personalRequiredMessages("mobile number") }}</mat-error>
                                  <mat-error *ngIf="activationFormGroup.get('mobileNumber').errors?.pattern">
                                    {{ this.appMessagesService.customValidator("mobile number") }}</mat-error>
                                  <mat-icon matSuffix
                                    *ngIf="!activationFormGroup.get('mobileNumber').errors?.required && isUserValid &&
                                                !activationFormGroup.get('mobileNumber').errors?.pattern && !verifiedPhoneNumber"
                                    class="btn-back wizardsvgclass  svgclass20 hw20 custom-position"
                                    title="click here to receive activation code"
                                    [ngClass]="(!activationFormGroup.get('mobileNumber').errors?.required && isUserValid &&
                                  !activationFormGroup.get('mobileNumber').errors?.pattern && !verifiedPhoneNumber)?'wizardsvgclassActive':''" svgIcon="icon_next"
                                    (click)="sendCode('mobile', 'registration', 'send', null, activationFormGroup.get('countryCode').value + activationFormGroup.get('mobileNumber').value)">
                                  </mat-icon>
                                  <mat-icon class="btn-back wizardsvggreenclass hw20 svgclass20 custom-position-tick"
                                    matSuffix *ngIf="verifiedPhoneNumber" svgIcon="icon_right"></mat-icon>
                                </mat-form-field>
                                <strong
                                  *ngIf="!activationFormGroup.get('mobileNumber').errors?.required && isUserValid &&
                                !activationFormGroup.get('mobileNumber').errors?.pattern && !verifiedPhoneNumber && !showMobileAuthenticationBox"
                                  class="nomargin nopadding font10 info-text position-absolute text-grey">Click arrow to
                                  receive activation code</strong>
                              </td>
                            </tr>
                            <tr *ngIf="showMobileAuthenticationBox">
                              <td colspan="4">
                                <span class="bmd-form-group codebox ml30">
                                  <div class="input-group">
                                    <div class="loginall">
                                      <input type="text" #otp1 class="mobile authenticationcode" maxlength="1" size="1"
                                        (keyup)="onKeyup($event,otp2,'','mobile', 'registration', 'send', null, activationFormGroup.get('countryCode').value + activationFormGroup.get('mobileNumber').value)"
                                        (keydown)="onKeydown($event)">
                                      <input type="text" #otp2 class="mobile authenticationcode" maxlength="1" size="1"
                                        (keyup)="onKeyup($event,otp3,otp1,'mobile', 'registration', 'send', null, activationFormGroup.get('countryCode').value + activationFormGroup.get('mobileNumber').value)"
                                        (keydown)="onKeydown($event)">
                                      <input type="text" #otp3 class="mobile authenticationcode" maxlength="1" size="1"
                                        (keyup)="onKeyup($event,otp4,otp2,'mobile', 'registration', 'send', null, activationFormGroup.get('countryCode').value + activationFormGroup.get('mobileNumber').value)"
                                        (keydown)="onKeydown($event)">
                                      <input type="text" #otp4 class="mobile authenticationcode" maxlength="1" size="1"
                                        (keyup)="onKeyup($event,otp5,otp3,'mobile', 'registration', 'send', null, activationFormGroup.get('countryCode').value + activationFormGroup.get('mobileNumber').value)"
                                        (keydown)="onKeydown($event)">
                                      <input type="text" #otp5 class="mobile authenticationcode" maxlength="1" size="1"
                                        (keyup)="onKeyup($event,otp6,otp4,'mobile', 'registration', 'send', null, activationFormGroup.get('countryCode').value + activationFormGroup.get('mobileNumber').value)"
                                        (keydown)="onKeydown($event)">
                                      <input type="text" #otp6 class="mobile authenticationcode" maxlength="1" size="1"
                                        (keyup)="onKeyup($event,'',otp5,'mobile', 'registration', 'send', null, activationFormGroup.get('countryCode').value + activationFormGroup.get('mobileNumber').value)"
                                        (keydown)="onKeydown($event)">
                                      <mat-icon class="svgclass25 text-danger cross-icon" svgIcon="icon_close"
                                        (click)="clear('mobile')"></mat-icon>
                                      <mat-icon class="svgclass25 text-grey ml_inside" svgIcon="icon_refresh"
                                        (click)="resend('mobile', 'registration', 'send', null, activationFormGroup.get('countryCode').value + activationFormGroup.get('mobileNumber').value)">
                                      </mat-icon>
                                      <p class="loginAlertTxt nomargin"><span class="asteriskDisplay">*</span>
                                        {{ this.appMessagesService.personalRequiredMessages("activation code") }}</p>
                                      <div *ngIf="mobileTimer > 0" style="line-height: 14px;" class="loginAlertTxt">
                                        <span class="asteriskDisplay">*</span>
                                        {{
                                        this.sharedService.formatString(this.appMessagesService.appStaticMessages.AppLevel.ResendCodeWaitError,
                                        this.mobileTimer) }}
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="spinner" *ngIf="isLoading">
                  </div>
                  <div class="tab-pane" id="account">
                    <div class="row">
                      <div class="col-sm-6">
                        <mat-form-field class="example-full-width has-label">
                          <mat-placeholder>User Name </mat-placeholder>
                          <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                          <input type="text" name="username" matInput id="userName" formControlName="userName"
                            [ngClass]="this.tenantConfigSettings?.activation?.usernameTypeId != 1 ? 'text-grey' : ''"
                            (blur)="checkUsernameAvailability()" (input)="checkUsernameAvailability()">
                          <mat-error *ngIf="activationFormGroup.get('userName').errors?.required">
                            {{ this.appMessagesService.personalRequiredMessages("user name") }}</mat-error>
                          <mat-error
                            *ngIf="!activationFormGroup.get('userName').errors?.required && activationFormGroup.get('userName').errors?.pattern">
                            {{usernameErrorMsgPattern}}</mat-error>
                          <span *ngIf="!isUsernameUnique" class="error_msg">{{
                            this.appMessagesService.appStaticMessages.Employee.UniqueUserName }}</span>
                        </mat-form-field>
                        <div class="two-col">
                          <div class="row">
                            <div class="col-xs-12 col-md-6 country_width">
                              <mat-form-field class="example-full-width has-label">
                                <mat-select class="margin_country" [(value)]="defaultCountryUniqueIDIcon"
                                  panelClass="CustomMatSelectCountry">
                                  <mat-select-trigger>
                                    <img width="20" height="15" src="{{defaultCountryUniqueIDIcon}}" alt="Country">
                                  </mat-select-trigger>
                                  <mat-option *ngFor="let c of countryCodes" [value]="c.flagIcon"
                                    (click)="onCountryDDLChange(c)">
                                    <img width="20" height="15" src="{{c.flagIcon}}" alt="Flag">
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col-md-6 mt1 uniq_width position-relative">
                              <mat-form-field class="uniquecode">
                                <mat-placeholder>{{defaultSelectedUniqueIDFormat}} </mat-placeholder>
                                <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                                <input type="text" name="uniqueid" matInput id="uniqueId" formControlName="uniqueId"
                                  [maxlength]="uniqueIdMaxLength"
                                  [pattern]="(this.employeePersonalInfo && this.employeePersonalInfo.uniqueId !== '-')? '' : uniqueIdPattern"
                                  (change)="validateUniqueID($event.target.value)" #uniqueId>
                                <mat-hint
                                  *ngIf="isvalidFlag && showUniqueIdHint && defaultSelectedCountryCode === '+44'">
                                  {{this.sharedService.formatString(this.appMessagesService.appStaticMessages.AppLevel.UniqueIdHint
                                  , defaultSelectedUniqueIDFormat)}}
                                </mat-hint>
                                <mat-error *ngIf="activationFormGroup.get('uniqueId').errors?.required">
                                  {{ this.appMessagesService.personalRequiredMessages(defaultSelectedUniqueIDFormat) }}
                                </mat-error>
                                <mat-error
                                  *ngIf="(this.employeePersonalInfo && this.employeePersonalInfo.uniqueId !== '-') && (!activationFormGroup.get('uniqueId').errors?.required) && activationFormGroup.get('uniqueId').errors?.pattern">
                                  {{ this.appMessagesService.customValidator(defaultSelectedUniqueIDFormat) }}
                                </mat-error>
                              </mat-form-field>
                              <span class="mat-error cus_error posAbsolute" id="uniqueIdError"
                                *ngIf="!activationFormGroup.get('uniqueId').errors?.required  && !activationFormGroup.get('uniqueId').errors?.pattern && !isvalidFlag">
                                <span
                                  *ngIf="(this.employeePersonalInfo.uniqueId === '-' && uniqueId.value !== '-') || (this.employeePersonalInfo.uniqueId !== '-' && uniqueId.value === '-');else elseTemplate">
                                  {{this.sharedService.formatString(this.appMessagesService.appStaticMessages.AppLevel.UniqueIdHint
                                  , defaultSelectedUniqueIDFormat)}}
                                </span>
                                <ng-template #elseTemplate>
                                  {{ this.appMessagesService.appStaticMessages.AppLevel.Your
                                  }}{{defaultSelectedUniqueIDFormat}}{{
                                  this.appMessagesService.appStaticMessages.AppLevel.isInvalid }}
                                </ng-template>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row" formGroupName="passwordGroup">
                          <mat-form-field class="example-full-width has-label">
                            <mat-placeholder>Password </mat-placeholder>
                            <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                            <input type="{{userPasswordType}}" name="password" matInput id="password"
                              formControlName="password" maxlength="26">
                            <mat-error
                              *ngIf="activationFormGroup.get('passwordGroup').get('password').errors?.required">
                              {{ this.appMessagesService.personalRequiredMessages("password") }}
                            </mat-error>
                            <mat-error
                              *ngIf="!activationFormGroup.get('passwordGroup').get('password').errors?.required && activationFormGroup.get('passwordGroup').get('password').errors?.pattern">
                              {{passwordErrorMsgPattern}}
                            </mat-error>
                            <a (click)="togglePasswordShowHide()"
                              class="material-icons password_toggle_icon cursor-pointer">{{_passwordText}}</a>
                          </mat-form-field>
                          <mat-form-field class="example-full-width has-label">
                            <mat-placeholder>Re-enter Password </mat-placeholder>
                            <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                            <input type="{{userConfirmPasswordType}}" matInput id="confirmPassword"
                              name="confirmPassword" formControlName="confirmPassword" maxlength="26">
                            <mat-error
                              *ngIf="activationFormGroup.get('passwordGroup').get('confirmPassword').errors?.required">
                              {{ this.appMessagesService.appStaticMessages.AppLevel.ReEnterPassword }}
                            </mat-error>
                            <a (click)="toggleConfirmPasswordShowHide()"
                              class="material-icons password_toggle_icon cursor-pointer">{{_cnfrmPasswordText}}</a>
                          </mat-form-field>
                          <div class="mat-error cus_error"
                            *ngIf="!activationFormGroup.get('passwordGroup').get('password').errors?.required && !activationFormGroup.get('passwordGroup').get('confirmPassword').errors?.required && activationFormGroup.get('passwordGroup').errors?.invalid">
                            {{ this.appMessagesService.appStaticMessages.AppLevel.PasswordsDoesNotMatch }}
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="col-md-12">
                            <h5 class="description">Default Communication <span class="asteriskDisplay">*</span></h5>
                          </div>
                          <div class="col-xs col-sm-5 col-md-5 pr0"
                            *ngIf="preferedDefaultCommunication == 1 || preferedDefaultCommunication == 3">
                            <div class="form-check form-check-inline">
                              <label class="form-check-label">
                                <input class="form-check-input" type="radio" name="communicationPreferenceId" value="1"
                                  (change)="clearVerifiedCommunicationPreferenceCheck('mobile');"
                                  formControlName="communicationPreferenceId">
                                SMS/Push Notification
                                <span class="circle">
                                  <span class="check"></span>
                                </span>
                              </label>
                            </div>
                          </div>
                          <div class="col-xs col-sm-5 col-md-3 pr0"
                            *ngIf="preferedDefaultCommunication == 2 || preferedDefaultCommunication == 3">
                            <div class="form-check form-check-inline">
                              <label class="form-check-label">
                                <input class="form-check-input" type="radio" name="communicationPreferenceId" value="2"
                                  (change)="clearVerifiedCommunicationPreferenceCheck('email');"
                                  formControlName="communicationPreferenceId">
                                Email
                                <span class="circle">
                                  <span class="check"></span>
                                </span>
                              </label>
                            </div>
                          </div>
                          <div class="col-xs col-sm-3 col-md-3 pr0 pl0" *ngIf="preferedDefaultCommunication == 3">
                            <div class="form-check form-check-inline">
                              <label class="form-check-label">
                                <input class="form-check-input" type="radio" name="communicationPreferenceId" value="3"
                                  (change)="clearVerifiedCommunicationPreferenceCheck('both');"
                                  formControlName="communicationPreferenceId">
                                Both
                                <span class="circle">
                                  <span class="check"></span>
                                </span>
                              </label>
                            </div>
                          </div>
                          <mat-icon matSuffix
                            *ngIf="!activationFormGroup.get('communicationPreferenceId').errors?.required &&
                        !activationFormGroup.get('communicationPreferenceId').errors?.pattern && !verifiedCommunicationPreference"
                            class="wizardsvgclass svgclass24 btn-back wizardsvgclassActive hw22" svgIcon="icon_next"
                            (click)="sendCode('communication', 'registration', 'send', activationFormGroup.get('emailAddress').value, activationFormGroup.get('countryCode').value + activationFormGroup.get('mobileNumber').value, activationFormGroup.get('communicationPreferenceId').value)">
                          </mat-icon>
                          <mat-icon class="btn-back wizardsvggreenclass svgclass24 hw22" matSuffix
                            *ngIf="verifiedCommunicationPreference" svgIcon="icon_right"></mat-icon>
                          <strong
                            *ngIf="!activationFormGroup.get('communicationPreferenceId').errors?.required &&
                          !activationFormGroup.get('communicationPreferenceId').errors?.pattern && !verifiedCommunicationPreference && !showCommunicationPreferenceAuthenticationBox"
                            class="font10 text-grey col-md-12">Click arrow to receive activation code</strong>
                        </div>
                        <div *ngIf="showCommunicationPreferenceAuthenticationBox">
                          <div>
                            <span class="bmd-form-group-communication bmd-form-group codebox">
                              <div class="input-group">
                                <div class="loginall">
                                  <input type="text" #otp1 class="communication authenticationcode" maxlength="1"
                                    size="1"
                                    (keyup)="onKeyup($event,otp2,'', 'communication', 'registration', 'send', activationFormGroup.get('emailAddress').value, activationFormGroup.get('countryCode').value + activationFormGroup.get('mobileNumber').value, activationFormGroup.get('communicationPreferenceId').value)"
                                    (keydown)="onKeydown($event)">
                                  <input type="text" #otp2 class="communication authenticationcode" maxlength="1"
                                    size="1"
                                    (keyup)="onKeyup($event,otp3,otp1, 'communication', 'registration', 'send', activationFormGroup.get('emailAddress').value, activationFormGroup.get('countryCode').value + activationFormGroup.get('mobileNumber').value, activationFormGroup.get('communicationPreferenceId').value)"
                                    (keydown)="onKeydown($event)">
                                  <input type="text" #otp3 class="communication authenticationcode" maxlength="1"
                                    size="1"
                                    (keyup)="onKeyup($event,otp4,otp2, 'communication', 'registration', 'send', activationFormGroup.get('emailAddress').value, activationFormGroup.get('countryCode').value + activationFormGroup.get('mobileNumber').value, activationFormGroup.get('communicationPreferenceId').value)"
                                    (keydown)="onKeydown($event)">
                                  <input type="text" #otp4 class="communication authenticationcode" maxlength="1"
                                    size="1"
                                    (keyup)="onKeyup($event,otp5,otp3, 'communication', 'registration', 'send', activationFormGroup.get('emailAddress').value, activationFormGroup.get('countryCode').value + activationFormGroup.get('mobileNumber').value, activationFormGroup.get('communicationPreferenceId').value)"
                                    (keydown)="onKeydown($event)">
                                  <input type="text" #otp5 class="communication authenticationcode" maxlength="1"
                                    size="1"
                                    (keyup)="onKeyup($event,otp6,otp4, 'communication', 'registration', 'send', activationFormGroup.get('emailAddress').value, activationFormGroup.get('countryCode').value + activationFormGroup.get('mobileNumber').value, activationFormGroup.get('communicationPreferenceId').value)"
                                    (keydown)="onKeydown($event)">
                                  <input type="text" #otp6 class="communication authenticationcode" maxlength="1"
                                    size="1"
                                    (keyup)="onKeyup($event,'',otp5, 'communication', 'registration', 'send', activationFormGroup.get('emailAddress').value, activationFormGroup.get('countryCode').value + activationFormGroup.get('mobileNumber').value, activationFormGroup.get('communicationPreferenceId').value)"
                                    (keydown)="onKeydown($event)">
                                  <mat-icon class="svgclass25 text-danger cross-icon" svgIcon="icon_close"
                                    (click)="clear('communication')"></mat-icon>
                                  <mat-icon class="svgclass25 text-grey ml_inside" svgIcon="icon_refresh"
                                    (click)="resend('communication', 'registration', 'send', activationFormGroup.get('emailAddress').value, activationFormGroup.get('countryCode').value + activationFormGroup.get('mobileNumber').value, activationFormGroup.get('communicationPreferenceId').value)">
                                  </mat-icon>
                                  <p class="loginAlertTxt nomargin"><span class="asteriskDisplay">*</span>
                                    {{ this.appMessagesService.personalRequiredMessages("activation code") }}</p>
                                  <div *ngIf="emailTimer > 0 || mobileTimer > 0" style="line-height: 14px;"
                                    class="loginAlertTxt"><span class="asteriskDisplay">*</span>
                                    {{
                                    this.sharedService.formatString(this.appMessagesService.appStaticMessages.AppLevel.ResendCodeWaitError,
                                    (this.emailTimer > 0 ? this.emailTimer : this.mobileTimer)) }}
                                  </div>
                                </div>
                              </div>
                            </span>
                            <div *ngIf="showSpamMsg && !(emailTimer > 0 || mobileTimer > 0)" class="loginAlertTxt">
                              {{this.appMessagesService.appStaticMessages.AppLevel.EmailSpamMessage}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="security">
                    <div class="scroll_pane">
                      <perfect-scrollbar style="max-width: 100%;" class="perfectScroll_custom">
                        <div id="accordion" role="tablist" class="pr10 pl10">
                          <!-- one accordion -->
                          <div class="card-collapse">
                            <div class="card-header" role="tab" id="headingOne">
                              <h3 class="mt0 mb0">
                                <a class="collapsed" data-toggle="collapse" href="#collapseOne" aria-expanded="false"
                                  aria-controls="collapseOne">
                                  <span>
                                    <!-- <i class="material-icons norotate display-block float-left">settings</i> -->
                                    <span class="">
                                      <b class="font15">Security Questions</b>
                                    </span>
                                  </span>
                                  <i class="material-icons mr10">keyboard_arrow_down</i>
                                </a>
                              </h3>
                            </div>
                            <div id="collapseOne" class="collapse" role="tabpanel" aria-labelledby="headingOne"
                              data-parent="#accordion">
                              <div class="row ml0 mr0" formArrayName="questionGroupArray">
                                <div class="col-md-6"
                                  *ngFor="let itemrow of activationFormGroup.get('questionGroupArray')['controls']; let i=index">
                                  <div [formGroupName]="i">
                                    <div class="form-check side_by_side">
                                      <label class="form-check-label">
                                        <input class="form-check-input" type="checkbox" formControlName="sqCheckbox"
                                          (change)="questionChecked($event,i)" checked (input)="finishButton(true)">
                                        <span class="form-check-sign">
                                          <span class="check"></span>
                                        </span>
                                      </label>
                                    </div>
                                    <div class="side_by_side RightField">
                                      <mat-form-field>
                                        <mat-placeholder>{{itemrow.get('placeholder').value}} </mat-placeholder>
                                        <input type="text" class="input-lg nomargin nopadding" name="question" matInput
                                          formControlName="questionText" id="{{'questionId' + i}}"
                                          (input)="finishButton(true)">
                                        <mat-error
                                          *ngIf="itemrow.get('questionText').errors?.required && itemrow.get('sqCheckbox').value==true">
                                          {{ this.appMessagesService.personalRequiredMessages("answer") }}
                                        </mat-error>
                                        <mat-error
                                          *ngIf="itemrow.get('questionText').errors?.whitespace && !itemrow.get('questionText').errors?.required">
                                          {{ this.appMessagesService.customValidator("answer") }}</mat-error>
                                      </mat-form-field>
                                      <input type="hidden" formControlName="questionId">
                                      <input type="hidden" formControlName="securityId">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- one accordion ends -->
                          <!-- two accordion -->
                          <div class="card-collapse" *ngIf="isPersonalQuesChecked == true">
                            <div class="card-header" role="tab" id="headingTwo">
                              <h3 class="mt0 mb0">
                                <a class="collapsed" data-toggle="collapse" href="#collapseTwo" aria-expanded="false"
                                  aria-controls="collapseTwo">
                                  <span>
                                    <!-- <i class="material-icons norotate display-block float-left">settings</i> -->
                                    <span class="">
                                      <b class="font15">Personal Questions</b>
                                    </span>
                                  </span>
                                  <i class="material-icons mr10">keyboard_arrow_down</i>
                                </a>
                              </h3>
                            </div>
                            <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo"
                              data-parent="#accordion">
                              <div *ngIf="isPersonalQuesChecked == true">
                                <div class="row ml0 mr0" style="padding-top: 15px">
                                  <div class="col-md-12">
                                    <h5 class="description font-weight-bold">Personal Questions:</h5>
                                  </div>
                                </div>
                                <div class="row ml0 mr0">
                                  <div class="col-md-6">
                                    <div class="float-left width100per mb10">
                                      <mat-form-field>
                                        <mat-placeholder>Enter your personal question </mat-placeholder>
                                        <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                                        <input matInput type="text" class="input-lg nomargin nopadding"
                                          formControlName="personalQuesText1" (input)="finishButton(true)">
                                        <mat-error
                                          *ngIf="activationFormGroup.get('personalQuesText1').errors?.required">
                                          {{ this.appMessagesService.personalRequiredMessages("personal question") }}
                                        </mat-error>
                                        <mat-error
                                          *ngIf="activationFormGroup.get('personalQuesText1').errors?.whitespace && !activationFormGroup.get('personalQuesText1').errors?.required">
                                          {{ this.appMessagesService.customValidator("question") }}</mat-error>
                                      </mat-form-field>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="float-left width100per mb10">
                                      <mat-form-field>
                                        <mat-placeholder>Enter your answer for personal question </mat-placeholder>
                                        <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                                        <input matInput type="text" class="input-lg nomargin nopadding"
                                          formControlName="personalAnsText1" (input)="finishButton(true)">
                                        <mat-error *ngIf="activationFormGroup.get('personalAnsText1').errors?.required">
                                          {{ this.appMessagesService.appStaticMessages.Employee.EnterPersonalAnswer }}
                                        </mat-error>
                                        <mat-error
                                          *ngIf="activationFormGroup.get('personalAnsText1').errors?.whitespace && !activationFormGroup.get('personalAnsText1').errors?.required">
                                          {{ this.appMessagesService.customValidator("answer") }}</mat-error>
                                      </mat-form-field>
                                    </div>
                                  </div>
                                </div>
                                <div class="row ml0 mr0" *ngIf="personalQuesCount == 2">
                                  <div class="col-md-6">
                                    <div class="float-left width100per mb10">
                                      <mat-form-field>
                                        <mat-placeholder>Enter your personal question </mat-placeholder>
                                        <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                                        <input matInput type="text" class="input-lg nomargin nopadding"
                                          formControlName="personalQuesText2" (input)="finishButton(true)">
                                        <mat-error
                                          *ngIf="activationFormGroup.get('personalQuesText2').errors?.required">
                                          {{ this.appMessagesService.personalRequiredMessages("personal question") }}
                                        </mat-error>
                                        <mat-error
                                          *ngIf="activationFormGroup.get('personalQuesText2').errors?.whitespace && !activationFormGroup.get('personalQuesText2').errors?.required">
                                          {{ this.appMessagesService.customValidator("question") }}</mat-error>
                                      </mat-form-field>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="float-left width100per mb10">
                                      <mat-form-field>
                                        <mat-placeholder>Enter your answer for personal question </mat-placeholder>
                                        <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                                        <input matInput type="text" class="input-lg nomargin nopadding"
                                          formControlName="personalAnsText2" (input)="finishButton(true)">
                                        <mat-error *ngIf="activationFormGroup.get('personalAnsText2').errors?.required">
                                          {{ this.appMessagesService.appStaticMessages.Employee.EnterPersonalAnswer }}
                                        </mat-error>
                                        <mat-error
                                          *ngIf="activationFormGroup.get('personalAnsText2').errors?.whitespace && !activationFormGroup.get('personalAnsText2').errors?.required">
                                          {{ this.appMessagesService.customValidator("answer") }}</mat-error>
                                      </mat-form-field>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- two accordion ends -->
                          <!-- three accordion -->
                          <div class="card-collapse">
                            <div class="card-header" role="tab" id="headingThree">
                              <h3 class="mt0 mb0">
                                <a class="collapsed" data-toggle="collapse" href="#collapseThree" aria-expanded="false"
                                  aria-controls="collapseThree">
                                  <span>
                                    <!-- <i class="material-icons norotate display-block float-left">settings</i> -->
                                    <span class="">
                                      <b class="font15">Recovery Question</b>
                                    </span>
                                  </span>
                                  <i class="material-icons mr10">keyboard_arrow_down</i>
                                </a>
                              </h3>
                            </div>
                            <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree"
                              data-parent="#accordion">
                              <div>
                                <div class="row ml0 mr0" style="padding-top: 15px">
                                  <div class="col-md-12">
                                    <h5 class="description font-weight-bold">Recovery Question:</h5>
                                  </div>
                                </div>
                                <div class="row ml0 mr0">
                                  <div class="col-md-6">
                                    <div class="float-left width100per mb10">
                                      <mat-form-field>
                                        <mat-placeholder>Question </mat-placeholder>
                                        <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                                        <input matInput type="text" class="input-lg nomargin nopadding" maxlength="100"
                                          formControlName="hintQuestion" (input)="finishButton(true)">
                                        <mat-error *ngIf="activationFormGroup.get('hintQuestion').errors?.required">
                                          {{ this.appMessagesService.personalRequiredMessages("hint question") }}
                                        </mat-error>
                                        <mat-error
                                          *ngIf="activationFormGroup.get('hintQuestion').errors?.whitespace && !activationFormGroup.get('hintQuestion').errors?.required">
                                          {{ this.appMessagesService.customValidator("question") }}</mat-error>
                                      </mat-form-field>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="float-left width100per">
                                      <mat-form-field>
                                        <mat-placeholder>Answer </mat-placeholder>
                                        <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                                        <input matInput type="text" class="input-lg nomargin nopadding" maxlength="100"
                                          formControlName="hintAnswer" (input)="finishButton(true)">
                                        <mat-error *ngIf="activationFormGroup.get('hintAnswer').errors?.required">
                                          {{ this.appMessagesService.appStaticMessages.Employee.EnterHintAnswer }}
                                        </mat-error>
                                        <mat-error
                                          *ngIf="activationFormGroup.get('hintAnswer').errors?.whitespace && !activationFormGroup.get('hintAnswer').errors?.required">
                                          {{ this.appMessagesService.customValidator("answer") }}</mat-error>
                                      </mat-form-field>
                                    </div>
                                    <div class="float-left width100per mb10">
                                      <mat-form-field>
                                        <mat-placeholder>Hint for your answer </mat-placeholder>
                                        <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                                        <input matInput type="text" class="input-lg nomargin nopadding"
                                          formControlName="hint" maxlength="100" (input)="finishButton(true)">
                                        <mat-error *ngIf="activationFormGroup.get('hint').errors?.required">
                                          {{ this.appMessagesService.appStaticMessages.Employee.EnterHint }}
                                        </mat-error>
                                        <mat-error
                                          *ngIf="activationFormGroup.get('hint').errors?.whitespace && !activationFormGroup.get('hint').errors?.required">
                                          {{ this.appMessagesService.customValidator("hint") }}</mat-error>
                                        <span class="mat-error customErr"
                                          *ngIf="!activationFormGroup.get('hint').errors?.required && !activationFormGroup.get('hint').errors?.whitespace && (activationFormGroup.get('hint').value === activationFormGroup.get('hintAnswer').value)">
                                          {{ this.appMessagesService.appStaticMessages.Employee.AnswerHintSameError
                                          }}</span>
                                      </mat-form-field>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- three accordion ends -->
                        </div>
                        <!--Accordion ends-->
                      </perfect-scrollbar>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer card-footer mb10 mr30 ml30 ptop0">
                <div class="mr-auto">
                  <a>
                    <mat-icon class="wizardsvgclass btn-previous  svgclass34" svgIcon="icon_back"></mat-icon>
                  </a>
                </div>
                <div class="ml-auto" style="display: contents;">
                  <span class="messageForActive"
                    *ngIf="currentPage === 1 && verifiedEmailAddress && verifiedPhoneNumber ">{{msgForTabValidation1}}</span>
                  <span class="messageForActive"
                    *ngIf="(!activationFormGroup.get('passwordGroup').get('password').errors) && currentPage === 2 && isvalidFlag && verifiedCommunicationPreference">{{msgForTabValidation2}}</span>
                  <a>
                    <mat-icon class="wizardbigsvgclass svgclass32 btn-next cursor-pointer"
                      [ngClass]="(verifiedEmailAddress && verifiedPhoneNumber && currentPage === 1) || ((!activationFormGroup.get('passwordGroup').get('password').errors) && isvalidFlag && verifiedCommunicationPreference && currentPage === 2)? 'wizardsvgclassActive':''"
                      name="next" svgIcon="icon_next">
                    </mat-icon>
                  </a>
                  <div class="finishTxt btn-finish text-uppercase font500"> {{isFormValid?'Finish':''}}
                    <button type="button" rel="tooltip" (click)="finishButton()" class="btn btn-round btn-fab btn-sm"
                      [ngClass]="isFormValid?'btn-success':'btn-disabled cursor-default'">
                      <i class="material-icons">check</i>
                    </button>
                  </div>
                </div>
                <div class="clearfix"></div>
              </div>
            </form>
          </div>
        </div>
        <!-- wizard container -->
      </div>
    </div>
  </div>
</div>