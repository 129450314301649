import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IEmailingConfiguration } from '../Interfaces/IEmailingConfiguration';
import { BaseService } from './baseService.service';
import { RequestType } from './requestType';

@Injectable({
  providedIn: 'root',
})
export class ClientAdminEmailingService {

  tenant: string = window.sessionStorage.getItem('tenant');
  relativeURL = 'ClientAdminEmailing/';
  constructor(private baseService: BaseService) { }

  GetClientEmailingConfiguration(): Observable<IEmailingConfiguration> {
    return this.baseService.Execute<IEmailingConfiguration>(RequestType.GET, this.relativeURL + this.tenant, 'baseAdminEndpoint', null);
  }

  AddOrUpdateClientEmailingConfiguration(data: IEmailingConfiguration): Observable<IEmailingConfiguration> {
    return this.baseService.Execute<IEmailingConfiguration>(RequestType.POST, this.relativeURL + this.tenant, 'baseAdminEndpoint', data);
  }
}
