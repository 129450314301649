import { RouteInfo } from '../sidebar.component';

export const EmployeeROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icon: 'Dashboard',
    ab: 'D'
  },
  {
    path: '/myprofile',
    title: 'My Profile',
    type: 'link',
    icon: 'dashboard',
    ab: 'MP'
  },
  {
    path: '/calendar',
    title: 'My Calendar',
    type: 'link',
    icon: 'calendar',
    ab: 'MC'
  },
  {
    path: '/message-board',
    title: 'Message Board',
    type: 'link',
    icon: 'messageBoard',
    ab: 'MB'
  },
  {
    path: '/document-library',
    title: 'Document Library',
    type: 'link',
    icon: 'messageBoard',
    ab: 'DL'
  },
  {
    path: '/my-tickets',
    title: 'My Tickets',
    type: 'link',
    icon: 'dashboard',
    ab: 'MT'
  }
];
