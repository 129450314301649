<div class="position-relative">
  <div class="PopCloseIcon" (click)="close()"><i class="material-icons">close</i></div>
</div>
<div class="position-relative">
  <div class="row">
    <div class="col-md-12 formHeight">
      <p class="nopadding mb15 text-uppercase text-grey font500 pt1 pl5">
        {{currentRoleId!=5 && currentRolePermissions.canEdit?'Edit ':''}}Event</p>
      <div class="dialogHeight shadoweffect" [ngClass]="isDisabled ? 'bglight' : 'bgwhite'">
        <form *ngIf="showForm" [formGroup]="editEventForm" class="example-form pt10 pr5 pl5">
          <div class="row">
            <div class="col-md-3" *ngIf="currentRoleId === 5">
              <mat-form-field class="example-full-width">
                <mat-placeholder>Staff Number </mat-placeholder>
                <input matInput [value]="this.dialogData.data.staffNumber" type="text" readonly>
              </mat-form-field>
            </div>
            <div class="addDate" [ngClass]="currentRoleId!=5?'col-md-6':'col-md-9'">
              <mat-form-field class="example-full-width">
                <mat-placeholder>Event Type </mat-placeholder>
                <mat-placeholder class="asteriskDisplay">{{currentRoleId!=5 && currentRolePermissions.canEdit?'*':''}}
                </mat-placeholder>
                <mat-select (focus)="popupDropdownClose($event)" #subject [attr.disabled]='isDisabled'
                  formControlName="calendarDocumentId" (openedChange)="openedChange($event)">
                  <perfect-scrollbar style="max-width: 100%;"
                    class="CustomMatSelectDropDown_Scroll perfectScroll_custom">
                    <mat-option *ngFor="let dt of documentTypes" [value]="dt.id">
                      {{ dt.name }}
                    </mat-option>
                  </perfect-scrollbar>
                </mat-select>
                <mat-error *ngIf="editEventForm.controls.calendarDocumentId.errors?.required">
                  {{ this.appMessagesService.customSelector("subject") }}</mat-error>
              </mat-form-field>
            </div>
            <div *ngIf="currentRoleId!=5 && currentRolePermissions.canEdit" class="addDate col-md-6 checkBox">
              <div class="form-check">
                <label class="form-check-label labelStyle">
                  <input (focus)="popupDropdownClose($event)" type="checkbox" class="rightElements form-check-input"
                    formControlName="isPublished">Publish
                  <span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field class="example-full-width">
                <mat-placeholder>Start Date </mat-placeholder>
                <mat-placeholder class="asteriskDisplay">{{currentRoleId!=5 && currentRolePermissions.canEdit?'*':''}}
                </mat-placeholder>
                <input matInput [matDatepicker]="startDatePicker" (dateChange)="onStartDateChanged($event)" [min]="dpStartDate"
                  name="startDate" autocomplete="off" (click)="openCalendar(startDatePicker)" readonly
                  formControlName="eventStartDate" (mouseleave)="startDateMouseLeave()">
                <mat-datepicker [calendarHeaderComponent]="datepickerHeader" #startDatePicker panelClass="mat-datepicker-content" [startAt]="dpStartDate">
                </mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="startDatePicker">
                </mat-datepicker-toggle>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field class="example-full-width">
                <input formControlName="startTime" matInput value="" autocomplete="off"
                  [matAutocomplete]="autoStartTime" [(ngModel)]="startCurrentTime" (focus)="highlightChangedStartTime()"
                  (input)="highlightChangedStartTime()" (click)="openPanel($event, startTrigger)"
                  #startTrigger="matAutocompleteTrigger">
                <mat-placeholder>Start Time </mat-placeholder>
                <mat-placeholder class="asteriskDisplay" *ngIf="currentRoleId!=5 && currentRolePermissions.canEdit">*
                </mat-placeholder>
                <mat-autocomplete id="mat-com" panelClass="CustomMatSelectDropDown" #autoStartTime="matAutocomplete">
                  <perfect-scrollbar style="max-width: 100%;"
                    class="CustomMatSelectDropDown_Scroll perfectScroll_custom CustomAlignment">
                    <div class="custom_options">
                      <mat-option *ngFor="let t of startTimeList" [value]="t.timeIn12hrFormart"
                        matTooltipPosition="right">
                        <input type="radio" id="test1" name="startRadiobutton" [checked]="t.isActive"
                          (click)="selectStartTime(t.timeIn12hrFormart)">
                        <label for="test1"
                          (click)="selectStartTime(t.timeIn12hrFormart)">{{t.timeIn12hrFormart}}</label>
                      </mat-option>
                    </div>
                  </perfect-scrollbar>
                </mat-autocomplete>
                <mat-error
                  *ngIf="editEventForm.controls['startTime'].errors?.pattern || editEventForm.controls['startTime'].errors?.required">
                  {{this.appMessagesService.customValidator("time")}}</mat-error>
              </mat-form-field>
              <span class="mat-error customErr" *ngIf="isStartDateLessThanCurrentTime">
                {{ appMessagesService.appStaticMessages.PayManager.TimeLesserThanCurrentTimeError }}</span>
            </div>
            <div class="col-md-3">
              <mat-form-field class="example-full-width">
                <mat-placeholder>End Date </mat-placeholder>
                <mat-placeholder class="asteriskDisplay">{{currentRoleId!=5 && currentRolePermissions.canEdit?'*':''}}
                </mat-placeholder>
                <input matInput (click)="popupDropdownClose($event)" (dateChange)="onEndDateChanged($event)"
                  [matDatepicker]="endDatePicker" [min]="minEndDate" name="endDate" autocomplete="off"
                  (click)="openCalendar(endDatePicker)" readonly formControlName="eventEndDate"
                  (mouseleave)="endDateMouseLeave()">
                <mat-datepicker [calendarHeaderComponent]="datepickerHeader" #endDatePicker panelClass="mat-datepicker-content" [startAt]="selectedDate">
                </mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="endDatePicker">
                </mat-datepicker-toggle>
              </mat-form-field>
              <span class="mat-error customErr" *ngIf="this.isStartDateGreaterThanEndDate === true">
                {{ this.appMessagesService.appStaticMessages.AppLevel.DateRangeError }}</span>
            </div>
            <div class="col-md-3">
              <mat-form-field class="example-full-width">
                <input formControlName="endTime" matInput value="" autocomplete="off" [matAutocomplete]="autoEndTime"
                  [(ngModel)]="endCurrentTime" (focus)="highlightChangedEndTime()" (input)="highlightChangedEndTime()"
                  (click)="openPanel($event, endTrigger)" #endTrigger="matAutocompleteTrigger">
                <mat-placeholder>End Time </mat-placeholder>
                <mat-placeholder class="asteriskDisplay" *ngIf="currentRoleId!=5 && currentRolePermissions.canEdit">*
                </mat-placeholder>
                <mat-autocomplete panelClass="CustomMatSelectDropDown" #autoEndTime="matAutocomplete">
                  <perfect-scrollbar style="max-width: 100%;"
                    class="CustomMatSelectDropDown_Scroll perfectScroll_custom CustomAlignment">
                    <div class="custom_options">
                      <mat-option *ngFor="let t of endTimeList" [value]="t.timeIn12hrFormart"
                        matTooltipPosition="right">
                        <input type="radio" id="test1" name="endRadiobutton" [checked]="t.isActive"
                          (click)="selectEndTime(t.timeIn12hrFormart)">
                        <label for="test1" (click)="selectEndTime(t.timeIn12hrFormart)">{{t.timeIn12hrFormart}}</label>
                      </mat-option>
                    </div>
                  </perfect-scrollbar>
                </mat-autocomplete>
                <mat-error
                  *ngIf="editEventForm.controls['endTime'].errors?.pattern || editEventForm.controls['endTime'].errors?.required">
                  {{this.appMessagesService.customValidator("time")}}</mat-error>

              </mat-form-field>
              <span class="mat-error customErr" *ngIf="isEndTimeLessThanStartTime">
                {{ appMessagesService.appStaticMessages.PayManager.StartTimeLesserThanEndTime }}</span>
            </div>
          </div>
          <div class="row mt-s">
            <div class="addDate col-md-6">
              <mat-form-field class="example-full-width">
                <mat-placeholder>Location </mat-placeholder>
                <mat-placeholder class="asteriskDisplay">{{currentRoleId!=5 && currentRolePermissions.canEdit?'*':''}}
                </mat-placeholder>
                <mat-select panelClass="CustomMatSelectDropDown" #location (focus)="popupDropdownClose($event)"
                  name="location" formControlName="location" (selectionChange)="selectLocation($event.value)"
                  [(ngModel)]="selectedLocation" (openedChange)="openedChange($event)">
                  <perfect-scrollbar style="max-width: 100%;"
                    class="CustomMatSelectDropDown_Scroll perfectScroll_custom">
                    <mat-option *ngFor="let d of distinctLocations" [value]="d">{{d}}</mat-option>
                  </perfect-scrollbar>
                </mat-select>
                <mat-error *ngIf="editEventForm.controls.location.errors?.required">
                  {{ this.appMessagesService.customSelector("your location") }}</mat-error>
              </mat-form-field>
            </div>
            <div class="addTime col-md-6">
              <mat-form-field class="example-full-width">
                <mat-placeholder>Payroll </mat-placeholder>
                <mat-placeholder class="asteriskDisplay">{{currentRoleId!=5 && currentRolePermissions.canEdit?'*':''}}
                </mat-placeholder>
                <mat-select #payRoll (focus)="popupDropdownClose($event)" panelClass="CustomMatMultiSelectDropDown"
                  [ngClass]="allPayrolls? 'hiddenClass' : 'showClass'" name="Payroll" formControlName="payRoll" multiple
                  [(ngModel)]="selectedPayrollArray" (openedChange)="openedChange($event)">
                  <perfect-scrollbar style="max-width: 100%;" class="perfectScroll_custom">
                    <mat-option (click)="selectAll(d.payrollId)" *ngFor="let d of payRollList" [value]="d.payrollId">
                      {{d.clientPayrollName }}</mat-option>
                  </perfect-scrollbar>
                </mat-select>
                <mat-error *ngIf="editEventForm.controls.calendarDocumentId.errors?.required">
                  {{ this.appMessagesService.customSelector("your payroll") }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <mat-form-field class="example-full-width mt10 eventTitle">
            <mat-placeholder>Event Title </mat-placeholder>
            <mat-placeholder class="asteriskDisplay">{{currentRoleId!=5 && currentRolePermissions.canEdit?'*':''}}
            </mat-placeholder>
            <input [(ngModel)]="eventTitle" (focus)="popupDropdownClose($event)" (keyup)="eventTitleKeyUp()"
              formControlName="eventTitle" matInput autocomplete="off" maxlength="100">
            <mat-error *ngIf="editEventForm.controls.eventTitle.errors?.required">
              {{this.appMessagesService.customRequiredMessages("event title")}}</mat-error>
            <mat-error *ngIf="editEventForm.controls.eventTitle.errors?.maxlength">
              {{this.appMessagesService.appStaticMessages.PayManager.EnterCalendarMaxLengthError}}</mat-error>
            <mat-error *ngIf="editEventForm.controls.eventTitle.errors?.minlength">
              {{this.appMessagesService.appStaticMessages.PayManager.EnterCalendarMinLengthError}}</mat-error>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
</div>

  <div *ngIf="currentRoleId!=5 && currentRolePermissions.canEdit && ((this.securityService.securityObject.currentRoleId === this.dialogData.data.userRoleId) || (this.securityService.securityObject.currentRoleId === 1))" class="addEventFooter">
    <i (click)="delete()" class="ml5 material-icons trash cursor-pointer">
      delete_outline
    </i>
    <ng-container>
    <div class="editclosewrap">
      <div class="col-md-12">
        <div class="td-actions text-right actionButtons" style="height:34px;">
          <app-actions-component [enableReset]="isDirty" [enableSave]="isFormValid" (editClickEvent)="edit()"
            [resetWithoutChange]="isFormReset" (saveClickEvent)="save()" (resetClickEvent)="reset()"  *ngIf="(this.currentRolePermissions.roleAlias === 'App Manager' || this.currentRolePermissions.canEdit === true) && !this.currentPanelClass.includes('ViewEvCom') && !this.currentPanelClass.includes('EditEvComView')">
          </app-actions-component>
        </div>
      </div>
    </div>
  </ng-container>
  </div>
