import { ITicketAttachmentsData } from './ITicketAttachmentsData';

export class ITicketData {
  id: number;
  staffNumber: string;
  subject: string;
  description: string;
  ticketTypeId: number;
  ticketStatusId: number;
  assignedBy: string;
  assignedTo: string;
  ticketType: string;
  createdDate: Date;
  modifiedDate: Date;
  ticketStatus: string;
  rating: any;
  unreadMessages: number;
  attachmentsList: ITicketAttachmentsData[];
}
