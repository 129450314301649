import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';

import { Observable } from '../../../node_modules/rxjs/Observable';
import { IValidatedUserPreference, IValidatedUserPreferenceV2 } from '../Interfaces/identitymanagement/IValidatedUserPreference';
import { IQuestion } from '../Interfaces/user/IQuestions';
import { BaseService } from './baseService.service';
import { RequestType } from './requestType';

@Injectable({
  providedIn: 'root',
})
export class RoleActivationService {

  tenant: string = window.sessionStorage.getItem('tenant');
  relativeURL = `IdentityManagement/`;
  relativeURLV2 = `V2/IdentityManagement/`;

  constructor(private baseService: BaseService) { }

  GetSecurityQuestions(roleId: number, staffNumber: string): Observable<IQuestion[]> {
    return this.baseService.Execute<IQuestion[]>(RequestType.GET,
      this.relativeURL + `getsecurityquestions/${this.tenant}/${staffNumber}/${roleId}`, 'baseIdentityManagementEndpoint', null);
  }

  GetSecurityQuestionsV2(roleId: number, staffNumber: string, flow: number): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET,
      this.relativeURLV2 + `getsecurityquestions/${this.tenant}/${flow}/${roleId}?staffNumber=${encodeURIComponent(staffNumber)}`,
      'baseIdentityManagementEndpoint', null);
  }

  // updated as part of security fixes
  ValidateAndSendOTP(roleId: number, data: IQuestion[]): Observable<IValidatedUserPreference> {
    return this.baseService.Execute<IValidatedUserPreference>(RequestType.POST,
      this.relativeURL + `validateandsendotp/${this.tenant}/${roleId}`, 'baseIdentityManagementEndpoint', data);
  }

  ValidateAndSendOTPV2(roleId: number, staffNumber: string, data: IQuestion[], flow: number): Observable<IValidatedUserPreference> {
    return this.baseService.Execute<IValidatedUserPreference>(RequestType.POST,
      this.relativeURLV2 + `validateandsendotp/${this.tenant}/${flow}/${roleId}?staffNumber=${encodeURIComponent(staffNumber)}`,
      'baseIdentityManagementEndpoint', data);
  }

  UpdateRoleAsActivated(staffNumber: string, data: number): Observable<boolean> {
    return this.baseService.Execute<boolean>(RequestType.POST,
      this.relativeURL + `updateroleasactive/${this.tenant}/${staffNumber}`, 'baseIdentityManagementEndpoint', data);
  }

  // removed as part of security fixes
  // ValidateSecurityAnswers(roleId: number, staffNumber: string, data: IQuestion[]): Observable<IValidatedUserPreferenceV2> {
  //   return this.baseService.Execute<IValidatedUserPreferenceV2>(RequestType.POST,
  //     this.relativeURL + `validatesecurityanswers/${this.tenant}/${staffNumber}/${roleId}`, 'baseIdentityManagementEndpoint', data);
  // }

  ValidateSecurityAnswersV2(roleId: number, staffNumber: string, data: IQuestion[]): Observable<IValidatedUserPreferenceV2> {
    return this.baseService.Execute<IValidatedUserPreferenceV2>(RequestType.POST,
      this.relativeURLV2 + `validatesecurityanswers/${this.tenant}/${roleId}?staffNumber=${encodeURIComponent(staffNumber)}`,
      'baseIdentityManagementEndpoint', data);
  }

  ValidateHintAndSendOTP(roleId: number, staffNumber: string, data: IQuestion[],
                         hintEnable: boolean): Observable<IValidatedUserPreference> {
    return this.baseService.Execute<IValidatedUserPreference>(RequestType.POST,
      this.relativeURLV2 + `validatehintandsendotp/${this.tenant}/${roleId}/${hintEnable}?staffNumber=${encodeURIComponent(staffNumber)}`,
      'baseIdentityManagementEndpoint', data);
    }
}
