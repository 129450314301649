import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IUserDeviceSettings } from '../Interfaces/identitymanagement/IUserDeviceSettings';
import { IValidatedUserPreference } from '../Interfaces/identitymanagement/IValidatedUserPreference';
import { ITenantConfigurationSettings } from '../Interfaces/tenantconfiguration/ITenantConfigurationSettings';
import { IQuestion } from '../Interfaces/user/IQuestions';
import { IValidatedUserDetails } from '../Interfaces/user/IValidatedUserDetails';
import { BaseService } from './baseService.service';
import { RequestType } from './requestType';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root',
})
export class IdentityManagerService {

  relativeURL = 'IdentityManagement/';
  relativeURLV2 = `V2/IdentityManagement/`;

  tenant: string = window.sessionStorage.getItem('tenant');

  constructor(private baseService: BaseService, private securityService: SecurityService) {
  }

  ValidateEmployeeV2(data: any): Observable<IValidatedUserDetails> {
    return this.baseService.Execute<IValidatedUserDetails>(RequestType.POST,
      this.relativeURLV2 + 'validate/' + this.tenant, 'baseIdentityManagementEndpoint', data);
  }

  RegisterEmployeeV2(data: any): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURLV2 + 'create/' + this.tenant, 'baseIdentityManagementEndpoint', data);
  }

  UploadPicture(data: any, staffNumber: string = null): Observable<any> {
    staffNumber = staffNumber ? staffNumber : this.securityService.securityObject.staffNumber;
    return this.baseService.Execute<File>(RequestType.POST,
      this.relativeURL + `upload/${this.tenant}/${staffNumber}`, 'baseIdentityManagementEndpoint', data);
  }

  ValidateAuthenticationCode(data: any): Observable<boolean> {
    return this.baseService.Execute<boolean>(RequestType.POST,
      this.relativeURL + 'validateotp/' + this.tenant, 'baseIdentityManagementEndpoint', data);
  }

  ValidateAuthenticationCodeV2(data: any, flow: number, step: number, hintEnable: boolean): Observable<boolean> {
    return this.baseService.Execute<boolean>(RequestType.POST,
      this.relativeURLV2 + 'validateotp/' + this.tenant + `/${flow}/${step}/${hintEnable}`,
      'baseIdentityManagementEndpoint', data);
  }

  SendAuthenticationCode(data: any): Observable<boolean> {
    return this.baseService.Execute<boolean>(RequestType.POST,
      this.relativeURL + 'sendotp/' + this.tenant, 'baseIdentityManagementEndpoint', data);
  }

  GetTenantConfigurationSettings(): Observable<ITenantConfigurationSettings> {
    return this.baseService.Execute<ITenantConfigurationSettings>(RequestType.GET,
      this.relativeURL + `tenantconfigsettings/${this.tenant}`, 'baseIdentityManagementEndpoint', null);
  }

  // removed as part of security fixes
  // ValidateEmployeeForForgotUserName(data: any): Observable<any> {
  //   return this.baseService.Execute<any>(RequestType.POST,
  //     this.relativeURL + `validateforgotusername/${this.tenant}`, 'baseIdentityManagementEndpoint', data);
  // }

  ValidateEmployeeForForgotUserNameV2(data: any): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURLV2 + `validateforgotusername/${this.tenant}`, 'baseIdentityManagementEndpoint', data);
  }

  ValidateAutheticationCodeForRoleActivation(data: any, staffNumber: string, roleId: number): Observable<boolean> {
    return this.baseService.Execute<boolean>(RequestType.POST,
      this.relativeURL + `validateotp/roleactivation/${this.tenant}/${staffNumber}/${roleId}`,
      'baseIdentityManagementEndpoint', data);
  }

  // removed as part of security fixes
  // SendUsername(data: any, username: string): Observable<any> {
  //   return this.baseService.Execute<any>(RequestType.POST,
  //     this.relativeURL + `sendusername/${this.tenant}/${username}`, 'baseIdentityManagementEndpoint', data);
  // }

  SendUsernameV2(data: any): Observable<IValidatedUserPreference> {
    return this.baseService.Execute<IValidatedUserPreference>(RequestType.POST,
      this.relativeURLV2 + `sendusername/${this.tenant}`, 'baseIdentityManagementEndpoint', data);
  }

  ValidateUsernameV2(username: string): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURLV2 + `validateusername/${this.tenant}/${encodeURIComponent(username)}`, 'baseIdentityManagementEndpoint', null);
  }

  ValidateUsername(username: string): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `validateusername/${this.tenant}/${encodeURIComponent(username)}`, 'baseIdentityManagementEndpoint', null);
  }

  ResetEmployeePassword(data: any, ticketId?: number): Observable<any> {
    const roleId = (this.securityService.securityObject.currentRoleId) ? this.securityService.securityObject.currentRoleId : 5;
    if (ticketId) {
      return this.baseService.Execute<any>(RequestType.POST,
        this.relativeURL + `resetemployeepassword/${this.tenant}/${roleId}/${ticketId}`, 'baseIdentityManagementEndpoint', data);
    }
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `resetemployeepassword/${this.tenant}/${roleId}`, 'baseIdentityManagementEndpoint', data);
  }

  ResetPasswordV2(data: any, hintEnable: boolean, ticketId?: number): Observable<any> {
    const roleId = (this.securityService.securityObject.currentRoleId) ? this.securityService.securityObject.currentRoleId : 5;
    if (ticketId) {
      return this.baseService.Execute<any>(RequestType.POST,
        this.relativeURLV2 + `resetpassword/${this.tenant}/${roleId}/${hintEnable}/${ticketId}`, 'baseIdentityManagementEndpoint', data);
    }
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURLV2 + `resetpassword/${this.tenant}/${roleId}/${hintEnable}`, 'baseIdentityManagementEndpoint', data);
  }

  // removed as part of security fixes
  // ResetPassword(data: any, ticketId?: number): Observable<any> {
  //   const roleId = (this.securityService.securityObject.currentRoleId) ? this.securityService.securityObject.currentRoleId : 5;
  //   if (ticketId) {
  //     return this.baseService.Execute<any>(RequestType.POST,
  //       this.relativeURL + `resetpassword/${this.tenant}/${roleId}/${ticketId}`, 'baseIdentityManagementEndpoint', data);
  //   }
  //   return this.baseService.Execute<any>(RequestType.POST,
  //     this.relativeURL + `resetpassword/${this.tenant}/${roleId}`, 'baseIdentityManagementEndpoint', data);

  // }

  CheckDuplicateEmail(emailAddress: string): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `checkduplicateemail/${this.tenant}/${emailAddress}`, 'baseIdentityManagementEndpoint', null);
  }

  UnlockUserAccount(status: boolean, staffNumber: string): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST, this.relativeURL
      + `useraccount/lock/status/${this.tenant}/${this.securityService.securityObject.currentRoleId}/${staffNumber}/${status}`,
      'baseIdentityManagementEndpoint', null);
  }

  LogoutActivityLog(auditLogObj: any): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `activitylog/${this.tenant}`, 'baseIdentityManagementEndpoint', auditLogObj);
  }

  ReactivateEmployeeAccount(staffNumber: string): Observable<any> {
    const req = {
      staffNumber,
      currentStaffNumber: this.securityService.LoginUser,
      currenntUserRoleId: this.securityService.securityObject.currentRoleId
    };
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURL + `reactivate/employee/account/${this.tenant}`, 'baseIdentityManagementEndpoint', req);
  }

  // removed as part of security fixes
  // IsPasswordValid(staffNumber: any, data: any): Observable<any> {
  //   return this.baseService.Execute<any>(RequestType.POST,
  //     this.relativeURL + `validatePassword/${this.tenant}/${staffNumber}`, 'baseIdentityManagementEndpoint', data);
  // }

  IsPasswordValidV2(data: any): Observable<any> {
    return this.baseService.Execute<any>(RequestType.POST,
      this.relativeURLV2 + `validatePassword/${this.tenant}`, 'baseIdentityManagementEndpoint', data);
  }

  GetSecurityQuestions(roleId: number, staffNumber: string): Observable<IQuestion[]> {
    return this.baseService.Execute<IQuestion[]>(RequestType.GET,
      this.relativeURL + `getsecurityquestions/${this.tenant}/${staffNumber}/${roleId}`, 'baseIdentityManagementEndpoint', null);
  }

  ValidateSecurityQuestions(staffNumber: string, answerObject: any): Observable<IValidatedUserPreference> {
    return this.baseService.Execute<IValidatedUserPreference>(RequestType.POST,
      this.relativeURL + `validateuserbysecurityquestions/${this.tenant}/${staffNumber}/`,
      'baseIdentityManagementEndpoint', answerObject);
  }

  GetDeviceSettingsV2(staffNumber: string, flow: number): Observable<IUserDeviceSettings> {
    return this.baseService.Execute<IUserDeviceSettings>(RequestType.GET,
      this.relativeURLV2 + `getdevicesettings/${this.tenant}/${flow}?staffNumber=${encodeURIComponent(staffNumber)}`,
      'baseIdentityManagementEndpoint', null);
  }

  GetDeviceSettings(staffNumber: string): Observable<IUserDeviceSettings> {
    return this.baseService.Execute<IUserDeviceSettings>(RequestType.GET,
      this.relativeURL + `getdevicesettings/${this.tenant}/${staffNumber}`, 'baseIdentityManagementEndpoint', null);
  }

  SendAuthenticationCodeToEmployeeV2(data: any, staffNumber: string, flow: number, hintEnable: boolean): Observable<any> {
    return this.baseService.Execute<boolean>(RequestType.POST,
      this.relativeURLV2 + 'sendotp/' + this.tenant + `/${flow}/${hintEnable}?staffNumber=` + `${encodeURIComponent(staffNumber)}`,
      'baseIdentityManagementEndpoint', data);
  }

  SendAuthenticationCodeToEmployee(data: any, staffNumber: string): Observable<any> {
    return this.baseService.Execute<boolean>(RequestType.POST,
      this.relativeURL + 'sendotp/' + this.tenant + '/' + staffNumber, 'baseIdentityManagementEndpoint', data);
  }

  SendLockNotification(data: any): Observable<any> {
    return this.baseService.Execute<boolean>(RequestType.POST,
      this.relativeURLV2 + `sendlocknotification/${this.tenant}`,
      'baseIdentityManagementEndpoint', data);
  }
  sendLockMail(staffNumber: string): Observable<any> {
    return this.baseService.Execute<boolean>(RequestType.POST,
      this.relativeURLV2 + `sendlockmail/${this.tenant}?staffNumber=` + `${encodeURIComponent(staffNumber)}`,
      'baseIdentityManagementEndpoint', staffNumber);
  }
}
