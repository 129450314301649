import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-display-message-dialog',
  templateUrl: './display-message-dialog.component.html',
  styleUrls: ['./display-message-dialog.component.scss']

})
export class DisplayMessageialogComponent implements OnInit {

  messageBody: any;
  constructor(
    public dialogRef: MatDialogRef<DisplayMessageialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) {
    this.messageBody = dialogData.BodyText;
  }

  ngOnInit() {

  }

  close() {
    this.dialogRef.close();
  }
}
