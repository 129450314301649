import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ILanguage } from '../Interfaces/ILanguageModel';
import { ITemplateType } from '../Interfaces/ITemplateType';
import { IPayroll } from '../Interfaces/paymanager/IPayroll';
import { IUserRoles } from '../Interfaces/paymanager/IUserRoles';
import { IDocumentTypes } from './../Interfaces/IDocumentTypes';
import { BaseService } from './baseService.service';
import { RequestType } from './requestType';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  tenant: string = window.sessionStorage.getItem('tenant');
  relativeURL = 'master/';
  constructor(private baseService: BaseService) { }

  getRoles(): Observable<IUserRoles[]> {
    return this.baseService.Execute<IUserRoles[]>(RequestType.GET, this.relativeURL + `user/roles/${this.tenant}`,
      'baseSharedEndpoint', null);
  }
  GetSystemMessagesData(): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET, this.relativeURL + `messaging/systemmessages/${this.tenant}`,
     'baseSharedEndpoint', null);
  }
  GetPayrollMetadata(): Observable<IPayroll[]> {
    return this.baseService.Execute<IPayroll[]>(RequestType.GET, this.relativeURL + `getpayrollmetadata/${this.tenant}`,
     'baseSharedEndpoint', null);
  }

  getLanguages(): Observable<ILanguage[]> {
    return this.baseService.Execute<ILanguage[]>(RequestType.GET, this.relativeURL + `Languages/` + this.tenant,
    'baseSharedEndpoint', null);
  }

  getTemplateTypes(): Observable<ITemplateType[]> {
    return this.baseService.Execute<ITemplateType[]>(RequestType.GET, this.relativeURL + `TemplateTypes/` + this.tenant,
    'baseSharedEndpoint', null);
  }

  getDocumentTypes(): Observable<IDocumentTypes[]> {
    return this.baseService.Execute<IDocumentTypes[]>(RequestType.GET, this.relativeURL + `document/types/` + this.tenant,
    'baseSharedEndpoint', null);
  }

  getPensionschemes(): Observable<any[]> {
    return this.baseService.Execute<any[]>(RequestType.GET, this.relativeURL + `PensionSchemes/` + this.tenant,
    'baseSharedEndpoint', null);
  }
}
