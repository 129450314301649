import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { DialogEnum } from '../DialogEnum';
import { IDialogModel } from '../IDialogModel';

@Component({
  selector: 'app-cancel-dialog',
  templateUrl: './cancel-dialog.component.html'
})
export class CancelDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IDialogModel) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  onCloseConfirm() {
    this.dialogRef.close(DialogEnum.Confirm);
  }
  onCloseCancel() {
    this.dialogRef.close(DialogEnum.Cancel);
  }

}
