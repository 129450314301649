import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CreateTicketDialogComponent } from 'src/app/employee/dialogs/create-ticket-dialog/create-ticket-dialog.component';
import { SecurityService } from 'src/app/services/security.service';
import { SidebarRoutingService } from 'src/app/sidebar/sidebar-routing.service';

import { AppMaterialModule } from '../../app-material.module';
import { UpdatesDialogComponent } from '../components/updates-dialog/updates-dialog.component';
import { UtilityService } from '../services/utility.service';
import { NavbarComponent } from './navbar.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false
};

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    AppMaterialModule,
    PerfectScrollbarModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    SidebarRoutingService,
    UtilityService,
    SecurityService
  ],
  entryComponents: [
    CreateTicketDialogComponent,
    UpdatesDialogComponent
  ],
  declarations: [
    NavbarComponent,
    CreateTicketDialogComponent,
    UpdatesDialogComponent
  ],
  exports: [ NavbarComponent]
})

export class NavbarModule {
  isDisabled: boolean;
}
