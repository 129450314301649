<div class="position-relative">
  <div class="PopCloseIcon" (click)="close()"><i class="material-icons">close</i></div>
</div>
<div>
  <div mat-dialog-content class="noMaxHeight">
    <div class="container">
      <form class="form" [formGroup]="addTicketFormGroup">
        <div class="row">
          <div class="col-md-12">
            <p class="nopadding mb15 text-uppercase text-grey font500 pt15">RAISE TICKET</p>
          </div>
          <div class="col-md-4 selectFld">
            <mat-form-field>
              <mat-placeholder>Ticket Type </mat-placeholder>
              <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
              <mat-select #ticketTypes formControlName="ticketType" panelClass="CustomMatSelectDropDown"
                (openedChange)="openedChange($event)">
                <perfect-scrollbar style="max-width: 100%;" class="CustomMatSelectDropDown_Scroll perfectScroll_custom">
                  <mat-option *ngFor="let ticket of ticketType" [value]="ticket.id">
                    {{ ticket.type }}
                  </mat-option>
                </perfect-scrollbar>
              </mat-select>
              <mat-error *ngIf="addTicketFormGroup.controls['ticketType'].errors">
                {{this.appMessagesService.customSelector("ticket type")}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row fileUploadContainer">
          <div class="col-md-12">
            <p class="nopadding nomargin text-grey font12">
              <span class="cursor-pointer" (click)="fileChoose.click()">
                <i class="fa fa-paperclip"></i> Upload Documents
              </span>
              <input #fileChoose type="file" formControlName="file" class="fileUploadStyle" multiple
                (change)="onSelectFile($event)" />
            </p>
          </div>
          <div class="UploadMainPanel">
            <perfect-scrollbar class="maxwidth100">
              <div *ngFor="let file of uploadedFiles; let i=index" class="FileDiv">
                <p class="nopadding nomargin text-grey font12">{{file.name}}
                  <mat-icon class="svgclass popupAttachCloseIcons" svgIcon="icon_close" (click)="removeFile(i)">
                  </mat-icon>
                </p>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt20">
            <mat-form-field class="example-full-width">
              <mat-placeholder>Subject </mat-placeholder>
              <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
              <input matInput formControlName="subject" />
              <mat-error *ngIf="addTicketFormGroup.controls['subject'].errors">
                {{this.appMessagesService.appStaticMessages.Employee.NewTicketSubjectValidation}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12 mt20">
            <mat-form-field class="example-full-width">
              <mat-placeholder>Description </mat-placeholder>
              <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
              <textarea matInput formControlName="description" rows="3" class="TextAreaCusScroll"></textarea>
              <mat-error *ngIf="addTicketFormGroup.controls['description'].errors">
                {{this.appMessagesService.appStaticMessages.Helpdesk.NewTicketDescriptionValidation}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="container editclosewrap">
          <div class="col-md-12">
            <div class="td-actions text-right">
              <button (click)="reset()" type="button" rel="tooltip" class="btn btn-danger btn-round btn-fab btn-sm"
                title="" 
                [ngClass]="(!addTicketFormGroup.dirty) ? 'btn-disabled cursor-default' : ''"
                >
                <i class="material-icons">close</i>
              </button>
              <button type="button" rel="tooltip" (click)="save()"
                [ngClass]="(!addTicketFormGroup.dirty || !addTicketFormGroup.valid) ? 'btn-disabled cursor-default' : ''"
                class="btn btn-success btn-round btn-fab btn-sm" title="">
                <i class="material-icons">check</i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
