import { Injectable } from '@angular/core';

import { FormatterService } from './formatter.service';

@Injectable()
export class DocumentDataParsingService {

  constructor(private formatterService: FormatterService) {
  }

  convertStringToTable(tableString: string, currency: string): any {
    const jsondata: any = [];
    const datarow: string[][] = [];
    const itemArray: string[] = tableString.split(';');
    itemArray.forEach(item => {
      datarow.push(item.split('-'));
    });
    const obj: any = {};
    for (let i = 0; i < datarow.length; i++) {
      for (let j = 0; j < datarow[i].length; j++) {
        if (datarow[i][j] && !isNaN(Number(datarow[i][j]))) {
          obj[(i + '' + j)] = this.formatterService.getFormattedValue(currency, Number(datarow[i][j]), 2, false);
        } else if (datarow[i][j]) {
          obj[(i + '' + j)] = datarow[i][j];
        }
      }
    }
    jsondata.push(JSON.stringify(obj));
    return jsondata;
  }

  convertDateToJsonObj(datevalue: Date, feildName: string): any {
    const jsonObj: any = {};
    if (datevalue.toString().includes('/')) {
      const dateArray: string[] = datevalue.toString().split('/');
      for (let i = 0; i < dateArray.length; i++) {
        jsonObj[feildName + '' + i] = dateArray[i].toString();
      }
    } else if (datevalue.toString().includes('-')) {
      const dateArray: string[] = datevalue.toString().split('-');
      for (let i = 0; i < dateArray.length; i++) {
        jsonObj[feildName + '' + i] = dateArray[i].toString();
      }
    }
    return jsonObj;
  }

  convertDateToDigitsJsonObj(dateValue: Date, feildName: string): any {
    const jsonObj: any = {};
    if (dateValue.toString().includes('/')) {
      const dateArray: string[] = dateValue.toString().split('/');
      let dateDigits = '';
      dateArray.forEach(str => {
        dateDigits += str;
      });
      const dateDigitsArray = dateDigits.split('');
      for (let i = 0; i < dateDigitsArray.length; i++) {
        jsonObj[feildName + '' + i] = dateDigitsArray[i].toString();
      }
    } else if (dateValue.toString().includes('-')) {
      const dateArray: string[] = dateValue.toString().split('-');
      dateArray[2] = dateArray[2].substr(2, 2);
      let dateDigits = '';
      dateArray.forEach(str => {
        dateDigits += str;
      });
      const dateDigitsArray = dateDigits.split('');
      for (let i = 0; i < dateDigitsArray.length; i++) {
        jsonObj[feildName + '' + i] = dateDigitsArray[i].toString();
      }
    }
    return jsonObj;
  }

  convertNinToJsonObj(nin: string, feildName: string): any {
    const jsonObj: any = {};
    let ninArray: string[] = [];
    nin = nin.replace(/ /g, '');
    if (nin.trim().includes(' ')) {
      ninArray = nin.split(' ');
    } else if (!nin.trim().includes(' ')) {
      for (let i = 0, charsLength = nin.length; i < charsLength; i += 2) {
        ninArray.push(nin.substring(i, i + 2));
      }
    }
    for (let i = 0; i < ninArray.length; i++) {
      jsonObj[feildName + '' + i] = ninArray[i].toString();
    }
    return jsonObj;
  }

  convertDateToJsonObjP11(dateValue: Date, feildName: string): any {
    const jsonObj: any = {};
    if (dateValue.toString().includes('/')) {
      const dateArray = dateValue.toString().split('/');
      dateArray[2] = dateArray[2].substr(2, 2);
      for (let i = 0; i < dateArray.length; i++) {
        jsonObj[feildName + '' + i] = dateArray[i].toString();
      }
    } else if (dateValue.toString().includes('-')) {
      const dateArray = dateValue.toString().split('-');
      dateArray[2] = dateArray[2].substr(2, 2);
      for (let i = 0; i < dateArray.length; i++) {
        jsonObj[feildName + '' + i] = dateArray[i].toString();
      }
    }
    return jsonObj;
  }

  convertGenderToSingleChar(genderValue: string, feildName: string): any {
    const genJsonObj = {};
    const genderArray = genderValue.split('');
    genJsonObj[feildName] = genderArray[0];
    return genJsonObj;
  }

  convertBoolValuetoCheckMark(value: string, feildName: string): any {
    const boolJsonObj = {};
    if (value !== 'False') {
      boolJsonObj[feildName] = 'checked';
    } else {
      boolJsonObj[feildName] = '';
    }
    return boolJsonObj;
  }

  bindValuestoHtml(data: any, documentType = '', currency: string, formatter: FormatterService): any {
    let datesJsonObj: any;
    let tableData: any;
    Object.keys(data.documentData).forEach(element => {
      if (data.documentData[element] != null) {
        if (element.includes('customizeastable')) {
          tableData = JSON.parse(this.convertStringToTable(data.documentData[element], currency));
          if (tableData) {
            Object.keys(tableData).forEach(item => {
              data.template =
                data.template
                  .replace(new RegExp(`##${item}##`, 'gi'),
                  // tslint:disable-next-line:no-construct
                  !isNaN(tableData[item]) ? new Number(tableData[item]).toFixed(2) : tableData[item]);
                });
          }
        } else if (element.includes('customizableasstringarray')) {
          const feildString: string = element.toString().split('-')[0];
          datesJsonObj = this.convertNinToJsonObj(data.documentData[element], feildString);
          if (datesJsonObj) {
            Object.keys(datesJsonObj).forEach(item => {
              data.template = data.template.replace(new RegExp(`##${item}##`, 'gi'), datesJsonObj[item]);
            });
          }
        } else if (element.includes('customizegenderassinglechar')) {
          const feildString: string = element.toString().split('-')[0];
          datesJsonObj = this.convertGenderToSingleChar(data.documentData[element], feildString);
          if (datesJsonObj) {
            Object.keys(datesJsonObj).forEach(item => {
              data.template = data.template.replace(new RegExp(`##${item}##`, 'gi'), datesJsonObj[item]);
            });
          }
        } else if (element.includes('converttocheckmark')) {
          const feildString: string = element.toString().split('-')[0];
          datesJsonObj = this.convertBoolValuetoCheckMark(data.documentData[element], feildString);
          if (datesJsonObj) {
            Object.keys(datesJsonObj).forEach(item => {
              data.template = data.template.replace(new RegExp(`##${item}##`, 'gi'), datesJsonObj[item]);
            });
          }
        } else if (element.includes('customizableasdatep11')) {
          const feildString: string = element.toString().split('-')[0];
          datesJsonObj = this.convertDateToJsonObjP11(data.documentData[element], feildString);
          if (datesJsonObj) {
            Object.keys(datesJsonObj).forEach(item => {
              data.template = data.template.replace(new RegExp(`##${item}##`, 'gi'), datesJsonObj[item]);
            });
          }
        } else if (element.includes('customizableasdatedigits')) {
          const feildString: string = element.toString().split('-')[0];
          datesJsonObj = this.convertDateToDigitsJsonObj(data.documentData[element], feildString);
          if (datesJsonObj) {
            Object.keys(datesJsonObj).forEach(item => {
              data.template = data.template.replace(new RegExp(`##${item}##`, 'gi'), datesJsonObj[item]);
            });
          }
        } else if (element.includes('customizableasdate')) {
          const feildString: string = element.toString().split('-')[0];
          datesJsonObj = this.convertDateToJsonObj(data.documentData[element], feildString);
          if (datesJsonObj) {
            Object.keys(datesJsonObj).forEach(item => {
              data.template =
                data.template
                  .replace(new RegExp(`##${item}##`, 'gi'), datesJsonObj[item]);
            });
          }
        } else if (documentType) {
          let value = '';
          if (data.documentData[element] != null) {
            if (!isNaN(data.documentData[element])) {
              const regex = new RegExp('[^\\.]+\\.[0-9]{1,2}$', 'gi');
              if (regex.test(data.documentData[element])) {
                value = formatter.getFormattedValue(currency, Number(data.documentData[element]), 2, false);
              } else {
                value = data.documentData[element];
              }
            } else {
              value = data.documentData[element];
            }
            data.template = data.template.replace(new RegExp(`##${element}##`, 'gi'), value);
          }
        } else {
          data.template = data.template.replace(new RegExp(`##${element}##`, 'gi'),
            data.documentData[element] == null ? '' : data.documentData[element]);
        }
      }
    }
    );
    return data;
  }

  date_sort_desc(date1, date2) {
    if (date1 > date2) {
      return -1;
    }
    if (date1 < date2) {
      return 1;
    }
    return 0;
  }
}
