<nav #navbar class="navbar navbar-expand-lg navbar-absolute pt0">
  <div class="container-fluid">
    <div class="AppLogo">
      <img src="assets/img/icon.png" alt="wealden_reganto">
    </div>
    <!-- <button mat-button class="navbar-toggler btn-no-ripple" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button> -->
    <ul class="navbar-nav left-nav">
      <li class="nav-item">
        <a class="nav-link" title="Back to Landing">
          <mat-icon class="cursor-pointer wizardsvgclass svgclass20 Icons-grey" svgIcon="home_icon"
            (click)="showLanding.emit(true)">
          </mat-icon>
        </a>
      </li>
    </ul>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <div class="nav-role-list">
        <div class="Role dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="RoledropdownMenuButton"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            (click)="this.navigationProcessed.emit();">
            {{this.securityService.navigatedRole}}
            <i class="material-icons drpIcon">expand_more</i>
          </button>
          <ul class="dropdown-menu" aria-labelledby="RoledropdownMenuButton">
            <li *ngFor="let role of roles"
              [ngClass]="this.securityService.navigatedRole === role.title? 'active' : '' "><a class="dropdown-item"
                (click)="roleChanged(role.title)">{{role.title}}</a>
            </li>
          </ul>
        </div>
      </div>
      <ul class="navbar-nav">
        <!-- <ng-container *ngIf="shownavbarmenu"> -->
        <ng-container *ngIf="shownavbarmenu">
          <li class="nav-item top_nav" *ngIf="this.securityService.securityObject.currentRoleId != null &&
        this.securityService.securityObject.currentRoleId > 0" style="--animation-order: 1;">
            <a class="nav-link" (click)="changeTheme()" title="Appearance">
              <mat-icon class="cursor-pointer wizardsvgclass svgclass20 Icons-grey" svgIcon="icon-theme">
              </mat-icon>
            </a>
          </li>
          <li class="nav-item dropdown top_nav" *ngIf="(utilityService.isEmployee || utilityService.isHelpdeskManager)"
            style="--animation-order: 2;">
            <a aria-expanded="false" aria-haspopup="true" class="nav-link" data-toggle="dropdown"
              href="#" *ngIf = "utilityService.isEmployee"
              title='Notifications' id="navbarDropdownMenuLink" (click)="showNotificationsPopOver()">
              <ng-container
                *ngIf="stepsCompleted && utilityService.releaseNotificationDetails; else emptyNotifications">
                <mat-icon class="wizardsvgclass normIcon svgclass20 Icons-grey"
                  [ngClass]="(this.utilityService.isHelpdeskManager && this.utilityService.userNotifications.remindersCount == 0)? 'cursor-default':'cursor-pointer'"
                  *ngIf="utilityService.releaseNotificationDetails?.severity === 'High'" svgIcon="announcement_red">
                </mat-icon>
                <mat-icon class="wizardsvgclass normIcon svgclass20 Icons-grey"
                  [ngClass]="(this.utilityService.isHelpdeskManager && this.utilityService.userNotifications.remindersCount == 0)? 'cursor-default':'cursor-pointer'"
                  *ngIf="utilityService.releaseNotificationDetails?.severity === 'Medium'"
                  svgIcon="announcement_orange">
                </mat-icon>
                <mat-icon class="wizardsvgclass normIcon svgclass20 Icons-grey"
                  [ngClass]="(this.utilityService.isHelpdeskManager && this.utilityService.userNotifications.remindersCount == 0)? 'cursor-default':'cursor-pointer'"
                  *ngIf="utilityService.releaseNotificationDetails?.severity === 'Low'" svgIcon="announcement_blue">
                </mat-icon>
                <mat-icon class="wizardsvgclass normIcon svgclass20 Icons-grey"
                  [ngClass]="(this.utilityService.isHelpdeskManager && this.utilityService.userNotifications.remindersCount == 0)? 'cursor-default':'cursor-pointer'"
                  *ngIf="utilityService.releaseNotificationDetails?.status === 'Success'" svgIcon="announcement_green">
                </mat-icon>
                <mat-icon class="wizardsvgclass normIcon svgclass20 Icons-grey"
                  [ngClass]="(this.utilityService.isHelpdeskManager && this.utilityService.userNotifications.remindersCount == 0)? 'cursor-default':'cursor-pointer'"
                  *ngIf="utilityService.releaseNotificationDetails?.status === 'Completed'" svgIcon="announcement_line">
                </mat-icon>
              </ng-container>
              <ng-template #emptyNotifications>
                <mat-icon class="wizardsvgclass normIcon svgclass20 Icons-grey"
                  [ngClass]="(this.utilityService.isHelpdeskManager && this.utilityService.userNotifications.remindersCount == 0)? 'cursor-default':'cursor-pointer'"
                  svgIcon="announcement_line">
                </mat-icon>
              </ng-template>
              <span class="notification" *ngIf="utilityService.totalNotifications > 0">{{utilityService.totalNotifications}}</span>
            </a>
            <a aria-expanded="false" aria-haspopup="true" class="nav-link" data-toggle="dropdown"
              href="#" *ngIf = "utilityService.isHelpdeskManager"
              title='Reminders' id="navbarDropdownMenuLink" (click)="showNotificationsPopOver()">
              <ng-container
                *ngIf="this.utilityService.userNotifications.remindersCount > 0; else emptyNotifications">
                <mat-icon class="wizardsvgclass normIcon svgclass20 Icons-grey"
                  [ngClass]="(this.utilityService.isHelpdeskManager && this.utilityService.userNotifications.remindersCount == 0)? 'cursor-default':'cursor-pointer'"
                  svgIcon="reminder_icon">
                </mat-icon>
              </ng-container>
              <ng-template #emptyNotifications>
                <mat-icon class="wizardsvgclass normIcon svgclass20 Icons-grey"
                  [ngClass]="(this.utilityService.isHelpdeskManager && this.utilityService.userNotifications.remindersCount == 0)? 'cursor-default':'cursor-pointer'"
                  svgIcon="reminder_icon">
                </mat-icon>
              </ng-template>
              <span class="notification" *ngIf="utilityService.totalNotifications > 0">{{utilityService.totalNotifications}}</span>
            </a>
            <div aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu dropdown-menu-right menu_feature"
              [ngClass]="(this.utilityService.isHelpdeskManager && utilityService.userNotifications.remindersCount == 0)? 'visibiltyClass': ''"
              *ngIf="(utilityService.userNotifications) && (utilityService.isEmployee || utilityService.isHelpdeskManager)">
              <ul class="sub_feature">
                <li class="sub_nav-item orangeClr"
                  *ngIf="utilityService.isEmployee && utilityService.hasAccessToCalendar && utilityService.userNotifications.calendarEventCount>0">
                  <a class="sub_nav-link cursor-pointer" [routerLink]="['/calendar']"
                    routerLinkActive="router-link-active" (click)=showLanding.emit(false)>
                    <span class="sidebar-normal">Calendar</span>
                    <span class="notification-sub">{{utilityService.userNotifications.calendarEventCount}}</span>
                  </a>
                </li>
                <li class="sub_nav-item orangeClr"
                  *ngIf="utilityService.isEmployee && utilityService.hasAccessToMessages && utilityService.userNotifications.messagesCount>0">
                  <a class="sub_nav-link cursor-pointer" [routerLink]="['/message-board']"
                    (click)=showLanding.emit(false)>
                    <span class="sidebar-normal">Messages</span>
                    <span class="notification-sub">{{utilityService.userNotifications.messagesCount}}</span>
                  </a>
                </li>
                <li class="sub_nav-item orangeClr"
                  *ngIf="utilityService.isEmployee && utilityService.hasAccessToDouments && utilityService.userNotifications.documentsCount>0">
                  <a class="sub_nav-link cursor-pointer" [routerLink]="['/document-library']"
                    (click)=showLanding.emit(false)>
                    <span class="sidebar-normal">Documents</span>
                    <span class="notification-sub">{{utilityService.userNotifications.documentsCount}}</span>
                  </a>
                </li>
                <li class="sub_nav-item orangeClr"
                  *ngIf="utilityService.isEmployee && utilityService.hasAccessToVideos && utilityService.userNotifications.videosCount>0">
                  <a class="sub_nav-link cursor-pointer" [routerLink]="['/videolibrary']"
                    (click)=showLanding.emit(false)>
                    <span class="sidebar-normal">Videos</span>
                    <span class="notification-sub">{{utilityService.userNotifications.videosCount}}</span>
                  </a>
                </li>
                <li class="sub_nav-item orangeClr"
                  *ngIf="utilityService.isHelpdeskManager && utilityService.hasAccessToCalendar && (utilityService.userNotifications && utilityService.userNotifications.remindersCount>0)">
                  <a class="sub_nav-link cursor-pointer" [routerLink]="['/helpDeskManager/calendar']"
                    (click)=showLanding.emit(false)>
                    <span class="sidebar-normal">Reminders</span>
                    <span class="notification-sub">{{utilityService.userNotifications.remindersCount}}</span>
                  </a>
                </li>
                <li class="sub_nav-item orangeClr"
                  *ngIf="utilityService.isEmployee && utilityService.releaseNotificationDetails && disableNewFeatures === false">
                  <a class="sub_nav-link cursor-pointer" (click)="openUpdatesDialog()" title="Latest Updates">
                    <span class="sidebar-normal"> New Features</span>
                  </a>
                </li>
                <li class="sub_nav-item orangeClr"
                  *ngIf="utilityService.totalNotifications <= 0 && disableNewFeatures == true">
                  <a class="sub_nav-link cursor-pointer">
                    <span class="sidebar-normal">No New Notifications</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item top_nav" id="filterControl" *ngIf="utilityService.isFilterOn"
            style="--animation-order: 3;">
            <a id="filterControlRef" class="nav-link filter"
              title="{{utilityService.title?utilityService.title:'Filter'}}"
              (click)="utilityService.showFilterData=!utilityService.showFilterData;this.navigationProcessed.emit();">
              <mat-icon class="cursor-pointer wizardsvgclass svgclass20 Icons-grey" svgIcon="cr_filter_icon"></mat-icon>
            </a>
          </li>
          <li class="nav-item top_nav" *ngIf="utilityService.isEmployee" (clickOutside)="LatestPayslipSettingClose()"
            style="--animation-order: 4;">
            <a class="nav-link" title="Latest Payslip Setting" id="navbarDropdownToggleLink"
              (click)="LatestPayslipSettingOpen()">
              <mat-icon class="cursor-pointer wizardsvgclass svgclass20 Icons-grey" svgIcon="payslip_new">
              </mat-icon>
            </a>
            <div aria-labelledby="navbarDropdownToggleLink" Style="top:131px;"
              class="dropdown-menu dropdown-menu-right PaySlipSetting menu_payslip"
              [ngClass]="showPaySettings ? 'show':''">
              <ul class="sub_payslip">
                <li class="sub_nav-item orangeClr">
                  <a class="sub_nav-link cursor-pointer">
                    <div style="width: 100%;">
                      <div class="sub-title"
                        [ngClass]="this.utilityService.rectentPayslipStatus===1?'text-orange':'text-grey'">View
                        Payslip on Login </div>
                    </div>
                    <span class="mat-toggle text-right">
                      <mat-slide-toggle class="custom-mat-toggle" (change)="toggleViewPayslip()"
                        [checked]="this.utilityService.rectentPayslipStatus===1?true:false"></mat-slide-toggle>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <!-- <li class="nav-item top_nav curPayslipNav" *ngIf="utilityService.isEmployee" style="--animation-order: 5;">
            <a class="nav-link" [ngClass]="isPayslipOn?'PSlipOpend':''" (click)="OpenCurrentPayslipDialog()"
              title="Latest Payslip">
              <mat-icon class="cursor-pointer wizardsvgclass normIcon  svgclass20 Icons-grey" svgIcon="current_payslip">
              </mat-icon>
              <mat-icon class="cursor-pointer wizardsvgclass hoverIcon svgclass20 Icons-grey"
                svgIcon="current_payslip_hover"></mat-icon>
            </a>
          </li>
          <li class="nav-item top_nav" *ngIf="this.isTicketModuleAvailable && utilityService.isEmployee"
            style="--animation-order: 6;">
            <a class="nav-link" (click)="OpenNewTicketDialog()" title="Raise Ticket">
              <mat-icon class="cursor-pointer wizardsvgclass svgclass20 Icons-grey" svgIcon="raise_ticket"></mat-icon>
            </a>
          </li> -->
          <li class="nav-item top_nav" style="--animation-order: 6;" *ngIf="utilityService.isEmployee">
            <a class="nav-link accoLink photoLnk" *ngIf="utilityService.isEmployee" routerLink="/myprofile"
              title="My Account" (click)=showLanding.emit(false)>
              <!-- <mat-icon class="cursor-pointer wizardsvgclass svgclass20 Icons-grey" svgIcon="myaccount"></mat-icon> -->
                <img src="{{imageUrl}}" alt="{{name}}" class="myProfilePhoto" />
            </a>
          </li>
          <li class="nav-item top_nav" style="--animation-order: 7;">
            <a title="Logout" class="nav-link logout cursor-pointer" (click)="logOut()"
              style="position: relative;left:2px">
              <mat-icon class="cursor-pointer wizardsvgclass svgclass20 Icons-grey" svgIcon="logout_icon"></mat-icon>
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</nav>

<div
  *ngIf="utilityService.releaseNotificationDetails && showNotificationBox && utilityService.releaseNotificationDetails.status != 'Completed'">
  <div *ngIf="utilityService.releaseNotificationDetails.notificationEnabled" data-notify="container"
    class="alert custom alert-with-icon animated fadeInDown" role="alert"
    [ngClass]="utilityService.releaseNotificationDetails.severity === 'High' ? 'alert-danger' : utilityService.releaseNotificationDetails.severity === 'Medium' ? 'alert-orange':utilityService.releaseNotificationDetails.severity === 'Low' || (utilityService.releaseNotificationDetails.severity != null)? 'alert-blue':utilityService.releaseNotificationDetails.severity == null ?'green':'green'"
    data-notify-position="top-right"
    style="display: inline-block; margin: 0px auto; position: fixed; transition: all 0.5s ease-in-out 0s; z-index: 1031; top: 25px; right: 54px; width:auto;">
    <i class="material-icons notifications"
      [ngClass]="utilityService.releaseNotificationDetails.severity === 'High'? 'alert-danger' : utilityService.releaseNotificationDetails.severity === 'Medium' ? 'alert-orange':(utilityService.releaseNotificationDetails.severity === 'Low' || ( utilityService.releaseNotificationDetails.severity != null))? 'alert-blue':utilityService.releaseNotificationDetails.severity == null ?'alert-green':'alert-green'"
      data-notify="icon">notifications</i>
    <button type="button" data-dismiss="alert" aria-label="Close" class="close mat-button mat-button-base"
      (click)="closeNotifications()" style="position: absolute; right: 5px; top: 2px; font-size: 16px;">
      <span class="mat-button-wrapper">
        <i class="material-icons" style="font-size: 16px;">close</i>
      </span>
    </button>
    <div class="text-center pr7" style="display: inline-flex;align-items: center;">
      <ng-container *ngIf="utilityService.releaseNotificationDetails.status === 'Success'">
        <span class="message" style="width: 200px;">Update successfully applied.</span>
      </ng-container>
      <ng-container *ngIf="utilityService.releaseNotificationDetails.status !== 'Success'">
        <span
          *ngIf="utilityService.releaseNotificationDetails.status === 'Failed' && utilityService.releaseNotificationDetails.severity !='High'"
          class="message" style="width: 200px;">You have open actions remaining to complete your upgrade.
          <span *ngIf="(utilityService.releaseNotificationDetails.noOfdays >= 0)">You
            have {{utilityService.releaseNotificationDetails.noOfdays}} days remaining to complete this before you are
            locked out.</span>
        </span>
        <span
          *ngIf="utilityService.releaseNotificationDetails.status === 'InProgress' && utilityService.releaseNotificationDetails.severity !='High'"
          class="message" style="width: 200px;">The updates still need to be completed.
          <span *ngIf="(utilityService.releaseNotificationDetails.noOfdays >= 0)">You
            have {{utilityService.releaseNotificationDetails.noOfdays}} days remaining to complete this before you are
            locked out.</span>
        </span>
        <span
          *ngIf="(utilityService.releaseNotificationDetails.status === 'InProgress' || utilityService.releaseNotificationDetails.status === 'Failed') && utilityService.releaseNotificationDetails.severity ==='High'"
          class="message" style="width: 200px;">Please complete the action to continue using the application.
          <span *ngIf="(utilityService.releaseNotificationDetails.noOfdays >= 0)">You
            have
            {{utilityService.releaseNotificationDetails.noOfdays > 0 ?
            utilityService.releaseNotificationDetails.noOfdays : 0}}
            days remaining to complete this before you are locked out.</span>
        </span>
        <button type="button" class="btn-subscribe" (click)="openUpdatesDialog()">{{(utilityService.releaseNotificationDetails.status === 'InProgress') ?
          'Continue' :'Get Details'}}</button>
      </ng-container>
    </div>
  </div>
</div>
