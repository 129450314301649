import { Injectable, Injector } from '@angular/core';
import { PreloadingStrategy, Route, Router } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { SecurityService } from 'src/app/services/security.service';

@Injectable({
  providedIn: 'root'
})
export class CustomPreloadingStrategyService implements PreloadingStrategy {
  isHelpDeskFeatureAvailable = false;
  constructor(private injector: Injector, public securityService: SecurityService) {
  }
  preload(route: Route, loadMe: () => Observable<any>): Observable<any> {
    const router = this.injector.get(Router);
    if (route.data && route.data['preload'] && route.path === '') {
      const delay: number = route.data['delay'];
      return timer(delay).pipe(
        flatMap(_ => {
          return loadMe();
        }));
    }
    if (router.url === '/dashboard' &&
      ((this.securityService.securityObject.claims.superAdministrator !== null && route.path === 'administrator')
        || (this.securityService.securityObject.claims.payManager !== null && route.path === 'paymanager')
        || (this.IsHelpDeskAvailable() && (route.path === 'helpDeskManager' || route.path === 'helpDeskAdmin')))) {
      const delay: number = route.data['delay'];
      return timer(delay).pipe(
        flatMap(_ => {
          return loadMe();
        }));
    }
    return of(null);
  }
  IsHelpDeskAvailable() {
    if ((this.securityService.securityObject.claims.superAdministrator &&
      this.securityService.securityObject.claims.superAdministrator.roleMenu.childMenus.filter(x => x.title === 'Helpdesk').length > 0) ||
      (this.securityService.securityObject.claims.payManager &&
        this.securityService.securityObject.claims.payManager.roleMenu.childMenus.filter(x => x.title === 'Helpdesk').length > 0) ||
      (this.securityService.securityObject.claims.helpDeskManager || this.securityService.securityObject.claims.helpDeskAdministrator)) {
      return true;
    }
    return false;
  }
}
