import { AbstractControl, ValidatorFn } from '@angular/forms';

export class ValidationHelper {

  public static numeric(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value !== undefined && (isNaN(c.value)) && c.value !== null && (c.value.trim().length === 0)) {
        return { numeric: true };
      }
      return null;
    };
  }

  public static ratingRange(min: number, max: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value !== undefined && (isNaN(c.value) || c.value < min || c.value > max)) {
        return { range: true };
      }
      return null;
    };
  }

  public static hasWhiteSpaces(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value !== undefined && c.value !== null && (c.value.trim().length === 0)) {
        return { whitespace: true };
      }
      return null;
    };
  }

  public static maxLimit(limit: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value !== undefined && c.value !== null && (c.value.length > limit)) {
        return { maxLimit: true };
      }
      return null;
    };
  }

  public static isEmpty(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const pattern = /\S+/;
      if (c.value !== undefined && c.value !== null && !(pattern.test(c.value))) {
        return { isEmpty: true };
      }
      return null;
    };
  }
}
