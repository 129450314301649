import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IActivationConfiguration } from '../Interfaces/IActivationConfiguration';
import { BaseService } from './baseService.service';
import { RequestType } from './requestType';

@Injectable({
  providedIn: 'root',
})
export class ClientAdminActivationService {

  tenant = window.sessionStorage.getItem('tenant');
  relativeURL = 'ClientAdminActivation/';

  constructor(private baseService: BaseService) { }

  GetClientActivationConfiguration(): Observable<IActivationConfiguration> {
    return this.baseService.Execute<IActivationConfiguration>(RequestType.GET,
      this.relativeURL + this.tenant, 'baseAdminEndpoint', null);
  }

  AddOrUpdateClientActivationConfiguration(data: IActivationConfiguration): Observable<IActivationConfiguration> {
    return this.baseService.Execute<IActivationConfiguration>(RequestType.POST,
      this.relativeURL + this.tenant, 'baseAdminEndpoint', data);
  }

}
