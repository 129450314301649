<!-- LOGIN START HERE -->
<div class="wrapper wrapper-full-page">
  <div class="page-header login-page header-filter vh-100" filter-color="black">
    <div class="container pt0 d-flex">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ml-auto mr-auto loginOutPanel">
        <div class="LoginContainer d-flex">
          <div class="LoginLeft">
            <div class="DivFlex">
              <div class="Lftblock">
                <div class="text-center font15 mb20 font400 skip-link">Welcome to</div>
                <div class="weladenLogo"><img src="assets/img/wealden_reganto.svg" alt="wealden_reganto">
                </div>
                <ngx-slick-carousel class="carousel mt50" #slickModal="slick-carousel" [config]="slideConfig">
                  <div ngxSlickItem class="slide" *ngFor="let slide of slides">
                    <div>
                      <mat-icon svgIcon="{{slide.Icon}}"></mat-icon>
                      <div class="pl20 data">
                        <div class="colorpink slideTitle">{{slide.Header}}</div>
                        <p class="content">{{slide.Content}}</p>
                      </div>
                    </div>
                  </div>
                </ngx-slick-carousel>
              </div>
            </div>
          </div>
          <div class="card card-login">
            <!-- <div class="Logo text-uppercase mt20 mb20 text-center">
              <img src="assets/img/RegantoLogo.png" alt="ap-govt-logo" class="width160 d-inline-block align-middle">
            </div> -->
            <div class="DivFlex">
              <div class="Lpanel" *ngIf="!passwordReset">
                <form class="form">
                  <div class="card-body mt0">
                    <div class="loginH">
                      <div class="Ttext">Sign In</div>
                      <p>Get access to payslips and other documents</p>
                    </div>
                    <div class="login_form">
                      <!-- <span class="bmd-form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="material-icons">face</i>
                            </span>
                          </div>
                          <input type="text" class="form-control" name="userName" placeholder="Username"
                            [(ngModel)]="user.userName" (input)="clearVerifiedCheck();">
                        </div>
                      </span> -->
                      <div>
                        <mat-form-field class="example-full-width">
                          <mat-placeholder>Username</mat-placeholder>
                          <input matInput type="text" name="userName"
                            [(ngModel)]="user.userName" (input)="clearVerifiedCheck();">
                        </mat-form-field>
                      </div>
                      <div (capsLock)="capsOn=$event">
                        <mat-form-field class="example-full-width">
                          <mat-placeholder>Password</mat-placeholder>
                          <input matInput type="{{loginPasswordType}}" class="" name="password"
                            [(ngModel)]="user.password" (input)="clearVerifiedCheck();"
                            (keypress)="$event.keyCode == 13 ? validateEmployeeCredentials() : null">
                          <a (click)="toggleLoginPasswordShow()"
                            class="material-icons pwd_toggle_icon cursor-pointer viewIcon">{{_loginPwdText}}</a>
                          <mat-icon matSuffix *ngIf="user.userName && user.password && !verifiedUserCredentials && isTwoFactorEnabled">
                            <mat-icon class="wizardsvgclass svgclass22 btn-back hw20 " svgIcon="icon_next"
                              (click)="validateEmployeeCredentials()">
                            </mat-icon>
                          </mat-icon>
                          <mat-icon class="btn-back svgclass20 wizardsvggreenclass hw20" matSuffix
                            *ngIf="verifiedAuthenticationCode && isTwoFactorEnabled" svgIcon="icon_right"></mat-icon>
                        </mat-form-field>
                      </div>
                      <div class="row footer_links">
                        <div class="col-md-6">
                          <a [routerLink]="['/pages/username/user-reset']" class=""><span class="">Forgot Username
                            </span></a>
                        </div>
                        <div class="col-md-6 text-right">
                          <a (click)="validateAndRedirect()" class="cursor-pointer"><span class="">Forgot
                              Password
                            </span></a>
                        </div>
                      </div>
                      <mat-hint class="capsLockTxt" *ngIf="capsOn">Caps Lock is on</mat-hint>
                      <div
                        [ngClass]="tenantConfigSettings != null && tenantConfigSettings.isMobileAppConfigured == true? 'authenticationcode_palceholder_smsenabled' : 'authenticationcode_palceholder'">
                        <div *ngIf="showAuthenticationBox" class="text-center">
                          <div class="input-group">
                            <div>
                              <input type="text" #otp1 class="login authenticationcode" maxlength="1" size="1"
                                (keyup)="onKeyup($event,otp2,'', validateUserPreference.communicationPreference, 'login', 'send', validateUserPreference.emailAddress, validateUserPreference.mobileNumber)"
                                (keydown)="onKeydown($event)">
                              <input type="text" #otp2 class="login authenticationcode" maxlength="1" size="1"
                                (keyup)="onKeyup($event,otp3,otp1, validateUserPreference.communicationPreference, 'login', 'send', validateUserPreference.emailAddress, validateUserPreference.mobileNumber)"
                                (keydown)="onKeydown($event)">
                              <input type="text" #otp3 class="login authenticationcode" maxlength="1" size="1"
                                (keyup)="onKeyup($event,otp4,otp2, validateUserPreference.communicationPreference, 'login', 'send', validateUserPreference.emailAddress, validateUserPreference.mobileNumber)"
                                (keydown)="onKeydown($event)">
                              <input type="text" #otp4 class="login authenticationcode" maxlength="1" size="1"
                                (keyup)="onKeyup($event,otp5,otp3, validateUserPreference.communicationPreference, 'login', 'send', validateUserPreference.emailAddress, validateUserPreference.mobileNumber)"
                                (keydown)="onKeydown($event)">
                              <input type="text" #otp5 class="login authenticationcode" maxlength="1" size="1"
                                (keyup)="onKeyup($event,otp6,otp4, validateUserPreference.communicationPreference, 'login', 'send', validateUserPreference.emailAddress, validateUserPreference.mobileNumber)"
                                (keydown)="onKeydown($event)">
                              <input type="text" #otp6 class="login authenticationcode" maxlength="1" size="1"
                                (keyup)="onKeyup($event,'',otp5, validateUserPreference.communicationPreference, 'login', 'send', validateUserPreference.emailAddress, validateUserPreference.mobileNumber)"
                                (keydown)="onKeydown($event)">
                              <mat-icon class="svgclass25 text-danger cross-icon" svgIcon="icon_close"
                                (click)="clear('login')">
                              </mat-icon>
                              <mat-icon class="svgclass25 text-grey ml_inside" svgIcon="icon_refresh"
                                (click)="resend(validateUserPreference.communicationPreference, 'login', 'send', validateUserPreference.emailAddress, validateUserPreference.mobileNumber)">
                              </mat-icon>
                              <mat-icon class="sendSMS"
                                *ngIf="tenantConfigSettings != null && tenantConfigSettings.isMobileAppConfigured && validateUserPreference.communicationPreference !='Email'"
                                (click)="sendAuthenticationCode('Mobile', 'login', 'send',validateUserPreference.emailAddress, validateUserPreference.mobileNumber, true);"
                                title="Send through SMS" svgIcon="icon_comment"></mat-icon>
                              <p class="nomargin"
                                [ngClass]="tenantConfigSettings != null && tenantConfigSettings.isMobileAppConfigured == true? 'loginAlertTxt_SmsEnabled' : 'loginAlertTxt'">
                                <span class="asteriskDisplay">*</span>
                                {{ this.appMessagesService.personalRequiredMessages("authorisation code") }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="showSpamMsg && !(emailTimer > 0 || mobileTimer > 0)" style="line-height: 14px;"
                          class="loginAlertTxt_SmsEnabled">
                          {{this.appMessagesService.appStaticMessages.AppLevel.EmailSpamMessage}}</div>
                        <div *ngIf="emailTimer > 0 || mobileTimer > 0" style="line-height: 14px;"
                          class="loginAlertTxt_SmsEnabled">
                          <span class="asteriskDisplay">*</span>
                          {{
                          this.sharedService.formatString(this.appMessagesService.appStaticMessages.AppLevel.ResendCodeWaitError,
                          (this.emailTimer > 0 ? this.emailTimer : this.mobileTimer)) }}
                        </div>
                      </div>
                      <div class="row btns_row">
                        <div class="col-12 col-md-12">
                          <div class="LoginBtn full_width">
                            <!-- <a mat-raised-button [routerLink]="['/activation']" mat-raised-button class="btn btn-rose">Activate</a> -->
                            <a mat-raised-button class="btn btn-rose" (click)="validateEmployeeCredentials()">SIGN
                              IN</a>
                          </div>
                        </div>
                      </div>
                      <div class="orSec">
                        <div class="line">or</div>
                        <a mat-raised-button class="btn btn-default" [routerLink]="['/activation']">Activate your Account</a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div *ngIf="passwordReset" class="Lpanel">
                <form [formGroup]="passwordForm">
                  <div class="loginH mb25">
                    <div class="Ttext text-center">Update Password</div>
                  </div>
                  <div formGroupName="passwordGroup">
                      <div class="form-group pos_relative">
                        <mat-form-field class="example-full-width">
                          <mat-placeholder>Current Password </mat-placeholder>
                          <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                          <input [(ngModel)]="currentPassword" type="{{currentPasswordType}}" name="currentPassword"
                            matInput id="currentPassword" formControlName="currentPassword" (input)="clearError()"
                            (keyup)="currentPasswordCheck()" maxlength="26">
                            <a (click)="toggleCurrentPasswordShow()"
                          class="material-icons pwd_toggle_icon">{{_curPwdText}}</a>
                          <mat-error *ngIf="passwordForm.get('passwordGroup').get('currentPassword').errors?.required">
                            {{ this.appMessagesService.customRequiredMessages("current password") }}</mat-error>
                          <span class="mat-error customErr"
                            *ngIf="currentPasswordCheckError && !passwordForm.get('passwordGroup').get('currentPassword').errors?.required">
                            {{this.appMessagesService.appStaticMessages.AppLevel.CurrentPasswordCheck}}
                          </span>
                        </mat-form-field>

                      </div>
                      <div class="form-group pos_relative">
                        <mat-form-field class="example-full-width">
                          <mat-placeholder>New Password </mat-placeholder>
                          <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                          <input [(ngModel)]="password" type="{{passwordType}}" name="password" matInput id="password"
                            formControlName="password" (input)="clearError()" maxlength="26">
                            <a (click)="togglePasswordShow()" class="material-icons pwd_toggle_icon">{{_pwdText}}</a>
                          <mat-error *ngIf="passwordForm.get('passwordGroup').get('password').errors?.required">
                            {{ this.appMessagesService.personalRequiredMessages("new password") }}</mat-error>
                          <mat-error
                            *ngIf="!passwordForm.get('passwordGroup').get('password').errors?.required && (currentPassword==password)">
                            {{passwordSameError}}</mat-error>
                          <mat-error
                            *ngIf="(currentPassword!=password) &&!passwordForm.get('passwordGroup').get('password').errors?.required && passwordForm.get('passwordGroup').get('password').errors?.pattern">
                            {{passwordErrorMsgPattern}}</mat-error>
                        </mat-form-field>

                      </div>
                      <div class="form-group position-relative">
                        <mat-form-field class="example-full-width">
                          <mat-placeholder>Confirm Password </mat-placeholder>
                          <mat-placeholder class="asteriskDisplay">*</mat-placeholder>
                          <input type="{{confirmPasswordType}}" matInput id="confirmPassword" name="confirmPassword"
                            formControlName="confirmPassword" (input)="clearError()" maxlength="26">
                            <a (click)="toggleConfirmPasswordShow()"
                          class="material-icons pwd_toggle_icon">{{_cnfrmPwdText}}</a>
                          <mat-error *ngIf="passwordForm.get('passwordGroup').get('confirmPassword').errors?.required">
                            {{ this.appMessagesService.appStaticMessages.AppLevel.ReEnterPassword }}</mat-error>
                        </mat-form-field>
                        <div class="mat-error cus_hint cust_error" *ngIf="!passwordForm.get('passwordGroup').get('password').errors?.required
                                                              && !passwordForm.get('passwordGroup').get('confirmPassword').errors?.required
                                                              && passwordForm.get('passwordGroup').errors?.invalid">
                          {{ this.appMessagesService.appStaticMessages.AppLevel.PasswordsDoesNotMatch }}
                        </div>
                      </div>
                  </div>
                  <div class="td-actions text-right userSecAct ml15 mr0 mb15">
                    <button (click)="redirectToLogin()" type="button" rel="tooltip"
                      class="btn btn-danger btn-round btn-fab btn-sm mr4" title="">
                      <i class="material-icons">close</i>
                    </button>
                    <button
                      [ngClass]="(!isChangedPasswordValid || currentPasswordCheckError) ? 'btn-disabled cursor-default' : '' "
                      (click)="resetPassword()" type="button" rel="tooltip"
                      class="btn btn-success btn-round btn-fab btn-sm mr0" title="">
                      <i class="material-icons">check</i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <!-- footer starts here -->
    <div class="fixed-bottom font12">
      <div class="row" style="align-items: baseline;">
        <div class="col-lg-6 col-sm-12  footerBox1 text-left">
          <span>© 2023 Reganto Ltd. All Rights Reserved.</span>
        </div>
        <div class="col-lg-6 col-sm-12 footerBox2 text-right">
          <ul class="linkhoverd">
            <li><a href="https://www.reganto.com/policies/copyrights.html" target="_blank" rel="noreferrer">
                © Copyright
              </a></li>
            <li>|</li>
            <li><a href="https://www.reganto.com/policies/privacy_policy.html" target="_blank" rel="noreferrer">
                Privacy Policy
              </a></li>
            <li>|</li>
            <li><a href="https://www.reganto.com/policies/cookies.html" target="_blank" rel="noreferrer">
                Cookies Preferences
              </a></li>
            <li>|</li>
            <li class="pl12 PoweredBy">
              <span>PoweredBy</span>
              <img src="assets/img/RegantoLogo_footer.svg" class="height30 pb4 align-bottom" alt="RegantoLogo">
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- footer ends here -->
  </div>
</div>
<!-- LOGIN END HERE -->
