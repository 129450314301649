export class DocumentModel {
  id: number;
  docName: string;
  payrollId: string;
  payrollName: string;
  groupId: number[];
  startDate: Date;
  endDate: Date;
  docFiles: any[];
  comments: string;
}
