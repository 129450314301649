<div class="pop_medium pos_r" style="height: auto;">
  <div class="position-relative">
    <div class="PopCloseIcon" (click)="close()"><i class="material-icons">close</i></div>
  </div>
  <div class="container PL_reset">
    <form>
      <div class="text-center">
        <div class="card-login">
          <h4 class="text-center text-default text-uppercase pt15 pb20 font400 font16 text-grey">Role Activation</h4>
          <div class="alert alert-danger cus_alert_pos" *ngIf="isError">
            <div class="container-fluid pos_r">
              <div class="alert-icon">
                <i class="material-icons">error_outline</i>
              </div>
              <span>{{errorMessage}}</span>
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true"><i class="material-icons">clear</i></span>
              </button>
            </div>
          </div>
          <div class="input-group mt15">
            <div class="loginall">
              <div class="otp_panes">
                <input type="text" #otp1 class="roleAssignment loginpassword2" maxlength="1" size="1"
                  (keyup)="onKeyup($event,otp2,'', validateUserPreference.communicationPreference, 'roleAssignment', 'send', validateUserPreference.emailAddress, validateUserPreference.mobileNumber)"
                  (keydown)="onKeydown($event)">
                <input type="text" #otp2 class="roleAssignment loginpassword2" maxlength="1" size="1"
                  (keyup)="onKeyup($event,otp3,otp1, validateUserPreference.communicationPreference, 'roleAssignment', 'send', validateUserPreference.emailAddress, validateUserPreference.mobileNumber)"
                  (keydown)="onKeydown($event)">
                <input type="text" #otp3 class="roleAssignment loginpassword2" maxlength="1" size="1"
                  (keyup)="onKeyup($event,otp4,otp2, validateUserPreference.communicationPreference, 'roleAssignment', 'send', validateUserPreference.emailAddress, validateUserPreference.mobileNumber)"
                  (keydown)="onKeydown($event)">
                <input type="text" #otp4 class="roleAssignment loginpassword2" maxlength="1" size="1"
                  (keyup)="onKeyup($event,otp5,otp3, validateUserPreference.communicationPreference, 'roleAssignment', 'send', validateUserPreference.emailAddress, validateUserPreference.mobileNumber)"
                  (keydown)="onKeydown($event)">
                <input type="text" #otp5 class="roleAssignment loginpassword2" maxlength="1" size="1"
                  (keyup)="onKeyup($event,otp6,otp4, validateUserPreference.communicationPreference, 'roleAssignment', 'send', validateUserPreference.emailAddress, validateUserPreference.mobileNumber)"
                  (keydown)="onKeydown($event)">
                <input type="text" #otp6 class="roleAssignment loginpassword2" maxlength="1" size="1"
                  (keyup)="onKeyup($event,'',otp5, validateUserPreference.communicationPreference, 'roleAssignment', 'send', validateUserPreference.emailAddress, validateUserPreference.mobileNumber)"
                  (keydown)="onKeydown($event)">
                <mat-icon class="svgclass30 mat_icons text-danger cross-icon cursor-pointer" svgIcon="icon_close"
                  (click)="clear('roleAssignment')">
                </mat-icon>
                <mat-icon class="svgclass30 mat_icons text-grey ml_inside cursor-pointer" svgIcon="icon_refresh"
                  (click)="resend(validateUserPreference.communicationPreference, 'roleAssignment', 'send', validateUserPreference.emailAddress, validateUserPreference.mobileNumber)">
                </mat-icon>
                <mat-icon class="svgclass30 mat_icons text-grey ml_inside cursor-pointer"
                  *ngIf="validateUserPreference.communicationPreference != 'Email' &&  tenantConfigSettings != null && tenantConfigSettings.isMobileAppConfigured"
                  (click)="sendAuthenticationCode('Mobile', 'login', 'send', validateUserPreference.emailAddress, validateUserPreference.mobileNumber, true);"
                  title="Send through SMS" svgIcon="icon_comment"></mat-icon>
              </div>
              <div style="padding-left: 5px;">
                <p align="left" class="font10 nomargin loginAlertTxt"><span class="asteriskDisplay">*</span>
                  {{ this.appMessagesService.personalRequiredMessages("activation code") }}</p>
                <p align="left" class="font10 nomargin loginAlertTxt"
                  *ngIf="showSpamMsg && !(emailTimer > 0 || mobileTimer > 0)" style="    width: 240px;
                  line-height: normal;">
                  {{this.appMessagesService.appStaticMessages.AppLevel.EmailSpamMessage}}</p>
                <p *ngIf="emailTimer > 0 || mobileTimer > 0" style="line-height: 14px;text-align: left;" align="left"
                  class="font10 nomargin loginAlertTxt"><span class="asteriskDisplay">*</span>
                  {{
                  this.sharedService.formatString(this.appMessagesService.appStaticMessages.AppLevel.ResendCodeWaitError,
                  (this.emailTimer > 0 ? this.emailTimer : this.mobileTimer)) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
