<!--date picker starts here-->
<div class="date">
    <!--result card starts here-->
    <div class="result">
      <!--date-title starts here-->
      <div class="date-title cursor-pointer" (click)="yearClicked()">
       {{getDate() | date:'yyyy'}}
      </div>
      <!--date title ends here-->
      <!--label starts here-->
      <div class="label" (click)="monthClicked()">
        {{getDate() | date:'EEE, LLL d'}}
      </div>
      <!--label ends here-->
    </div>
    <!--result card ends here-->
    <!--button selectors starts here-->
    <div class="selector">
      <!--left button starts here-->
      <button mat-icon-button class="left" (click)="previousClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <!--left button ends here-->
      <!--month and year button starts her-->
      <div class="month">
        <!--month button -->
        <button mat-icon-button class="month-label" (click)="monthClicked()"
          *ngIf="this.calendar.currentView === 'month'">
          <span class="example-header-label">{{ this.periodButtonText.substring(0,4) }}</span>
        </button>
        <!--month button ends here-->
        <!--year button-->
        <button mat-icon-button class="year-label" (click)="yearClicked()">
          <span
            class="example-header-label">{{ this.calendar.currentView === 'month' ? this.periodButtonText.substring(4) : this.periodButtonText }}</span>
        </button>
        <!--year button end here-->
      </div>
      <!--month and year button ends here-->
      <!--right button starts here-->
      <button mat-icon-button class="right" (click)="nextClicked()">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
      <!--right button ends here-->
    </div>
    <!--button selector ends here-->
  </div>
  <!--date picker ends here-->
  