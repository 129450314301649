import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IdentityManagerService } from 'src/app/services/identity-manager.service';
import { DialogService } from 'src/app/shared/dialog/dailog.service';
import { AppMessagesService } from 'src/app/shared/services/app-messages.service';

@Component({
  selector: 'app-lock-account',
  templateUrl: './lock-account.component.html',
  styleUrls: ['./lock-account.component.scss']
})
export class LockAccountComponent implements OnInit {
  staffNumber: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private identityManagerService: IdentityManagerService,
    public appMessagesService: AppMessagesService,
    private dialogService: DialogService) { }

  ngOnInit() {
    this.staffNumber = this.activatedRoute.snapshot.params.staffNumber;
    this.identityManagerService.sendLockMail(this.staffNumber).subscribe(response => {
      if (response) {
        this.dialogService.getSucessDialog('Success',
          this.appMessagesService.appStaticMessages.Employee.AccountLocked, true).afterClosed().subscribe(result => {
            if (result === 0) {
              window.close();
            }
          });
      }
    }, _error => {
      this.dialogService.getErrorDialogBoxWithMessage(_error.error.error[0].message).afterClosed().subscribe(res => {
        if (res === 0) {
          window.close();
        }
      });
    });
  }

}
