import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { SecurityService } from '../services/security.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private securityService: SecurityService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.securityService.securityObject.bearerToken;
    if (accessToken && request.responseType !== 'blob') {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    }

    return new Observable(observer => {
      next.handle(request)
        .subscribe(event => {
            if (event instanceof HttpResponse) {
              observer.next(event);
            }
          }, err => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.securityService.ResetSecurityObject();
                window.open('?returnUrl=' + this.router.url, '_self');
              } else {
                observer.error(err);
              }
            }
          },
          () => {
            observer.complete();
          });
    });
  }
}
