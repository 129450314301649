import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatDatepicker, MatDialog } from '@angular/material';
import { ErrorStateMatcher } from '@angular/material/core';
import crypto from 'crypto-js';
import {
  CustomDatePickerComponent,
} from 'src/app/shared/components/generic/custom-date-picker/custom-date-picker.component';
import { ImageCropperComponent } from 'src/app/shared/components/generic/image-cropper/image-cropper.component';
import { DateService } from 'src/app/shared/services/date.service';

import { ValidationHelper } from '../../../helpers/validation-helper';
import { IActivationConfiguration } from '../../Interfaces/IActivationConfiguration';
import { IAuthModel } from '../../Interfaces/identitymanagement/IAuthModel';
import { ITenantConfigurationSettings } from '../../Interfaces/tenantconfiguration/ITenantConfigurationSettings';
import { IEmployeePersonalInfo } from '../../Interfaces/user/IEmployeePersonalInfo';
import { ISecurityQuestionsGroup } from '../../Interfaces/user/ISecurityQuestions';
import { ClientAdminActivationService } from '../../services/clientAdmin-activation.service';
import { SharedService } from '../../services/shared.service';
import { UserProfileService } from '../../services/user-profile.service';
import { DialogService } from '../../shared/dialog/dailog.service';
import { DialogEnum } from '../../shared/dialog/DialogEnum';
import { AppMessagesService } from '../../shared/services/app-messages.service';
import { IdentityManagerService } from './../../services/identity-manager.service';
import { IconService } from './../../shared/services/svg-icon-service';

declare const $: any;
interface FileReaderEventTarget extends EventTarget {
  result: string;
}
interface FileReaderEvent extends Event {
  target: FileReaderEventTarget;
  getMessage(): string;
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-wizard-cmp',
  templateUrl: 'wizard.component.html',
  styleUrls: ['wizard.component.scss']
})
export class WizardComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() employeePersonalInfo: IEmployeePersonalInfo;
  @ViewChild('profilePicture', { static: false }) profilePicture: ElementRef;
  selectedCountry = 'assets/img/flags/GB.png';
  tenant: string = window.sessionStorage.getItem('tenant');
  staffNumber = '';
  userSelectedQuestions = [];
  countryCodes = [];
  countryUniqueIDIcon = '';
  selectedCountryId: any;
  activationConfiguration: IActivationConfiguration;
  activationFormGroup: FormGroup;
  questionGroup: FormArray;
  questionList: any[];
  securityQuesCount: number;
  personalQuesCount: number;
  isPersonalQuesChecked = false;
  isvalidFlag = true;
  selectedFile: File = null;
  showEmailAuthenticationBox = false;
  showMobileAuthenticationBox = false;
  showCommunicationPreferenceAuthenticationBox = false;
  verifiedEmailAddress = false;
  verifiedPhoneNumber = false;
  verifiedCommunicationPreference = false;
  invalidAttempts = 0;
  tenantConfigSettings: ITenantConfigurationSettings;
  usernameErrorMsgPattern = '';
  passwordErrorMsgPattern: string;
  usernameValue: any = '';
  selectedUniqueIDFormat = 'Unique Id';
  mask = '0000-000000';
  isUserValid = false;
  isLoading = false;
  count = 0;
  emailConfiguration: boolean;
  isUsernameUnique = true;
  showHdiePassword = false;
  showSpamMsg = false;
  showHideConfirmPassword = false;
  _passwordText = 'visibility_off';
  _cnfrmPasswordText = 'visibility_off';
  userPasswordType = 'password';
  userConfirmPasswordType = 'password';
  dateOfBirthModel: any = {
    maxDate: new Date(new Date().setMonth(new Date().getMonth() - 0))
  };
  imageFileExtensionList = ['JPEG', 'JPG', 'GIF', 'BMP', 'TIF', 'TIFF', 'PNG'];
  empImageUrl = '../../../assets/img/default-avatar.png';
  originalImageUrl: any;
  fileName: any;
  showRemovePicIcon = false;
  emailTimer: number = 0;
  mobileTimer: number = 0;
  showUniqueIdHint = true;
  defaultCountryUniqueIDIcon: string;
  defaultSelectedUniqueIDFormat: string;
  defaultSelectedCountryId: number = 1;
  defaultSelectedCountryCode: string = '+44';
  datepickerHeader = CustomDatePickerComponent;
  isFormValid = false;
  msgForTabValidation1 = 'About you is validated go to activation';
  msgForTabValidation2 = 'Activation is validated go to security';
  currentPage = 1;
  preferedDefaultCommunication: any;
  uniqueIdPattern: string;
  uniqueIdMaxLength = 9;

  constructor(
    private formBuilder: FormBuilder,
    public appMessagesService: AppMessagesService,
    private iconService: IconService,
    private dialogService: DialogService,
    public cdRef: ChangeDetectorRef,
    private clientAdminActivationService: ClientAdminActivationService,
    private userProfileService: UserProfileService,
    private dateService: DateService,
    private identityManagerRegistrationService: IdentityManagerService,
    public sharedService: SharedService,
    public dialog: MatDialog) {
    this.iconService.init();
    this.userSelectedQuestions = [];
  }
  sendCode(type, purposeType, option, emailAddress, phoneNumber = null, communicationPreferenceId = null) {
    this.emailConfiguration = this.tenantConfigSettings.emailingConfiguration.isEmailingOn;
    let isCommunicationPreference = false;
    if (type === 'communication') {
      if (!this.isUsernameValid() || !this.activationFormGroup.controls.uniqueId.valid || !this.isvalidFlag
        || !this.activationFormGroup.controls.passwordGroup.get('password').valid ||
        !this.activationFormGroup.controls.passwordGroup.get('confirmPassword').valid ||
        !this.activationFormGroup.controls.communicationPreferenceId.valid) {
        this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.AppLevel.CorrectFieldsOnLeft, false)
          .afterClosed();
        this.markFormGroupTouched(this.activationFormGroup);
        this.verifiedCommunicationPreference = false;
        this.showCommunicationPreferenceAuthenticationBox = false;
        return false;
      }
      isCommunicationPreference = true;
      switch (communicationPreferenceId) {
        case '1':
          type = 'mobile';
          break;
        case '2':
          type = 'email';
          break;
        case '3':
          type = 'both';
          break;
      }
      this.sendAuthenticationCode(type, purposeType, option, emailAddress, phoneNumber, isCommunicationPreference);
    } else if (type === 'email') {
      this.identityManagerRegistrationService.CheckDuplicateEmail(emailAddress).subscribe(result => {
        if (result) {
          this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.Employee.EmailAlreadyInUse, false)
            .afterClosed();
        } else {

          this.sendAuthenticationCode(type, purposeType, option, emailAddress, phoneNumber, isCommunicationPreference);
        }
      });
    } else {
      if (type === 'both' && this.emailConfiguration === false) {
        type = 'mobile';
      }

      this.sendAuthenticationCode(type, purposeType, option, emailAddress, phoneNumber, isCommunicationPreference);
    }
  }

  checkUsernameAvailability() {
    const userName = this.activationFormGroup.get('userName').value;
    const staffNumber = this.activationFormGroup.get('staffNumber').value;
    if (!this.activationFormGroup.get('userName').errors) {
      this.userProfileService.IsUsernameUnique(userName, staffNumber).subscribe(result => {
        this.isUsernameUnique = result;
      });
    }
  }

  togglePasswordShowHide() {
    this.showHdiePassword = !this.showHdiePassword;
    if (this.showHdiePassword) {
      this.userPasswordType = 'text';
      this._passwordText = 'visibility';
    } else {
      this.userPasswordType = 'password';
      this._passwordText = 'visibility_off';
    }
  }

  toggleConfirmPasswordShowHide() {
    this.showHideConfirmPassword = !this.showHideConfirmPassword;
    if (this.showHideConfirmPassword) {
      this.userConfirmPasswordType = 'text';
      this._cnfrmPasswordText = 'visibility';
    } else {
      this.userConfirmPasswordType = 'password';
      this._cnfrmPasswordText = 'visibility_off';
    }
  }

  clear(type) {
    $(`.${type}.authenticationcode`).each(function() {
      $(this).val('');
    });
  }
  resend(type, purposeType, option, emailAddress, phoneNumber = null, communicationPreferenceId = null) {
    let isCommunicationPreference = false;
    let resendStatus;
    this.clear(type);
    if (type === 'communication') {
      isCommunicationPreference = true;
      switch (communicationPreferenceId) {
        case '1':
          type = 'mobile';
          break;
        case '2':
          type = 'email';
          break;
        case '3':
          type = 'both';
          break;
      }

      if (type === 'both' && !this.emailConfiguration) {
        type = 'mobile';
      }
    }
    if (type.toLowerCase() === 'email' || type.toLowerCase() === 'both') {
      this.emailTimer = this.sharedService.checkEmailTimer();
      resendStatus = this.emailTimer === 0;
    } else if (type.toLowerCase() === 'mobile') {
      this.mobileTimer = this.sharedService.checkMobileTimer();
      resendStatus = this.mobileTimer === 0;
    }
    if (resendStatus) {
      this.showSpamMsg = (type.toLowerCase() === 'email' || type.toLowerCase() === 'both') ? true : false;
      this.sendAuthenticationCode(type, purposeType, option, emailAddress, phoneNumber, isCommunicationPreference);
    }
  }
  onKeyup(evt, next, prev, type = null, purposeType = null, option = null, emailAddress = null,
          phoneNumber = null, communicationPreferenceId = null) {
    if (this.onKeydown(evt) && next !== '' && evt.key !== 'Backspace') {
      next.focus();
    }
    if (evt.key === 'Backspace' && prev !== '') {
      prev.focus();
    }
    let otp = '';
    $(`.${type}.authenticationcode`).each(function() {
      if ($(this).val()) {
        otp = `${otp}${$(this).val()}`;
      } else { return false; }
    });
    let isCommunicationPreference = false;
    if (otp.length === 6) {
      if (type === 'communication') {
        isCommunicationPreference = true;
        switch (communicationPreferenceId) {
          case '1':
            type = 'mobile';
            break;
          case '3':
            if (this.emailConfiguration === false) {
              type = 'mobile';
            } else {
              type = 'email';
            }
            break;
          case '2':
            type = 'email';
            break;
        }
      }
      this.validateAuthenticationCode(type, purposeType, option, emailAddress, phoneNumber, otp, isCommunicationPreference);
    }
  }
  onKeydown(event) {
    const pattern = /^\d*[0-9](|.\d*[0-9])?$/;
    if (event.keyCode !== 8 && !pattern.test(event.key)) {
      return false;
    }
    return true;
  }
  openCalendar(DOBDate: MatDatepicker<Date>) {
    if (DOBDate.startAt === null && DOBDate._datepickerInput.value === null) {
      DOBDate.startAt = this.dateService.getDateBefore(0, 'years');
    }
    DOBDate.open();
  }
  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && form.get(field).touched;
  }
  validatePasswords(c: FormGroup) {
    if (c.get('password').value !== c.get('confirmPassword').value) {
      return { invalid: true };
    }
    return { invalid: false };

  }

  getUniqueIdPattern(value: string) {
    let alphaCount = 0;
    let numberCount = 0;
    let lastChar = '';
    let pattern = '';
    for (let i = 0; i < value.length; i++) {
      value[i] === 'A' ? alphaCount++ : numberCount++;
      if (lastChar !== '' && lastChar !== value[i]) {
        pattern += (lastChar === 'A' ? '[A-Za-z]{' + alphaCount : '[0-9]{' + numberCount) + '}';
        alphaCount = value[i] === 'A' ? 1 : 0;
        numberCount = value[i] === 'X' ? 1 : 0;
      }
      lastChar = value[i];
    }
    if (lastChar !== '') {
      pattern += (lastChar === 'A' ? '[A-Za-z]{' + alphaCount : '[0-9]{' + numberCount) + '}';
    }
    return '^' + pattern;
  }

  clearVerifiedEmailCheck() {
    this.verifiedEmailAddress = false;
    this.showEmailAuthenticationBox = false;
    this.showSpamMsg = false;
    this.verifiedCommunicationPreference = false;
    this.showCommunicationPreferenceAuthenticationBox = false;
  }
  clearVerifiedMobileCheck() {
    this.verifiedPhoneNumber = false;
    this.showMobileAuthenticationBox = false;
    this.verifiedCommunicationPreference = false;
    this.showCommunicationPreferenceAuthenticationBox = false;
  }
  clearVerifiedCommunicationPreferenceCheck(type: string) {
    this.verifiedCommunicationPreference = false;
    this.showCommunicationPreferenceAuthenticationBox = false;
    this.showSpamMsg = false;
    if (type === 'email') {
      this.sharedService.mobileTimeOutCounter = 0;
      this.sharedService.mobileTimeOutInterval = 0;
      this.mobileTimer = 0;
    } else if (type === 'mobile') {
      this.sharedService.emailTimeOutCounter = 0;
      this.sharedService.emailTimeOutInterval = 0;
      this.emailTimer = 0;
    } else {
      this.sharedService.mobileTimeOutCounter = 0;
      this.sharedService.mobileTimeOutInterval = 0;
      this.mobileTimer = 0;
      this.sharedService.emailTimeOutCounter = 0;
      this.sharedService.emailTimeOutInterval = 0;
      this.emailTimer = 0;
    }
  }
  checkInvalidAttemps(isSuccess: boolean = false, message: string = '') {
    if (isSuccess) {
      this.invalidAttempts = 0;
    } else if (this.invalidAttempts === 4) {
      this.invalidAttempts++;
      this.dialogService.getSucessDialog('Error',
        this.appMessagesService.appStaticMessages.AppLevel.InvalidAttemptsWillBeLocked + ', ' + message, false).afterClosed();
    } else if (this.invalidAttempts > 4) {
      this.sharedService.LockDownUserActivation(this.employeePersonalInfo.staffNumber).subscribe(response => {
        if (response) {
          this.dialogService.getSucessDialog('Error',
            this.appMessagesService.appStaticMessages.AppLevel.InvalidAttemptsLockdown, false).afterClosed();
        }
      });
      this.invalidAttempts = 0;
      $('#wizardProfile').bootstrapWizard('show', 0);
      this.verifiedEmailAddress = false;
      this.verifiedPhoneNumber = false;
      this.verifiedCommunicationPreference = false;
      this.showCommunicationPreferenceAuthenticationBox = false;
      this.showEmailAuthenticationBox = false;
      this.showSpamMsg = false;
      this.showMobileAuthenticationBox = false;
      this.selectedCountry = 'assets/img/flags/GB.png';
      this.activationFormGroup.get('dateOfBirth').enable();
      this.employeePersonalInfo = null;
      this.ngOnInit();
    } else {
      this.invalidAttempts++;
    }
  }
  usernameValidators() {
    const userNameControl = this.activationFormGroup.get('userName');
    if (this.tenantConfigSettings.activation.usernameTypeId === 1) {
      userNameControl.enable();
      let userNamePattern = '^';
      let subPattern = '';
      this.usernameErrorMsgPattern = '';
      let isRegexConfigured = false;
      if (this.tenantConfigSettings.usernameConfiguration.isAlpha) {
        userNamePattern = userNamePattern + '(?=.*?[a-zA-Z])';
        subPattern = 'a-zA-Z';
        this.usernameErrorMsgPattern = 'alphabets, ';
        isRegexConfigured = true;
      }
      if (this.tenantConfigSettings.usernameConfiguration.isNumeric) {
        userNamePattern = userNamePattern + '(?=.*?[0-9])';
        subPattern = subPattern + '0-9';
        this.usernameErrorMsgPattern = this.usernameErrorMsgPattern + 'numbers ';
        isRegexConfigured = true;
      }
      if (this.tenantConfigSettings.usernameConfiguration.isCharacters) {
        userNamePattern = userNamePattern + '(?=.*?[' +
          this.tenantConfigSettings.usernameConfiguration.allowedSpecialCharacters + '])';
        subPattern = subPattern + this.tenantConfigSettings.usernameConfiguration.allowedSpecialCharacters;
        this.usernameErrorMsgPattern = this.usernameErrorMsgPattern + 'and symbols (!, $, -, etc..)';
        isRegexConfigured = true;
      }
      this.usernameErrorMsgPattern = this.usernameErrorMsgPattern.replace(/(.+)(, )$/, '$1');
      this.usernameErrorMsgPattern = this.usernameErrorMsgPattern.replace(/^and (.+)/, '$1');
      this.usernameErrorMsgPattern = this.usernameErrorMsgPattern.replace(/^(.+)(, and )(.+)$/, '$1' + ' and ' + '$3');
      const lengthPattern = '{' + this.tenantConfigSettings.usernameConfiguration.minimumLength +
        ',' + this.tenantConfigSettings.usernameConfiguration.maximumLength + '}';
      if (isRegexConfigured === true) {
        userNamePattern = userNamePattern + '[' + subPattern + ']' + lengthPattern + '$';
      } else {
        userNamePattern = '.' + lengthPattern + '$';
      }
      this.usernameErrorMsgPattern = 'Please enter ' + this.tenantConfigSettings.usernameConfiguration.minimumLength +
        ' to ' + this.tenantConfigSettings.usernameConfiguration.maximumLength + ' characters' +
        ((this.usernameErrorMsgPattern != null && this.usernameErrorMsgPattern !== '') ?
          ' of ' + this.usernameErrorMsgPattern : '');
      userNameControl.setValidators([Validators.pattern(userNamePattern), userNameControl.validator]);
    } else {
      userNameControl.setValidators([Validators.pattern('.*'), userNameControl.validator]);
    }
  }
  passwordValidators() {
    let passwordPattern = '^';
    let subPattern = '';
    this.passwordErrorMsgPattern = '';
    let isRegexConfigured = false;
    if (this.tenantConfigSettings.passwordConfiguration.isAlpha) {
      passwordPattern = passwordPattern + '(?=.*?[a-zA-Z])';
      subPattern = 'a-zA-Z';
      this.passwordErrorMsgPattern = 'alphabets, ';
      isRegexConfigured = true;
    }
    if (this.tenantConfigSettings.passwordConfiguration.isNumeric) {
      passwordPattern = passwordPattern + '(?=.*?[0-9])';
      subPattern = subPattern + '0-9';
      this.passwordErrorMsgPattern = this.passwordErrorMsgPattern + 'numbers ';
      isRegexConfigured = true;
    }
    if (this.tenantConfigSettings.passwordConfiguration.isCharacters) {
      passwordPattern = passwordPattern + '(?=.*?[' + this.tenantConfigSettings.passwordConfiguration.allowedSpecialCharacters + '])';
      subPattern = subPattern + this.tenantConfigSettings.passwordConfiguration.allowedSpecialCharacters;
      this.passwordErrorMsgPattern = this.passwordErrorMsgPattern + 'and symbols (!, $, -, etc..)';
      isRegexConfigured = true;
    }
    this.passwordErrorMsgPattern = this.passwordErrorMsgPattern.replace(/(.+)(, )$/, '$1');
    this.passwordErrorMsgPattern = this.passwordErrorMsgPattern.replace(/^and (.+)/, '$1');
    this.passwordErrorMsgPattern = this.passwordErrorMsgPattern.replace(/^(.+)(, and )(.+)$/, '$1' + ' and ' + '$3');
    const lengthPattern = '{' + this.tenantConfigSettings.passwordConfiguration.minimumLength +
      ',' + this.tenantConfigSettings.passwordConfiguration.maximumLength + '}';
    if (isRegexConfigured === true) {
      passwordPattern = passwordPattern + '[' + subPattern + ']' + lengthPattern + '$';
    } else {
      passwordPattern = '.' + lengthPattern + '$';
    }
    this.passwordErrorMsgPattern = 'Please enter ' + this.tenantConfigSettings.passwordConfiguration.minimumLength +
      ' to ' + this.tenantConfigSettings.passwordConfiguration.maximumLength + ' characters' +
      ((this.passwordErrorMsgPattern != null) ? ' of ' + this.passwordErrorMsgPattern : '');
    this.activationFormGroup.controls.passwordGroup.get('password').setValidators([Validators.pattern(passwordPattern),
    this.activationFormGroup.controls.passwordGroup.get('password').validator]);
    this.activationFormGroup.controls.passwordGroup.get('confirmPassword').setValidators([Validators.pattern(passwordPattern),
    this.activationFormGroup.controls.passwordGroup.get('confirmPassword').validator]);
  }
  setValidatorsByTenantConfig() {
    // Load coutry flag icons and coutry codes for mobile and for Unique ID
    this.countryCodes = this.tenantConfigSettings.country.map(countryObj => {
      return {
        id: countryObj.id,
        value: countryObj.countryCode,
        flagIcon: countryObj.flagIconLocation
      };
    });
    // Assign Email validators
    if (this.tenantConfigSettings.emailingConfiguration.emailType === 2) {
      const emailPattern = '^[\\w]+([\\_\\-\\.][\\w]+)*(' + Array.prototype.map.call(this.tenantConfigSettings.corporateEmailDomains,
        item => item.domain).join('|') + ')$';
      this.activationFormGroup.get('emailAddress').setValidators([Validators.pattern(new RegExp(emailPattern, 'i')),
      this.activationFormGroup.get('emailAddress').validator]);
    }
    // Username validators
    this.usernameValidators();
    // Password validators
    this.passwordValidators();
  }
  createGroup() {
    this.activationFormGroup = this.formBuilder.group({
      // To add a validator, we must first convert the string value into an array.
      // The first item in the array is the default value if any, then the next item in the array is the validator.
      // Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      firstName: [null, [Validators.required, Validators.maxLength(70)]],
      lastName: [null, [Validators.required, Validators.maxLength(70)]],
      staffNumber: [null, Validators.required],
      dateOfBirth: [null, Validators.required],
      mobileNumber: [null, Validators.required],
      emailAddress: [null, [Validators.required,
      Validators.pattern('^[\\w]+([\\_\\-\\.][\\w]+)*@[a-zA-Z0-9]{1,21}[-]{0,1}[a-zA-Z0-9]+(\\.\\w+)*\\.[a-zA-Z]{2,6}$')]],
      userName: ['', Validators.required],
      country: [null, Validators.required],
      uniqueId: [null, Validators.required],
      passwordGroup: this.formBuilder.group({
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]]
      }, { validator: this.validatePasswords }),
      communicationPreferenceId: ['1', Validators.required],
      countryCode: ['+44', Validators.required],
      questionGroupArray: this.formBuilder.array([]),
      personalQuesText1: [null, [Validators.required, ValidationHelper.hasWhiteSpaces()]],
      personalAnsText1: [null, [Validators.required, ValidationHelper.hasWhiteSpaces()]],
      personalQuesText2: [null, [Validators.required, ValidationHelper.hasWhiteSpaces()]],
      personalAnsText2: [null, [Validators.required, ValidationHelper.hasWhiteSpaces()]],
      hintQuestion: [null, [Validators.required, ValidationHelper.hasWhiteSpaces()]],
      hintAnswer: [null, [Validators.required, ValidationHelper.hasWhiteSpaces()]],
      hint: [null, [Validators.required, ValidationHelper.hasWhiteSpaces()]]
    });
    this.activationFormGroup.controls.userName.valueChanges.subscribe(data => {
      if ((data == null || (data === '' && data.length === 0)) && this.isUsernameUnique) {
        this.isUsernameUnique = true;
      } else if (!this.isUsernameUnique) {
        this.isUsernameUnique = true;
      }
    });
  }
  ngOnInit() {
    $('#removeImageButton').hide();
    // tslint:disable-next-line: no-this-assignment
    const currentObj = this;
    this.createGroup();
    this.identityManagerRegistrationService.GetTenantConfigurationSettings().subscribe((response: ITenantConfigurationSettings) => {
      this.tenantConfigSettings = <ITenantConfigurationSettings>response;
      this.preferedDefaultCommunication = parseInt(this.tenantConfigSettings.clientGlobalSettings['PreferedDefaultCommunication'], 10);
      this.activationFormGroup.controls['communicationPreferenceId'].setValue(this.preferedDefaultCommunication.toString());
      this.sharedService.maxAttemptsForResend =
        parseInt(this.tenantConfigSettings.clientGlobalSettings['MaxAttemptsforResend'], 10);
      this.sharedService.maxSecondsToWaitBeforeResend =
        parseInt(this.tenantConfigSettings.clientGlobalSettings['MaxSecondsToWaitBeforeResend'], 10);
      this.setValidatorsByTenantConfig();
      const mobileFormat = this.tenantConfigSettings.country.
        find(x => x.flagIconLocation === this.selectedCountry).mobileFormat.match(/x/ig);
      if (mobileFormat != null && mobileFormat.length > 0) {
        this.activationFormGroup.get('mobileNumber').setValidators([Validators.pattern('[0-9]{' + mobileFormat.length + '}'),
        this.activationFormGroup.get('mobileNumber').validator]);
      }
      const mobileNumberFormatMask = this.tenantConfigSettings.country.
        find(x => x.flagIconLocation === this.selectedCountry).mobileFormat;
      if (mobileNumberFormatMask != null && mobileNumberFormatMask.length > 0) {
        const replacePattern = /\x/gi;
        this.mask = mobileNumberFormatMask.toLocaleLowerCase().replace(replacePattern, '0');
      }
      this.uniqueIdPattern = this.getUniqueIdPattern(this.tenantConfigSettings.country.find(x => x.countryCode === '+44').uniqueIdFormat);
      this.defaultCountryUniqueIDIcon = this.tenantConfigSettings.country.find(x => x.countryCode === '+44').flagIconLocation;
      this.defaultSelectedUniqueIDFormat = this.tenantConfigSettings.country.find(x => x.countryCode === '+44').uniqueIdType;
      this.defaultSelectedCountryId = this.tenantConfigSettings.country.find(x => x.countryCode === '+44').id;
      this.defaultSelectedCountryCode = '+44';
    });
    this.showEmailAuthenticationBox = false;
    this.showSpamMsg = false;
    this.showMobileAuthenticationBox = false;
    this.showCommunicationPreferenceAuthenticationBox = false;
    // Wizard Initialization
    $('.card-wizard').bootstrapWizard({
      tabClass: 'nav nav-pills',
      nextSelector: '.btn-next',
      previousSelector: '.btn-previous',
      onNext(_tab, _navigation, index) {
        if (index === 1) {
          currentObj.checkAboutYouTabValidation();
        } else if (index === 2) {
          currentObj.checkActivationTabValidation();
        }
        return false;
      },
      onInit(_tab: any, navigation: any, index: any) {
        // check number of tabs and fill the entire row
        let $total = navigation.find('li').length;
        const $wizard = navigation.closest('.card-wizard');
        // tslint:disable-next-line: variable-name
        const $_firstLi = navigation.find('li:first-child a').html();
        // tslint:disable-next-line: variable-name
        const $_movingDiv = $('<div class="moving-tab">' + $_firstLi + '</div>');
        $('.card-wizard .wizard-navigation').append($_movingDiv);
        $total = $wizard.find('.nav li').length;
        // tslint:disable-next-line: variable-name
        let $_liWidth = 100 / $total;
        const _totalSteps = $wizard.find('.nav li').length;
        let _moveDistance = $wizard.width() / _totalSteps;
        let _indexTemp = index;
        let _verticalLevel = 0;
        const _mobileDevice = $(document).width() < 600 && $total > 3;
        if (_mobileDevice) {
          _moveDistance = $wizard.width() / 2;
          _indexTemp = index % 2;
          $_liWidth = 50;
        }
        $wizard.find('.nav li').css('width', $_liWidth + '%');
        const _stepWidth = _moveDistance;
        _moveDistance = _moveDistance * _indexTemp;
        const $current = index + 1;
        if ($current === 1 || (_mobileDevice === true && (index % 2 === 0))) {
          _moveDistance -= 8;
        } else if ($current === _totalSteps || (_mobileDevice === true && (index % 2 === 1))) {
          _moveDistance += 8;
        }
        if (_mobileDevice) {
          const x: any = index / 2;
          _verticalLevel = parseInt(x, 0);
          _verticalLevel = _verticalLevel * 38;
        }
        $wizard.find('.moving-tab').css('width', _stepWidth);
        $('.moving-tab').css({
          transform: 'translate3d(' + _moveDistance + 'px, ' + _verticalLevel + 'px, 0)',
          transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'
        });
        $('.moving-tab').css('transition', 'transform 0s');
      },
      onTabClick(_tab: any, _navigation: any, index: any, clickedIndex: any) {
        if (index === 0 && clickedIndex === 1) {
          currentObj.checkAboutYouTabValidation();
        } else if (index === 0 && clickedIndex === 2) {
          $('#wizardProfile').bootstrapWizard('show', 0);
          currentObj.onWrongTab();
        } else if (index === 1 && clickedIndex === 2) {
          currentObj.checkActivationTabValidation();
        } else if (index === 1 && clickedIndex === 0) {
          $('#wizardProfile').bootstrapWizard('show', 0);
        } else if (index === 2) {
          currentObj.currentPage = 2;
          return true;
        }
        return false;
      },
      onTabShow(_tab: any, navigation: any, index: any) {
        if (index === 0) {
          currentObj.currentPage = 1;
        } else if (index === 1) {
          currentObj.currentPage = 2;
        }
        let $total = navigation.find('li').length;
        let $current = index + 1;
        const $wizard = navigation.closest('.card-wizard');
        // If it's the last tab then hide the last button and show the finish instead
        if ($current >= $total) {
          $($wizard).find('.btn-next').hide();
          $($wizard).find('.btn-finish').show();
        } else {
          $($wizard).find('.btn-next').show();
          $($wizard).find('.btn-finish').hide();
        }
        const _buttonText = navigation.find('li:nth-child(' + $current + ') a').html();
        setTimeout(() => {
          $('.moving-tab').text(_buttonText);
        }, 150);
        const checkbox = $('.footer-checkbox');
        if (index !== 0) {
          $(checkbox).css({
            opacity: '0',
            visibility: 'hidden',
            position: 'absolute'
          });
        } else {
          $(checkbox).css({
            opacity: '1',
            visibility: 'visible'
          });
        }
        $total = $wizard.find('.nav li').length;
        // tslint:disable-next-line: variable-name
        let $_liWidth = 100 / $total;
        const _totalSteps = $wizard.find('.nav li').length;
        let _moveDistance = $wizard.width() / _totalSteps;
        let _indexTemp = index;
        let _verticalLevel = 0;
        const _mobileDevice = $(document).width() < 600 && $total > 3;
        if (_mobileDevice) {
          _moveDistance = $wizard.width() / 2;
          _indexTemp = index % 2;
          $_liWidth = 50;
        }
        $wizard.find('.nav li').css('width', $_liWidth + '%');
        const _stepWidth = _moveDistance;
        _moveDistance = _moveDistance * _indexTemp;
        $current = index + 1;
        if ($current === 1 || (_mobileDevice === true && (index % 2 === 0))) {
          _moveDistance -= 8;
        } else if ($current === _totalSteps || (_mobileDevice === true && (index % 2 === 1))) {
          _moveDistance += 8;
        }
        if (_mobileDevice) {
          const x: any = index / 2;
          _verticalLevel = parseInt(x, 0);
          _verticalLevel = _verticalLevel * 38;
        }
        $wizard.find('.moving-tab').css('width', _stepWidth);
        $('.moving-tab').css({
          transform: 'translate3d(' + _moveDistance + 'px, ' + _verticalLevel + 'px, 0)',
          transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'
        });
      }
    });
    // Prepare the preview for profile picture
    $('#wizard-picture').change(function() {
      const input = $(this);
      if (input[0].files && input[0].files[0] && (((input[0].files[0].size) / 1000) / 1000) <= 2) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(input[0].files[0]);
        fileReader.onload = () => {
          $('#employeePicturePreview').attr('src', fileReader.result);
          $('#removeImageButton').show();
        };
      }
    });

    $('[data-toggle="wizard-radio"]').click(function() {
      const wizard = $(this).closest('.card-wizard');
      wizard.find('[data-toggle="wizard-radio"]').removeClass('active');
      $(this).addClass('active');
      $(wizard).find('[type="radio"]').removeAttr('checked');
      $(this).find('[type="radio"]').attr('checked', 'true');
    });
    $('[data-toggle="wizard-checkbox"]').click(function() {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        $(this).find('[type="checkbox"]').removeAttr('checked');
      } else {
        $(this).addClass('active');
        $(this).find('[type="checkbox"]').attr('checked', 'true');
      }
    });
    $('.set-full-height').css('height', 'auto');
    this.activationFormGroup.get('emailAddress').disable();
    this.activationFormGroup.get('mobileNumber').disable();
    this.isUserValid = false;
    this.activationFormGroup.get('staffNumber').reset();
  }
  ngOnChanges(_changes: SimpleChanges) {
    const input = $(this);
    if (input[0].files && input[0].files[0]) {
      const reader: any = new FileReader();
      reader.onload = (e: FileReaderEvent) => {
        $('#employeePicturePreview').attr('src', e.target.result).fadeIn('slow');
      };
      reader.readAsDataURL(input[0].files[0]);
    }
  }
  ngAfterViewInit() {
    this.cdRef.detectChanges();
    $(window).resize(() => {
      $('.card-wizard').each(function() {
        const $wizard = $(this);
        const index = $wizard.bootstrapWizard('currentIndex');
        const $total = $wizard.find('.nav li').length;
        // tslint:disable-next-line:variable-name
        let $_liWidth = 100 / $total;
        const _totalSteps = $wizard.find('.nav li').length;
        let _moveDistance = $wizard.width() / _totalSteps;
        let _indexTemp = index;
        let _verticalLevel = 0;
        const _mobileDevice = $(document).width() < 600 && $total > 3;
        if (_mobileDevice) {
          _moveDistance = $wizard.width() / 2;
          _indexTemp = index % 2;
          $_liWidth = 50;
        }
        $wizard.find('.nav li').css('width', $_liWidth + '%');
        const _stepWidth = _moveDistance;
        _moveDistance = _moveDistance * _indexTemp;
        const $current = index + 1;
        if ($current === 1 || (_mobileDevice === true && (index % 2 === 0))) {
          _moveDistance -= 8;
        } else if ($current === _totalSteps || (_mobileDevice === true && (index % 2 === 1))) {
          _moveDistance += 8;
        }
        if (_mobileDevice) {
          const x: any = index / 2;
          _verticalLevel = parseInt(x, 0);
          _verticalLevel = _verticalLevel * 38;
        }
        $wizard.find('.moving-tab').css('width', _stepWidth);
        $('.moving-tab').css({
          transform: 'translate3d(' + _moveDistance + 'px, ' + _verticalLevel + 'px, 0)',
          transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'
        });
        $('.moving-tab').css({
          transition: 'transform 0s'
        });
      });
    });
  }

  InsertIntoAuditLog(status: string, activity: string, message: string, type: string) {
    const auditLogObj = {
      logType: 'Activation',
      staffNumber: this.employeePersonalInfo.staffNumber,
      userRoleId: 5,
      status,
      activity,
      message,
      type
    };
    this.sharedService.InsertAuditLog(auditLogObj).subscribe(response => {
      if (response) {
      }
    });
  }

  validateUniqueID(uniqueId: any) {
    uniqueId = crypto.SHA256(uniqueId).toString(crypto.enc.Base64);
    if (uniqueId && (uniqueId.toLowerCase() !== this.employeePersonalInfo.uniqueId.toLowerCase())) {
      this.isvalidFlag = false;
      this.checkInvalidAttemps(false, 'you have entered Invalid Unique Id');
      this.InsertIntoAuditLog('Failed', 'Unique Id Validation', 'Invalid Unique Id entered', 'GET');
    } else {
      this.isvalidFlag = true;
      this.showUniqueIdHint = false;
      if (uniqueId.toLowerCase() !== '') {
        this.checkInvalidAttemps(true);
      }
    }
  }
  onCountryDDLChange(event: any) {
    if (event.value != null) {
      this.selectedCountryId = event.id;
    }
    this.countryUniqueIDIcon = event.flagIcon;
    this.selectedUniqueIDFormat = this.tenantConfigSettings.country.find(x => x.id === event.id).uniqueIdType;
    this.uniqueIdPattern = this.getUniqueIdPattern(this.tenantConfigSettings.country.find(x => x.id === event.id).uniqueIdFormat);
    this.uniqueIdMaxLength = this.tenantConfigSettings.country.find(x => x.id === event.id).uniqueIdFormat.length;

    this.defaultCountryUniqueIDIcon = event.flagIcon;
    this.defaultSelectedUniqueIDFormat = this.tenantConfigSettings.country.find(x => x.id === event.id).uniqueIdType;
    this.defaultSelectedCountryId = event.id;
    this.defaultSelectedCountryCode = this.tenantConfigSettings.country.find(x => x.id === event.id).countryCode;
  }
  onMobileCountryDDLChange(event: any) {
    const value = this.tenantConfigSettings.country.find(x => x.flagIconLocation === event.value).countryCode;
    if (value != null) {
      this.verifiedPhoneNumber = false;
      this.showMobileAuthenticationBox = false;
      this.activationFormGroup.patchValue({
        countryCode: value
      });
      const mobileFormat = this.tenantConfigSettings.country.find(x => x.countryCode === value).mobileFormat.match(/x/ig);
      if (mobileFormat != null && mobileFormat.length > 0) {
        this.activationFormGroup.get('mobileNumber').setValidators([Validators.pattern('[0-9]{' + mobileFormat.length + '}'),
        this.activationFormGroup.get('mobileNumber').validator]);
      }
      this.countryUniqueIDIcon = this.tenantConfigSettings.country.find(x => x.countryCode === value).flagIconLocation;
      this.selectedUniqueIDFormat = this.tenantConfigSettings.country.find(x => x.countryCode === value).uniqueIdType;
      this.selectedCountryId = this.tenantConfigSettings.country.find(x => x.countryCode === value).id;

      const mobileNumberFormatMask = this.tenantConfigSettings.country.find(x => x.countryCode === value).mobileFormat;
      if (mobileNumberFormatMask != null && mobileNumberFormatMask.length > 0) {
        const replacePattern = /\x/gi;
        this.mask = mobileNumberFormatMask.toLocaleLowerCase().replace(replacePattern, '0');
      }
    }
  }

  getSecurityCountries() {
    return this.countryCodes.filter(x => x.value === '+44' || x.value === '+91' || x.value === '+1');
  }
  onWrongTab() {
    return false;
  }
  /*
  * Validate user after providing First Name, Last Name, Staff Number and Date of birth by matching them with the data available in DB provided by tenant
  */
  checkAboutYouTabValidation() {
    if (!this.activationFormGroup.controls.emailAddress.valid || !this.activationFormGroup.controls.mobileNumber.valid
      || !this.isUserValid) {
      this.activationFormGroup.controls.emailAddress.markAsTouched();
      this.activationFormGroup.controls.mobileNumber.markAsTouched();
      return false;
    }
    if (!this.verifiedEmailAddress) {
      this.dialogService.getSucessDialog('Error',
        this.appMessagesService.appStaticMessages.AppLevel.EmailNotVerified, false).afterClosed();
      return false;
    }
    if (!this.verifiedPhoneNumber) {
      this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.AppLevel.MobileNotVerified, false)
        .afterClosed();
      return false;
    }
    if (this.tenantConfigSettings.activation.usernameTypeId === 2) {
      this.activationFormGroup.patchValue({
        userName: this.activationFormGroup.controls.emailAddress.value
      });
      this.usernameValue = this.activationFormGroup.controls.emailAddress.value;
      this.activationFormGroup.get('userName').disable();
    } else if (this.tenantConfigSettings.activation.usernameTypeId === 3) {
      this.activationFormGroup.patchValue({
        userName: this.activationFormGroup.controls.staffNumber.value
      });
      this.usernameValue = this.activationFormGroup.controls.staffNumber.value;
      this.activationFormGroup.get('userName').disable();
    }
    this.sharedService.emailTimeOutInterval = 0;
    this.sharedService.mobileTimeOutInterval = 0;
    this.sharedService.emailTimeOutCounter = 0;
    this.sharedService.mobileTimeOutCounter = 0;
    this.emailTimer = 0;
    this.mobileTimer = 0;
    this.currentPage = 2;
    if (this.selectedFile != null && $('#employeePicturePreview').attr('src').match('blob.core.windows.net') === null) {
      this.uploadPicture();
    } else {
      $('#wizardProfile').bootstrapWizard('show', 1);
      return true;
    }
  }
  validateuserDetails() {
    if (!this.activationFormGroup.controls.firstName.valid || !this.activationFormGroup.controls.lastName.valid
      || !this.activationFormGroup.controls.staffNumber.valid || !this.activationFormGroup.controls.dateOfBirth.valid
    ) {
      this.activationFormGroup.controls.firstName.markAsTouched();
      this.activationFormGroup.controls.lastName.markAsTouched();
      this.activationFormGroup.controls.staffNumber.markAsTouched();
      this.activationFormGroup.controls.dateOfBirth.markAsTouched();
      return false;
    }
    this.employeePersonalInfo = this.activationFormGroup.value;
    const empDataToValidate = {
      staffNumber: this.activationFormGroup.value.staffNumber,
      firstName: this.activationFormGroup.value.firstName,
      lastName: this.activationFormGroup.value.lastName,
      dateOfBirth: this.dateService.getLocalFormatedDate(this.activationFormGroup.value.dateOfBirth)
    };

    this.identityManagerRegistrationService.ValidateEmployeeV2(empDataToValidate).subscribe(response => {
      if (response.activationFailedCount <= 3) {
        if (response != null && response.status === '-1') {
          this.InsertIntoAuditLog('Failed', 'User Details Validation', 'User details exists', 'GET');
          $('#wizardProfile').bootstrapWizard('show', 0);
          this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.Employee.ProfileAlreadyActivated, false)
            .afterClosed().subscribe(() => this.RedirectUser());
          return false;
        }
        if (response != null && response.status === '-2') {
          this.InsertIntoAuditLog('Failed', 'User Details Validation', 'User locked', 'GET');
          this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.Employee.AccountLockedDown, false)
            .afterClosed().subscribe();
          this.activationFormGroup.reset();
          return false;
        }
        if (response != null && response.status === '-3') {
          this.InsertIntoAuditLog('Failed', 'User Details Validation', 'User Access Expired', 'GET');
          this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.Employee.AccessExpired, false)
            .afterClosed().subscribe();
          this.activationFormGroup.reset();
          return false;
        }
        if (response != null && response != null && response.status === '1') {
          this.employeePersonalInfo.uniqueId = response.uniqueId.toString();
          this.staffNumber = response.staffNumber;
          this.activationFormGroup.get('firstName').disable();
          this.activationFormGroup.get('lastName').disable();
          this.activationFormGroup.get('staffNumber').disable();
          this.activationFormGroup.get('dateOfBirth').disable();
          this.isUserValid = true;
          this.activationFormGroup.get('emailAddress').enable();
          this.activationFormGroup.get('mobileNumber').enable();
          this.activationFormGroup.controls.emailAddress.markAsUntouched();
          this.activationFormGroup.controls.mobileNumber.markAsUntouched();
        } else if (response !== null && response.status === null) {
          this.InsertIntoAuditLog('Failed', 'User Details Validation', 'User does not exists', 'GET');
          this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.Employee.UserDetailsNotMatching,
            false).afterClosed();
          return false;
        }
      } else {
        this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.Employee.InvalidDetails,
          false).afterClosed();
        return false;
      }
    });
    return false;
  }
  RedirectToLogin(result) {
    if (result === DialogEnum.Confirm) {
      this.RedirectUser();
    }
  }
  isUsernameValid() {
    if (this.tenantConfigSettings.activation.usernameTypeId === 1) {
      return this.activationFormGroup.controls.userName.valid;
    }
    return true;
  }
  checkActivationTabValidation() {
    if (!this.isUsernameValid() || !this.activationFormGroup.controls.uniqueId.valid ||
      !this.activationFormGroup.controls.passwordGroup.get('password').valid || !this.activationFormGroup.controls.passwordGroup.
        get('confirmPassword').valid || !this.activationFormGroup.controls.communicationPreferenceId.valid) {
      this.activationFormGroup.controls.uniqueId.markAsTouched();
      this.activationFormGroup.controls.passwordGroup.get('password').markAsTouched();
      this.activationFormGroup.controls.passwordGroup.get('confirmPassword').markAsTouched();
      this.activationFormGroup.controls.communicationPreferenceId.markAsTouched();
      if (this.tenantConfigSettings.activation.usernameTypeId === 1) {
        this.activationFormGroup.controls.userName.markAsTouched();
      }
      return false;
    }
    if (!this.verifiedCommunicationPreference) {
      this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.Employee.UserProfileNotVerified, false)
        .afterClosed();
      return false;
    }
    if (this.isvalidFlag === false || this.activationFormGroup.controls.passwordGroup.errors.invalid) {
      return false;
    }
    if (!this.isUsernameUnique) {
      this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.Employee.UniqueUserName, false).afterClosed();
      return false;
    }
    this.sharedService.emailTimeOutInterval = 0;
    this.sharedService.mobileTimeOutInterval = 0;
    this.sharedService.emailTimeOutCounter = 0;
    this.sharedService.mobileTimeOutCounter = 0;
    this.emailTimer = 0;
    this.mobileTimer = 0;
    this.clientAdminActivationService.GetClientActivationConfiguration().subscribe(
      (response: IActivationConfiguration) => {
        this.checkInvalidAttemps(true);
        this.activationConfiguration = response;
        this.questionList = this.activationConfiguration.securityQuestionsList;
        this.isPersonalQuesChecked = this.activationConfiguration.security.isPersonalQuestionsChecked;
        this.securityQuesCount = this.activationConfiguration.security.numberOfSQToBeAnswered;
        this.personalQuesCount = this.activationConfiguration.security.numberOfPQToBeAsked;
        this.count = 0;
        this.userSelectedQuestions = [];
        this.loadFormwithQuestions();
        $('#wizardProfile').bootstrapWizard('show', 2);
        this.currentPage = 0;
        return true;
      }, _error => {
        this.dialogService.getErrorDialogBox().afterClosed();
        $('#wizardProfile').bootstrapWizard('show', 1);
        this.checkInvalidAttemps();
      });
    return false;
  }
  finishButton(isValidation = false) {
    this.userSelectedQuestions = [];
    if (this.count < this.securityQuesCount) {
      if (isValidation) {
        this.isFormValid = false;
        return this.isFormValid;
      }
      this.dialogService.getErrorDialogBoxWithMessage(
        'Please answer minimum ' + this.securityQuesCount + ' question(s)').afterClosed();
      return false;
    }
    if (this.count >= this.securityQuesCount) {
      for (let i = 0; i < this.activationFormGroup.controls.questionGroupArray.value.length; i++) {
        if (this.activationFormGroup.controls.questionGroupArray.value[i].sqCheckbox === true) {
          if (this.activationFormGroup.controls.questionGroupArray.value[i].questionText) {
            this.userSelectedQuestions.push(this.activationFormGroup.controls.questionGroupArray.value[i]);
          } else if (!this.activationFormGroup.controls.questionGroupArray.value[i].questionText) {
            this.userSelectedQuestions = [];
            if (isValidation) {
              this.isFormValid = false;
              return this.isFormValid;
            }
            this.dialogService.getSucessDialog('Error',
              this.appMessagesService.appStaticMessages.AppLevel.SecurityQuestionSelected, false).afterClosed();
            return false;
          }
        }
      }
    }
    if (this.isPersonalQuesChecked) {
      if (!this.activationFormGroup.controls.personalQuesText1.valid || !this.activationFormGroup.controls.personalAnsText1.value) {
        this.userSelectedQuestions = [];
        if (isValidation) {
          this.isFormValid = false;
          return this.isFormValid;
        }
        this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.AppLevel.PersonalQuestion, false)
          .afterClosed();
        return false;
      }
      if (this.personalQuesCount === 2 && (!this.activationFormGroup.controls.personalQuesText2.valid
        || !this.activationFormGroup.controls.personalAnsText2.valid)) {
        this.userSelectedQuestions = [];
        if (isValidation) {
          this.isFormValid = false;
          return this.isFormValid;
        }
        this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.AppLevel.PersonalQuestion, false)
          .afterClosed();
        return false;
      }
    }
    if (!this.activationFormGroup.controls.hintQuestion.valid || !this.activationFormGroup.controls.hintAnswer.value) {
      this.userSelectedQuestions = [];
      if (isValidation) {
        this.isFormValid = false;
        return this.isFormValid;
      }
      this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.AppLevel.HintQuestion, false)
        .afterClosed();
      return false;
    }
    if (!this.activationFormGroup.controls.hint.value) {
      this.userSelectedQuestions = [];
      if (isValidation) {
        this.isFormValid = false;
        return this.isFormValid;
      }
      this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.Employee.EnterHint, false)
        .afterClosed();
      return false;
    }
    if (this.activationFormGroup.controls.hint.value === this.activationFormGroup.controls.hintAnswer.value) {
      this.userSelectedQuestions = [];
      if (isValidation) {
        this.isFormValid = false;
        return this.isFormValid;
      }
      this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.Employee.AnswerHintSameError, false)
        .afterClosed();
      return false;
    }
    const clientSecurityQuestions = [];
    this.userSelectedQuestions.filter(o => {
      if (o.questionText.toString().trim().length <= 0 || o.questionText.toString().trim() === '') {
        return;
      }
      clientSecurityQuestions.push({
        id: o.questionId,
        question: o.placeholder.toString().trim(),
        answer: o.questionText
      });

    });

    const hintQuestion: Array<{ question: any, answer: any, hint: any }> = [];
    hintQuestion.push({
      question: this.activationFormGroup.value.hintQuestion,
      answer: this.activationFormGroup.value.hintAnswer,
      hint: this.activationFormGroup.value.hint
    });

    const personalQuestions: Array<{ question: any, answer: any }> = [];
    if (this.isPersonalQuesChecked) {
      personalQuestions.push({
        question: this.activationFormGroup.value.personalQuesText1,
        answer: this.activationFormGroup.value.personalAnsText1
      });
      if (this.personalQuesCount === 2) {
        personalQuestions.push({
          question: this.activationFormGroup.value.personalQuesText2,
          answer: this.activationFormGroup.value.personalAnsText2
        });
      }
    }

    const personalSecurityQuestions = personalQuestions.map(o => {
      if (o.question != null) {
        return {
          id: 0,
          question: o.question,
          answer: o.answer
        };
      }
    });

    const hintSecurityQuestions = hintQuestion.map(o => {
      if (o.question != null) {
        return {
          id: 0,
          question: o.question,
          answer: o.answer,
          hint: o.hint
        };
      }
    });
    const profileSecurity: ISecurityQuestionsGroup = {
      personalSecurityQuestions,
      clientSecurityQuestions,
      hintSecurityQuestions
    };
    if (isValidation) {
      this.isFormValid = true;
      return this.isFormValid;
    }
    this.userProfileService.UpdateSecurityQuestionForRegistrationV2(profileSecurity, this.staffNumber).subscribe(response => {
      if (response) {
        this.checkInvalidAttemps(true);
        this.setNewStaffNumber(this.staffNumber);
        this.registerUser();
      }
    }, _error => {
      this.checkInvalidAttemps();
      if (_error && _error.error && _error.error.error[0] && _error.error.error[0].message && _error.error.error[0].message !== '') {
        this.dialogService.getErrorDialogBoxWithMessage(_error.error.error[0].message).afterClosed().subscribe(res => {
          if (res === 0) {
            window.location.reload();
          }
        });
      } else {
        this.dialogService.getErrorDialogBox().afterClosed();
      }
    }
    );
  }

  setNewStaffNumber(sn: string) {
    sn += this.activationFormGroup.controls.uniqueId.value;
    this.staffNumber = crypto.SHA256(sn).toString(crypto.enc.Base64);
  }

  /*
  * Register user with required details(Staff Number, User Name, Country, Email, Mobile Number, Communication Preference, Sign In type and Password)
  * add onto active directory
  */
  registerUser() {
    const empDataToRegister = {
      imageUrl: this.selectedFile === null ? null : $('#employeePicturePreview').attr('src'),
      originalImageUrl: this.selectedFile === null ? null : this.originalImageUrl,
      staffNumber: this.staffNumber,
      username: ((this.tenantConfigSettings.activation.usernameTypeId === 1)
        ? this.activationFormGroup.value.userName : this.usernameValue),
      countryId: this.selectedCountryId,
      emailAddress: this.activationFormGroup.value.emailAddress,
      mobileCountryCode: this.activationFormGroup.get('countryCode').value,
      mobileNumber: this.activationFormGroup.value.mobileNumber,
      communicationPreferenceId: this.activationFormGroup.value.communicationPreferenceId,
      password: crypto.SHA256(this.activationFormGroup.value.passwordGroup.password).toString(crypto.enc.Base64)
    };
    this.identityManagerRegistrationService.RegisterEmployeeV2(empDataToRegister).subscribe(response => {
      if (response === true) {
        this.checkInvalidAttemps(true);
        $('#wizardProfile').bootstrapWizard('show', 2);
        this.dialogService.getSucessDialog('Success', this.appMessagesService.appStaticMessages.AppLevel.ProfileActivationSuccesful, true)
          .afterClosed().subscribe(() => this.RedirectUser());
        return true;
      }
      this.checkInvalidAttemps();
      $('#wizardProfile').bootstrapWizard('show', 2);
      this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.AppLevel.ErrorEncounteredTryAgain, false)
        .afterClosed();

    });
  }
  RedirectUser() {
    window.open('?returnUrl=', '_self');
  }
  uploadPicture() {
    if (!this.selectedFile) {
      return false;
    }
    let blob;
    const formData = new FormData();
    if (this.empImageUrl !== 'assets/img/default-avatar.png') {
      blob = this.sharedService.dataURLtoFile(this.empImageUrl, this.fileName);
      formData.append('image', blob, this.fileName);
      formData.append('image', this.selectedFile, this.fileName);
    }
    this.identityManagerRegistrationService.UploadPicture(formData, this.activationFormGroup.controls.staffNumber.value)
      .subscribe(response => {
        if (response != null && response[0].match('blob.core.windows.net') != null) {
          $('#employeePicturePreview').attr('src', response[0]);
          this.originalImageUrl = response[1];
          this.checkInvalidAttemps(true);
          $('#wizardProfile').bootstrapWizard('show', 1);
          return true;
        }
        this.checkInvalidAttemps(false, 'Invalid image type. Please select JPEG, JPG, GIF, BMP or PNG.');
        this.dialogService.getSucessDialog('Error',
          this.appMessagesService.appStaticMessages.AppLevel.InvalidImageTryAgain, false).afterClosed();
        $('#wizardProfile').bootstrapWizard('show', 0);
        return false;

      }, error => {
        this.checkInvalidAttemps();
        if (error && error.error && error.error.error && error.error.error.length > 0) {
          this.dialogService.getSucessDialog('Error', error.error.error[0].message, false).afterClosed();
        } else {
          this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.AppLevel.ErrorEncounteredTryAgain, false)
            .afterClosed();
        }
        $('#wizardProfile').bootstrapWizard('show', 0);
        return false;
      });
  }
  validateAuthenticationCode(type: string, purposeType: string, option: string, emailAddress: string, mobileNumber: string,
                             code: string, isCommunicationPreference: boolean = false) {
    const authModel: IAuthModel = {
      type,
      purposeType,
      option,
      emailAddress,
      mobileNumber,
      otp: code,
      staffNumber: this.staffNumber,
      isVerifyCommunication: isCommunicationPreference
    };
    this.identityManagerRegistrationService.ValidateAuthenticationCodeV2(authModel, 2, 1, false).subscribe(response => {
      if (response != null && response === true) {
        this.checkInvalidAttemps(true);
        if (isCommunicationPreference) {
          this.showCommunicationPreferenceAuthenticationBox = !response;
          this.verifiedCommunicationPreference = true;
        } else if (type === 'email') {
          this.showEmailAuthenticationBox = !response;
          this.showSpamMsg = false;
          this.verifiedEmailAddress = true;
        } else if (type === 'mobile') {
          this.showMobileAuthenticationBox = !response;
          this.verifiedPhoneNumber = true;
        }
      } else {
        this.checkInvalidAttemps(false, 'You have entered Invalid OTP');
        this.InsertIntoAuditLog('Failed', 'OTP Validation', 'Invalid OTP entered', 'GET');
        this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.AppLevel.RequestNewCode, false).afterClosed();
      }
    }, error => {
      this.checkInvalidAttemps(false, 'You have entered Invalid OTP');
      if (error != null && error.error != null && error.error !== undefined) {
        if (this.invalidAttempts !== 0) {
          if (error && error.error && error.error.error && error.error.error.length > 0) {
            this.InsertIntoAuditLog('Failed', 'OTP Validation', 'Invalid OTP entered', 'GET');
            this.dialogService.getSucessDialog('Error', error.error.error[0].message, false).afterClosed();
          } else {
            this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.AppLevel.ErrorEncounteredTryAgain, false)
              .afterClosed();
          }
        }
      } else {
        this.dialogService.getSucessDialog('Error', error, false).afterClosed();
      }
    });
  }
  sendAuthenticationCode(
    type: string,
    purposeType: string,
    option: string,
    emailAddress: string,
    mobileNumber: string,
    isCommunicationPreference: boolean = false) {
    this.emailConfiguration = this.tenantConfigSettings.emailingConfiguration.isEmailingOn;
    const authModel: IAuthModel = {
      type,
      purposeType,
      option,
      emailAddress,
      mobileNumber,
      otp: null
    };
    this.identityManagerRegistrationService.SendAuthenticationCode(authModel).subscribe(response => {
      if (response != null) {
        if (type === 'both' && !this.emailConfiguration) {
          type = 'mobile';
        }
        if (type.toLowerCase() === 'email' || type.toLowerCase() === 'both') {
          this.sharedService.emailTimeOutCounter = this.sharedService.emailTimeOutCounter + 1;
        } else {
          this.sharedService.mobileTimeOutCounter = this.sharedService.mobileTimeOutCounter + 1;
        }
        if (isCommunicationPreference) {
          this.showCommunicationPreferenceAuthenticationBox = response;
        } else if (type === 'email') {
          this.showEmailAuthenticationBox = response;
        } else if (type === 'mobile') {
          this.showMobileAuthenticationBox = response;
        }
      }
    }, error => {
      if (isCommunicationPreference) {
        this.showCommunicationPreferenceAuthenticationBox = false;
      } else if (type === 'email') {
        this.showEmailAuthenticationBox = false;
        this.showSpamMsg = false;
      } else if (type === 'mobile') {
        this.showMobileAuthenticationBox = false;
      }
      if (error && error.error && error.error.error && error.error.error.length > 0) {
        this.dialogService.getSucessDialog('Error', error.error.error[0].message, false).afterClosed();
      } else {
        this.dialogService.getSucessDialog('Error', this.appMessagesService.appStaticMessages.AppLevel.ErrorEncounteredTryAgain, false)
          .afterClosed();
      }
    });
  }
  createRows(question: any) {
    return this.formBuilder.group({
      sqCheckbox: [question.sqCheckbox],
      placeholder: [question.question],
      // tslint:disable-next-line: no-sparse-arrays
      questionText: [, [Validators.required, ValidationHelper.hasWhiteSpaces()]],
      questionId: [question.id],
      securityId: [question.securityId]
    });
  }
  addItem(question: any): void {
    this.questionGroup = <FormArray>this.activationFormGroup.get('questionGroupArray');
    this.questionGroup.push(this.createRows(question));
  }
  loadFormwithQuestions() {
    this.questionGroup = <FormArray>this.activationFormGroup.get('questionGroupArray');
    this.questionGroup.controls = [];
    for (let i = 0; i < this.questionList.length; i++) {
      if (this.questionList[i].isSelected === true) {
        this.addItem(this.questionList[i]);
      }
    }
  }
  questionChecked(event: any, question: number) {
    const elementRef = document.getElementById('questionId' + question);
    if (event.currentTarget.checked) {
      this.count++;
      elementRef.focus();
    } else {
      this.count--;
      const questionGroup = <FormArray>this.activationFormGroup.get('questionGroupArray');
      questionGroup.controls[question]['controls'].questionText.setValue('');
    }
  }
  removeProfilePic() {
    this.dialogService.getConfirmDialog('Confirm', this.appMessagesService.appStaticMessages.Employee.RemoveProfilePicConfirmation, true)
      .afterClosed().subscribe(result => {
        if (result === DialogEnum.Confirm) {
          $('#employeePicturePreview').attr('src', 'assets/img/default-avatar.png');
          $('#removeImageButton').hide();
          this.selectedFile = null;
          this.showRemovePicIcon = false;
        }
      });
  }
  openImageCropper() {
    this.dialog.open(ImageCropperComponent, {
      disableClose: true,
      panelClass: 'ImageCropperWindow',
      width: '40%',
      data: {
        originalImageURL: null,
        imageFileExtensionList: this.imageFileExtensionList
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.empImageUrl = result.croppedImage;
        this.originalImageUrl = result.originalImage;
        this.selectedFile = result.originalImage;
        this.fileName = result.fileName;
        $('#employeePicturePreview').attr('src', this.empImageUrl);
        this.showRemovePicIcon = true;
      }
    });
  }
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
