import { Pipe, PipeTransform } from '@angular/core';

import { Feature } from './../../Interfaces/security/feature';

@Pipe({
  // tslint:disable-next-line: pipe-prefix
  name: 'sideMenuFilter'
})
export class SideMenuFilterPipe implements PipeTransform {

  constructor() { }

  transform(menuItemsArray: Feature[], parentMenuFeatureId: number): Feature[] {
    let filterList: Feature[] = menuItemsArray;
    if (filterList && filterList.length > 0) {
      filterList = menuItemsArray.filter(o => o.parentMenuFeatureId === parentMenuFeatureId);
    }
    return filterList;
  }

}
