import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { RequestType } from './requestType';

@Injectable(
  {
    providedIn: 'root',
  }
)
export class BaseService {
  constructor(private httpClient: HttpClient) {
  }

  Execute<T>(requestType: RequestType, relativeURL: string, endpoint: string, data: any): Observable<T> {
    switch (requestType) {

      case RequestType.GET:
        return this.httpClient.get<T>(environment[endpoint] + relativeURL);

      case RequestType.POST:
        return this.httpClient.post<T>(environment[endpoint] + relativeURL, data);

      case RequestType.DELETE:
        return this.httpClient.delete<T>(environment[endpoint] + relativeURL);
    }
  }
}
