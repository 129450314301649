import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateService {

  dateFormat: string = 'DD-MM-YYYY';
  constructor() {
  }

  getCurrentDate(): string {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    const currentDate = mm + '-' + dd + '-' + yyyy;
    return currentDate;
  }

  getCurrentUtcDate(): string {
    return this.localToUtc(new Date());
  }

  getDateAfter(intervalPeriod: number, interval: any) {
    return moment().add(intervalPeriod, interval);
  }

  getDateBefore(intervalPeriod: number, interval: any) {
    return moment().subtract(intervalPeriod, interval).toDate();
  }

  // used in messaging for ('LL') format.
  getFormatedCurrentDate(format: string) {
    return moment(new Date()).format(format);
  }

  getFormatedDate(date: any, format: string) {
    return moment(date).format(format);
  }

  getFormattedDate(value) {
    return moment(value, 'DD/MM/YYYY').format(this.dateFormat);
  }

  getFormattedDateTime(value) {
    return moment(value).format('YYYY-MM-DD HH:mm:ss');
  }

  getLocalFormatedDate(date: any) {
    return moment(date).format('LL');
  }

  getGlobalFormatedDate(date: any) {
    return moment(date).format(this.dateFormat);
  }

  // used MM/DD/YYYY format.
  getStartOfMonthByFormat(format: string) {
    return moment().startOf('month').format(format);
  }

  getDateFormatedForInterval(date: any, interval: any, intervalPeriod: number, dateFormat: string) {
    return moment(date).add(intervalPeriod, interval).format(dateFormat);
  }

  utcToLocal(utcDate) {
    return moment.utc(utcDate).local().format(this.dateFormat + ' hh:mm A');
  }
  utcToLocalCustomFormat(utcDate, customFormat) {
    return moment.utc(utcDate).local().format(customFormat);
  }
  utcToLocalDate(utcDate) {
    return moment.utc(utcDate).local().format(this.dateFormat);
  }
  utcToLocalTime(utcDate) {
    return moment.utc(utcDate).local().format('hh:mm A');
  }
  utcToLocalTime24hrFormat(utcDate) {
    return moment.utc(utcDate).local().format('HH:mm');
  }
  localToUtc(date: any) {
    return moment.utc(date).utc().format('YYYY-MM-DD HH:mm:ss');
  }
  local24hrto12hrFormat(localTime) {
    return moment(localTime, ['HH:mm']).format('hh:mm A');
  }
  local12hrto24hrFormart(localTime) {
    return moment(localTime, ['hh:mm A']).format('HH:mm');
  }
}
