import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { BaseService } from './baseService.service';
import { RequestType } from './requestType';

@Injectable({
  providedIn: 'root',
})
export class BlockchainService {

  tenant = window.sessionStorage.getItem('tenant');
  relativeURL = 'shared/blockchaindocumentdata/' + this.tenant + '/';

  constructor(private baseService: BaseService) { }

  public getDocumentDetails(blockid: string, documentAlias: string): Observable<any> {
    return this.baseService.Execute<any>(RequestType.GET, this.relativeURL + `${blockid}/${documentAlias}`, 'baseSharedEndpoint', null);
  }
}
