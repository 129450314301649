import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './auth-layout.component.html'
})
export class AuthLayoutComponent implements OnInit {

  _mobileMenuVisible: any = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;

  constructor() {
    this.sidebarVisible = false;
  }
  ngOnInit() {
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(() => {
      toggleButton.classList.add('toggled');
    }, 500);
    body.classList.add('nav-open');

    this.sidebarVisible = true;
  } sidebarClose() {
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  } sidebarToggle() {
    const body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
      const $layer = document.createElement('div');
      $layer.setAttribute('class', 'close-layer');
      if (body.querySelectorAll('.wrapper-full-page')) {
        document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
      } else if (body.classList.contains('off-canvas-sidebar')) {
        document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
      }
      setTimeout(() => {
        $layer.classList.add('visible');
      }, 100);
      $layer.onclick = function() { // asign a function
        body.classList.remove('nav-open');
        this._mobileMenuVisible = 0;
        $layer.classList.remove('visible');
        this.sidebarClose();
      }.bind(this);

      body.classList.add('nav-open');
    } else {
      document.getElementsByClassName('close-layer')[0].remove();
      this.sidebarClose();
    }
  }
}
