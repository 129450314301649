import { Injectable } from '@angular/core';

interface KeyValuePair {
  key: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class FormatterService {
  locale: KeyValuePair[] = [{ key: 'GBP', value: 'en-GB' }, { key: 'INR', value: 'en-IN' }];

  getFormattedValue(key: string, value: number, fractionDigits: number, isCurSymbolRequired: boolean = true): string {
    const result = this.locale.find(x => x.key === key);
    let format = '';
    if (result) {
      format = result.value;
    } else {
      format = 'en-GB';
    }
    value = Number(value);

    if (isCurSymbolRequired) {
      return value.toLocaleString(format, {
       style: 'currency',
       currency: key,
       maximumFractionDigits: fractionDigits,
       minimumFractionDigits: fractionDigits
     });
    }
    return value.toLocaleString(format, {
        style: 'decimal',
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits
      });
  }
}
