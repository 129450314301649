import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatAutocompleteTrigger, MatDatepicker, MatDialogRef } from '@angular/material';
import moment from 'moment';
import { NotificationActivityTypeEnum } from 'src/app/Interfaces/NotificationActivityTypeEnum';
import { NotificationPurposeEnum } from 'src/app/Interfaces/NotificationPurposeEnum';
import { SharedService } from 'src/app/services/shared.service';
import {
  CustomDatePickerComponent,
} from 'src/app/shared/components/generic/custom-date-picker/custom-date-picker.component';

import { ApplicationHelper } from '../../../../helpers/application-helper';
import { ValidationHelper } from '../../../../helpers/validation-helper';
import { IEditEventDialogData } from '../../../Interfaces/paymanager/IEditEventDialogData';
import { IHelpCalendar } from '../../../Interfaces/paymanager/IHelpCalendar';
import { CalendarEventService } from '../../../services/calendarEvent.service';
import { MasterDataService } from '../../../services/masterData.service';
import { SecurityService } from '../../../services/security.service';
import { UserProfileService } from '../../../services/user-profile.service';
import { DialogService } from '../../../shared/dialog/dailog.service';
import { DialogEnum } from '../../../shared/dialog/DialogEnum';
import { AppMessagesService } from '../../../shared/services/app-messages.service';
import { DateService } from './../../../shared/services/date.service';

@Component({
  selector: 'app-edit-event-dailog',
  templateUrl: './edit-event-dailog.component.html',
  styleUrls: ['./edit-event-dailog.component.scss']
})
export class EditEventDailogComponent implements OnInit {
  datepickerHeader = CustomDatePickerComponent;
  currentRoleId: number;
  isDisabled = true;
  isDirty: boolean;
  isFormValid: boolean;
  editEventForm: FormGroup;
  documentTypes: any = [];
  helpCalendar: IHelpCalendar;
  creatorRoleId: number;
  modifierRoleId: number;
  startDate: Date;
  endDate: Date;
  currentRolePermissions: any;
  minStartDate: Date;
  selectedDate: Date;
  minEndDate: Date;
  locations: any[] = [];
  selectedPayrollArray: any[] = [];
  distinctLocations: any[] = [];
  selectedLocation: any;
  payRollList: any[] = [];
  showForm = false;
  @ViewChild('payRoll', { static: false }) payRoll;
  @ViewChild('location', { static: false }) location;
  @ViewChild('subject', { static: false }) subject;
  @ViewChild('startDatePicker', { static: false }) picker;
  @ViewChild('endDatePicker', { static: false }) endDatePicker;
  popUpSelected = false;
  actualStartDate: string;
  actualEndDate: string;
  showCalendarInput = true;
  eventTitle = '';
  isEndDatePopupopen = false;
  isStartDatePopupopen = false;
  isStartDateGreaterThanEndDate = false;
  isFormReset = false;
  startTimeList: string[] = [];
  endTimeList: string[] = [];
  startCurrentTime: string;
  endCurrentTime: string;
  isStartDateLessThanCurrentTime = false;
  isEndTimeLessThanStartTime = false;
  allPayrolls = false;
  currentPanelClass: string | string[] = [];
  dpStartDate: Date;
  constructor(
    public appMessagesService: AppMessagesService,
    private eRef: ElementRef,
    public dialogRef: MatDialogRef<EditEventDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IEditEventDialogData,
    private dialogService: DialogService, private formBuilder: FormBuilder,
    private calendarEventService: CalendarEventService,
    private employeeApiService: UserProfileService,
    private dateService: DateService,
    private masterDataService: MasterDataService,
    public securityService: SecurityService,
    private sharedService: SharedService) {
    this.dialogRef.disableClose = true;
    this.selectedDate = this.startDate;
    this.eventTitle = this.dialogData.data.eventTitle;
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      if (this.popUpSelected) {
        this.payRoll.close();
        this.location.close();
        this.subject.close();
      }
      if (this.isEndDatePopupopen) {
        this.endDatePicker.close();
      }
      if (this.isStartDatePopupopen) {
        this.picker.close();
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.currentRolePermissions = this.dialogData.currentRolePermissions;
    this.currentPanelClass = this.dialogRef._containerInstance._config.panelClass;
    this.getLocations();
    this.setRoleIds();
  }
  reset() {
    if (!this.isDisabled) {
    if (this.editEventForm.dirty) {
    this.dialogService.getConfirmDialogForReset().afterClosed()
              .subscribe(result => {
    this.resetForm(result);
              }
              );
          } else {
    this.isFormReset = !this.isFormReset;
    this.resetForm(0);
          }
        }
      }
  eventTitleKeyUp() {
    this.isDisabled = this.validForm() && !this.editEventForm.dirty
      && (this.eventTitle.trim().length !== 0);
    if (this.editEventForm.get('eventTitle').value.trim().length === 0) {
      this.editEventForm.controls.eventTitle.setValue('');
    }
  }
  getLocations() {
    if (this.securityService && this.securityService.securityObject && this.securityService.securityObject.currentRoleId === 5) {
      this.employeeApiService.GetPayrollMetadata().subscribe(resp => {
        this.locations = resp;
        this.locations.forEach(elem => {
          if (!this.distinctLocations.includes(elem.location)) {
            this.distinctLocations.push(elem.location);
          }
        });
        this.selectedLocation = this.dialogData.data.location;
        this.setPayrolls();
        this.generateTime();
      });
    } else {
      this.masterDataService.GetPayrollMetadata().subscribe(resp => {
        this.locations = resp;
        this.locations.forEach(elem => {
          if (!this.distinctLocations.includes(elem.location)) {
            this.distinctLocations.push(elem.location);
          }
        });
        this.selectedLocation = this.dialogData.data.location;
        this.setPayrolls();
        this.generateTime();
      });
    }
  }

  setFormRelatedData() {
    this.getDocumentTypes();
    this.dialogRef.updateSize('600px', 'auto');
    this.editEventForm.valueChanges.subscribe(() => this.isDirty = this.editEventForm.dirty);
    this.editEventForm.valueChanges.subscribe(() => this.isFormValid = this.validForm()
      && this.editEventForm.dirty && (this.eventTitle.trim().length !== 0));
    ApplicationHelper.enableOrDisableFormControls(this.editEventForm, this.isDisabled);
    this.showForm = true;
  }
  setPayrolls() {
    this.payRollList.length = 0;
    this.payRollList.push({ clientPayrollId: 0, clientPayrollName: 'All', payrollId: 0 });
    this.locations.forEach(element => {
      if (element.location === this.selectedLocation) {
        this.payRollList.push({
          clientPayrollId: element.clientPayrollId, clientPayrollName: element.clientPayrollId + ' - ' + element.clientPayrollName,
          payrollId: element.id
        });
      }
    });
  }

  delete() {
    const eventId = this.dialogData.data.id;
    const creatorRoleId = this.dialogData.data.userRoleId;
    if (this.currentRolePermissions.roleAlias === 'App Manager'
      || (this.modifierRoleId === this.creatorRoleId && this.currentRolePermissions.canDelete === true)) {
      this.dialogService.getConfirmDialog('Delete event',
        this.appMessagesService.appStaticMessages.PayManager.RemoveConfirmation, true).
        afterClosed().subscribe(alertResponse => {
          if (alertResponse === 0) {
            this.calendarEventService.deleteEvent(eventId, this.currentRoleId, creatorRoleId)
              .subscribe(resp => {
                if (resp) {
                  this.dialogService.getSucessDialog('Success',
                    this.appMessagesService.appStaticMessages.PayManager.EventDeletedSuccessfully, true);
                  this.dialogRef.close();
                }
              }, (_error => {
                this.dialogService.getConfirmDialog('Error',
                  this.appMessagesService.appStaticMessages.AppLevel.ErrorEncounteredTryAgain, false);
              }));
          }
        });
    } else {
      this.dialogService.getConfirmDialog('Error', this.appMessagesService.appStaticMessages.AppLevel.EventDeleteError, false);
    }
  }

  edit() {
    if (this.currentRolePermissions.roleAlias === 'App Manager'
      || (this.creatorRoleId === this.modifierRoleId && this.currentRolePermissions.canEdit === true)) {
      this.isDisabled = false;
      ApplicationHelper.enableOrDisableFormControls(this.editEventForm, this.isDisabled);
    } else {
      this.dialogService.getSucessDialog('Warning', this.appMessagesService.appStaticMessages.AppLevel.EventEditError, false);
    }
  }
  close() {
    this.isDisabled = true;
    this.dialogRef.close();
  }
  validForm(): boolean {
    return this.editEventForm.valid;
  }
  createGroup() {
    this.selectedPayrollArray = [];
    this.dpStartDate = new Date(this.dateService.utcToLocalCustomFormat(this.dialogData.data.eventStartDate, 'MMM DD, YYYY HH:mm'));
    this.startDate = new Date(this.dateService.utcToLocalCustomFormat(this.dialogData.data.eventStartDate, 'MMM DD, YYYY HH:mm'));
    this.endDate = new Date(this.dateService.utcToLocalCustomFormat(this.dialogData.data.eventEndDate, 'MMM DD, YYYY HH:mm'));
    const startDate = moment(new Date(this.startDate)).format('MMM DD, YYYY');
    const endDate = moment(new Date(this.endDate)).format('MMM DD, YYYY');
    const datePickerEndDate = moment(new Date()).format('MMM DD, YYYY');
    const currentDate = moment(new Date()).format('MMM DD, YYYY');
    const startDateActual = this.dateService.getGlobalFormatedDate(this.startDate);
    const endDateActual = this.dateService.getGlobalFormatedDate(this.endDate);
    this.actualStartDate = startDateActual;
    this.actualEndDate = endDateActual;
    const startTime = this.dateService.utcToLocalTime24hrFormat(this.dialogData.data.eventStartDate);
    const endTime = this.dateService.utcToLocalTime24hrFormat(this.dialogData.data.eventEndDate);
    this.startCurrentTime = this.dateService.local24hrto12hrFormat(startTime);
    this.endCurrentTime = this.dateService.local24hrto12hrFormat(endTime);
    if (startDate < endDate && startDate < currentDate && endDate > datePickerEndDate) {
      this.minStartDate = new Date(this.dateService.getFormatedDate(new Date(this.startDate), 'MM-DD-YYYY') + ' ' + startTime);
    } else {
      this.minStartDate = new Date(this.dateService.getFormatedDate(Date.now(), 'MM-DD-YYYY') + ' ' + startTime);
    }
    this.minEndDate = new Date(datePickerEndDate + ' ' + endTime);
    this.editEventForm = this.formBuilder.group({
      calendarDocumentId: [this.dialogData.data.calendarDocumentId, [Validators.required]],
      eventTitle: [this.dialogData.data.eventTitle, [Validators.required, ValidationHelper.hasWhiteSpaces(),
      Validators.minLength(5), Validators.maxLength(80)]],
      eventStartDate: [startDate, [Validators.required]],
      eventEndDate: [endDate, [Validators.required]],
      startTime: ['', [Validators.required, Validators.pattern('((0[1-9])|(1[0-2])):[0-5][0-9](\\s(A|P)M)')]],
      endTime: ['', [Validators.required, Validators.pattern('((0[1-9])|(1[0-2])):[0-5][0-9](\\s(A|P)M)')]],
      isPublished: [this.dialogData.data.isPublished],
      location: [this.dialogData.data.location, [Validators.required]],
      payRoll: [this.dialogData.data.payrollIdList, [Validators.required]]
    });
    if (this.dialogData.data.payrollIdList.includes(0)) {
      this.allPayrolls = true;
      this.payRollList.forEach(payroll => {
        this.selectedPayrollArray.push(payroll.payrollId);
      });
    } else {
      this.allPayrolls = false;
      this.selectedPayrollArray = this.dialogData.data.payrollIdList;
    }
    this.setFormRelatedData();
    this.editEventForm.patchValue({ eventStartDate: new Date(this.startDate), eventEndDate: new Date(this.endDate) });
  }
  getDocumentTypes() {
    if (this.currentRoleId === 5) {
      this.employeeApiService.getDocumentTypes().subscribe(documentTypesResponse => {
        documentTypesResponse.filter(document => {
          if (document.name.toLowerCase() !== 'reminder') {
            this.documentTypes.push(document);
          }
        });
      });
    } else {
      this.calendarEventService.getDocumentTypes().subscribe(documentTypesResponse => {
        documentTypesResponse.filter(document => {
          if (document.name.toLowerCase() !== 'reminder') {
            this.documentTypes.push(document);
          }
        });
      });
    }
  }
  save() {
    if (!this.editEventForm.dirty || !this.editEventForm.valid || !this.isFormValid) {
      return false;
    }
    const endDate = moment(new Date(this.editEventForm.value.eventEndDate)).format('MMM DD, YYYY');
    const startDate = moment(new Date(this.editEventForm.value.eventStartDate)).format('MMM DD, YYYY');
    this.helpCalendar = <IHelpCalendar>this.editEventForm.value;
    this.helpCalendar.id = this.dialogData.data.id;
    const startTime = this.dateService.local12hrto24hrFormart(this.editEventForm.value.startTime) + ':00';
    this.helpCalendar.eventStartDate = moment(startDate + ' ' + startTime).utc().format().toString();
    if (this.endDate) {
      const endTime = this.dateService.local12hrto24hrFormart(this.editEventForm.value.endTime) + ':00';
      this.helpCalendar.eventEndDate = moment(endDate + ' ' + endTime).utc().format().toString();
      this.helpCalendar.isAllDay = false;
    } else {
      this.helpCalendar.eventEndDate = this.helpCalendar.eventStartDate;
    }
    this.helpCalendar.isPublished = this.editEventForm.value.isPublished;
    this.helpCalendar.location = this.selectedLocation;
    if (this.selectedPayrollArray.includes(0)) {
      this.helpCalendar.payrollIdList = [];
      this.helpCalendar.payrollIdList.push(0);
    } else {
      this.helpCalendar.payrollIdList = this.selectedPayrollArray;
    }
    if ((new Date(this.helpCalendar.eventEndDate) < new Date(this.helpCalendar.eventStartDate))) {
      this.isEndTimeLessThanStartTime = true;
      this.validateForm();
    } else if (((new Date(new Date(this.helpCalendar.eventStartDate).setSeconds(1)) < new Date(new Date().setSeconds(0)))
      && (new Date(new Date().setSeconds(0)) < this.startDate && this.endDate > (new Date(new Date().setSeconds(0)))))
      || new Date(new Date(this.helpCalendar.eventEndDate).setSeconds(1)) < new Date(new Date().setSeconds(0))) {
      this.isStartDateLessThanCurrentTime = true;
      this.validateForm();
    } else {
      this.calendarEventService.insertCalendarEvent(this.helpCalendar, this.modifierRoleId, this.creatorRoleId).subscribe(_data => {
        this.sharedService.sendNotificationsByPurposeAndActivity(NotificationPurposeEnum.CalendarEventUpdated, NotificationActivityTypeEnum.CalendarChanged, _data).subscribe(_res => { });
        this.dialogService.getSucessDialog('Success', this.appMessagesService.appStaticMessages.PayManager.EventUpdated, true);
        this.dialogRef.close();
      }, _error => {
        this.dialogService.getErrorDialogBox();
      });
    }
  }
  setRoleIds() {
    this.modifierRoleId = this.dialogData.currentRolePermissions.id;
    this.currentRoleId = this.dialogData.currentRolePermissions.id;
    this.creatorRoleId = this.dialogData.data.userRoleId;
  }
  resetForm(result) {
    {
      if (result === DialogEnum.Confirm) {
        this.isDisabled = true;
        this.editEventForm.reset();
        ApplicationHelper.enableOrDisableFormControls(this.editEventForm, this.isDisabled);
        this.ngOnInit();
      }
    }
  }
  selectLocation(selLoc: string) {
    this.editEventForm.controls.payRoll.setValue(null);
    this.selectedLocation = selLoc;
    this.payRollList.length = 0;
    this.payRollList.push({ clientPayrollId: 0, clientPayrollName: 'All', payrollId: 0 });
    this.locations.forEach(element => {
      if (element.location === selLoc) {
        this.payRollList.push({
          clientPayrollId: element.clientPayrollId, clientPayrollName: element.clientPayrollId + ' - ' + element.clientPayrollName,
          payrollId: element.id
        });
      }
    });
  }
  selectAll(a) {
    if (a === 0 && this.editEventForm.controls.payRoll.value.includes(0)) {
      this.selectedPayrollArray = [];
      this.payRollList.forEach(element => {
        this.selectedPayrollArray.push(element.payrollId);
      });
    } else if (a === 0 && this.editEventForm.controls.payRoll.value.length === this.payRollList.length - 1) {
      this.selectedPayrollArray = this.selectedPayrollArray.filter(element => element.payrollId !== 0);
    } else if (this.editEventForm.controls.payRoll.value.includes(0) &&
      this.editEventForm.controls.payRoll.value.length === this.payRollList.length - 1) {
      this.selectedPayrollArray = [];
      this.payRollList.forEach(element => {
        if (element.payrollId !== 0 && element.payrollId !== a) {
          this.selectedPayrollArray.push(element.payrollId);
        }
      });
    }
    this.payRoll.open();
    if (this.selectedPayrollArray.includes(0)) {
      this.allPayrolls = true;
    } else {
      this.allPayrolls = false;
    }
  }
  popupDropdownClose(_event) {
    this.location.close();
    this.payRoll.close();
    this.subject.close();
    this.picker.close();
  }
  openedChange(opened: boolean) {
    if (opened) {
      this.popUpSelected = true;
    } else {
      this.popUpSelected = false;
    }
  }
  openCalendar(picker: MatDatepicker<Date>): void {
    this.isEndDatePopupopen = false;
    this.isStartDatePopupopen = false;
    if (!this.isDisabled) {
      picker.open();
    }
  }
  onEndDateChanged(event: any) {
    event = new Date(this.dateService.getFormatedDate(event.value, 'MMM DD, YYYY'));
    this.actualEndDate = this.dateService.getGlobalFormatedDate(event);
    const eventEndDate = event;
    this.editEventForm.patchValue({ eventEndDate });
    this.editEventForm.markAsDirty();
    this.isDirty = this.editEventForm.dirty;
    this.isFormValid = this.validForm() && this.editEventForm.dirty;

    if (new Date(eventEndDate) >= new Date(this.editEventForm.controls.eventStartDate.value)) {
      this.isStartDateGreaterThanEndDate = false;
    }
  }
  onStartDateChanged(event: any) {
    event = new Date(this.dateService.getFormatedDate(event.value, 'MMM DD, YYYY'));
    this.actualStartDate = this.dateService.getGlobalFormatedDate(event);
    const eventStartDate = event;
    this.editEventForm.patchValue({ eventStartDate });
    this.editEventForm.markAsDirty();
    this.isDirty = this.editEventForm.dirty;
    this.isFormValid = this.validForm() && this.editEventForm.dirty;
    this.minEndDate = new Date(this.minEndDate.setDate(event.getDate()));
    this.minEndDate = new Date(this.minEndDate.setMinutes(event.getMinutes()));

    if (new Date(this.editEventForm.controls.eventEndDate.value) < new Date(eventStartDate)) {
      this.isStartDateGreaterThanEndDate = true;
    }
  }
  endDateMouseLeave() {
    this.isEndDatePopupopen = true;
  }
  startDateMouseLeave() {
    this.isStartDatePopupopen = true;
  }
  generateTime() {
    const timeList = [];
    let hours = -1;
    let minutes = new Date().getMinutes();
    let hoursIn12HrFormart = -1;
    let timePeriod = ' AM';
    while ((hours) < 24) {
      if (timeList.length > 0 && timeList[timeList.length - 1].time === '23:30') {
        break;
      }
      if (minutes > 30) {
        timeList.push({
          isActive: false, time: (hours + 1).toString().length === 1 ?
            ('0' + (hours + 1) + ':00') : ((hours + 1) + ':00'), timeIn12hrFormart:
            (hoursIn12HrFormart + 1 === 0 ? '12:00' + timePeriod : (hoursIn12HrFormart + 1).toString().length === 1 ?
              ('0' + (hoursIn12HrFormart + 1) + ':00') + timePeriod : ((hoursIn12HrFormart + 1) + ':00') + timePeriod)
        });
        hours++;
        hoursIn12HrFormart++;
        minutes = 30;
      } else {
        if (hours !== -1) {
          timeList.push({
            isActive: false, time: hours.toString().length === 1 ? ('0' + hours + ':30') :
              (hours + ':30'), timeIn12hrFormart: (hoursIn12HrFormart === 0 ? '12:30' + timePeriod :
                hoursIn12HrFormart.toString().length === 1 ? ('0' + hoursIn12HrFormart + ':30') + timePeriod :
                  (hoursIn12HrFormart + ':30') + timePeriod)
          });
        }
        if (timeList.length > 0) {
          if (timeList[timeList.length - 1].time === '11:30') {
            hoursIn12HrFormart = -1; timePeriod = ' PM';
          } else {
            hoursIn12HrFormart = hoursIn12HrFormart;
          }
        }
        minutes = 31;
      }
    }
    this.startTimeList = timeList;
    this.endTimeList = timeList;
    this.createGroup();
  }
  selectTime(_timeIn12hrFormart: any, timeList: any): any {
    timeList.forEach(element => {
      element.isActive = false;
    });
    if (timeList.filter(x => x.timeIn12hrFormart === _timeIn12hrFormart).length > 0) {
      timeList.filter(x => x.timeIn12hrFormart === _timeIn12hrFormart)[0].isActive = true;
    }
    return timeList;
  }
  selectStartTime(timeIn12hrFormart: any) {
    this.startTimeList = this.selectTime(timeIn12hrFormart, this.startTimeList);
    this.validateForm();
  }
  selectEndTime(timeIn12hrFormart: any) {
    this.endTimeList = this.selectTime(timeIn12hrFormart, this.endTimeList);
    this.validateForm();
  }
  highlightChangedStartTime() {
    this.startTimeList = this.selectTime(this.startCurrentTime, this.startTimeList);
  }
  highlightChangedEndTime() {
    this.endTimeList = this.selectTime(this.endCurrentTime, this.endTimeList);
  }
  openPanel(ev: Event, trigger: MatAutocompleteTrigger) {
    ev.stopPropagation();
    trigger.openPanel();
  }
  validateForm() {
    const endDate = moment(new Date(this.editEventForm.value.eventEndDate)).format('MMM DD, YYYY');
    const startDate = moment(new Date(this.editEventForm.value.eventStartDate)).format('MMM DD, YYYY');
    this.editEventForm.controls.startTime.valueChanges.subscribe(() => {
      this.helpCalendar = <IHelpCalendar>this.editEventForm.value;
      const startTime = this.startCurrentTime + ':00';
      this.helpCalendar.eventStartDate = moment(startDate + ' ' + startTime).utc().format().toString();
      if (this.endDate) {
        const endTime = this.endCurrentTime + ':00';
        this.helpCalendar.eventEndDate = moment(endDate + ' ' + endTime).utc().format().toString();
        this.helpCalendar.isAllDay = false;
      } else {
        this.helpCalendar.eventEndDate = this.helpCalendar.eventStartDate;
      }
      if ((new Date(this.helpCalendar.eventEndDate) < new Date(this.helpCalendar.eventStartDate))) {
        this.isEndTimeLessThanStartTime = true;
      } else {
        this.isEndTimeLessThanStartTime = false;
      }
    });
    this.editEventForm.controls.endTime.valueChanges.subscribe(() => {
      this.helpCalendar = <IHelpCalendar>this.editEventForm.value;
      const startTime = this.startCurrentTime + ':00';
      this.helpCalendar.eventStartDate = moment(startDate + ' ' + startTime).utc().format().toString();
      if (this.endDate) {
        const endTime = this.endCurrentTime + ':00';
        this.helpCalendar.eventEndDate = moment(endDate + ' ' + endTime).utc().format().toString();
        this.helpCalendar.isAllDay = false;
      } else {
        this.helpCalendar.eventEndDate = this.helpCalendar.eventStartDate;
      }
      if ((new Date(this.helpCalendar.eventEndDate) < new Date(this.helpCalendar.eventStartDate))) {
        this.isEndTimeLessThanStartTime = true;
      } else {
        this.isEndTimeLessThanStartTime = false;
      }
    });
    this.editEventForm.controls.endTime.valueChanges.subscribe(() => {
      this.helpCalendar = <IHelpCalendar>this.editEventForm.value;
      const startTime = this.startCurrentTime + ':00';
      this.helpCalendar.eventStartDate = moment(startDate + ' ' + startTime).utc().format().toString();
      if (this.endDate) {
        const endTime = this.endCurrentTime + ':00';
        this.helpCalendar.eventEndDate = moment(endDate + ' ' + endTime).utc().format().toString();
        this.helpCalendar.isAllDay = false;
      } else {
        this.helpCalendar.eventEndDate = this.helpCalendar.eventStartDate;
      }
      if (((new Date(new Date(this.helpCalendar.eventStartDate).setSeconds(1)) < new Date(new Date().setSeconds(0)))
        && (new Date(new Date().setSeconds(0)) < this.startDate && this.endDate > (new Date(new Date().setSeconds(0)))))
        || new Date(new Date(this.helpCalendar.eventEndDate).setSeconds(1)) < new Date(new Date().setSeconds(0))) {
        this.isStartDateLessThanCurrentTime = true;
      } else {
        this.isStartDateLessThanCurrentTime = false;
      }
    });
    this.editEventForm.controls.startTime.valueChanges.subscribe(() => {
      this.helpCalendar = <IHelpCalendar>this.editEventForm.value;
      const startTime = this.startCurrentTime + ':00';
      this.helpCalendar.eventStartDate = moment(startDate + ' ' + startTime).utc().format().toString();
      if (this.endDate) {
        const endTime = this.endCurrentTime + ':00';
        this.helpCalendar.eventEndDate = moment(endDate + ' ' + endTime).utc().format().toString();
        this.helpCalendar.isAllDay = false;
      } else {
        this.helpCalendar.eventEndDate = this.helpCalendar.eventStartDate;
      }
      if (((new Date(new Date(this.helpCalendar.eventStartDate).setSeconds(1)) < new Date(new Date().setSeconds(0)))
        && (new Date(new Date().setSeconds(0)) < this.startDate && this.endDate > (new Date(new Date().setSeconds(0)))))
        || new Date(new Date(this.helpCalendar.eventEndDate).setSeconds(1)) < new Date(new Date().setSeconds(0))) {
        this.isStartDateLessThanCurrentTime = true;
      } else {
        this.isStartDateLessThanCurrentTime = false;
      }
    });
  }
}
