<div *ngIf="showEditButton" class="container editclosewrapEmpAbt">
    <div class="col-md-12">
        <div class="td-actions text-right">
            <button type="button" rel="tooltip" [ngClass]="enableReset ? '' : 'btn-disabled cursor-default' " (click)="reset()"
                class="btn btn-danger btn-round btn-fab btn-sm" title="">
                <i class="material-icons">close</i>
                <div class="ripple-container"></div>
            </button>
            <button type="button" rel="tooltip" [ngClass]="enableEdit ? '' : 'btn-disabled cursor-default' " (click)="edit()"
                class="btn btn-warning btn-round btn-fab btn-sm" title="">
                <i class="material-icons">edit</i>
                <div class="ripple-container"></div>
            </button>
            <button type="button" rel="tooltip" [ngClass]="enableSave ? '' : 'btn-disabled cursor-default' " (click)="save()"
                class="btn btn-success btn-round btn-fab btn-sm" title="">
                <i class="material-icons">check</i>
                <div class="ripple-container"></div>
            </button>
        </div>
    </div>
</div>
<div *ngIf="!showEditButton" class="container editclosewrapEmpAbt">
    <div class="col-md-12">
        <div class="td-actions text-right">
            <button type="button" rel="tooltip" [ngClass]="enableResetForNonEdit ? '' : 'btn-disabled cursor-default' "
                (click)="resetForNonEdit()" class="btn btn-danger btn-round btn-fab btn-sm" title="">
                <i class="material-icons">close</i>
                <div class="ripple-container"></div>
            </button>
            <button type="button" rel="tooltip" [ngClass]="enableSaveForNonEdit ? '' : 'btn-disabled cursor-default' "
                (click)="saveForNonEdit()" class="btn btn-success btn-round btn-fab btn-sm" title="">
                <i class="material-icons">check</i>
                <div class="ripple-container"></div>
            </button>
        </div>
    </div>
</div>
