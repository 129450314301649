import { FormGroup } from '@angular/forms';

export class ApplicationHelper {
  public static enableOrDisableFormControls(form: FormGroup, enableFormControl: boolean) {
    Object.keys(form.controls).forEach(key => {
      if (enableFormControl) {
        form.get(key).disable();
      } else {
        form.get(key).enable();
      }
    });
  }
  public static markFormGroupControlsAsTouched(formGroup: FormGroup) {

    Object.keys(formGroup.controls).forEach(control => {
      if (formGroup.get(control).value === undefined || formGroup.get(control).value === '') {
        formGroup.get(control).markAsTouched();
      }
    });
  }
}
