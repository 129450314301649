import { Injectable } from '@angular/core';

import appStaticMessagesData from '../../../assets/i18n/en.json';

@Injectable({
  providedIn: 'root',
})
export class AppMessagesService {

  appStaticMessages: any;

  constructor() {
    this.appStaticMessages = appStaticMessagesData;
  }
  customRequiredMessages(fieldName: string) {
    return this.appStaticMessages.AppMessageService.customRequiredMessages + fieldName + '.';
  }
  personalRequiredMessages(fieldName: string) {
    return this.appStaticMessages.AppMessageService.personalRequiredMessages + fieldName + '.';
  }
  customSelector(fieldName: string) {
    return this.appStaticMessages.AppMessageService.customSelector + fieldName + '.';
  }
  customValidator(fieldName: string) {
    return this.appStaticMessages.AppMessageService.customValidator + fieldName + '.';
  }
  minLengthRequired(fieldName: string, minLength: number) {
    return this.appStaticMessages.AppMessageService.minimum + fieldName
      + this.appStaticMessages.AppMessageService.lenghtShouldBe + minLength + '.';
  }
  maxLengthRequired(fieldName: string, maxLength: number) {
    return this.appStaticMessages.AppMessageService.maximum + fieldName
      + this.appStaticMessages.AppMessageService.lenghtShouldBe + maxLength + '.';
  }
}
