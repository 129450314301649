import { AfterViewChecked, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

import { AppMessagesService } from '../../services/app-messages.service';
import updates from './../../../../assets/i18n/updates.json';
import { SecurityService } from './../../../services/security.service';

@Component({
  selector: 'app-updates-dialog',
  templateUrl: './updates-dialog.component.html',
  styleUrls: ['./updates-dialog.component.scss']
})
export class UpdatesDialogComponent implements OnInit, AfterViewChecked {

  @ViewChild('updatesPS', { static: false }) perfectScroll: PerfectScrollbarComponent;
  updates: any;

  isSuperAdministrator: boolean;
  isPayManager: boolean;
  isHelpdeskManager: boolean;
  isHelpdeskAssistant: boolean;
  showScrollTop: boolean;

  isReganto: boolean;
  isWhatsNew: boolean;
  isLastStep: boolean;
  stepsCompleted: any;

  totalSteps = ['1', '2', '3', '4', '5'];
  skipedSteps = [];
  status = '';
  isInitialized = false;

  constructor(
    public appMessagesService: AppMessagesService,
    public dialogRef: MatDialogRef<UpdatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private securityService: SecurityService
  ) {
    this.updates = updates;
    this.stepsCompleted = this.dialogData.completedSteps && JSON.parse(this.dialogData.completedSteps)[0].CompletedSteps.split(',');
  }

  checker = (arr, target) => target.every(v => arr.includes(v));

  ngOnInit() {
    this.isSuperAdministrator = this.securityService.securityObject.claims.superAdministrator &&
      this.securityService.securityObject.claims.superAdministrator.isActive;
    this.isPayManager = this.securityService.securityObject.claims.payManager &&
      this.securityService.securityObject.claims.payManager.isActive;
    this.isHelpdeskManager = this.securityService.securityObject.claims.helpDeskManager &&
      this.securityService.securityObject.claims.helpDeskManager.isActive;
    this.isHelpdeskAssistant = this.securityService.securityObject.claims.helpDeskAdministrator &&
      this.securityService.securityObject.claims.helpDeskAdministrator.isActive;
    this.isInitialized = true;
  }

  checkIfPermissions(roles, permission, step, actionRequired) {
    if (!actionRequired && !this.skipedSteps.includes(step)) {
      this.skipedSteps.push(step);
      this.securityService.GetReleaseNotification(this.updates.version, step, 'NA').subscribe(_response => {
      });
    }
    if (this.securityService.securityObject.claims) {
      if (roles.includes(1) && this.isSuperAdministrator &&
        this.getClaims(this.securityService.securityObject.claims.superAdministrator.roleMenu.childMenus, permission)) {
        return true;
      }
      if (roles.includes(2) && this.isPayManager &&
        this.getClaims(this.securityService.securityObject.claims.payManager.roleMenu.childMenus, permission)) {
        return true;
      }
      if (roles.includes(3) && this.isHelpdeskManager &&
        this.getClaims(this.securityService.securityObject.claims.helpDeskManager.roleMenu.childMenus, permission)) {
        return true;
      }
      if (roles.includes(4) && this.isHelpdeskAssistant &&
        this.getClaims(this.securityService.securityObject.claims.helpDeskAdministrator.roleMenu.childMenus, permission)) {
        return true;
      }
      if (roles.includes(5) && this.getClaims(this.securityService.securityObject.claims.employee.roleMenu.childMenus, permission)) {
        return true;
      }
    }
    if (!this.skipedSteps.includes(step)) {
      this.skipedSteps.push(step);
      this.securityService.GetReleaseNotification(this.updates.version, step, 'UA').subscribe(_response => {
      });
    }
    return false;
  }

  getClaims(rolePermisions, validatePermissions) {
    const permissions = [];
    rolePermisions.forEach(x => permissions.push(x.abbreviation));
    return permissions.some(r => validatePermissions.includes(r));
  }

  ngAfterViewChecked() {
    if (this.isInitialized) {
      setTimeout(() => {
        this.scrollToTop(0);
        this.isInitialized = false;
      }, 100);
    }
    this.onUpdatesScroll();
  }

  onUpdatesScroll() {
    const scrollAt = this.perfectScroll.directiveRef.position(true).y.toString();
    if (parseFloat(scrollAt) > 200) {
      this.showScrollTop = true;
      if (parseFloat(scrollAt) > 399) {
        this.isReganto = false;
        this.isWhatsNew = true;
      }
      this.isLastStep = false;
      if (parseFloat(scrollAt) > 2000) {
        this.isLastStep = true;
        this.isReganto = false;
        this.isWhatsNew = false;
      }
    } else {
      this.isReganto = true;
      this.isWhatsNew = false;
      this.showScrollTop = false;
    }
    this.perfectScroll.directiveRef.update();
  }

  scrollToTop(delay = 2000) {
    this.perfectScroll.directiveRef.scrollToY(0, delay);
  }

  performUpdate(step) {
    switch (step) {
      case 1:
        this.close(step);
        break;
    }
  }

  close(step = 0) {
    this.securityService.GetReleaseNotification(this.updates.version, step)
      .subscribe(_response => {
        if (step === 1) {
          _response.menu = 'on';
        }
        if (step === -1) {
          _response.manual = 'on';
        }
        if (step === 6) {
          _response.status = 'Success';
          _response.severity = null;
        }
        this.dialogRef.close(_response);
      });
  }

  save() {
    if (this.isLastStep) {
      this.stepsCompleted.push('6');
      this.close(6);
    }
  }

  moveToTab1() {
    this.perfectScroll.directiveRef.scrollToY(0);
  }

  moveToTab2() {
    this.perfectScroll.directiveRef.scrollToY(400);
  }

  moveToTab3() {
    this.perfectScroll.directiveRef.scrollToY(2010);
  }
}
