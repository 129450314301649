<div class="prev_btn">
  <div class="PopCloseIcon" (click)="close(-1)"><i class="material-icons">close</i></div>
</div>
<nav class="navbar navbar-expand-lg navbar-dark nav-custom">
  <div class="container">
    <div class="collapse navbar-collapse" id="probootstrap-navbar">
      <ul class="navbar-nav">
        <li class="nav-item"><a class="nav-link" [ngClass]="isReganto?'nav-link-active':''"
            (click)="moveToTab1()">What's
            new in reganto</a>
        </li>
        <li class="nav-item"><a class="nav-link" [ngClass]="isWhatsNew?'nav-link-active':''"
            (click)="moveToTab2()">What's New</a></li>
        <li class="nav-item"><a class="nav-link" [ngClass]="!isReganto && !isWhatsNew?'nav-link-active':''"
            (click)="moveToTab3()">About Release</a></li>
      </ul>
    </div>
    <div class="navbar-brand">
      <img src="../../../../assets/img/popIcons/logo.svg" alt="logo">
    </div>
  </div>
</nav>
<div class="container-fluid pr10 pt10 pb50">
  <perfect-scrollbar class="perfectScroll_custom customScrollbar" #updatesPS>
    <div class="seccard">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 col-lg-6 ml-auto d-flex align-items-center mt-4 mt-md-0">
            <div>
              <h2 class="font-weight-bold font16">You asked. We listened</h2>
              <p class="font13">
                We have added new features that will improve your experience in using Reganto. Please review and update
                the app which will only take a few minutes to complete.
              </p>
            </div>
          </div>
          <div class=" col-md-6 col-lg-6 text-right">
            <div>
              <img alt="Web Studio" class="img-fluid" src="../../../../assets/img/popIcons/img0.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END nav -->
    <ng-container *ngFor="let update of updates.updates;let i=index;">
      <div class="secdiv" id="scrollSection" *ngIf="update.hasImage; else bulleted">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 d-flex align-items-center mt-4 mt-md-0">
              <div>
                <h4 class="font-weight-bold font12 subhead">{{update.rolesText}}</h4>
                <h2 class="font-weight-bold font16">{{update.heading}}</h2>
                <p class="font13">{{update.description}}</p>
                <button mat-raised-button
                  [ngClass]="stepsCompleted?.includes((i+1).toString()) && update.resultRequired ? 'cursor-default' : 'cursor-pointer'"
                  [class]="stepsCompleted?.includes((i+1).toString()) && update.resultRequired ? update.resultClass:update.btnClass"
                  *ngIf="checkIfPermissions(update.roles,update.permission,(i+1),update.actionRequired) && update.actionRequired"
                  (click)="performUpdate(i+1)">{{stepsCompleted?.includes((i+1).toString()) &&
                  update.resultRequired ? update.resultText : update.btnText}}</button>
                <span class="alert-icon"
                  *ngIf="update.actionMandatory && dialogData.status === 'InProgress' && !stepsCompleted?.includes((i+1).toString())">
                  <img src="../.././assets/img/popIcons/alert.svg" class="svgclass30" alt='alert-icon' />
                </span>
              </div>
            </div>
            <div class="col-md-6 text-right">
              <div class="images">
                <img class="img1" [src]="update.imageUrl" alt="logo">
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #bulleted>
        <div class="arrows_div secdiv" id="scrollSection">
          <div class="container-fluid">
            <div class="row align-items-center PlanDetails">
              <div class="col-md-10 col-lg-10">
                <div class="title text-left">{{update.heading}}</div>
                <div class="points">
                  <ul>
                    <li *ngFor="let list of update.features">
                      <div class="font500 dataText">{{list.title}}</div>
                      <div class="dataText" *ngFor="let item of list.description">{{item}}</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <div class="secdiv section_bottom" id="scrollSection">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 d-flex align-items-center mb-4">
            <div>
              <h2 class="font-weight-bold font16">Reganto Releases</h2>
              <p class="font13">
                Our Product team at Reganto is constantly looking at ways to improve your experience with the app. We
                will keep your app regularly updated with new features and functionality that we believe will enhance
                your experience as a user.
              </p>
            </div>
          </div>

          <div class="col-md-12 d-flex align-items-center mb-4">
            <div>
              <h2 class="font-weight-bold font16">Your experience is important to us</h2>
              <p class="font13">
                Reganto manages an upgrade process that aims to keep your app at the latest release. All releases will
                come with a step-by-step guide assisting users for a smooth and seamless upgrade to be completed. These
                releases will inform the user about the changes, whether its new functionality or fixing areas we are
                improving for you.
              </p>
            </div>
          </div>

          <div class="col-md-12 d-flex align-items-center">
            <div>
              <h2 class="font-weight-bold font16">Actions to take</h2>
              <p class="font13">
                During the upgrade, some features may require that you perform certain tasks to complete and activate
                these. Just follow the steps in the timely manner to complete this successfully.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</div>
<div class="container-fluid"
  style="position: absolute; bottom: 0px; height: 40px; background-color:#fff; box-shadow:4px 0px 10px 0rem #2a3f4129;">
  <div class="row pt10 pb10">
    <div class="col-md-12">
      <div class="checkbox">
        <div class="form-check mb0">
          <label class="form-check-label"
            [ngClass]="isLastStep && !stepsCompleted?.includes('6') ?'cursor-pointer':'cursor-default'">
            <input class="form-check-input" type="checkbox"
              [disabled]="!isLastStep || stepsCompleted?.includes('6')"
              [checked]="stepsCompleted?.includes('6')" (click)="save()">
              I have viewed the new features and updates. Don't show this notification again.
            <span class="form-check-sign"
              [ngClass]="isLastStep && !stepsCompleted?.includes('6') ?'cursor-pointer':'cursor-default'">
              <span class="check"></span>
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="moveTOTop" (click)="scrollToTop()" *ngIf="showScrollTop">
  <img src="../.././assets/img/popIcons/backToTop.svg" />
</div>
